import './style.css';

const TermsPage = () => {
    return (
        <div className="section-content-wrapper terms-container">
            <div id="ContentArea_C001_Col00" className="sf_colsIn" data-sf-element="Banner Area" data-placeholder-label="Banner Area">
                <div className="backgroung-img-block">
                    <div className="content">
                        <h1>Terms & Conditions</h1>
                        <h2></h2>
                    </div>
                </div>
            </div>
            <div className="main-content">
                <div>
                    <h2><strong>Confirmation</strong></h2>
                    <p>A credit card is required for all reservations prior to confirmation: a corporate letter of responsibility may be held in lieu of a cash deposit.</p>

                </div>
                <div>
                    <h2><strong>Cancellations</strong></h2>
                    <p>Cancellations and fees depend on location. &nbsp;Please contact your Housing Specialist for details.</p>
                </div>
                <div>
                    <h2><strong>Extending or shortening your stay</strong></h2>
                    <p><em>Extensions</em>: Please notify us as soon as possible. A credit adjustment will be made if your actual stay results in a lower daily rate.</p>
                    <p><em>Early departures:</em> A 3-day notice is required. We will make a debit adjustment if early departure results in a higher daily rate and will refund any prepaid, unused rental charges over the required notice period.</p>

                </div>
                <div>
                    <h2><strong>Rates</strong></h2>
                    <p>Oasis offers special discounts for groups and guests staying more than 30 nights. Consider the quality of the accommodations and the level of service and support we provide, and we think you'll agree - Oasis offers a first-rate value.</p>
                    <p>Our rates vary per night, depending on location, length of stay, seasonal availability, and features offered in your accommodations.</p>

                </div>
            </div>
        </div>
    );
}

export default TermsPage;