import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import {debounce} from 'lodash'
//components
import Wrapper from '../../assets/controls/google-wrapper'
import CustomDropdown from '../../assets/elements/input/custom-dropdown-alt'
import SearchLocation from '../../assets/elements/input/search-location-alt'

// import DropdownMultiple from '../../assets/controls/dropdown-multiple'
import CustomDropdownMultiple from '../../assets/elements/input/custom-dropdown-multiple-alt'
import CheckboxMultiple from '../../assets/controls/checkbox-multiple'
import Checkbox from '../../assets/elements/checkbox'

import ActionButton from '../../assets/elements/button/action-button'

import SectionSubtitle from '../../assets/elements/section-subtitle'
// import Accordion from '../../assets/elements/accordion/accordion'
import ValidationGroup from 'devextreme-react/validation-group';

//constans
import { FLOORS, MILES } from '../../../constant/dropdown'
import { BUILDING_TYPES } from '../../../constant/building-types'
import { DEFAULT_VALUES as PAGINATION } from '../../../constant/pagination'

//actions
import { changeBuildingTypes, changePetsFriendly, changeLocation, changeFloor, changeMiles, clearData, clearPages,changeLoading } from '../../../actions/property-search'
import { setLocation } from '../../../actions/property-request'

//utils
import { getSearchProperties,getSearchPropertiesByPlaceId } from '../../../utils/search-properties'

//styles
import '../styles/property-filter.css'
import { GeoAltFill } from 'react-bootstrap-icons';

//images
import LocationIcon from '../../../images/location.png'
import RadiusIcon from '../../../images/location_radius.png'
import FloorPlanIcon from '../../../images/floor plan.png'
import BuildingIcon from '../../../images/request-icons/building.svg'
import FilterIcon from '../../../images/filter.svg'

const PropertyFilter = ({ clearLocationField,floorPlanTypes,location,petsFriendly, changePetsFriendly, changeBuildingTypes, changeLocation, changeFloor, changeMiles, floor, miles, buildingTypes, changeLoading, setLocation,setClearLocationField,clearPages,mode,setMode }) => {
  const [error, setError] = useState(false)
  const [activeFilter, setActiveFilter] = useState(false)
  const getProperties = e => {
    e && e.preventDefault()
    // if (geometry.latitude && geometry.longitude && location.id) {
    //   clearPages()
    //   getSearchProperties({
    //     skip: PAGINATION.skip,
    //     take: PAGINATION.take
    //   })
    //   setError(false)
    // }
    // else {
    //   setError(true)
    // }
  }
  const getPetFriendly = (id, value) => {
    changePetsFriendly(value)
  }

  // const getPropertiesDebounce = debounce(_ => {
  //   location.id && getSearchPropertiesByPlaceId(location.id,PAGINATION.skip,PAGINATION.take)
  // },3000)


  // useEffect(() => {
  //   location.id && getSearchPropertiesByPlaceId(location.id,PAGINATION.skip,PAGINATION.take)
  // }, [location.id])
  useEffect(() => {
    // getPropertiesDebounce()   
    if(location.id || mode.type === 'map')
    {
      changeLoading(true)
      clearPages()
    }
    const timerId = setTimeout(() => {
      if(location.id || mode.type === 'map')
      {
        getSearchPropertiesByPlaceId(location.id,PAGINATION.skip,PAGINATION.take,true)
      }
    },3000)
    if(!location.id && mode.type !== 'map')
    {
      changeLoading(false)
    }
    return () => clearTimeout(timerId)
  }, [location.id,buildingTypes,floor,miles,petsFriendly,mode])

  
  return (
    <div className='property-filter'>
      <div className='property-filter__title'>
        <SectionSubtitle>Filter</SectionSubtitle>
      </div>
      <div className='property-filter__content'>
        <div className={`property-filter__content__button ${activeFilter ? '' : 'property-filter__content__button--inactive'}`} onClick={_=> setActiveFilter(!activeFilter)}>
          {activeFilter ? <>X Filter</> : <img src={FilterIcon} alt="" />}
        </div>
        <form onSubmit={getProperties} className={`property-filter__content__form ${activeFilter ? 'property-filter__content__form--active':'property-filter__content__form--inactive'}`}>
          <ValidationGroup>
            <div className='property-filter__content__form__fields'>
              <div className='property-filter__content__form__fields__location'>
                {/* {mode.type === 'map' && <div className='property-filter__content__form__fields__location__placeholder-layout' onClick={_=>setMode('field')}><span>SEARCH FROM MAP VIEW</span></div>} */}
                <div className='property-filter__content__form__fields__location__placeholder-field'>
                  <Wrapper>
                    <SearchLocation
                      // onChange={changeLocation}
                      inputId="propertyFilterSearchLocation"
                      location={location}
                      onChange={(data) => {
                        changeLocation(data)
                        setLocation(data)
                      }}
                      // onChangeGeometry={changeGeometry}
                      classCustom='filter__content__section1__location'
                      placeholder={mode.type === 'map' ? 'Map View' : 'Enter a location'}
                      mode={mode}
                      setMode={setMode}
                      // label='Location'
                      // groupButton={{ direction: 'left', name: <GeoAltFill /> }}
                      requiredValue={(mode?.type === 'map') ? false : true} 
                      inputIcon={LocationIcon}
                      clearField={clearLocationField}
                      setClearField={setClearLocationField}
                      noBorders={true}
                      />
                  </Wrapper>
                </div>
              </div>
              
              <div className='property-filter__content__form__fields__radius'>
                <CustomDropdown
                  inputIcon={RadiusIcon}
                  values={MILES}
                  value={miles}
                  setValue={changeMiles} 
                  noBorders={true}
                  disable={mode.type === 'map' ? true : false}
                />
              </div>
              <div className='property-filter__content__form__fields__floor'>
                {floorPlanTypes.length > 0 && 
                  <CustomDropdownMultiple
                    inputIcon={FloorPlanIcon}
                    values={floorPlanTypes}
                    value={floor}
                    onChangeValue={changeFloor} 
                    selectAllText="All Floor Plans"
                    noBorders={true}
                    placeholder="Choose Floorplan"
                    />
                }
              </div>
              <div className='property-filter__content__form__fields__building'>
                <CustomDropdownMultiple
                  inputIcon={BuildingIcon}
                  values={BUILDING_TYPES}
                  value={buildingTypes}
                  onChangeValue={changeBuildingTypes} 
                  selectAllText="All types of properties"
                  noBorders={true}
                  placeholder="Choose Building type"
                />
              </div>
              <div className='property-filter__content__form__fields__pets'>
                <Checkbox
                  id='petFriendlyCheck'
                  checkValue={petsFriendly}
                  onChange={getPetFriendly}
                  label='Pets Friendly' 
                />
              </div>
            </div>
          </ValidationGroup>
        </form>
      </div>
      
    </div>
  )
}



function mapStateToProps({ search,dictionary }) {
  return {
    buildingTypes: search.buildingTypes,
    // geometry: search.geometry,
    location: search.location,
    floor: search.floor,
    miles: search.miles,
    petsFriendly: search.petsFriendly,
    floorPlanTypes: dictionary.floorPlanTypes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    //changeCheckIn: data => dispatch(changeCheckIn(data)),
    //changeCheckOut: data => dispatch(changeCheckOut(data)),
    changePetsFriendly: data => dispatch(changePetsFriendly(data)),
    changeLoading: value => dispatch(changeLoading(value)),
    changeLocation: data => dispatch(changeLocation(data)),
    setLocation: data => dispatch(setLocation(data)),
    changeFloor: data => dispatch(changeFloor(data)),
    changeMiles: data => dispatch(changeMiles(data)),
    changeBuildingTypes: data => dispatch(changeBuildingTypes(data)),
    // changeGeometry: data => dispatch(changeGeometry(data)),
    clearData: () => dispatch(clearData()),
    clearPages: () => dispatch(clearPages())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyFilter)