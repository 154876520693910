import {connect} from 'react-redux'

//components
import CustomRequirements from '../assets/controls/custom-requirements'

//devextreme
import notify from 'devextreme/ui/notify';

//utils
import {getDetailsByGoogle} from '../../utils/google-search'
import {getPropertiesNoMatch} from '../../utils/my-basket'

//actions
import {
  setDataToCheck,
  setLocation,
  setMiles,
  setBudgetMax,
  setBudgetMin,
  setQtyAdults,
  setQtyChildren,
  setIsPets,
  setQtyPets,
  setMoveIn,
  setMoveOut,
  setDateRange,
  setCurrencyError,
  setCurrencyType,
  setBuildingTypes,
  setFloorPlan,
  setNotes,
  setGuestUser,
  setSelectedGuestId,
  setGuestPhone,
  setGuestEmail,
  setIsGuestCheckboxSelected,
  setIsExistingUser,
  setSelectedDataAmenities,
  setDataKeysAmenities,
  setIsGuestPhoneValidMessage,
  setIsGuestPhoneValidState,
  setGuestUserEmpty,
  setClearLocationField,
  setPetDetails,
  setMaidService,
  setParkingSlots
} from '../../actions/my-basket'

//styles
import './styles/my-basket-requirements.css'

const Requirements = ({
  basket,
  clearLocationField,
  refListProperties,
  currencyTypes,
  floorPlanTypes,
  amenities,
  fields,
  setDataToCheck,
  setLocation,
  setMiles,
  setBudgetMin,
  setBudgetMax,
  setQtyAdults,
  setQtyChildren,
  setIsPets,
  setQtyPets,
  setMoveIn,
  setMoveOut,
  setDateRange,
  setCurrencyError,
  setCurrencyType,
  setBuildingTypes,
  setFloorPlan,
  setNotes,
  setGuestUser,
  setGuestPhone,
  setGuestEmail,
  setIsGuestCheckboxSelected,
  setSelectedDataAmenities,
  setDataKeysAmenities,
  setIsGuestPhoneValidMessage,
  setIsGuestPhoneValidState,
  setGuestUserEmpty,
  setIsExistingUser,
  setSelectedGuestId,
  setClearLocationField,
  setPetDetails,
  setParkingSlots,
  setMaidService
}) => {
  const {
    location,
    isPets,
    buildingTypes,
    floorPlan,
    miles
  } = fields


  const checkProperties  = async _ => {
    // e.preventDefault()
    if(basket?.data?.length  > 0)
    {
      let address
      if(location.id)
      {
       address = await getDetailsByGoogle(location.id)
      }
      if ((address?.latitude && address?.longitude) || (buildingTypes.length > 0 || floorPlan.length > 0 || isPets))
      {
          window.scrollTo(0, refListProperties.current.offsetTop - 90)
          setDataToCheck(address.latitude , address.longitude , miles , buildingTypes.includes('all') ? [] : buildingTypes , floorPlan, isPets)
          // getPropertiesNoMatch()
      }
      else
      {
        if(!address?.isValid && address?.message)
          notify(address.message, "info" , 6000);
        else
          notify('To check properties you need one of the following fields: location, floor plan, type of properties or pets.', "info" , 6000);
      }
    }
    else{
      notify("There isn't properties to check", "info" , 6000);
    }
    
  }  

  return (
    <CustomRequirements 
      clearLocationField = {clearLocationField}
      currencyTypes = {currencyTypes}
      floorPlanTypes = {floorPlanTypes}
      amenities = {amenities}
      fields = {fields}
      setLocation = {setLocation}
      setMiles = {setMiles}
      setBudgetMin = {setBudgetMin}
      setBudgetMax = {setBudgetMax}
      setQtyAdults = {setQtyAdults}
      setQtyChildren = {setQtyChildren}
      setIsPets = {setIsPets}
      setQtyPets = {setQtyPets}
      setMoveIn = {setMoveIn}
      setMoveOut = {setMoveOut}
      setDateRange = {setDateRange}
      setCurrencyError = {setCurrencyError}
      setCurrencyType = {setCurrencyType}
      setBuildingTypes = {setBuildingTypes}
      setFloorPlan = {setFloorPlan}
      setNotes = {setNotes}
      setGuestUser = {setGuestUser}
      setGuestPhone = {setGuestPhone}
      setGuestEmail = {setGuestEmail}
      setIsGuestCheckboxSelected = {setIsGuestCheckboxSelected}
      setSelectedDataAmenities = {setSelectedDataAmenities}
      setDataKeysAmenities = {setDataKeysAmenities}
      setIsGuestPhoneValidMessage = {setIsGuestPhoneValidMessage}
      setIsGuestPhoneValidState = {setIsGuestPhoneValidState}
      setGuestUserEmpty = {setGuestUserEmpty}
      setIsExistingUser = {setIsExistingUser}
      setSelectedGuestId = {setSelectedGuestId}
      setClearLocationField = {setClearLocationField}
      setPetDetails = {setPetDetails}
      setMaidService={setMaidService}
      setParkingSlots={setParkingSlots}
      action = {checkProperties}
      disableSubmit={true}
      labelActionButton = 'Check Properties'
      />
  )
}
function mapStateToProps({ basket }) {
  return {
    basket: basket.basket,
    clearLocationField: basket.clearLocationField,
    fields: basket.fields
  };
}
function mapDispatchToProps( dispatch ) {
  return {
    setDataToCheck: (latitude, longitude, radius, propertyType,floor,pet) => dispatch(setDataToCheck(latitude, longitude, radius, propertyType,floor,pet)),
    setLocation: value => dispatch(setLocation(value)),
    setMiles: value => dispatch(setMiles(value)),
    setMaidService: value => dispatch(setMaidService(value)),
    setParkingSlots: value => dispatch(setParkingSlots(value)),
    setBudgetMax: value => dispatch(setBudgetMax(value)),
    setBudgetMin: value => dispatch(setBudgetMin(value)),
    setQtyAdults: value => dispatch(setQtyAdults(value)),
    setQtyChildren: value => dispatch(setQtyChildren(value)),

    setIsPets: value => dispatch(setIsPets(value)),
    setQtyPets: value => dispatch(setQtyPets(value)),
    setPetDetails: value => dispatch(setPetDetails(value)),

    setMoveIn: value => dispatch(setMoveIn(value)),
    setMoveOut: value => dispatch(setMoveOut(value)),
    setDateRange: value => dispatch(setDateRange(value)),

    setCurrencyError: value => dispatch(setCurrencyError(value)),
    setCurrencyType: value => dispatch(setCurrencyType(value)),

    setBuildingTypes: value => dispatch(setBuildingTypes(value)),
    setFloorPlan: value => dispatch(setFloorPlan(value)),

    setNotes: value => dispatch(setNotes(value)),

    setGuestUser: value => dispatch(setGuestUser(value)),
    setSelectedGuestId: value => dispatch(setSelectedGuestId(value)),
    setGuestUserEmpty: value => dispatch(setGuestUserEmpty(value)),

    setGuestPhone: value => dispatch(setGuestPhone(value)),
    setIsGuestPhoneValidMessage: value => dispatch(setIsGuestPhoneValidMessage(value)),
    setIsGuestPhoneValidState: value => dispatch(setIsGuestPhoneValidState(value)),

    setGuestEmail: value => dispatch(setGuestEmail(value)),

    setIsExistingUser: value => dispatch(setIsExistingUser(value)),
    setIsGuestCheckboxSelected: value => dispatch(setIsGuestCheckboxSelected(value)),

    setSelectedDataAmenities: value => dispatch(setSelectedDataAmenities(value)),
    setDataKeysAmenities: value => dispatch(setDataKeysAmenities(value)),
    setClearLocationField: value => dispatch(setClearLocationField(value))
  };
}
export default connect(mapStateToProps,mapDispatchToProps) (Requirements)