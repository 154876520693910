import {useCallback,memo,useState} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-input-alt.css'
import '../../styles/input/custom-email-alt.css'


//components
import { TextBox } from 'devextreme-react/text-box';

import {
    Validator,
    RequiredRule,
    EmailRule
  } from 'devextreme-react/validator';

const CustomEmail = ({label, value, setValue,requiredValue,isDisabled,reference,inputIcon,placeholder,iconSize}) => {
  const [isFocus, setIsFocus] = useState(false)
  const onChangeValue = useCallback((data) => {
      setValue(data)
  }, []);
  const onKeyValidation = useCallback(e=>{
    setIsFocus(true)
    let regex = /[<>]/
    regex.test(e.event.key) && e.event.preventDefault()
  },[])
  
  return (
    <div className='custom-input-alt'>
        {label && <div className='custom-input-alt__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>}
            <div className={`custom-input-alt__container ${inputIcon ? '' : 'custom-input-alt__container--noIcon'} ${requiredValue ? (isFocus ? 'custom-email-alt__focus-in' : 'custom-email-alt__focus-out'): ''}`}>
              <TextBox
                value={value}
                disabled={isDisabled}
                valueChangeEvent="input"
                onValueChange={onChangeValue}
                onKeyDown={onKeyValidation}
                placeholder={placeholder}
                onFocusIn={_=>setIsFocus(true)}
                onFocusOut={_=>setIsFocus(false)}
                onEnterKey={_=>setIsFocus(false)}
              >
                {requiredValue &&
                  <Validator
                    ref={reference}
                  >
                    <RequiredRule message={`${label ? label : placeholder} is required`} />
                    <EmailRule message="The email must have a correct format" />
                  </Validator>
                }
              </TextBox>
              <div className='custom-input-alt__container__icon'>{iconSize ? <img src={inputIcon} width={iconSize} height={iconSize}/> : <img src={inputIcon}/>}</div>
          </div>
      </div>
  )
}

export default memo(CustomEmail)