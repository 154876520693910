import {
    updateUserData,
    setProfileImage,
    setLoading,
    setAccountUpdated,
    setGuests,
    setListGuests
} from "../actions/user-form";
import userManager from "./user-manager";
import baseService from "../services/base.service";
import { AuthorizationService } from "../services/authorization.service";
import store from "../store";

import {getUserId} from './user'

export const setIsAccountUpdated = (isAccountUpdated) => {
    store.dispatch(setAccountUpdated(isAccountUpdated));
};

export const setIsLoading = (isLoading) => {
    store.dispatch(setLoading(isLoading));
};

export const updateUserProfileData = (data) => {
    store.dispatch(updateUserData(data));
};

export const updateUserProfileImage = (data) => {
    store.dispatch(setProfileImage(data));
};

export const handleLogOut = ({ navigate, idToken }) => {
    AuthorizationService.logout();
    userManager.signoutRedirect({ "id_token_hint": idToken });
    navigate("/");
};

export const getGuests = _ => {
    try {
        // store.dispatch(setLoadingCareerListHistory(true))
      
        const userId =  store.getState().auth?.profile?.sub ? store.getState().auth.profile.sub : getUserId()
        const url = `api/v1/guestContact/GetUserContactList/${userId}`;
        baseService({
          method: 'GET',
          baseURL: process.env.REACT_APP_API_ENDPOINT,
          url: url,
          headers:{
            'Content-Type': 'application/json'
          },
        }).then(({data}) => {
          let formattedData =  data.map(item => {

            return {
                firstname:{
                    name: 'First Name',
                    value: item.firstName
                },
                lastname:{
                    name: 'Last Name',
                    value: item.lastName
                },
                phone:{
                    name: 'Phone',
                    value: item.phoneNumber
                },
                email:{
                    name: 'Email',
                    value: item.email
                }
            }
          })
          store.dispatch(setGuests(formattedData))
      }).finally(()=>{
        //   store.dispatch(setLoadingCareerListHistory(false))
      })
      .catch( error => {
        //   store.dispatch(setLoadingCareerListHistory(false))
          store.dispatch(setGuests([]))
      })
      
    } catch (error) {
    //   store.dispatch(setLoadingCareerListHistory(false))
      store.dispatch(setGuests([]))
    }
}

export const getListGuests = _ => {
  try {
      const userId =  store.getState().auth?.profile?.sub ? store.getState().auth.profile.sub : getUserId()
      const url = `api/v1/guestContact/GetUserContactList/${userId}`;
      baseService({
        method: 'GET',
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        url: url,
        headers:{
          'Content-Type': 'application/json'
        },
      }).then(({data}) => {
        store.dispatch(setListGuests(data))
    })
    .catch( error => {
      //   store.dispatch(setLoadingCareerListHistory(false))
        store.dispatch(setListGuests([]))
    })
    
  } catch (error) {
  //   store.dispatch(setLoadingCareerListHistory(false))
    store.dispatch(setListGuests([]))
  }
}