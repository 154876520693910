import { LoadIndicator } from 'devextreme-react/load-indicator';
import { connect } from 'react-redux'

function Spinner({ isVisible }) {
    return (
        <div>{
            isVisible ? <div className='load-indicator-panel'>
            <LoadIndicator id="load-indicator" height={60} width={60} /> 
            </div> : null}
        </div>
    );
};
function mapStateToProps(props) {
    return {
        isVisible: props.loadIndicator.isVisible
    };
}

export default connect(mapStateToProps, null)(Spinner)