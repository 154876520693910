import {
    SET_COMPANY,
    SET_FIRST_NAME,
    SET_LAST_NAME,
    SET_PHONE_NUMBER,
    SET_EMAIL_ADDRESS,
    SET_DESTINATION_CITY,
    SET_ACCOMMODATION_LEVEL,
    SET_PROPERTY_NAME,
    SET_NUMBER_OF_BEDROOMS,
    SET_MAXIMUM_COMMUTE_DISTANCE,
    SET_MAXIMUM_COMMUTE_DISTANCE_TYPE,
    SET_NIGHTLY_BUDGET,
    SET_BUDGET_CURRENCY_ID,
    SET_CHECK_IN_DATE,
    SET_CHECK_OUT_DATE,
    SET_DATES_IS_FLEXIBLE,
    SET_TOTAL_ADULTS,
    SET_TOTAL_CHILDREN,
    SET_SPECIAL_REQUIREMENTS,
    RESET_QUOTE_FORM,
    SET_LOCATION
} from "../constant/property-request";

export const setCompany = (company) => {
    return {
        type: SET_COMPANY,
        payload: company
    };
};

export const setFirstName = (firstName) => {
    return {
        type: SET_FIRST_NAME,
        payload: firstName
    };
};

export const setLastName = (lastName) => {
    return {
        type: SET_LAST_NAME,
        payload: lastName
    };
};

export const setPhoneNumber = (phoneNumber) => {
    return {
        type: SET_PHONE_NUMBER,
        payload: phoneNumber
    };
};

export const setEmailAddress = (emailAddress) => {
    return {
        type: SET_EMAIL_ADDRESS,
        payload: emailAddress
    };
};

export const setDestinationCity = (destinationCity) => {
    return {
        type: SET_DESTINATION_CITY,
        payload: destinationCity
    };
};

export const setAccommodationLevel = (accommodationLevel) => {
    return {
        type: SET_ACCOMMODATION_LEVEL,
        payload: accommodationLevel
    };
};

export const setPropertyName = (propertyName) => {
    return {
        type: SET_PROPERTY_NAME,
        payload: propertyName
    };
};

export const setNumberOfBedrooms = (numberOfBedrooms) => {
    return {
        type: SET_NUMBER_OF_BEDROOMS,
        payload: numberOfBedrooms
    };
};

export const setMaximumCommuteDistance = (maximumCommuteDistance) => {
    return {
        type: SET_MAXIMUM_COMMUTE_DISTANCE,
        payload: maximumCommuteDistance
    };
};

export const setMaximumCommuteDistanceType = (maximumCommuteDistanceType) => {
    return {
        type: SET_MAXIMUM_COMMUTE_DISTANCE_TYPE,
        payload: maximumCommuteDistanceType
    };
};

export const setNightlyBudget = (nightlyBudget) => {
    return {
        type: SET_NIGHTLY_BUDGET,
        payload: nightlyBudget
    };
};

export const setBudgetCurrencyId = (budgetCurrencyId) => {
    return {
        type: SET_BUDGET_CURRENCY_ID,
        payload: budgetCurrencyId
    };
};

export const setCheckInDate = (checkInDate) => {
    return {
        type: SET_CHECK_IN_DATE,
        payload: checkInDate
    };
};

export const setCheckOutDate = (checkOutDate) => {
    return {
        type: SET_CHECK_OUT_DATE,
        payload: checkOutDate
    };
};

export const setDatesIsFlexible = (datesIsFlexible) => {
    return {
        type: SET_DATES_IS_FLEXIBLE,
        payload: datesIsFlexible
    };
};

export const setTotalAdults = (totalAdults) => {
    return {
        type: SET_TOTAL_ADULTS,
        payload: totalAdults
    };
};

export const setTotalChildren = (totalChildren) => {
    return {
        type: SET_TOTAL_CHILDREN,
        payload: totalChildren
    };
};

export const setSpecialRequirements = (specialRequirements) => {
    return {
        type: SET_SPECIAL_REQUIREMENTS,
        payload: specialRequirements
    };
};

export const resetQuoteForm = () => {
    return {
        type: RESET_QUOTE_FORM,
        payload: {}
    };
};

export const setLocation = (location) => {
    return {
        type: SET_LOCATION,
        payload: location
    };
};