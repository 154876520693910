import {
    SET_COMPLETED_TRANSACTION_ITEMS
} from "../constant/transaction-integration";

const defaultState = {
    completedTransactionDict: {}
};

export default function transactionIntegrationReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_COMPLETED_TRANSACTION_ITEMS:
            return {
                ...state,
                completedTransactionDict: action.payload
            }
        default:
            return state;
    }
}