const {
    REACT_APP_STS_CLIENT_ID,
    REACT_APP_STS_AUTHORITY,
} = process.env;

const getIsAdmin = _ => {
        const user = JSON.parse(localStorage.getItem(`oidc.user:${REACT_APP_STS_AUTHORITY}:${REACT_APP_STS_CLIENT_ID}`));
        let effectiveData = user != null && new Date(user.expires_at * 1000) > new Date()
        let isAdmin = JSON.parse(localStorage.getItem('ODYSSEY.USER.AUTHENTICATION'))?.profile?.role === 'Odyssey.Administrator'
        return user != null && effectiveData && isAdmin
    }

export { getIsAdmin }