import {useNavigate} from 'react-router-dom'
import {connect} from 'react-redux'

import './styles/property-sent.css'

import {setDefault} from '../../actions/list-a-property'
//contants
import {PROPERTY_SENT} from '../../constant/list-a-property'
const PropertySent = ({setDefault}) => {
  const navigate = useNavigate()
  const navigateTo = route => {
    setDefault()
    navigate(route)

  }
  return (
    <div className='property-sent'>
        <div className='property-sent__title'>{PROPERTY_SENT.title}</div>
        <div className='property-sent__description'>{PROPERTY_SENT.description}</div>
        <div className='property-sent__buttonarea'>
          <span onClick={_=>navigateTo('/list-your-property')}>{PROPERTY_SENT.link.add_property}</span>
          <span onClick={_=>navigateTo('/')}>{PROPERTY_SENT.link.main}</span>
          <span onClick={_=>navigateTo('/list-your-property/become-a-partner')}>{PROPERTY_SENT.link.partner}</span>
        </div>
    </div>
  )
}
function mapDispatchToProps(dispatch){
  return{
    setDefault: () => dispatch(setDefault())
  }
}
export default connect(null,mapDispatchToProps)(PropertySent)