import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from 'react-router-dom';

import userManager from "../utils/user-manager";
import { AuthorizationService } from "../services/authorization.service";

import { receiveProfileData, receiveAccessToken, receiveRefreshToken, receiveIdToken } from "../actions/auth";

const AuthCallback = ({ profile, receiveTokens, receiveProfile }) => {
    const navigate = useNavigate();

    if (isEmpty(profile)) {
        userManager.signinRedirectCallback().then(async response => {
            await AuthorizationService.setUserAccessToken(response.access_token);
            receiveTokens(response.access_token, response.refresh_token, response.id_token);
            receiveProfile(response.profile);

            const redirectTo = response.state && response.state.redirectTo ? response.state.redirectTo : "/profile";
            navigate(redirectTo, { state: { redirectTo: redirectTo } });

        }).catch((err) => {
            console.warn("AuthCallback", err);
        });

        return <div className="section-content-wrapper">Redirecting...</div>;
    };
};

function mapStateToProps({ auth }) {
    return {
        profile: auth.profile
    };
}

function mapDispatchToProps(dispatch) {
    return {
        receiveProfile: data => dispatch(receiveProfileData(data)),
        receiveTokens: (accessToken, refreshToken, idToken) => {
            dispatch(receiveAccessToken(accessToken));
            dispatch(receiveRefreshToken(refreshToken));
            dispatch(receiveIdToken(idToken));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthCallback);
