import './styles/received-filter.css'

const RequestFilter = ({value,setValue}) => {
  return (
    <div className='requests-filter'>
        <span>Filter:</span>
        <span className={`requests-filter_item ${value === 'all' ? 'requests-filter_item--active' : 'requests-filter_item--inactive'}`} onClick={_ => setValue('all')}>Inbox</span>
        <span className={`requests-filter_item ${value === 'new' ? 'requests-filter_item--active' : 'requests-filter_item--inactive'}`} onClick={_ => setValue('new')}>New</span>
        <span className={`requests-filter_item ${value === 'viewed' ? 'requests-filter_item--active' : 'requests-filter_item--inactive'}`} onClick={_ => setValue('viewed')}>Viewed</span>
        <span className='requests-filter_separation'/>
        <span className={`requests-filter_item ${value === 'deleted' ? 'requests-filter_item--active' : 'requests-filter_item--inactive'}`} onClick={_ => setValue('deleted')}>Deleted</span>
    </div>
  )
}

export default RequestFilter