import { useState } from 'react'
import CustomTextarea from '../../elements/input/custom-textarea-alt'

const AccordionTextarea = ({label,labelSize,value,setValue,requiredValue,isDisabled}) => {
  const [isActive,setIsActive] = useState(false)
  return (
    <div className='accordion-textarea'>
      { !isActive ?
      <div className='accordion-textarea__title' style={{cursor:'pointer', color: '#008da9',width:'fit-content'}} onClick={_=>setIsActive(!isActive)}>+ {label}</div>
      :
      <CustomTextarea labelSize={labelSize} label={label} value={value} setValue={setValue} requiredValue={requiredValue} isDisabled={isDisabled} onClickLabel={_=>setIsActive(!isActive)} inputSize={60}/>
      }
    </div>
  )
}

export default AccordionTextarea