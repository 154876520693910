import {useState,useEffect,useRef} from 'react'
import {connect} from 'react-redux'
import {useNavigate} from 'react-router-dom'
//components
import ContentWrapper from '../assets/elements/content-wrapper'
import Title from '../assets/elements/section-title'
import Subtitle from '../assets/elements/section-subtitle'
import Requirements from './requirements'
import ListProperties from './list-properties'
import MessageBanner from '../assets/elements/custom-alert'
import ActionButton from '../assets/elements/button/action-button'
import SecondaryButton from '../assets/elements/button/secondary-action-button'
import Loading from '../assets/controls/loading'

//constant
import {PHONE} from '../../constant/messages'

//actions
import {
  setCurrencyError,
  setIsGuestPhoneValidMessage,
  setIsGuestPhoneValidState,
  setGuestUserEmpty,
  setClearLocationField
} from '../../actions/my-basket'

//utils
import {postSendRequest,getAllBasket,setDataToCheckDefault} from '../../utils/my-basket'
import {getDetailsByGoogle} from '../../utils/google-search'
//styles
import './styles/my-basket.css'

//devextreme
import {ValidationGroup} from 'devextreme-react/validation-group'

import notify from 'devextreme/ui/notify';

const MyBasketContent = ({noMatch,loading,loadingSendRequest,fields,setCurrencyError,setIsGuestPhoneValidMessage,setIsGuestPhoneValidState,setGuestUserEmpty,setClearLocationField}) => {
  const navigate = useNavigate()
  const [bannerActive, setBannerActive] = useState(true)
  const refRequirements = useRef(null)
  const refListProperties =  useRef(null)
  const refValidationGroup = useRef(null)
  useEffect(() => {
    getAllBasket()
    setClearLocationField(false)
    return () => {
      setDataToCheckDefault()
    }
  }, [])
  
  const onEditRequirements = e => {
    e.preventDefault()
    window.scrollTo(0, refRequirements.current.offsetTop - 90)
  }
  const onSendRequest = async(e) => {
    e.preventDefault()

    if(onValidation(e))
    {
      let address = await getDetailsByGoogle(fields.location.id)
      if(address.isValid)
      {
        let {isValid,message,...rest} = address
        if(await postSendRequest(rest)) 
          navigate('/my-requests')
      }
      else
      {
        notify(address.message, "error" , 3000);
        window.scrollTo(0, refRequirements.current.offsetTop - 90)
      }
    }
    
  }
  const onValidation = _ => {
    try {
      let isValid = true
      // isValid = e?.validationGroup?.validate()?.isValid
      isValid = refValidationGroup.current.instance.validate().isValid
      if((fields.budgetMin || fields.budgetMax) && !fields.currencyType)
      {
        setCurrencyError(true)
        isValid = false
      }

      if(fields.isGuestCheckBoxSelected)
      {
        if(!fields.guestPhone)
        {
          setIsGuestPhoneValidState(false)
          setIsGuestPhoneValidMessage(PHONE.required)
          isValid = false
        }
        if(!fields.isGuestPhoneValidState)
        {
          isValid = false
        }
        if(Object.keys(fields.guestUser).length <= 0 || fields.guestUser?.firstName.length <= 0 || fields.guestUser?.lastName.length <= 0 )
        {
          setGuestUserEmpty(true)
          isValid = false
        }
      }
      if(!isValid)
      {
        window.scrollTo(0, refRequirements.current.offsetTop - 90)
        notify(`Missing required fields`, "error" , 3000);
      }
      return isValid
    } catch (error) {
      notify(`Something went wrong`, "error" , 3000);
      return false
    }
    
  }
  return (
    <ContentWrapper>
      <form className='my-basket' onSubmit={onSendRequest}>
        <ValidationGroup ref={refValidationGroup}>
          <Title>My Cart for Quotes</Title>
        {
          bannerActive &&  
          <div className='my-basket__banner'>
            <MessageBanner>
              <div className='my-basket__banner__container'> 
                <span>Please fill in requirements to start. You can create one request for few properties, but pay attention - requirements should match with the property.</span> 
                <SecondaryButton action={_ => setBannerActive(false)}>Got It!</SecondaryButton>
              </div>
              </MessageBanner>
          </div>
        }
        <div ref={refRequirements}>
          <Subtitle>Requirements</Subtitle>
          <Requirements refListProperties={refListProperties}/>
        </div>
        <div>
          <div className='my-basket__title' ref={refListProperties}>
            <Subtitle>List of Properties</Subtitle>
            <Loading isVisble={loading} size={30}/>
          </div>
          <ListProperties />
        </div>
        {noMatch && 
          <div style={{marginTop: '15px', width: 'fit-content'}}>
            <MessageBanner>
              Some of your selected properties don’t match to your requirements, and will be moved out to Favorites
            </MessageBanner>
          </div>
        }
        
        <div className='my-basket__buttons'>
          
          <ActionButton action={onValidation} disabled={loadingSendRequest || loading}>{noMatch ? 'OK, send request without it' : 'Send Request'}</ActionButton>
          <SecondaryButton action={onEditRequirements}>Edit Requirements</SecondaryButton>
          <Loading isVisble={loadingSendRequest} size={30}/>
        </div>
       </ValidationGroup>
      </form>
    </ContentWrapper>
  )
}
function mapStateToProps({ basket }) {
  return {
    loading: basket.loading,
    noMatch: basket.noMatch,
    loadingSendRequest: basket.loadingSendRequest,
    fields: basket.fields
  };
}
function mapDispatchToProps( dispatch ) {
  return {
    setCurrencyError: value => dispatch(setCurrencyError(value)),
    setIsGuestPhoneValidMessage: value => dispatch(setIsGuestPhoneValidMessage(value)),
    setIsGuestPhoneValidState: value => dispatch(setIsGuestPhoneValidState(value)),
    setGuestUserEmpty: value => dispatch(setGuestUserEmpty(value)),
    setClearLocationField: value => dispatch(setClearLocationField(value))
  };
}
export default connect(mapStateToProps,mapDispatchToProps)(MyBasketContent)