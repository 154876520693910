import {
    CHANGE_LOCATION,
    CHANGE_CHECKIN,
    CHANGE_CHECKOUT,
} from "../constant/landing";

const defaultState = {
    location:{
        id: '',
        value: ''
    },
    checkIn: '',
    checkOut: '',
};

export default function landingReducer(state = defaultState, action) {
    switch (action.type) {
        case CHANGE_LOCATION:
            return {
                ...state,
                location: action.payload
            };
        case CHANGE_CHECKIN:
            return {
                ...state,
                checkIn: action.payload
            };
        case CHANGE_CHECKOUT:
            return {
                ...state,
                checkOut: action.payload
            };
        default:
            return state;
    }
}