import {useEffect,useState} from 'react'
//styles
import '../styles/custom-popup-gallery.css'
//icons
import {XCircle, ArrowLeftCircleFill,ArrowRightCircleFill} from 'react-bootstrap-icons'
const CustomPopupGallery = ({visible,onHiding,images,defaultImage=0}) => {

  const [indexImage,setIndexImage] = useState(0)

  const changeIndex = type =>{
    switch (type) {
        case 'prev':
            setIndexImage(indexImage - 1)
            break;
    
        default:
            setIndexImage(indexImage + 1)
            break;
    }
  }
  
  useEffect(() => {
        document.body.style.overflowY = visible ? 'hidden':'auto'

  }, [visible])
  
  useEffect(() => {
    setIndexImage(defaultImage)
  }, [defaultImage])
  
  // useEffect(() => {
  //   console.log(images)
  // }, [images])
  

  return (
        <div className={visible ? 'custom-popup-gallery custom-popup-gallery--active' : 'custom-popup-gallery'}>
            {visible && 
            <>
                <div className='custom-popup-gallery__close'>
                    <button onClick={onHiding}><XCircle/></button>
                </div>
                <div className='custom-popup-gallery__content'>
                    <button className={indexImage > 0 ? '' : 'noVisible'} onClick={()=>changeIndex('prev')}><ArrowLeftCircleFill/></button>
                    <div className='custom-popup-gallery__content__image'>
                        <img src={images[indexImage]} alt="" />
                    </div>
                    <button className={images.length > indexImage+1 ? '':'noVisible'} onClick={()=>changeIndex('next')}><ArrowRightCircleFill/></button>
                </div>
            </>
            }
        </div>
  )
}

export default CustomPopupGallery