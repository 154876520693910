import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Accordion } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchAccordionData } from '../../../utils/faq'

const FaqAccordion = () => {
    const { data, loading, error } = useSelector(state => state.faq)

    useEffect(() => {
        fetchAccordionData()
    }, [])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error}</div>
    }

    return (
        <>
            {
                data.map((item, index) => {
                    return (
                        <>
                            <Accordion eventKey={index} key={index} className='container-fluid w-75'>
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header>
                                        <strong className='text-dark'>
                                            {item.question}
                                        </strong>
                                    </Accordion.Header>
                                    <Accordion.Body key={item.id}>
                                        <div dangerouslySetInnerHTML={{ __html: item.html }} />
                                        <div className='p-3'>
                                            <p className='text-dark lead'>
                                                {item.answer}
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                    )
                })
            }
        </>
    )
}

const mapStateToProps = state => ({
    data: state.faq.data,
    loading: state.faq.loading,
    error: state.faq.error,
})

export default connect(mapStateToProps)(FaqAccordion)