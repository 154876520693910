import {useState,useEffect} from 'react'
import ReCAPTCHA from "react-google-recaptcha";

//styles
import '../styles/custom-recaptcha.css'
const CustomRecaptcha = ({recaptchaRef,isInvalid}) => {
  const [recaptchaInvalid, setRecaptchaInvalid] = useState({
    status:false,
    message: ''
  })
  const getRecaptcha = _ =>{
    if(recaptchaRef.current.getValue())
        setRecaptchaInvalid({
            status:false,
            message: ''
        })
    else
        setRecaptchaInvalid({
            status:true,
            message: 'Please check the box I am not a robot'
        })
  }
  useEffect(() => {
    if(isInvalid)
        setRecaptchaInvalid({
            status:true,
            message: 'Please check the box I am not a robot'
        })
    else
        setRecaptchaInvalid({
            status:false,
            message: ''
        })
  }, [isInvalid])
  
  return (
    <div>
        <ReCAPTCHA sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`} ref={recaptchaRef} onChange={getRecaptcha} hl='en'/>
        {recaptchaInvalid.status && <div><span className='recaptcha--error'>{recaptchaInvalid.message}</span></div>}
    </div>
  )
}

export default CustomRecaptcha