import {useNavigate,useParams} from 'react-router-dom'
import {useEffect} from 'react'
import {connect} from 'react-redux'

//components
import SectionHeader from '../assets/elements/section-header'
import ContentWrapper from '../assets/elements/content-wrapper'
import SectionSubtitle from '../assets/elements/section-subtitle'
import ActionButton from '../assets/elements/button/action-button'
import Loading from '../assets/controls/loading'
import Alert from '../assets/elements/custom-alert'
//utils
import {getVacanciesById} from '../../utils/career'
//actions
import {setDefaultCareerById} from '../../actions/career'
//constants
import {CAREER_DETAILS,HEADER} from '../../constant/career'
//styles
import './styles/career-by-id.css'

const CareerById = ({careerListById,loading,message,setDefault}) => {
  const navigate = useNavigate()
  const params = useParams()
  const navigateApplyNow = _ =>{
    navigate(`/careers/apply/${params.id}`)
  }
  useEffect(() => {
    if(params.id)
        getVacanciesById(params.id)
  }, [params.id])
  useEffect(() => {  
    return () => {
      setDefault()
    }
  }, [])
  
  return (
      <div className="section-content-wrapper">
            <SectionHeader title={careerListById?.jobTitle} subtitle={careerListById?.location} image={HEADER.img}/>
            <ContentWrapper>
                <div className='career-by-id'>
                  {message.content && 
                    <Alert type={message.error ? 'error' : ''}>
                      <div className='career-by-id__message'>
                        {message.content}
                      </div>
                    </Alert>}
                  <Loading isVisble={loading}/>
                  {Object.keys(careerListById).length > 0 &&
                    <>
                    {careerListById?.shortDescription && <div dangerouslySetInnerHTML={{__html:careerListById?.shortDescription}}/>}
                    {careerListById?.responsibilities && <div className='career-by-id__section'>
                        <SectionSubtitle>{CAREER_DETAILS.sections.role}</SectionSubtitle>
                        <div dangerouslySetInnerHTML={{__html:careerListById?.responsibilities}}/>
                    </div>}
                    {careerListById?.qualifications && <div className='career-by-id__section'>
                        <SectionSubtitle>{CAREER_DETAILS.sections.qualify}</SectionSubtitle>
                        <div dangerouslySetInnerHTML={{__html:careerListById?.qualifications}}/>
                    </div>}
                    {careerListById?.compensation && <div className='career-by-id__section'>
                        <SectionSubtitle>{CAREER_DETAILS.sections.salary}</SectionSubtitle>
                        <div dangerouslySetInnerHTML={{__html:careerListById?.compensation}}/>
                    </div>}
                    <div className='career-by-id__button'>
                        <ActionButton action={navigateApplyNow}>{CAREER_DETAILS.buttonName}</ActionButton>
                    </div>
                  </> }                
                </div>
            </ContentWrapper>
    </div>
  )
}
function mapStateToProps({ career }) {
    return {
        careerListById: career.careerListById,
        message: career.messageCareerListById,
        loading: career.loadingCareerListById
    };
  }
function mapDispatchToProps(dispatch){
    return{
      setDefault: () => dispatch(setDefaultCareerById())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CareerById)