import {useState} from 'react'

//components
import Slider from '../../../assets/controls/slider'
import PopupGallery from '../../../assets/elements/custom-popup-gallery'

const FloorPlans = ({
    slidesData
}) => {
  const [visible,setVisible] = useState(false)
  const [indexImage,setIndexImage] = useState(0)
  const showPopUp = (index) => {
    setVisible(!visible)
    !isNaN(index) && setIndexImage(index)
  }
  return (
    <div>
        <Slider 
            data={slidesData} 
            action={showPopUp} 
            arrowHeight={30} 
            arrowWidth={30}
        />
        <PopupGallery 
            visible={visible}
            onHiding={showPopUp}
            images={slidesData} 
            defaultImage={indexImage}
        />
    </div>
  )
}

export default FloorPlans