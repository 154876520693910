import {useCallback,useState,memo,useEffect} from 'react'

//styles
import '../../styles/input/custom-input-alt.css'
import '../../styles/input/custom-phone.css'
import 'react-phone-input-2/lib/style.css'


//components
import PhoneInput from 'react-phone-input-2'

//constants
import {PHONE} from '../../../../constant/messages'
const CustomPhone = ({label, value, setValue,requiredValue,isValid,setIsValid,validMessage,setValidMessage,isDisabled,placeholder,labelMode}) => {
  const [showMessage, setShowMessage] = useState(false)
  const onFocusInput = useCallback(e=>{
    setShowMessage(true)
  },[])
  const onBlurInput = useCallback(()=> {
    setShowMessage(false)
  })
  const onChangeValue = useCallback((data,country) => {
      if(requiredValue)
      {
        let count = country.format?.split('').filter(char=> char === '.')?.length
        
        if(data.length > count/2)
        {
            setIsValid(true)
            setValidMessage('')
        }
        else
        {
              if(data)
              {
                setIsValid(false)
                setValidMessage(PHONE.format)
              }
              else{
                setIsValid(false)
                setValidMessage(PHONE.required)
              }
        }
      }
      setValue(data)
  }, [requiredValue]);

  
  return (
    <div className='custom-input-alt'>
        {label && <div className='custom-input-alt__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>}
        <div className={`custom-phone ${labelMode ? 'custom-phone--label-mode' : ''}`}>
          <div className='custom-phone__container'>
            <PhoneInput
              isValid={isValid}
              value={value}
              disabled={isDisabled}
              placeholder={placeholder}
              onFocus={onFocusInput}
              onBlur={onBlurInput}
              onChange={onChangeValue}
            />
            {!isValid && requiredValue && <div className='custom-phone__container__icon'></div>}
          </div>
          {showMessage && validMessage && requiredValue && <span className='custom-phone--error'>{validMessage}</span>}
        </div>
    </div>
  )
}

export default memo(CustomPhone)