import {useState,useEffect} from 'react'
import {connect} from 'react-redux'

//components
import Pagination from '../../assets/controls/pagination'
import Loading from '../../assets/controls/loading'
import Alert from '../../assets/elements/custom-alert'
import CareerTable from '../career-table'

//constants
import {CAREERS_LIST,PAGINATION} from '../../../constant/career'

//utils
import {getHistory} from '../../../utils/career'

//actions
import {setDefaultCareerListHistory} from '../../../actions/career'

const CareerListAdminHistory = ({loadingHistory,careerListHistory,careerListTotalHistory,setDefaultCareerListHistory,messageHistory}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState()

  
  useEffect(() => {
    getHistory((currentPage-1)*PAGINATION.take)
  }, [currentPage])
  useEffect(() => {
    if(careerListTotalHistory > 0)
    {
        setTotalPages(Math.ceil(careerListTotalHistory/PAGINATION.take))
    }
  }, [careerListTotalHistory])
  useEffect(() => {
    return () => {
      setDefaultCareerListHistory()
    }
  }, [])
  return (
    <div>
        <div className={`mb-2 ${careerListTotalHistory > 0 ? 'career-list__pagination' : 'career-list__pagination--center'}`}>
          {careerListTotalHistory > 0 && 
            <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
          }
          <Loading isVisble={loadingHistory} size={26}/>
        </div>
        {careerListHistory.length > 0 &&
            <CareerTable headers={CAREERS_LIST.positionHeaders} content={careerListHistory}/>
        }
        {messageHistory.content && <Alert type={messageHistory.error? 'error' : ''}><div className='career-list__message'>{messageHistory.content}</div></Alert>}
        
    </div>
  )
}
function mapStateToProps({ career }) {
  return {
    careerListHistory: career.careerListHistory,
    careerListTotalHistory: career.careerListTotalHistory,
    loadingHistory: career.loadingCareerListHistory,
    messageHistory: career.messageCareerListHistory
  };
}
function mapDispatchToProps(dispatch){
  return{
      setDefaultCareerListHistory: () => dispatch(setDefaultCareerListHistory())
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(CareerListAdminHistory)
