import notify from 'devextreme/ui/notify';

export default class NotificationsService {

    static showSuccessNotification(message, duration = 3000) {
        notify(message, "success", duration);
    };

    static showErrorNotification(message, duration = 3000) {
        notify(message, "error", duration);
    }

    static showWarningNotification(message, duration = 3000) {
        notify(message, "warning", duration);
    }
}
