import React from "react";
import "./create-account/styles/layer-mask-styles.css"
import LoaderComponent from "./create-account/Sub-Components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { verifyAccountRequest, verifyAccountLoading, verifyAccountTimeElapsed, verifyAccountCall, useVerifyAccountService } from "../utils/verify-account";

const VerifyAccount = ({ loading, timeElapsed, request, makeCall }) => {
    const navigate = useNavigate();
    const { id, username } = useParams();
    const { post, get } = useVerifyAccountService(navigate, id, username);

    React.useEffect(() => {
        async function verifyAccount() {
            if (!request) return;
            verifyAccountRequest({ request: false });
            post(`/api/v1/accounts/verify/${id}`)
                .then(response => {
                    return response;
                }).catch(error => console.log(error));
        }
        verifyAccount();
    }, [id, request, post]);

    React.useEffect(() => {
        async function verifyAccountUser() {
            if (!makeCall) return;
            verifyAccountCall({ makeCall: false });
            verifyAccountTimeElapsed({ timeElapsed: timeElapsed + 5 });
            if (timeElapsed >= 60) {
                verifyAccountLoading({ loading: false });
                clearInterval(intervalId);
                navigate("/account/verification-failed");
                return;
            }
            get(`/api/v1/accounts/exist/${username})`)
                .then(response => {
                    return response;
                }).catch(error => console.log(error));
        }
        const intervalId = setInterval(verifyAccountUser, 5000);
        return () => clearInterval(intervalId);
    }, [makeCall, username, timeElapsed, navigate, get]);

    return (
        <div className="layer-mask">
            <div className="msg-container-verify">
                <div className="msg-box">
                    <div className="d-flex justify-content-center align-items-center p-3">
                        {loading ? (
                            <>
                                <div className="msg-box-loader"><LoaderComponent /></div>
                                <div className="msg-box-header mx-3 mt-3">
                                    <p className="text-center">Waiting for activation of your account&nbsp;<span className="fw-bold">{username}</span></p>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.verifyAccount.loading,
        timeElapsed: state.verifyAccount.timeElapsed,
        request: state.verifyAccount.request
    }
};

export default connect(mapStateToProps)(VerifyAccount);