
import {useState, useEffect} from 'react'

//images
import {ReactComponent as RightArrow} from '../../../images/pagination-icons/right_arrow.svg'
import {ReactComponent as LeftArrow} from '../../../images/pagination-icons/left_arrow.svg'

//styles
import '../styles/pagination-alt.css'

const Pagination = ({totalPages,skip,take,setSkip,type}) => {
  const [toPage, setToPage] = useState(take)

  const goBack = _ => {
    if(skip > 0)
    {
      setSkip(skip - take)
    }
  }

  const goForward = _ => {
    if(toPage < totalPages)
    {
      if(type === 'redux')
        setSkip(skip + take)
      else
        setSkip(previous => previous + take)
    }
  }
  useEffect(() => {
    (skip + take) > totalPages ? setToPage(totalPages) : setToPage(skip +take)
  }, [totalPages,skip])

  

  return (
    <div className='pagination-alt'>
        <span> {skip === 0 ? 1 : skip + 1} - {toPage} from {totalPages} </span> 
        <div className='pagination-alt__control-container'>
          <div className='pagination-alt__arrow-container' onClick={goBack}>
            <LeftArrow className={`pagination-alt__arrow ${skip > 0 ? 'pagination-alt__arrow--active' : 'pagination-alt__arrow--inactive'}`} onClick={goBack}/>
          </div>
          <div className='pagination-alt__arrow-container' onClick={goForward}>
            <RightArrow className={`pagination-alt__arrow ${skip < totalPages && toPage !== totalPages  ? 'pagination-alt__arrow--active' : 'pagination-alt__arrow--inactive'}`}/>
          </div>
        </div>
    </div>
  )
}

export default Pagination