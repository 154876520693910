import React from "react";
import { connect } from "react-redux";
import UserForm from "./profile/UserForm";

const Profile = ({ profile }) => {
	return (
		<div className="section-content-wrapper">
			<div className="user-profile">
				<UserForm id={profile.sub} />
			</div>
		</div>
	);
}

const mapStateToProps = ({ auth }) => {
	return {
		profile: auth.profile
	}
};

function mapDispatchToProps(dispatch) {
	return {
		dispatch
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);