import React from "react";
import { Link } from "react-router-dom";

const NewsSectionArticle = ({ id, image, alt, title, date, time, author, summary }) => {
    return (
        <>
            <div className="row news-section-article flex-wrap mt-3 mb-3">
                {image ?
                    <div className="d-flex justify-content-start align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-2 p-0">
                        <div className="img-container">
                            <img src={image} alt={alt} style={{ width: "295px", height: "184px" }} />
                        </div>
                    </div>
                    : null}
                {image ? (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-10 p-0 ps-lg-5 p-xl-0 ps-xxl-5">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="col-md-8 col-lg-6 col-xl-9 col-xxl-12 pe-md-0 ps-lg-0 ps-xxl-0 mt-2">
                                <span className="news-section-article__title breakpoint">
                                    {title.substring(0, 70) + "..."}
                                </span>
                            </div>
                        </div>
                        <div className="news-section-article__date-author-info mt-2 mb-2 breakpoint">
                            <span className="news-section-article__date">{date}</span>,&nbsp;
                            <span className="news-section-article__time">{time}</span> by&nbsp;
                            <span className="news-section-article__author">{author}</span>&nbsp;
                        </div>
                        <div className="d-flex breakpoint mb-2">
                            <span className="news-section-article__summary" dangerouslySetInnerHTML={{ __html: summary.substring(0, 390) }} />
                        </div>
                        <Link to={`/news/${id}`} className="news-section-article__read-more breakpoint">
                            Full Story &gt;&gt;
                        </Link>
                    </div>
                ) : (
                    <div className="col-12 m-0 p-0 mt-2">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="col-12">
                                <span className="news-section-article__title">
                                    {title.substring(0, 70) + "..."}
                                </span>
                            </div>
                        </div>
                        <div className="news-section-article__date-author-info mt-2 mb-2">
                            <span className="news-section-article__date">{date}</span>,&nbsp;
                            <span className="news-section-article__time">{time}</span> by&nbsp;
                            <span className="news-section-article__author">{author}</span>&nbsp;
                        </div>
                        <div className="d-flex mb-2">
                            <span className="news-section-article__summary" dangerouslySetInnerHTML={{ __html: summary.substring(0, 335) }} />
                        </div>
                        <Link to={`/news/${id}`} className="news-section-article__read-more">
                            Full Story &gt;&gt;
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
};

export default NewsSectionArticle;