import SectionTitle from '../assets/elements/section-title'
//components
import RenovationTag from './renovation/renovation-tag'
//images
import CertificatedImage from '../../images/oasis_sert_icon.png'
//styles
import './styles/title.css'
const Title = ({id,name,companyName,type,renovated,renovatedDate,isOasisCertified}) => {
  return (
    <div className='details-title'>
      <div className='details-title__section'>
        <SectionTitle>{name}</SectionTitle>
        <div className='details-title__section__name'>{companyName}</div>
      </div>
      <div className='details-title__section'>
        <div className='details-title__section__buildingtype'>{type}</div>
        {isOasisCertified && <img src={CertificatedImage} alt="" className='details-title__section__img'/>}
        {/* <div className='title-certified'></div> */}
        {renovated && <RenovationTag customClass='details-title__renovation-date-tag-font' rdate={renovatedDate}/>}
      </div>
    </div>
  )
}

export default Title