import React from "react";
import "../styles/admin-article-modal-styles.css";
import Form, { GroupItem, SimpleItem, Label, RequiredRule } from "devextreme-react/form";
import TextBox from "devextreme-react/text-box";
import CustomImageUploader from "./CustomImageUploader";
import CustomImagePreview from "./CustomImagePreview";
import { Button } from "devextreme-react/button";
import baseService from "../../../services/base.service";
import CustomEditor from '../../assets/elements/input/custom-html-editor'

const AdminEditArticleModal = ({ id, onClose, onArticleUpdated }) => {
    const [fetchedPhotoURL, setFetchedPhotoURL] = React.useState(null);
    const [Title, setTitle] = React.useState("");
    const [TextOfNews, setTextOfNews] = React.useState("");
    const [Photo, setPhoto] = React.useState(null);
    const [currentPhotoId, setCurrentPhotoId] = React.useState(null);
    const [keepCurrentPhoto, setKeepCurrentPhoto] = React.useState(true);
    const [photoPreview, setPhotoPreview] = React.useState(null);
    const [fileNameTitle, setFileNameTitle] = React.useState(null);

    React.useEffect(() => {
        async function fetchPostData() {
            await baseService({
                method: "GET",
                baseURL: process.env.REACT_APP_API_ENDPOINT,
                url: `/api/v1/news/${id}`,
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async (res) => {
                const postData = res.data;
                setTitle(postData.title);
                setTextOfNews(postData.textOfNews);
                setFetchedPhotoURL(postData.photoURL);
                setCurrentPhotoId(postData.photoId);
                setPhotoPreview(postData.photoId);
                setFileNameTitle(postData.photoId);
                return res;
            }).catch(res => { return res; });
        };
        fetchPostData();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        const UserProfile = JSON.parse(localStorage.getItem('ODYSSEY.USER.AUTHENTICATION'));
        const UserProfileToken = UserProfile?.profile?.sub;

        const formData = new FormData();
        formData.append("Title", Title);
        formData.append("TextOfNews", TextOfNews);
        formData.append("UserProfileId", UserProfileToken);
        formData.append("KeepCurrentPhoto", keepCurrentPhoto);

        if (Photo) {
            formData.append("Photo", Photo);
        } else if (currentPhotoId) {
            formData.append("PhotoId", currentPhotoId);
        };

        await baseService({
            method: "PUT",
            baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
            url: `/api/v1/news/${id}`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "Authorization": `Bearer ${UserProfileToken}`,
            },
            withCredentials: true,
        }).then((response) => {
            onArticleUpdated(id);
            return response;
        }).catch((res) => { return res; });
        onClose();
    };

    const handleFileUpload = (file) => file ? setPhoto(file) : setPhoto(null);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const paddingMobile = windowWidth >= 320 && windowWidth <= 567 ? "mb-3" : "form-padding";

    return (
        <div className="admin-article-modal-overlay">
            <div className="admin-article-modal">
                <div className="container">
                    <h4 className="admin-article-modal-title">Edit News</h4>
                    <form action="" onSubmit={handleSubmit}>
                        <Form className={paddingMobile}>
                            <GroupItem>
                                <SimpleItem cssClass="modal-label text-capitalize" isRequired={true}>
                                    <Label text="News Title" />
                                    <TextBox className="w-100" value={Title} onValueChanged={(e) => setTitle(e.value)}>
                                        <RequiredRule message="News Title is required" />
                                    </TextBox>
                                </SimpleItem>
                                <SimpleItem cssClass="modal-label-image-preview text-capitalize">
                                    <Label text="Upload Picture" />
                                    {photoPreview ?
                                        <div>
                                            <CustomImagePreview photoPreview={photoPreview}
                                                removePhoto={() => {
                                                    setPhotoPreview(null)
                                                    setKeepCurrentPhoto(false)
                                                }} fileName={fileNameTitle} />
                                        </div>
                                        :
                                        <div className="file-uploader-query">
                                            <CustomImageUploader setPhoto={handleFileUpload} setCurrentPhotoId={setCurrentPhotoId}
                                                fetchedPhotoId={currentPhotoId} />
                                        </div>
                                    }
                                </SimpleItem>
                                <SimpleItem
                                    cssClass={photoPreview ? "modal-label text-capitalize m-0 p-0 mt-3" : "modal-label text-capitalize"}
                                    isRequired={true}
                                >
                                    <Label text="Text of News" />
                                    <CustomEditor
                                        value={TextOfNews}
                                        setValue={setTextOfNews}
                                        requiredValue={true}
                                        messageName='Text of News'
                                        height={250}
                                    />
                                </SimpleItem>
                            </GroupItem>
                            <GroupItem>
                                <SimpleItem cssClass="mt-3">
                                    <Button className="oasis-btn-edit" text="Edit News" useSubmitBehavior={true} />
                                    <Button className="oasis-cancel-btn" text="Cancel" onClick={onClose} />
                                </SimpleItem>
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminEditArticleModal;