import {connect} from 'react-redux'
import {useEffect} from 'react'
import notify from 'devextreme/ui/notify';

//components
import ContentWrapper from './assets/elements/content-wrapper'
import SectionHeader from './assets/elements/section-header'
import SectionTitle from './assets/elements/section-title'
import Information from './assets/elements/information'
import PartnerCard from './become-a-partner/partner-levels/partner-levels-card'
import CEOTag from './become-a-partner/CEO/presentation-tag'
//constants
import {HEADER_TITLE, HEADER_SUBTITLE,TITLE,DESCRIPTION,INFORMATION_CARDS,TITLE_PARTNER,PARTNER_LEVELS,TITLE_INTERESTED,CEO_WORDS} from '../constant/become-a-partner'
//actions
import {setDefault} from '../actions/become-a-partner'
//styles
import './become-a-partner/styles/become-a-partner.css'
//images
import Homeslide from '../images/homeslide01.jpg'
const BecomeAPartner = ({requestedPartnership,setDefaultPartnership}) => {
  useEffect(() => {
    return () => setDefaultPartnership()
  }, [])
  
  useEffect(() => {
    if(requestedPartnership.message) 
    {
      if(requestedPartnership.status)
      {
        notify(requestedPartnership.message, "success", 3000);
      }
      else{
        notify(requestedPartnership.message, "error", 3000);
      }
    }
  }, [requestedPartnership])
  
  return (
    <div>
      <SectionHeader title={HEADER_TITLE} subtitle={HEADER_SUBTITLE} image={Homeslide}/>
      <ContentWrapper>
          <div className='become-a-partner' id='become-a-partner'>
            <div className='become-a-partner__section'>
              <div className='become-a-partner__section__title'><SectionTitle>{TITLE}</SectionTitle></div>
              <p>
                {DESCRIPTION}
              </p>
              <div className='become-a-partner__information-cards'>
                 {INFORMATION_CARDS.map((item,index)=>
                    <Information key={index} title={item.title}>{item.description}</Information>
                  )} 
              </div>
            </div>
            <div className='become-a-partner__section'>
              <div className='become-a-partner__section__title'><SectionTitle>{TITLE_PARTNER}</SectionTitle></div>
              <div className='become-a-partner__section__partner-cards'>
                  {PARTNER_LEVELS.map((level,index) => <PartnerCard key={index} data={level}/>)}
              </div>
            </div>
            <div className='become-a-partner__section'>
              <div className='become-a-partner__section__title'>
              <SectionTitle>{TITLE_INTERESTED}</SectionTitle></div>
              <CEOTag data={CEO_WORDS}/>
            </div>
          </div>
        </ContentWrapper>
    </div>
  )
}
function mapStateToProps({ becomeAPartner }) {
  return {
    requestedPartnership: becomeAPartner.requestPartnership,
  };
}
function mapDispatchToProps(dispatch){
  return{
    setDefaultPartnership: () => dispatch(setDefault())
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(BecomeAPartner)