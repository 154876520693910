import {
    SET_REQUEST_PARTNERSHIP,
    SET_LOADING,
    SET_DEFAULT
} from "../constant/become-a-partner";

export const setRequestPartnership = data => ({
    type: SET_REQUEST_PARTNERSHIP,
    payload: data
});

export const setLoading = data => ({
    type: SET_LOADING,
    payload: data
});

export const setDefault = _ => ({
    type: SET_DEFAULT
})