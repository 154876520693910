import {
    SET_COMPANY,
    SET_FIRST_NAME,
    SET_LAST_NAME,
    SET_PHONE_NUMBER,
    SET_EMAIL_ADDRESS,
    SET_DESTINATION_CITY,
    SET_ACCOMMODATION_LEVEL,
    SET_PROPERTY_NAME,
    SET_NUMBER_OF_BEDROOMS,
    SET_MAXIMUM_COMMUTE_DISTANCE,
    SET_MAXIMUM_COMMUTE_DISTANCE_TYPE,
    SET_NIGHTLY_BUDGET,
    SET_BUDGET_CURRENCY_ID,
    SET_CHECK_IN_DATE,
    SET_CHECK_OUT_DATE,
    SET_DATES_IS_FLEXIBLE,
    SET_TOTAL_ADULTS,
    SET_TOTAL_CHILDREN,
    SET_SPECIAL_REQUIREMENTS,
    RESET_QUOTE_FORM,
    SET_LOCATION
} from "../constant/property-request";
import moment from "moment";
import "moment-timezone";

const userTimeZone = moment.tz.guess();

const initialState = {
    location: "",
    company: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    destinationCity: "",
    accommodationLevel: 0,
    propertyName: "",
    numberOfBedrooms: 0,
    maximumCommuteDistance: 0,
    maximumCommuteDistanceType: 0,
    distance: 0,
    nightlyBudget: 0,
    budgetCurrencyId: 0,
    checkInDate: moment().tz(userTimeZone).startOf("day").toDate(),
    checkOutDate: moment().tz(userTimeZone).startOf("day").toDate(),
    datesIsFlexible: false,
    totalAdults: 0,
    totalChildren: 0,
    specialRequirements: "",
}

export default function propertyRequestReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOCATION:
            return {
                ...state,
                location: action.payload
            };
        case SET_COMPANY:
            return {
                ...state,
                company: action.payload
            };
        case SET_FIRST_NAME:
            return {
                ...state,
                firstName: action.payload
            };
        case SET_LAST_NAME:
            return {
                ...state,
                lastName: action.payload
            };
        case SET_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: action.payload
            };
        case SET_EMAIL_ADDRESS:
            return {
                ...state,
                emailAddress: action.payload
            };
        case SET_DESTINATION_CITY:
            return {
                ...state,
                destinationCity: action.payload
            };
        case SET_ACCOMMODATION_LEVEL:
            return {
                ...state,
                accommodationLevel: action.payload
            };
        case SET_PROPERTY_NAME:
            return {
                ...state,
                propertyName: action.payload
            };
        case SET_NUMBER_OF_BEDROOMS:
            return {
                ...state,
                numberOfBedrooms: action.payload
            };
        case SET_MAXIMUM_COMMUTE_DISTANCE:
            return {
                ...state,
                maximumCommuteDistance: action.payload
            };
        case SET_MAXIMUM_COMMUTE_DISTANCE_TYPE:
            return {
                ...state,
                maximumCommuteDistanceType: action.payload
            };
        case SET_NIGHTLY_BUDGET:
            return {
                ...state,
                nightlyBudget: action.payload
            };
        case SET_BUDGET_CURRENCY_ID:
            return {
                ...state,
                budgetCurrencyId: action.payload
            };
        case SET_CHECK_IN_DATE:
            return {
                ...state,
                checkInDate: moment(action.payload).tz(userTimeZone).startOf("day").toISOString()
            };
        case SET_CHECK_OUT_DATE:
            return {
                ...state,
                checkOutDate: moment(action.payload).tz(userTimeZone).startOf("day").toISOString()
            };
        case SET_DATES_IS_FLEXIBLE:
            return {
                ...state,
                datesIsFlexible: action.payload
            };
        case SET_TOTAL_ADULTS:
            return {
                ...state,
                totalAdults: action.payload
            };
        case SET_TOTAL_CHILDREN:
            return {
                ...state,
                totalChildren: action.payload
            };
        case SET_SPECIAL_REQUIREMENTS:
            return {
                ...state,
                specialRequirements: action.payload
            };
        case RESET_QUOTE_FORM:
            return {
                ...state,
                company: "",
                firstName: "",
                lastName: "",
                phoneNumber: "",
                emailAddress: "",
                destinationCity: "",
                accommodationLevel: 0,
                propertyName: "",
                numberOfBedrooms: 0,
                maximumCommuteDistance: 0,
                maximumCommuteDistanceType: 0,
                distance: 0,
                nightlyBudget: 0,
                budgetCurrencyId: 0,
                checkInDate: moment().tz(userTimeZone).startOf("day").toDate(),
                checkOutDate: moment().tz(userTimeZone).startOf("day").toDate(),
                datesIsFlexible: false,
                totalAdults: 0,
                totalChildren: 0,
                specialRequirements: "",
            }
        default:
            return state
    }
}