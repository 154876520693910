import store from "../store";
import baseService from "../services/base.service";

//utils
import { getPrimaryPropertyImage,getPropertyImage } from '../utils/files'
import {getFavorites} from './favorites'
//actions
import {changePreviousUrl,changeMessage,setFilterProperties,changeLoading,changeFilterTotalProperties,setPropertyMap,setPropertyFromList,setPropertyFromMap} from '../actions/property-search'

//constant
import {BUILDING_TYPES} from '../constant/building-types'
import {MESSAGE_NOT_FOUND,MESSAGE_ERROR} from '../constant/messages'

let controller
let prevRadius
let prevMap
const getRadiusMapInMiles = () => {
  let map = store.getState().search.currentMap
  const radiusEarth = 3963.0;
  const toRadians = Math.PI / 180;
  const mapBounds = map.getBounds();
  if(mapBounds)
  {
    const mapCenter = map.getCenter();
    const mapNE = mapBounds.getNorthEast();
    const mapSW = mapBounds.getSouthWest(); 

    const latCenter = mapCenter.lat() * toRadians;
    const lngCenter = mapCenter.lng() * toRadians;
    const latNE = mapNE.lat() * toRadians;
    const lngNE = mapNE.lng() * toRadians;
    const latSW = mapSW.lat() * toRadians; 
    const lngSW = mapSW.lng() * toRadians; 

    // Calculate the distances between the center and the nearest edges
    const distanceNorth = radiusEarth * Math.acos(Math.sin(latCenter) * Math.sin(latNE) + Math.cos(latCenter) * Math.cos(latNE) * Math.cos(lngNE - lngCenter));
    const distanceSouth = radiusEarth * Math.acos(Math.sin(latCenter) * Math.sin(latSW) + Math.cos(latCenter) * Math.cos(latSW) * Math.cos(lngNE - lngCenter));
    const distanceWest = radiusEarth * Math.acos(Math.sin(latCenter) * Math.sin(latCenter) + Math.cos(latCenter) * Math.cos(latCenter) * Math.cos(lngCenter - lngSW));
    const distanceEast = radiusEarth * Math.acos(Math.sin(latCenter) * Math.sin(latCenter) + Math.cos(latCenter) * Math.cos(latNE) * Math.cos(lngNE - lngCenter));
    // Find the shortest distance (the smallest radius)
    const shortestDistance = Math.min(distanceNorth, distanceSouth, distanceWest, distanceEast);
    prevMap = map
    prevRadius = Math.round(shortestDistance);
    return Math.round(shortestDistance);
  }
  else{
    if(prevMap !== map && prevRadius)
      return prevRadius
    else
      return 0
  }
 
}
const getPropertiesMap = (geometry,fromMap) => {
  try {

    const lastUrl = store.getState().search.url
    let startIndex = lastUrl?.indexOf('&BuildingTypes')
    let endIndex = lastUrl?.indexOf('&skip')
    let cutLastUrl = lastUrl?.substring(startIndex,endIndex)
    
    let radius = `&Radius=${store.getState().search.switchLocationSearch.type === 'map' ? getRadiusMapInMiles() : store.getState().search.miles}`
    let buildingTypes = ''
      if(store.getState().search.buildingTypes.length > 0 && !store.getState().search.buildingTypes.includes('all'))
      {
        store.getState().search.buildingTypes.forEach(type =>
          buildingTypes += `&BuildingTypes=${type}`
        )
      }
      else{
        BUILDING_TYPES.forEach(type =>
          buildingTypes += `&BuildingTypes=${type.id}`
        )
      }
    let floors = ''
      if(store.getState().search.floor.length > 0 && !store.getState().search.floor.includes('all'))
      { 
          store.getState().search.floor.forEach(type =>
            floors += `&FloorPlans=${type}`
          )
      }
      else{
        let allFloorPlans = store.getState().dictionary.floorPlanTypes
        allFloorPlans.forEach(type =>
          floors += `&FloorPlans=${type.id}`
        )
      }
    let pets = store.getState().search.petsFriendly ? '&petsAllowed=true' : ''
    let filter = store.getState().search.petsFriendly ? '&filter=["petsAllowed","=",true]' : ''

    let newUrl = `${buildingTypes}${floors}${filter}`
    let url = `${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/search/markers-list?${geometry}${radius}${buildingTypes}${floors}${pets}`;
    baseService.get(url, {
      method:'GET',
      withCredentials:false,
      headers: {
        'Content-Type': 'application/json',
      },
      signal: controller.signal
      }).then(({data}) => {
        let formattedData = data.map(property=>{
          return {
            ...property,
              imageUrl: getPrimaryPropertyImage(property.id)
          }
        })
        if(fromMap && newUrl === cutLastUrl)
        {        
          store.dispatch(setPropertyMap('noRefresh',formattedData))
        }
        else
        {
          store.dispatch(setPropertyMap(null,formattedData))
        }
      }).catch(error => {
        store.dispatch(setPropertyMap(null,[]))
      })
  } catch (error) {
    // console.log('catch',error)
  }

}
const setSearchProperties = async(geometry,skip,take) =>{
  try {
      
    if(controller)
      controller.abort()
    
    controller  = new AbortController();
    
    store.dispatch(changeLoading(true))
    let radius = `&Radius=${store.getState().search.switchLocationSearch.type === 'map' ? getRadiusMapInMiles() : store.getState().search.miles}`
    let buildingTypes = ''
    if(store.getState().search.buildingTypes.length > 0 && !store.getState().search.buildingTypes.includes('all'))
    {
      store.getState().search.buildingTypes.forEach(type =>
        buildingTypes += `&BuildingTypes=${type}`
      )
    }
    else{
      BUILDING_TYPES.forEach(type =>
        buildingTypes += `&BuildingTypes=${type.id}`
      )
    }
    let floors = ''
    if(store.getState().search.floor.length > 0 && !store.getState().search.floor.includes('all'))
    { 
        store.getState().search.floor.forEach(type =>
          floors += `&FloorPlans=${type}`
        )
    }
    else{
      let allFloorPlans = store.getState().dictionary.floorPlanTypes
      allFloorPlans.forEach(type =>
        floors += `&FloorPlans=${type.id}`
      )
    }
    let filter = store.getState().search.petsFriendly ? '&filter=["petsAllowed","=",true]' : ''
    let pagination = `&skip=${skip}&take=${take}`
   
    const url = `${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/search/list?${geometry}${radius}${buildingTypes}${floors}${filter}${pagination}&requireTotalCount=true`;
    if(store.getState().search.url===url)
    {
      let allData = store.getState().search.filterProperties
      if(allData.length > 0)
      {
        store.dispatch(changeMessage(''))
        store.dispatch(changeLoading(false))
        let dataFavorites = await getFavorites()
        let favoriteIds = dataFavorites.map(item => item.id)
        let formattedData = allData.map(property=>{
          return {
            ...property,
              isLiked: favoriteIds.includes(property.id)
          }
        })
        store.dispatch(setFilterProperties(formattedData))
      }
      else{
        store.dispatch(changeLoading(false))
      }
    }
    else {
       if(skip === 0)
        {
          getPropertiesMap(geometry,store.getState().search.switchLocationSearch.type === 'map')
        }
        let dataFavorites = await getFavorites()
        baseService.get(url, {
        method:'GET',
        withCredentials:false,
        headers: {
          'Content-Type': 'application/json',
        },
        signal: controller.signal
        }).then(({data})=>{
          let favoriteIds = dataFavorites.map(item => item.id)
          store.dispatch(changeFilterTotalProperties(data.totalCount))
          
          let allData = []
          if(skip === 0)
          {
              allData = data.data
              store.dispatch(setPropertyFromList(''))
              store.dispatch(setPropertyFromMap(''))
          }
          else
          {
              allData = [...store.getState().search.filterProperties,...data.data]
          }
          let formattedData = allData.map(property=>{
            let sliceImages = property.propertyImage.filter((image,index) => index < 3).map(image => {
                return{
                  imageAlt: `Image #${image.imageId}`,
                  imageSrc: getPropertyImage(image.imageId),
                }
              }
            )

            return {
                ...property,
                isLiked: favoriteIds.includes(property.id),
                // likeId: likeId[0]?.id,
                imageUrl: getPrimaryPropertyImage(property.id),
                sliceImages:[
                  {
                    imageAlt: `Image #${property.id}`,
                    imageSrc: getPrimaryPropertyImage(property.id),
                  },
                  ...sliceImages]
            }
          })
          store.dispatch(setFilterProperties(formattedData))
          // store.dispatch(setPropertyMap(formattedData))
          if(data.totalCount <= 0)
            store.dispatch(changeMessage(MESSAGE_NOT_FOUND))
          else
            store.dispatch(changeMessage(''))
  
        }).finally(()=> {
          store.dispatch(changeLoading(false))
        })
        .catch( error => {
          store.dispatch(changeMessage(MESSAGE_ERROR))
          store.dispatch(changeLoading(false))
        })      

    }

    store.dispatch(changePreviousUrl(url))
  } catch (error) {
    store.dispatch(changeMessage(MESSAGE_ERROR))
    store.dispatch(changeLoading(false))
  }

}

const getGeometryByGoogle = async(address_id) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${address_id}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  let result = await fetch(url)
  return await result.json()
}


const getSearchPropertiesByPlaceId = async(id,skip,take) =>{
  if(store.getState().search.switchLocationSearch.type === 'map')
    {
      await setSearchProperties(store.getState().search.switchLocationSearch.value,skip,take)
    }
  else{
    let response = await getGeometryByGoogle(id)
    let geometry = `Latitude=${response.results[0].geometry.location.lat}&Longitude=${response.results[0].geometry.location.lng}`
    await setSearchProperties(geometry,skip,take)  
  }
}
const getSearchProperties = async(skip,take) =>{
  // store.getState().search.switchLocationSearch

  if(store.getState().search.switchLocationSearch.type === 'map')
  {
    await setSearchProperties(store.getState().search.switchLocationSearch.value,skip,take)
  }
  else{
    let id = store.getState().search.location.id
    let response = await getGeometryByGoogle(id)
    let geometry = `Latitude=${response.results[0].geometry.location.lat}&Longitude=${response.results[0].geometry.location.lng}`
    await setSearchProperties(geometry,skip,take)
  }
}



export {getSearchProperties,getSearchPropertiesByPlaceId,getPropertiesMap}