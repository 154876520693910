import { useState,useEffect } from 'react'
import {connect} from 'react-redux'
import {Link,useLocation, useMatch} from 'react-router-dom'
//components
import IconNumber from '../../assets/elements/icon-number'

//images
import { ReactComponent as MessagesImg } from "../../../images/messages.svg";

//styles
import '../styles/navbar-dropdown.css'

//utils
import {getAllRequests} from '../../../utils/received-requests'
const ReceivedRequests = ({allRequests,iconColor}) => {
  const location = useLocation()
  const matchLocation = useMatch('/received-requests/:name')
  const [active, setActive] = useState(false)
  const [totalRequest, setTotalRequest] = useState(0)
  useEffect(() => {
    getAllRequests()
  }, [])
  
  useEffect(() => {
    let total = Object.keys(allRequests).reduce((acc,request)=> acc + allRequests[request],0)
    if(typeof total === 'number')
    {
      setTotalRequest(total)
    }
    else{
      setTotalRequest(0)
    }
  }, [allRequests])
  
  return (
    <div className={`navbar-message ${active ? 'navbar-message--active':''} ${matchLocation ? 'activeTab' : ''}`} onMouseLeave={()=>setActive(false)}>
        <div className={`navbar-message__icon navbar-message__icon--${iconColor}`} onClick={()=>setActive(!active)}>
            <div>
              <MessagesImg style={{ fill: iconColor === 'text-white' ? '#fff' : '#000' }}/>
            </div>
            <div>
              <IconNumber number={totalRequest} hideZero={true}/>
            </div>
        </div>
        <div className={`navbar-message__dropdown ${active ? 'navbar-message__dropdown--active':''}`}>
            <div className='navbar-message__dropdown__title'>RECEIVED REQUESTS</div>
            <Link to='/received-requests/contact-us' className={location.pathname === '/received-requests/contact-us' ? 'navbar-message__dropdown__item--active':''}><span>Contact us </span><IconNumber number={allRequests.numberOfContactUsMessages}/></Link>
            <Link to='/received-requests/list-your-properties' className={location.pathname === '/received-requests/list-your-properties' ? 'navbar-message__dropdown__item--active':''}><span>List your properties </span><IconNumber number={allRequests.numberOfListAPropertyMessages}/></Link>
            <Link to='/received-requests/became-a-partner' className={location.pathname === '/received-requests/became-a-partner' ? 'navbar-message__dropdown__item--active':''}><span>Became a partner </span><IconNumber number={allRequests.numberOfBecameAPartnerMessages}/></Link>
            <Link to='/received-requests/request-a-property' className={location.pathname === '/received-requests/request-a-property' ? 'navbar-message__dropdown__item--active':''}><span>Request a property </span><IconNumber number={allRequests.numberOfRequestAPropertyMessages}/></Link>
            <Link to='/received-requests/careers' className={location.pathname === '/received-requests/careers' ? 'navbar-message__dropdown__item--active':''}><span>Careers </span><IconNumber number={allRequests.numberOfVacanciesApplications}/></Link>
        </div>
    </div>
  )
}

function mapStateToProps({ receivedRequests }) {
  return {
    allRequests: receivedRequests.allRequests
  };
}

export default connect(mapStateToProps)(ReceivedRequests)