import { useState, useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import baseService from '../../services/base.service'
import { APPLICATION_STATE } from '../../constant/session'
import { useLocation } from 'react-router-dom';
import { history } from "../app";

const MaintenanceHub = () => {
    const [connection, setConnection] = useState(null);
    var location = useLocation();

    useEffect(() => {
        if (sessionStorage.getItem(APPLICATION_STATE) === null || sessionStorage.getItem(APPLICATION_STATE) !== 'IsRunning') {
            baseService.get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/application/state`)
                .then(res => {
                    sessionStorage.setItem(APPLICATION_STATE, res.data);
                    if (location.pathname === "/maintenance" && res.data === "IsRunning") {
                        history.replace(`${window.location.origin.toString()}`);
                        window.location.reload();
                    }
                })
        }
        else if (location.pathname === "/maintenance" && sessionStorage.getItem(APPLICATION_STATE) === 'IsRunning'){
            history.replace(`${window.location.origin.toString()}`);
            window.location.reload();
        }

    }, []);

    useEffect(() => {

        const newConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_API_ENDPOINT}/hubs/maintenance`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    connection.on('SendApplicationState', message => {
                        sessionStorage.setItem(APPLICATION_STATE, message.applicationState);

                        if (location.pathname === "/maintenance" && message.applicationState === "IsRunning") {
                            history.replace(`${window.location.origin.toString()}`);
                            window.location.reload();   
                        }
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);
};

export default MaintenanceHub