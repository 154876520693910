import NoPropertyPhoto from '../images/no_property_photo.png'

const getOdysseyImage = (imageId) => {
    return imageId ? `${process.env.REACT_APP_API_ENDPOINT}/api/v1/files/${imageId}` : NoPropertyPhoto;
}
const getOdysseyFile = (fileId,isAProperty) => {
    let baseUrl = isAProperty ? process.env.REACT_APP_PROPERTY_API_ENDPOINT : process.env.REACT_APP_API_ENDPOINT
    return fileId ? `${baseUrl}/api/v1/files/${fileId}` : (isAProperty ? NoPropertyPhoto : '');
}

const newsSectionImage = (imageId) => {
    return imageId ? `${process.env.REACT_APP_API_ENDPOINT}/api/v1/files/${imageId}` : null;
}

const getPropertyImage = (imageId) => {
    return imageId ? `${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/files/images/${imageId}` : NoPropertyPhoto;
}

const getPrimaryPropertyImage = (propertyId) => {
    return propertyId ? `${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/files/main-property-image/${propertyId}` : NoPropertyPhoto;
}

export { newsSectionImage, getOdysseyImage, getOdysseyFile, getPropertyImage, getPrimaryPropertyImage }