import {
    SET_FIRST_NAME,
    SET_LAST_NAME,
    SET_EMAIL,
    SET_PHONE_NUMBER,
    SET_LOGO,
    UPDATE_USER_DATA,
    SET_PROFILE_IMAGE,
    SET_LOADING,
    SET_ACCOUNT_UPDATED,
    SET_GUESTS,
    SET_LIST_GUESTS
} from "../constant/user-form";

const defaultState = {
    isAccountUpdated: false,
    isLoading: false,
    profileImage: null,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    logo: null,
    guests: [],
    listGuests: []
};

export default function authReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_ACCOUNT_UPDATED:
            return {
                ...state,
                isAccountUpdated: action.payload,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_PROFILE_IMAGE:
            return {
                ...state,
                profileImage: action.payload,
            };
        case SET_FIRST_NAME:
            return {
                ...state,
                firstName: action.payload,
            };
        case SET_LAST_NAME:
            return {
                ...state,
                lastName: action.payload,
            };
        case SET_EMAIL:
            return {
                ...state,
                email: action.payload,
            };
        case SET_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: action.payload,
            };
        case SET_LOGO:
            return {
                ...state,
                logo: action.payload,
            };
        case UPDATE_USER_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case SET_GUESTS:
            return {
                ...state,
                guests: action.payload,
            };

        case SET_LIST_GUESTS:
            return {
                ...state,
                listGuests: action.payload,
            };
        default:
            return state;
    }
}