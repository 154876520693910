import React,{useEffect,useState} from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import notify from 'devextreme/ui/notify';

//components
import ActionButton from '../assets/elements/button/action-button'
import FloorPlanImage from '../../images/floor plan.png'
import FavoriteButton from '../assets/elements/button/favorite-button'
import Dropdown from '../assets/elements/input/dropdown'
import Loading from '../assets/controls/loading'
import NotLoggedMessage from '../assets/elements/not_logged_message'
//action
import { setFloor } from '../../actions/property-details'

//utils
import { getIsThisPropertyLiked,postFavorite,deleteFavorite } from '../../utils/favorites'
import { postPropertyToCart, checkIfInCart } from '../../utils/property-details'
import {isLoggedIn} from '../../utils/user'
//styles

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/fontawesome-free-solid'
import './styles/basket-form.css'
import baseService from '../../services/base.service';

const BasketForm = ({ floors, floor, setFloor, propertyId, isAddedToCart }) => {
  const [isLiked, setIsLiked] = useState(false)
  const [isLikedLoading, setIsLikedLoading] = useState(false)
  const [isWarningVisible, setIsWarningVisible] = useState(false)
  const [resetWarningVisible, setResetWarningVisible] = useState(false)
  const [isPropertyAddedToCart, setIsPropertyAddedToCart] = useState(false)
  const { id } = useParams();
  const navigate = useNavigate();

  const addToCart = async() => {
      let result = await postPropertyToCart(floor, propertyId);
      await checkIfInCart(propertyId) 
      setIsPropertyAddedToCart(result)
  };

  const handleDropdownChange = (value) => {
    setFloor(value);
    localStorage.setItem("selectedFloorPlanItem", JSON.stringify(value));
  };
  const handleIsPropertyLiked = async _ =>{
    setIsLikedLoading(true)
    let isPropertyLiked = await getIsThisPropertyLiked(propertyId)
    setIsLiked(isPropertyLiked)
    setIsLikedLoading(false)
  }

  useEffect(() => {
      setFloor('')
  }, [id])

    useEffect(() => {
        setIsPropertyAddedToCart(isAddedToCart)
    }, [isAddedToCart])

  useEffect(() => {
    handleIsPropertyLiked()
  }, [])

  const likeAProperty = propertyId => {
    if(isLoggedIn())
      postFavorite(propertyId,id)
    else{
      setResetWarningVisible(!resetWarningVisible)
      setIsWarningVisible(true)
    }
  }
  const unLikeAProperty = propertyId => {
    if(isLoggedIn())
      deleteFavorite(propertyId)
    else{
      setResetWarningVisible(!resetWarningVisible)
      setIsWarningVisible(true)
    }
    }

    const deleteBasketProperty = async(id) => {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart`;
        const formData = new FormData()
        formData.append('PropertyId', propertyId)
        let response = await baseService.delete(url, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: formData
        })
        if (response?.data) {
            setIsPropertyAddedToCart(false)
            notify('Removed from cart', "success", 3000);
        }
        else {
            notify('Something went wrong try again later', "error", 3000);
        }
        return response.data
    }

    const removeFromCart = async() => {
        let response = await deleteBasketProperty(propertyId);
    }
  return (
    <>
          <div className='basketform'>
              {isPropertyAddedToCart ?
                  <React.Fragment>
                      <div className={'added-to-cart-text-block'}>
                          <div><span>You already added this property to cart.</span></div>
                          <div><span>Selected <b>Floor plan</b>: {`${isAddedToCart.floorPlanTypes[0].floorPlanName}`}.</span></div>
                      </div>
                      <div className={'remove-from-cart-block'}>
                          <FontAwesomeIcon icon={faTrash} color={'red'} />
                          <button className={'remove-from-cart'} onClick={removeFromCart}>Remove from cart</button>
                      </div>
                  </React.Fragment>
                  :
                  <React.Fragment>
                      <div className='basketform__input'>
                          <img src={FloorPlanImage} alt="" />
                          <Dropdown classCustom='basketform__input--large' values={floors} value={floor}
                              onChangeValue={(value) => { handleDropdownChange(value); }}></Dropdown>
                      </div>
                      <div className='basketform__buttonarea'>
                          <ActionButton action={addToCart}>{'Add to Cart for Quotes'}</ActionButton>
                          {
                              isLikedLoading ?
                                  <Loading isVisble={isLikedLoading} size={20} />
                                  :
                                  <FavoriteButton key={id} propertyId={propertyId} isLiked={isLiked} likeAProperty={likeAProperty} unLikeAProperty={unLikeAProperty} />
                          }
                      </div>
                  </React.Fragment>
              }
        
      </div>
      <NotLoggedMessage 
        message='to add property in Favourite List'
        visible={isWarningVisible}
        setVisible={setIsWarningVisible}
        resetTrigger={resetWarningVisible}
      />
    </>
    
  )
}
function mapStateToProps({ details }) {
  return {
    floor: details.floor
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setFloor: value => dispatch(setFloor(value))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BasketForm)