import axios from "axios";
import { AuthorizationService } from "../../services/authorization.service";
import NotificationsService from "../notifications.service";
import { history } from "../../components/app";
import store from "../../store";
import { showLoader, hideLoader} from '../../actions/load-indicator'
import baseService from "../base.service";
import { USER_ACCESS_TOKEN } from "../../constant/session";
import { startTrackingCompletedTransaction } from "../../actions/transaction-integration";

const {
    REACT_APP_STS_CLIENT_ID,
    REACT_APP_STS_AUTHORITY,
} = process.env;

export const response = {
    onFulfilled: async (response) => {

        if (response.config.transactionIntegration) {
            if (response.data.transactionId) {

                return startTrackingCompletedTransaction(response.data.transactionId)
                    .then(res => {
                        if (response.config.showLoader !== false && response.config.closeLoaderAfter !== false)
                            store.dispatch(hideLoader());

                        response.transaction = res;
                        return Promise.resolve(response);
                    })
                    .catch(res => {
                        if (response.config.showLoader !== false && response.config.closeLoaderAfter !== false)
                            store.dispatch(hideLoader());

                        response.transaction = res;
                        return Promise.reject(response);
                    });
            } else
                store.dispatch(hideLoader());
        }

        return response;
    },
    onReject: async (error) => {
        
        if (!error.config)
            return Promise.reject(error);

        const originalRequest = error.config;

        if (originalRequest.transactionIntegration && response.config.showLoader !== false)
            store.dispatch(hideLoader());

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            var access_token = JSON.parse(localStorage.getItem(`oidc.user:${REACT_APP_STS_AUTHORITY}:${REACT_APP_STS_CLIENT_ID}`)).access_token;
            if (!access_token) {
                history.replace(`${window.location.origin.toString()}/login`);
                window.location.reload();
                return Promise.reject(error);
            }
            return AuthorizationService.setUserAccessToken(access_token)
                .then(res => {
                    if (res.status === 200) {
                        originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem(USER_ACCESS_TOKEN)).token;

                        return axios(originalRequest)
                            .then(r => r)
                            .catch(e => {
                                history.replace(`${window.location.origin.toString()}/login`);
                                window.location.reload();
                                return Promise.reject(error);
                            });
                    }
                    else if (!res.data) {
                        history.replace(`${window.location.origin.toString()}/login`);
                        window.location.reload();
                        return Promise.reject(error);
                    }
                })
                .catch(error => {
                    history.replace(`${window.location.origin.toString()}/login`);
                    window.location.reload();
                    return Promise.reject(error);
                })
        }
        else if (error.response.status === 404) {
            history.replace(`${window.location.origin.toString()}/not-found`);
            window.location.reload();
        }
        else if (error.response.status === 403) {
            NotificationsService.showErrorNotification("Access is denied. You do not have access to this operation");
        }
        else if (error.response.status === 503) {
            if (window.location.pathname !== '/maintenance') {
                history.replace(`${window.location.origin.toString()}/maintenance`);
                window.location.reload();
            }
        }
        else if (error.response.status === 429) {
            var retryAfter = parseInt(error.response.headers['retry-after']);

            store.dispatch(showLoader());

            await new Promise(resolve => setTimeout(resolve, retryAfter * 1000));

            store.dispatch(hideLoader());

            return baseService(originalRequest);
        }
        else {
            if (error.response.data.message)
                NotificationsService.showErrorNotification(error.response.data.message);
            else if (error.response.data.Message)
                NotificationsService.showErrorNotification(error.response.data.Message);
            else if (error.response.data)
                NotificationsService.showErrorNotification(error.response.data);
            else if (error.message)
                NotificationsService.showErrorNotification(error.message);
        }
        return Promise.reject(error);
    }
};

export const request = {
    onFulfilled: function (config) {
        if (config.transactionIntegration && config.showLoader !== false)
            store.dispatch(showLoader());

        if (localStorage.getItem(USER_ACCESS_TOKEN)) {
            const accessToken = JSON.parse(localStorage.getItem(USER_ACCESS_TOKEN)).token;

            config.headers['Authorization'] = `Bearer ${accessToken}`
        }
       
        return config;
    },
    onReject: function (error) {
        return Promise.reject(error);
    }
}; 
