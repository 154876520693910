import React from "react";
import PropertyRequestForm from "../components/property-request/property-request-form";

const PropertyRequest = () => {
    return (
        <>
            <PropertyRequestForm />
        </>
    );
};

export default PropertyRequest;