export const ADMINISTRATION_TYPES = {
    'jobs': {
        title: 'JOBS',
        columnName: 'Job Name',
        list: [
            {
                name: 'Validate Expired Request Job',
                buttonName: 'Run Job',
                url: '/api/v1/prerequest/validate-expired-requests',
                message: 'Job was successfully created'
            }
        ]
    },
    'reports': {
        title: 'REPORTS'
    },
    'statistics': {
        title: 'STATISTICS',
    }
}