import { useState,useEffect } from 'react'
//components
import Subtitle from '../../assets/elements/section-subtitle'
import AmenitiesList from './amenities-list'
import AmenitiesTag from './amenities-tag'
//images
import PetsImage from '../../../images/pets_friendly.png'
//styles
import '../styles/amenities/amenities.css'
//constant
import {MESSAGE_NO_DATA} from '../../../constant/messages'
const Amenities = ({data,petsAllowed}) => {
  const [amenityTypeData, setAmenityTypeData] = useState(
    {
      property:[],
      unit: []
    }
  )

  useEffect(() => {
    setAmenityTypeData(
      {
        property: data.filter(item => item.amenityTypeId === 1),
        unit: data.filter(item => item.amenityTypeId === 2)
      }
    )
  }, [])
  

  return (
    <div className='amenities'>
      <Subtitle>Amenities</Subtitle>
      {(petsAllowed || data.length > 0) ? 
        <>
          <div className='amenities__tags'>
            {petsAllowed && <AmenitiesTag imagen={PetsImage} name='Pets Friendly'/>}
            {/* {petsAllowed && <AmenitiesTag imagen={PetsImage} name='Indoor Swimming Pool'/>*/}
            {/* petsAllowed && <AmenitiesTag imagen={PetsImage} name='Free Parking'/> */}
            {/*petsAllowed && <AmenitiesTag imagen={PetsImage} name='Free Wi-Fi'/>} */}
          </div>
          <div className='amenities__lists'>
            {amenityTypeData.property.length > 0 && <AmenitiesList title='Property Amenities' data={amenityTypeData.property}/>}
            {amenityTypeData.unit.length > 0 && <AmenitiesList title='Unit Amenities' data={amenityTypeData.unit}/>}
          </div>
        </>
      :
        MESSAGE_NO_DATA
      }
      
    </div>
  )
}

export default Amenities