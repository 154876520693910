import React from "react";
import "./styles/news-section-styles.css";
import NewsSectionArticleAdmin from "./Sub-Components/NewsSectionArticleAdmin";
import { Button } from "devextreme-react/button";
import AdminArticleModal from "./Sub-Components/AdminArticleModal";
import baseService from "../../services/base.service";
import { connect } from "react-redux";
import { newsRequest, handleArticleCreated, handleArticleDeleted, handleArticleUpdated } from "../../utils/news";
import { newsSectionImage } from '../../utils/files'

const NewsAdminComponent = ({ newsData }) => {
    const [showAdminArticleModal, setShowAdminArticleModal] = React.useState(false);
    const handleCloseModal = () => setShowAdminArticleModal(false);
    const disableScroll = React.useCallback(() => showAdminArticleModal ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto", [showAdminArticleModal]);

    React.useEffect(() => {
        disableScroll();
        return () => disableScroll();
    }, [showAdminArticleModal, disableScroll]);

    React.useEffect(() => {
        const minTake = 1000;
        async function fetchData() {
            await baseService({
                method: "GET",
                baseURL: process.env.REACT_APP_API_ENDPOINT,
                url: `/api/v1/news?take=${Math.max(newsData.length, minTake)}`,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(res => {
                newsRequest(res.data.data);
                return res;
            }).catch(res => { return res; });
        };
        fetchData();
    }, [newsData.length, newsRequest]);

    return (
        <>
            <section className="news-section">
                <div className="news-section-bg-container">
                    <div className="news-section-bg">
                        <div className="news-section-title">
                            <h1 className="text-uppercase">news</h1>
                            <h3 className="mt-3">See What's New.</h3>
                        </div>
                    </div>
                </div>
                <div className="news-section-content">
                    <div className="container-fluid" style={{ width: "88%", height: "auto" }}>
                        <div className="row mt-4">
                            <div className="d-flex my-3 p-0 justify-content-end align-items-center">
                                <Button text="Add New Article" type="success"
                                    className="property-request-submit-btn-button"
                                    onClick={() => setShowAdminArticleModal(true)}
                                />
                            </div>
                        </div>
                        <div className="mb-5">
                            {newsData && Array.isArray(newsData) && newsData.length > 0 && (
                                newsData.map((article) =>
                                    article ? (
                                        <NewsSectionArticleAdmin
                                            key={article.id}
                                            id={article.id}
                                            image={newsSectionImage(article.photoId)}
                                            alt={"news-article-image"}
                                            title={article.title}
                                            date={new Intl.DateTimeFormat('en-US', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                            }).format(new Date(article.publicationDate))}
                                            time={new Date().toLocaleTimeString()}
                                            author={article.addedBy}
                                            summary={article.textOfNews}
                                            onArticleDeleted={handleArticleDeleted}
                                            onArticleUpdated={handleArticleUpdated}
                                        />
                                    ) : null
                                )
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {showAdminArticleModal ? <AdminArticleModal onClose={handleCloseModal} onArticleCreated={handleArticleCreated} /> : null}
        </>
    );
};

const mapStateToProps = (state) => { return { newsData: state.news.newsData } };
const mapDispatchToProps = { handleArticleCreated, handleArticleDeleted, handleArticleUpdated };

export default connect(mapStateToProps, mapDispatchToProps)(NewsAdminComponent);