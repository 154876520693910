import {useState} from 'react'

//images
import { useEffect } from 'react'
import OasisIcon from '../../../../images/oasis_sert_icon.png'
import RenovatedIcon from '../../../../images/renovated.png'

//constant
import {MONTH_NAMES} from '../../../../constant/dictionary'

//styles
import '../../styles/title.css'

const Title = ({requestId, optionId, address, buildingType, isCertified, renovatedDate}) => { 
  
  return (
    <div className='request-option__title'>
        <div className='request-option__title__main'>
          <div>OPTION PREVIEW #{optionId}</div>
          <div>FOR REQUEST #{requestId}</div>
        </div>
        <div className='request-option__title__additional'>
          <div className='request-option__title__additional__info'>
            <div>{address}</div>
            <div className='request-option__title__building-type'>{buildingType}</div>
          </div>
          {(isCertified || renovatedDate) && <div className='request-option__title__additional__images'>
            {isCertified && <div className='request-option__title__certified'><img src={OasisIcon} alt="oasis_certified_icon"/></div>}
            {renovatedDate && <div className='request-option__title__renovated'><img src={RenovatedIcon} alt="renovated_icon"/><span>{renovatedDate}</span></div>}
          </div>}
        </div>
        
    </div>
  )
}

export default Title