import { useEffect, useState, useCallback, useRef } from 'react'
//components
import PopupGallery from '../assets/elements/custom-popup-gallery'
//styles
import './styles/gallery.css'
//images
import NoPhoto from '../../images/no_property_photo.png'
import { getPropertyImage } from '../../utils/files'

const Gallery = ({images,floorPlanSize,propertiesSize}) => {
  // const images = ['/File/ImageByFileId?fileId=3482b742-b033-4928-a3e6-3a653c28ebc3',
  // '/File/ImageByFileId?fileId=3482b742-b033-4928-a3e6-3a653c28ebc3',
  // '/File/ImageByFileId?fileId=3482b742-b033-4928-a3e6-3a653c28ebc3',
  // '/File/ImageByFileId?fileId=3482b742-b033-4928-a3e6-3a653c28ebc3',
  // '/File/ImageByFileId?fileId=3482b742-b033-4928-a3e6-3a653c28ebc3',
  // '/File/ImageByFileId?fileId=3482b742-b033-4928-a3e6-3a653c28ebc3'

  // ]

  const [screenSize, setScreenSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  const [customClass, setCustomClass] = useState('')
  const [indexImage, setIndexImage] = useState(0)
  const [displayImages, setDisplayImages] = useState([])
  const [allImages, setAllImages] = useState([])
  const [visible, setVisible] = useState(false)

  const filterImages = width => {
    let filteredData = []
    let qty = width > 992 ? 5 : 3

    filteredData = images.filter((image, index) => index < qty)

    let formattedImages = filteredData.map(image => {

      const extension = image.contentType;
      return {
        backImageUrl: getPropertyImage(image.imageId),
        imageUrl: `/property/images/${image.imageId}.${extension}`
      }
    }
    );
    
    if (formattedImages.length <= 0) {
      for (let i = 0; i < qty; i++) {
        formattedImages.push({
          backImageUrl: `${NoPhoto}`,
          imageUrl: `${NoPhoto}`
        });
      }
    }

    setDisplayImages(formattedImages)
  }
  const changeWidth = useCallback(_ => {
    setScreenSize({
      height: window.innerHeight,
      width: window.innerWidth
    })
  })
  const showPopUp = id => {
    if (images.length > 0) {
      setVisible(!visible)
      setIndexImage(id)
    }
  }
  const changeCustomClass = _ => {
    switch (images.length) {
      case 4:
        setCustomClass('gallery--4')
        break;
      case 3:
        setCustomClass('gallery--3')
        break;
      case 2:
        setCustomClass('gallery--2')
        break;
      case 1:
        setCustomClass('gallery--1')
        break;
      default:
        setCustomClass('gallery')
        break;
    }
  }


  useEffect(() => {
    changeCustomClass()
    filterImages(window.innerWidth)
  }, [screenSize])

  useEffect(() => {
    window.addEventListener('resize', changeWidth, false)
    return () => {
      window.removeEventListener('resize', changeWidth, false)
    }
  }, [])

  useEffect(() => {
      filterImages(window.innerWidth)
      let formattedImages = images.map(image => getPropertyImage(image.imageId))
      setAllImages(formattedImages)
      changeCustomClass()
  }, [images])

  return (
    <>
      <div className={customClass}>
        {displayImages.map((image, index) =>
          <div key={index} className='gallery__image' style={index + 1 === displayImages.length ? { position: 'relative' } : {}}>
            <img src={image.imageUrl}
              alt=""
              style={{
                backgroundImage: `url(${image.backImageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                textIndent: '-10000px'
              }}
              onClick={() => showPopUp(index)} />
            {index + 1 === displayImages.length && ((propertiesSize + floorPlanSize) - displayImages.length) > 0 &&
              <div className='gallery__image__more' onClick={() => showPopUp(0)} style={{ cursor: 'pointer' }}>
                {(propertiesSize - displayImages.length) > 0 && <p>+ {propertiesSize - displayImages.length} {propertiesSize - displayImages.length > 1 ? 'photos': 'photo'}</p>}
                {floorPlanSize > 0 && <p className='gallery__image__more--smaller'>+ {floorPlanSize} floor plan</p>}
              </div>}
          </div>
        )
        }
        <PopupGallery
          visible={visible}
          onHiding={showPopUp}
          images={allImages}
          defaultImage={indexImage}
        />
      </div>
    </>

  )
}

export default Gallery