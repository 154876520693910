import {useCallback,memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-input-alt.css'
import '../../styles/input/custom-number-group-dropdown.css'

//components
import { NumberBox } from 'devextreme-react/number-box';
import { DropDownButton } from 'devextreme-react/drop-down-button';

import {
    Validator,
    RequiredRule,
  } from 'devextreme-react/validator';

const CustomGroupDropdown = ({label, value, setValue,requiredValue, dropdownValue, setDropdownValue,values,isDisabled, min, max,inputIcon}) => {
  const onChangeValue = useCallback((data) => {
      setValue(data)
  }, []);
  const onChangeValueDropdown = useCallback((data) => {
        setDropdownValue(data.item)
    }, []);
  return (
    <div className='custom-input-alt'>
      <div className='custom-input-alt__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
        <div className='button-group'>
        <div className={`custom-input-alt__container ${inputIcon ? '' : 'custom-input-alt__container--noIcon'}`}> 
            <NumberBox
                  value={value}
                  disabled={isDisabled}
                  valueChangeEvent="input"
                  onValueChange={onChangeValue}
                  min={min ?? null}
                  max={max ?? null}
            >
            {requiredValue &&
                <Validator>
                <RequiredRule message={`${label} is required`} />
                </Validator>
            }
            </NumberBox>
            <div className='custom-input-alt__container__icon'><img src={inputIcon}/></div>
        </div>
            <DropDownButton
                    items={values}
                    useSelectMode={true}
                    keyExpr="id"
                    displayExpr="name"
                    selectedItemKey={dropdownValue.id}
                    onSelectionChanged={onChangeValueDropdown}
            />
        </div>
        
    </div>
  )
}

export default memo(CustomGroupDropdown)