import {useEffect} from 'react'
import { connect } from "react-redux";

import SectionSubtitle from '../../assets/elements/section-subtitle'
import GuestCard from './guest-card'

//utils
import {getGuests} from '../../../utils/user-form'

//css
import '../styles/guest-cards.css'
const GuestCards = ({guests}) => {
  useEffect(() => {
    getGuests()
  }, [])
  
  return (
    <>
    { guests.length > 0 &&
      <div>
        <SectionSubtitle>Guest Information That You Booked For</SectionSubtitle>
        <div className='guest-cards'>
            {guests.map((guest,index) => <GuestCard guest={guest} key={index}/>)}
        </div>
      </div>
      }
    </>
  )
}
const mapStateToProps = ({userForm}) => {
    return {
        guests: userForm.guests
    };
};
export default connect(mapStateToProps) (GuestCards)