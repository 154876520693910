import store from "../store";
import baseService from "../services/base.service";

//actions
import {setRequestPartnership,setLoading} from '../actions/become-a-partner'

let controller

const onRequestPartnership = (data) => {

  try {
    store.dispatch(setLoading(true))
    if(controller)
      controller.abort()
    
    controller  = new AbortController();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/request-partnership`;

    baseService.post(url, data, {
    headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
    },
    signal: controller.signal
    }).then(({data})=>{
        if(data)
          store.dispatch(setRequestPartnership({
            status: true,
            message: 'Your request was sent. We will contact you soon.'
          }))
        else
          store.dispatch(setRequestPartnership({
            status: false,
            message: 'Something went wrong. Please try again later.'
          }))
    }).finally(()=>{
        store.dispatch(setLoading(false))
    })
    .catch( error => {
    //   store.dispatch(setPropertyDetailsData([]))
      store.dispatch(setRequestPartnership({
        status: false,
        message: 'Something went wrong. Please try again later. '
      }))
      store.dispatch(setLoading(false))
    })
  } catch (error) {
    // store.dispatch(setPropertyDetailsData([]))
    store.dispatch(setRequestPartnership({
      status: false,
      message: 'Something went wrong. Please try again later. '
    }))
    store.dispatch(setLoading(false))
  }

}

export {onRequestPartnership}