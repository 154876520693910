import { useState,useEffect } from 'react'
import {useNavigationType} from 'react-router-dom'
//components
import SectionTitle from '../assets/elements/section-subtitle'
import PropertyCard from '../assets/controls/property-card'
import NotLoggedMessage from '../assets/elements/not_logged_message'

//utils
import { getPrimaryPropertyImage } from '../../utils/files'
import { postFavorite,deleteFavorite,getFavorites } from '../../utils/favorites'
import { isLoggedIn } from '../../utils/user'

//styles
import './styles/recommendations.css'
const Recommendations = ({data}) => {
  const browserHistoryType = useNavigationType()
  const [formattedData,setFormattedData] = useState([])
  const [isWarningVisible, setIsWarningVisible] = useState(false)
  const [resetWarningVisible,setResetWarningVisible] = useState(false)
  // const data = [
  //   {"id":4359,"metisId":4359,"name":"NINE 15 Apartments","formattedAddress":"915 N Franklin St, Tampa, FL 33602, USA","latitude":27.9518744000000000000000000,"longitude":-82.4591128000000100000000000,"petsAllowed":false,"buildingTypeId":1,"buildingTypeName":"Hotel","imageUrl":"/api/v1/files/images/ebcfb7f8-73ee-4f91-b19f-37e6bb8c15e3","recentlyRenovated":null,"dateOfRenovation":null,"renovationDetails":null,"distanceInMiles":0.14,"floorPlans":[],"amenities":[]},
  //   {"id":9441,"metisId":9441,"name":"MetisProperty222","formattedAddress":"Tampa, FL, USA","latitude":27.9505750000000000000000000,"longitude":-82.4571776000000000000000000,"petsAllowed":false,"buildingTypeId":1,"buildingTypeName":"Hotel","imageUrl":null,"recentlyRenovated":null,"dateOfRenovation":null,"renovationDetails":null,"distanceInMiles":0.18,"floorPlans":[],"amenities":[]},
  //   {"id":9320,"metisId":9320,"name":"prop_4","formattedAddress":"Tampa Riverwalk, Tampa, FL, USA","latitude":27.9446677000000000000000000,"longitude":-82.4587858000000000000000000,"petsAllowed":false,"buildingTypeId":1,"buildingTypeName":"Hotel","imageUrl":null,"recentlyRenovated":null,"dateOfRenovation":null,"renovationDetails":null,"distanceInMiles":0.37,"floorPlans":[],"amenities":[]},
  //   {"id":9337,"metisId":9337,"name":"p_03192021_1","formattedAddress":"Tampa Riverwalk, Tampa, FL, USA","latitude":27.9446677000000000000000000,"longitude":-82.4587858000000000000000000,"petsAllowed":true,"buildingTypeId":1,"buildingTypeName":"Hotel","imageUrl":null,"recentlyRenovated":null,"dateOfRenovation":null,"renovationDetails":null,"distanceInMiles":0.37,"floorPlans":[],"amenities":[]}
  // ]

  const setFavorites = async (data) => {
      let dataFavorites = await getFavorites()
      let favoriteIds = dataFavorites.map(item => item.id)
      let formattedData = data.map(property=>{
        let likeId = dataFavorites.filter(item => item.propertyId === property.id)
        return {
          ...property,
            isLiked: favoriteIds.includes(property.id),
            likeId: likeId[0]?.id,
        }
      })
      setFormattedData(formattedData)
  }

  const likeAProperty = (propertyId,metisId) => {
    if(isLoggedIn())
      postFavorite(propertyId,metisId)
    else{
      setResetWarningVisible(!resetWarningVisible)
      setIsWarningVisible(true)
    }
  }

  const unLikeAProperty = id => {
    if(isLoggedIn())
      deleteFavorite(id)
    else{
      setResetWarningVisible(!resetWarningVisible)
      setIsWarningVisible(true)
    }
  }

  useEffect(() => {
    let newData = data.map(item => ({
      ...item,
        imageUrl: getPrimaryPropertyImage(item.id)
    }))
    setFormattedData(newData)
  }, [data])

  useEffect(() => {
    if(data.length > 0 && browserHistoryType !== 'PUSH')
    {
      setFavorites(data)
    }
  }, [])
  return (
    <>
      <div className='recommendations'>
        <SectionTitle>You May Also Like</SectionTitle>
        <div className='recommendations__list'>
          {formattedData.map( item => 
            <PropertyCard key={item.id} data={item} likeAProperty={likeAProperty} unLikeAProperty={unLikeAProperty}/>)
          }
        </div>
      </div>
      <NotLoggedMessage 
        message='to add property in Favourite List'
        visible={isWarningVisible}
        setVisible={setIsWarningVisible}
        resetTrigger={resetWarningVisible}
      />
    </>
    
  )
}

export default Recommendations