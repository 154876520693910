import React from "react"
import "./styles/our-awards-styles.css"
import ImageSlider from "./Sub-Components/imageSlider";

const ourAwards = () => {
    return (
        <section className="our-awards" id="section-our-awards">
            <div className="row">
                <h3 className="text-center text-uppercase">awards &amp; accolades</h3>
            </div>
            <ImageSlider />
        </section>
    )
}

export default ourAwards