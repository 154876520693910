import React from "react";
import { Link } from "react-router-dom";
import "../styles/image-grid.css";

const ImageGrid = () => {
    const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    React.useEffect(() => {
        const handleResize = () => setPageWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="image-grid-container">
            <div className="row p-1 set-row">
                <div className="col-md-4 my-3 set-row-col">
                    <div className="new-york">
                        <Link
                            to='/property-search/New%20York,%20NY,%20USA/ChIJOwg_06VPwokRYv534QaPC8g'
                            onClick={scrollToTop}
                        >
                            <span>new york</span>
                        </Link>
                    </div>
                </div>
                <div className="col-md-8 my-3 set-row-col">
                    <div className="los-angeles">
                        <Link
                            to='/property-search/Los%20Angeles,%20CA,%20USA/ChIJE9on3F3HwoAR9AhGJW_fL-I'
                            onClick={scrollToTop}
                        >
                            <span>los angeles</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row p-1 set-row">
                {pageWidth >= 991 ? (
                    <>
                        <div className="col-md-4 my-3 set-row-col">
                            <div className="paris">
                                <Link
                                    to='/property-search/Paris,%20France/ChIJD7fiBh9u5kcRYJSMaMOCCwQ'
                                    onClick={scrollToTop}
                                >
                                    <span>paris</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 my-3 set-row-col">
                            <div className="miami">
                                <Link
                                    to='/property-search/Miami,%20FL,%20USA/ChIJEcHIDqKw2YgRZU-t3XHylv8'
                                    onClick={scrollToTop}
                                >
                                    <span>miami</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 my-3 set-row-col">
                            <div className="sydney">
                                <Link
                                    to='/property-search/Sydney%20NSW,%20Australia/ChIJP3Sa8ziYEmsRUKgyFmh9AQM'
                                    onClick={scrollToTop}
                                >
                                    <span>sydney</span>
                                </Link>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-md-4 my-3 set-row-col">
                            <div className="paris">
                                <Link
                                    to='/property-search/Paris,%20France/ChIJD7fiBh9u5kcRYJSMaMOCCwQ'
                                    onClick={scrollToTop}
                                >
                                    <span>paris</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 my-3 set-row-col">
                            <div className="miami">
                                <Link
                                    to='/property-search/Miami,%20FL,%20USA/ChIJEcHIDqKw2YgRZU-t3XHylv8'
                                    onClick={scrollToTop}
                                >
                                    <span>miami</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="row p-1 set-row">
                {pageWidth >= 991 ? (
                    <>
                        <div className="col-md-8 my-3 set-row-col">
                            <div className="london">
                                <Link
                                    to='/property-search/London,%20UK/ChIJdd4hrwug2EcRmSrV3Vo6llI'
                                    onClick={scrollToTop}
                                >
                                    <span>london</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 my-3 set-row-col">
                            <div className="singapore">
                                <Link
                                    to='/property-search/Singapore/ChIJdZOLiiMR2jERxPWrUs9peIg'
                                    onClick={scrollToTop}
                                >
                                    <span>singapore</span>
                                </Link>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-md-8 my-3 set-row-col">
                            <div className="sydney">
                                <Link
                                    to='/property-search/Sydney%20NSW,%20Australia/ChIJP3Sa8ziYEmsRUKgyFmh9AQM'
                                    onClick={scrollToTop}
                                >
                                    <span>sydney</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 my-3 set-row-col">
                            <div className="london">
                                <Link
                                    to='/property-search/London,%20UK/ChIJdd4hrwug2EcRmSrV3Vo6llI'
                                    onClick={scrollToTop}
                                >
                                    <span>london</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
                }
                {pageWidth < 991 && (
                    <>
                        <div className="col-md-4 my-3 set-row-col">
                            <div className="singapore">
                                <Link
                                    to='/property-search/Singapore/ChIJdZOLiiMR2jERxPWrUs9peIg'
                                    onClick={scrollToTop}
                                >
                                    <span>singapore</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default ImageGrid;