import React from "react"

const LogoWhite = () => {
    return (
        <img
            src={require("../../../assets/logos/Oasis_1c_inverted.png")}
            width="113px"
            height="56px"
            alt="oasis-logo"
        />
    )
}

export default LogoWhite;