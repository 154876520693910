import {useCallback,useRef,memo,useEffect} from 'react'
import 'devextreme/dist/css/dx.light.css';

//components

import { DropDownBox } from 'devextreme-react/drop-down-box';
import SelectBox from "devextreme-react/select-box";

import { List } from "devextreme-react/list";

//style
import '../../styles/input/custom-dropdown.css'
const Dropdown = ({values,value,setValue,disable,label,requiredValue,labelSize,isGroup,isEmpty,setIsEmpty,valueName,placeholder}) => {
  const dropDownBoxRef = useRef(null);
  const changeDropDownBoxValue = useCallback((arg) => {
      setValue(arg.addedItems[0].id);
      dropDownBoxRef.current.instance.close();
  }, []);
  const changeSelecboxValue = useCallback((e) => {
    setValue(e.value);
}, []);
  useEffect(() => {
    if(value)
    {
      setIsEmpty && setIsEmpty(false)
    }
  }, [value])
  
  return (
    <div className={`custom-dropdown ${label ? '' : 'custom-dropdown--nolabel'}`} style={labelSize ? {gridTemplateColumns: `${labelSize} calc(100% - ${labelSize} - 20px)`} : {}}>
       {label && <div className='custom-dropdown__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>}
        <div className={isEmpty ? 'custom-dropdown__container--error':''}>
          {isGroup ?
            <SelectBox
            dataSource={values}
            placeholder="Choose option"
            displayExpr={valueName ? valueName : "name"}
            valueExpr="id"
            grouped={true}
            groupTemplate={data=>data.key}
            value={value}
            onValueChanged={changeSelecboxValue}
            disabled={disable}
            />
            :
            <DropDownBox
            value={[value]}
            ref={dropDownBoxRef}
            placeholder={placeholder ? `Choose ${placeholder}` : "Choose option"}
            valueExpr="id"
            displayExpr="name"
            keyExpr="id"
            dataSource={values}
            disabled={disable}
            >
              <List
                  className='custom-dropdown__list'
                  selectionMode="single"
                  dataSource={values}
                  valueExpr="id"
                  displayExpr="name"
                  keyExpr="id"
                  selectedItemKeys={[value]}
                  onSelectionChanged={changeDropDownBoxValue}
              />
              </DropDownBox>
            }
          {(requiredValue &&  isEmpty) ? <div className='custom-dropdown__container__message--error'>{label ? label : placeholder} is required</div> : null}
        </div>
        
    </div>
   
  )
}
export default memo(Dropdown)