import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import SearchLocation from '../../../assets/elements/input/search-location'

import InputCalendar from '../../../assets/controls/input-calendar'
import Wrapper from '../../../assets/controls/google-wrapper'
import MainButton from '../../../assets/elements/button/main-button'

import { changeCheckIn, changeCheckOut, changeLocation } from '../../../../actions/landing'


const SearchBar = ({ location, checkIn, checkOut, changeCheckIn, changeCheckOut, changeLocation,clearLocation }) => {
  const navigate = useNavigate()
  const [error, setError] = useState(false)
  const [locationIsSelected, setlocationIsSelected] = useState(false)
  const [errorCalendar, setErrorCalendar] = useState(false)
  const searchProperties = () => {
    if (location.id) {
      setError(false)
      if (checkIn || checkOut) {
        if (checkIn && checkOut) {
          setErrorCalendar(false)
          navigate(`/property-search/${location.value}/${location.id}/${checkIn}/${checkOut}`)
        }
        else
          setErrorCalendar(true)
      }
      else {
        setErrorCalendar(false)
        navigate(`/property-search/${location.value}/${location.id}`)
      }
    }
    else {
      setError(true)
    }
  }
  useEffect(() => {
    if(locationIsSelected)
    {
        searchProperties()
        setlocationIsSelected(false)
    }
  }, [locationIsSelected])
  useEffect(() => {  
    return () => {
      clearLocation()
    }
  }, [])
  
  return (
    <div className='search_bar' >
      <Wrapper>
        <SearchLocation inputId='searchPropertySearchBarLocation' location={location} onChange={changeLocation} classCustom={`input-search brandon-black`} placeholder='CITY/STATE/ZIP' requiredValue={true} setIsSelected={setlocationIsSelected}/>
        {/* <InputText onChange={changeLocation} classCustom={`input-search ${error && 'input-search--error'} brandon-black`} placeholder='CITY/STATE/ZIP'></InputText> */}
      </Wrapper>
      <div className='calendar-container'>
        <InputCalendar error={errorCalendar} changeCheckIn={changeCheckIn} changeCheckOut={changeCheckOut} />
      </div>
      <MainButton size='small' action={searchProperties}>SEARCH</MainButton>
    </div>
  )
}
function mapStateToProps({ landing }) {
  return {
    location: landing.location,
    checkIn: landing.checkIn,
    checkOut: landing.checkOut
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCheckIn: data => dispatch(changeCheckIn(data)),
    changeCheckOut: data => dispatch(changeCheckOut(data)),
    changeLocation: data => dispatch(changeLocation(data)),
    clearLocation: () => dispatch(changeLocation({
      id: '',
      value: ''
    }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)