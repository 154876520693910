import {
    setCompany,
    setFirstName,
    setLastName,
    setPhoneNumber,
    setEmailAddress,
    setDestinationCity,
    setAccommodationLevel,
    setPropertyName,
    setNumberOfBedrooms,
    setMaximumCommuteDistance,
    setMaximumCommuteDistanceType,
    setNightlyBudget,
    setBudgetCurrencyId,
    setCheckInDate,
    setCheckOutDate,
    setDatesIsFlexible,
    setTotalAdults,
    setTotalChildren,
    setSpecialRequirements,
    resetQuoteForm
} from "../actions/property-request";
import store from "../store";

export const updateCompany = (e) => {
    store.dispatch(setCompany(e));
};

export const updateFirstName = (e) => {
    store.dispatch(setFirstName(e));
};

export const updateLastName = (e) => {
    store.dispatch(setLastName(e));
};

export const updatePhoneNumber = (e) => {
    store.dispatch(setPhoneNumber(e));
};

export const updateEmailAddress = (e) => {
    store.dispatch(setEmailAddress(e));
};

export const updateDestinationCity = (e) => {
    store.dispatch(setDestinationCity(e));
};

export const updateAccommodationLevel = (e) => {
    store.dispatch(setAccommodationLevel(e));
};

export const updatePropertyName = (e) => {
    store.dispatch(setPropertyName(e));
};

export const updateNumberOfBedrooms = (e) => {
    store.dispatch(setNumberOfBedrooms(e));
};

export const updateMaximumCommuteDistance = (e) => {
    store.dispatch(setMaximumCommuteDistance(e));
};

export const updateMaximumCommuteDistanceType = (e) => {
    store.dispatch(setMaximumCommuteDistanceType(e));
};

export const updateNightlyBudget = (e) => {
    store.dispatch(setNightlyBudget(e));
};

export const updateBudgetCurrencyId = (e) => {
    store.dispatch(setBudgetCurrencyId(e));
};

export const updateCheckInDate = (e) => {
    store.dispatch(setCheckInDate(e));
};

export const updateCheckOutDate = (e) => {
    store.dispatch(setCheckOutDate(e));
};

export const updateDatesIsFlexible = (e) => {
    store.dispatch(setDatesIsFlexible(e));
};

export const updateTotalAdults = (e) => {
    store.dispatch(setTotalAdults(e));
};

export const updateTotalChildren = (e) => {
    store.dispatch(setTotalChildren(e));
};

export const updateSpecialRequirements = (e) => {
    store.dispatch(setSpecialRequirements(e));
};

export const updateQuoteForm = () => {
    store.dispatch(resetQuoteForm());
};