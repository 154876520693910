import '../styles/section-header.css'
import SectionButton from './button/section-header-button'
const SectionHeader = ({title,subtitle,buttonLabel,navigateTo,image}) => {
  
  return (
    <div className='section-header' style={{backgroundImage: `url(${image})`}}>
            <div className='section-header__title'>{title}</div>
            <div className='section-header__subtitle'>{subtitle}</div>
            {buttonLabel && navigateTo && <div className='section-header__button'><SectionButton navigate={navigateTo}>{buttonLabel}</SectionButton></div>}
    </div>
  )
}

export default SectionHeader