import '../styles/guest-card.css'
const GuestCard = ({guest}) => {

  return (
    <>
    {
        guest &&
        <div className='guest-card'>
            {Object.keys(guest).map((item,index)=>
                <div key={index} className='guest-card__field'>
                    <div className='guest-card__field__name'>{guest[item].name}:</div>
                    <div className='guest-card__field__value'>{guest[item].value}</div>
                </div>
            )}
        </div>
    }
    </>
   
  )
}

export default GuestCard