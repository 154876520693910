import { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import baseService from "../../services/base.service";

//components
import GuestCards from './Sub-Components/guests-cards'
import ContentWrapper from '../assets/elements/content-wrapper'
import SectionSubtitle from '../assets/elements/section-subtitle'
import ProfileImage from "./Sub-Components/ProfileImage";
import ShowSaveButtons from "./Sub-Components/ShowSaveButtons";
import ManageButton from "./Sub-Components/ManagAccountInfoButton";
import EditPhotoModal from "./Sub-Components/EditPhotoModal";
import CustomPhone from '../assets/elements/input/custom-phone'
import DefaultAvatar from "../../images/default-avatar.png";
import SecondaryButton from '../assets/elements/button/secondary-action-button'
import CustomPopup from '../assets/elements/custom-popup'

//devextreme
import TextBox from "devextreme-react/text-box";
import Validator from "devextreme-react/validator";
import { RequiredRule, PatternRule } from "devextreme-react/form";
import { Button } from "devextreme-react/button";

//styles
import "./styles/profile-styles.css";
import "devextreme/dist/css/dx.light.css";

//utils
import { updateUserProfileImage, setIsLoading, setIsAccountUpdated, handleLogOut } from "../../utils/user-form";


const UserForm = ({ isLoading, isAccountUpdated, profileImage, id, firstName, lastName, email, phoneNumber }) => {
    const [showEditPhotoModal, setShowEditPhotoModal] = useState(false);
    // eslint-disable-next-line
    const navigate = useNavigate();
    const [formInputs, setFormInputs] = useState({ firstName, lastName, email, phoneNumber, disabled: true });
    const [formInputsDefault, setFormInputsDefault] = useState({ firstName, lastName, email, phoneNumber, disabled: true });

    const [phone, setPhone] = useState("");
    const [isPhoneValidState, setIsPhoneValidState] = useState(true)
    const [isPhoneValidMessage, setIsPhoneValidMessage] = useState("");
    const [visible, setVisible] = useState(false)
    const onHiding = _ => {
        setVisible(!visible)
    }
    useEffect(() => {
        if (isAccountUpdated) {
            setIsAccountUpdated(false);
            setFormInputsDefault({ ...formInputs, phoneNumber: phone, disabled: true })
        }
    }, [isAccountUpdated, formInputs, phone])
    async function putUserData() {
        setIsLoading(true)
        await baseService({
            method: "PUT",
            baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
            url: `/api/v1/accounts/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: { firstName: formInputs.firstName, lastName: formInputs.lastName, email: formInputs.email, phoneNumber: phone, logo: profileImage }
        }).then(({ data }) => {
            setIsAccountUpdated(true);
        }).finally(() => {
            setIsLoading(false);
        }).catch(res => console.log(res));
    };

    useEffect(() => {
        async function fetchUserData() {
            await baseService({
                method: "GET",
                baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
                url: `/api/v1/accounts/${id}`,
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(res => {
                setFormInputs({
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    email: res.data.email,
                    phoneNumber: res.data.phoneNumber,
                    disabled: true
                });
                setPhone(res.data.phoneNumber)
                setFormInputsDefault({
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    email: res.data.email,
                    phoneNumber: res.data.phoneNumber,
                    disabled: true
                })
                updateUserProfileImage(res.data.logo ? res.data.logo : null);
            }).catch(res => console.log(res));
        };
        fetchUserData();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (phone && isPhoneValidState) {
            putUserData();
            updateUserProfileImage(profileImage);
            setFormInputs(prevState => ({ ...prevState, disabled: true }));
        }
        else {
            setIsPhoneValidState(false)
            setIsPhoneValidMessage('The phone is required')
        }
    };
    const handleCancel = useCallback(_ => {
        setFormInputs(formInputsDefault)
        setPhone(formInputsDefault.phoneNumber)
        setIsPhoneValidState(true)
        setIsPhoneValidMessage('')
    }, [formInputsDefault])
    const handleMenuEvents = () => setFormInputs(prevState => ({ ...prevState, disabled: false }));
    const disableScroll = useCallback(() => showEditPhotoModal ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto", [showEditPhotoModal]);

    useEffect(() => {
        disableScroll();
        return () => disableScroll();
    }, [showEditPhotoModal, disableScroll]);

    const formStyles = formInputs.disabled ? `user-form-label-disabled label span` : `user-form-label label span`;

    return (
            <ContentWrapper>
                    <div className="user-profile">
                        <div>
                            <div className="user-profile__username">
                                <div className="user-profile__username__title">Hello,&nbsp;{formInputs.firstName}&nbsp;{formInputs.lastName}!</div>
                                <SecondaryButton action={handleLogOut}>Log Out</SecondaryButton>
                                {/* <Button className="log-out-btn px-3 py-1" onClick={handleLogOut}>Log Out</Button> */}
                            </div>
                            <div className="user-profile__email">
                                <p>You are logged in as<span className="fw-bold offset-title">&nbsp;{formInputs.email}</span></p>
                            </div>
                        </div>
                        {/* User Info */}
                        <div className="user-profile__sections">
                            <div>
                                <SectionSubtitle>Your Primary Account Information</SectionSubtitle>
                                <div className="user-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="user-form__container">
                                            <div className={`user-form__container__fields ${formInputs.disabled ? '' : 'user-form__container__fields--textbox'}`}>
                                                <div className="user-form__container__fields__field">
                                                    <span className="user-form__container__fields__field__label">First Name:</span>
                                                    {formInputs.disabled ? (
                                                        <p className="text-muted offset-font">{formInputs.firstName}</p>
                                                    ) : (
                                                        <div className="user-form__container__fields__field__input">

                                                        <TextBox disabled={formInputs.disabled} value={formInputs.firstName}
                                                            onValueChanged={(e) => setFormInputs(prevState => ({ ...prevState, firstName: e.value }))}
                                                            name="firstName">
                                                            <Validator>
                                                                <RequiredRule message="First Name is required" />
                                                                <PatternRule pattern="^[a-zA-Z\s]+$" message="Your first name should only contain letters and spaces" />
                                                            </Validator>
                                                        </TextBox>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="user-form__container__fields__field">
                                                    <span className="user-form__container__fields__field__label">Last Name:</span>
                                                    {formInputs.disabled ? (
                                                        <p className="text-muted offset-font">{formInputs.lastName}</p>
                                                    ) : (
                                                        <div className="user-form__container__fields__field__input">
                                                            <TextBox disabled={formInputs.disabled} value={formInputs.lastName}
                                                                onValueChanged={(e) => setFormInputs(prevState => ({ ...prevState, lastName: e.value }))}
                                                                name="lastName">
                                                                <Validator>
                                                                    <RequiredRule message="Last Name is required" />
                                                                    <PatternRule pattern="^[a-zA-Z\s]+$" message="Your last name should only contain letters and spaces" />
                                                                </Validator>
                                                            </TextBox>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="user-form__container__fields__field">
                                                    <span className="user-form__container__fields__field__label">Email:</span>
                                                    {formInputs.disabled ? (
                                                        <p className="text-muted offset-font">{formInputs.email}</p>
                                                    ) : (
                                                        <div className="user-form__container__fields__field__input">
                                                            <TextBox disabled={formInputs.disabled} value={formInputs.email}
                                                                onValueChanged={(e) => setFormInputs(prevState => ({ ...prevState, email: e.value }))}
                                                                name="email">
                                                                <Validator>
                                                                    <RequiredRule message="Email is required" />
                                                                    <PatternRule pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" message="Please enter a valid email address" />
                                                                </Validator>
                                                            </TextBox>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="user-form__container__fields__field">
                                                    <span className="user-form__container__fields__field__label">Phone:</span>
                                                    {formInputs.disabled ? (
                                                            <CustomPhone
                                                                labelMode={true}
                                                                isDisabled={true}
                                                                value={phone}
                                                            />
                                                    ) : (
                                                        <div className="user-form__container__fields__field__input">
                                                            <CustomPhone
                                                                isDisabled={formInputs.disabled}
                                                                value={phone}
                                                                setValue={setPhone}
                                                                isValid={isPhoneValidState}
                                                                setIsValid={setIsPhoneValidState}
                                                                validMessage={isPhoneValidMessage}
                                                                setValidMessage={setIsPhoneValidMessage}
                                                                requiredValue={!formInputs.disabled}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                {formInputs.disabled ?
                                                    <div>
                                                        <ManageButton isLoading={isLoading} handleMenuEvents={handleMenuEvents} label='Manage Account Info'/>
                                                    </div> :
                                                    <div className="user-form__container__buttons">
                                                        <ShowSaveButtons handleCancel={handleCancel} />
                                                    </div>
                                                }
                                            </div>
                                            <div className="user-form__container__photo">
                                                <div>
                                                    <ProfileImage
                                                        setShowEditPhotoModal={onHiding}
                                                        DefaultAvatar={DefaultAvatar}
                                                        profileImage={profileImage}
                                                    />
                                                </div>
                                            </div>
                                            <CustomPopup visible={visible} onHiding={onHiding}>
                                                <EditPhotoModal
                                                    profileImage={profileImage ? `data:image/jpeg;base64,${profileImage}` : DefaultAvatar}
                                                    updateUserProfileImage={profileImage ? `data:image/jpeg;base64,${profileImage}` : null}
                                                    id={id}
                                                    onClose={onHiding}
                                                />
                                            </CustomPopup>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <GuestCards />
                        </div>
                    </div>                
            </ContentWrapper >
    )
}

const mapStateToProps = (state, auth) => {
    return {
        isLoading: state.userForm.isLoading,
        profileImage: state.userForm.profileImage,
        email: state.userForm.email,
        firstName: state.userForm.firstName,
        lastName: state.userForm.lastName,
        phoneNumber: state.userForm.phoneNumber,
        idToken: auth.idToken
    };
};

export default connect(mapStateToProps)(UserForm);