import React from "react";
import { Link, useLocation } from "react-router-dom";
import serverIcon from "../images/server-icon.png";
import "./maintenance-page/styles/maintenance-styles.css";

const PropertyNotFoundPage = () => {
    const location = useLocation();
    const { id } = location.state || { id: '' };
    const pageStyles = {
        width: "100%",
        height: "auto",
        minHeight: "100vh",
    };

    return (
        <div style={pageStyles}>
            <div className="container mt-5 maintenance-container">
                <div className="row">
                    <div className="col-xs-1 col-sm-1 col-md-1 justify-content-center align-items-center mx-auto text-center">
                        <div className="server-icon-container">
                            <img src={serverIcon} alt="server icon" className="server-icon mb-3" />
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-12 col-md-11 justify-content-center align-items-center">
                        <p className="p-0 m-0">
                            Property {id} is unavailable on Stay With Oasis site.<br />Please contact us via <span className="maintenance-link">&#60;email@email.com&#62;</span> for more information.<br />
                        </p>
                        Go To&nbsp;
                        <Link to="/" className="maintenance-link">
                            Landing Page
                        </Link>
                        &nbsp;or&nbsp;
                        <Link to="/property-search" className="maintenance-link">
                            Property-Search
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyNotFoundPage;