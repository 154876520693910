import store from "../store";
import baseService from "../services/base.service";
//constants
import {PAGINATION,CAREERS_LIST,CAREERS_LIST_HISTORY,CAREER_DETAILS,CAREER_APPLY,CAREERS_LIST_ADMIN_FORM} from '../constant/career'
//actions
import {
    setCareerListPaginationCurrent, setAdminFormMessage, setAdminFormDefault, setAdminFormCompensation, setAdminFormDescription, setAdminFormJob, setAdminFormLocation, setAdminFormQualification, setAdminFormResponsabilities, setCareerList, setCareerListTotal, setLoadingCareerList, setMessageCareerList, setCareerListHistory, setCareerListTotalHistory, setLoadingCareerListHistory, setMessageCareerListHistory, setCareerListById, setLoadingCareerById, setApplyLoading, setApplyMessage, setDefaultApply, setDefaultApplyMessage, setMessageCareerById
} from '../actions/career'

//utils
// import userManager from './user-manager'

let controller

//GET
const getVacancies = async (skip) => {
  try {
    store.dispatch(setLoadingCareerList(true))

    if(controller)
      controller.abort()
    
    controller  = new AbortController();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/vacancies?skip=${skip}&take=${PAGINATION.take}&requireTotalCount=true`;

    baseService.get(url, {
    headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
    },
    signal: controller.signal
    }).then(({data}) => {
        if(data?.data?.length > 0)   
        {
          store.dispatch(setCareerList(data.data))
          store.dispatch(setCareerListTotal(data.totalCount))
          store.dispatch(setMessageCareerList(false,''))
        }   
        else
        {
            store.dispatch(setCareerList([]))
            store.dispatch(setCareerListTotal(0))
            store.dispatch(setMessageCareerList(false,CAREERS_LIST.messages.nodata))
        }
        
    }).finally(()=>{
        store.dispatch(setLoadingCareerList(false))
    })
    .catch( error => {
        store.dispatch(setLoadingCareerList(false))
        store.dispatch(setCareerList([]))
        store.dispatch(setMessageCareerList(true,CAREERS_LIST.messages.error))
    })
    
  } catch (error) {
    store.dispatch(setLoadingCareerList(false))
    store.dispatch(setCareerList([]))
    store.dispatch(setMessageCareerList(true,CAREERS_LIST.messages.error))
  }

}
const getVacanciesById = async (id) => {

  try {
    store.dispatch(setLoadingCareerById(true))

    if(controller)
      controller.abort()
    
    controller  = new AbortController();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/vacancies/${id}`;
    baseService.get(url,{
    headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
    },
    signal: controller.signal
    }).then(({data}) => {
        store.dispatch(setCareerListById(data))
        if(Object.keys(data).length > 0 )
          store.dispatch(setMessageCareerById(false,''))
        else
          store.dispatch(setMessageCareerById(false,CAREER_DETAILS.messages.nodata))
    }).finally(()=>{
      store.dispatch(setLoadingCareerById(false))
    })
    .catch( error => {
        store.dispatch(setLoadingCareerById(false))
        store.dispatch(setCareerListById({}))
        store.dispatch(setMessageCareerById(true,CAREER_DETAILS.messages.error))
    })
    
  } catch (error) {
    store.dispatch(setLoadingCareerById(false))
    store.dispatch(setCareerListById({}))
    store.dispatch(setMessageCareerById(true,CAREER_DETAILS.messages.error))
  }
  
}
const getHistory = async (skip) => {
  try {
    store.dispatch(setLoadingCareerListHistory(true))

    if(controller)
      controller.abort()
    
    controller  = new AbortController();
    const url = `/api/v1/vacancies/history?skip=${skip}&take=${PAGINATION.take}&requireTotalCount=true`;
    baseService({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      url: url,
      headers:{
        'Content-Type': 'application/json'
      },
      signal: controller.signal
    }).then(({data}) => {
      if(data?.data?.length > 0)   
      {
        store.dispatch(setCareerListHistory(data.data))
        store.dispatch(setCareerListTotalHistory(data.totalCount))
        store.dispatch(setMessageCareerListHistory(false,''))
      }   
      else
      {
          store.dispatch(setCareerListHistory([]))
          store.dispatch(setCareerListTotalHistory(0))
          store.dispatch(setMessageCareerListHistory(false,CAREERS_LIST_HISTORY.messages.nodata))
      }
      
  }).finally(()=>{
      store.dispatch(setLoadingCareerListHistory(false))
  })
  .catch( error => {
      store.dispatch(setLoadingCareerListHistory(false))
      store.dispatch(setCareerListHistory([]))
      store.dispatch(setMessageCareerListHistory(true,CAREERS_LIST_HISTORY.messages.error))
  })
  
} catch (error) {
  store.dispatch(setLoadingCareerListHistory(false))
  store.dispatch(setCareerListHistory([]))
  store.dispatch(setMessageCareerListHistory(true,CAREERS_LIST_HISTORY.messages.error))
}

}

const getVacanciesByIdAdmin = async (id) => {

  try {
    // store.dispatch(setLoadingCareerById(true))

    if(controller)
      controller.abort()
    
    controller  = new AbortController();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/vacancies/${id}`;
    baseService.get(url,{
    headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
    },
    signal: controller.signal
    }).then(({data}) => {
      store.dispatch(setAdminFormCompensation(data.compensation))
      store.dispatch(setAdminFormDescription(data.shortDescription))
      store.dispatch(setAdminFormJob(data.jobTitle))
      store.dispatch(setAdminFormLocation(data.location))
      store.dispatch(setAdminFormQualification(data.qualifications))
      store.dispatch(setAdminFormResponsabilities(data.responsibilities))

      // store.dispatch(setCareerListById(data))
        // if(Object.keys(data).length > 0 )
        //   store.dispatch(setMessageCareerById(false,''))
        // else
        //   store.dispatch(setMessageCareerById(false,CAREER_DETAILS.messages.nodata))
      
      }).finally(()=>{
      // store.dispatch(setLoadingCareerById(false))
    })
    .catch( error => {
        // store.dispatch(setLoadingCareerById(false))
        // store.dispatch(setCareerListById({}))
        // store.dispatch(setMessageCareerById(true,CAREER_DETAILS.messages.error))
    })
    
  } catch (error) {
    // store.dispatch(setLoadingCareerById(false))
    // store.dispatch(setCareerListById({}))
    // store.dispatch(setMessageCareerById(true,CAREER_DETAILS.messages.error))
  }
  
}

//POST
const postVacancyApply = async (id) => {
try {
  store.dispatch(setApplyLoading(true))
  let applyData = store.getState().career.careerApply

  if(controller)
    controller.abort()
  
  
  controller  = new AbortController();
  const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/vacancies/candidates/${id}`;
  const formData = new FormData()
  formData.append('FirstName',applyData.firstname)
  formData.append('LastName',applyData.lastname)
  formData.append('Email',applyData.email)
  formData.append('Resume',applyData.resume[0])
  formData.append('PhoneNumber',applyData.phone)
  formData.append('CoverLetter',applyData.coverLetter)
  formData.append('HowDidYouHearAboutUsType',applyData.hearUs)
  baseService.post(url,formData,{
  headers: {
      'accept': 'application/json',
      'Content-Type': 'application/json'
  },
  signal: controller.signal
  }).then(({data}) => {    
      store.dispatch(setApplyMessage({
        status: false,
        description: CAREER_APPLY.messages.success
      }))      
      store.dispatch(setDefaultApply())

  }).finally(()=>{
    store.dispatch(setApplyLoading(false))
    store.dispatch(setDefaultApplyMessage())   
  })
  .catch( error => {
      store.dispatch(setApplyMessage({
        status: true,
        description: CAREER_APPLY.messages.error
      }))
  })
  
} catch (error) {
  store.dispatch(setApplyLoading(false))
  store.dispatch(setApplyMessage({
    status: true,
    description: CAREER_APPLY.messages.error
  }))
}

}

const postCreateVacancy = async () => {
  return new Promise((resolve, reject) => {
  try {
    // store.dispatch(setApplyLoading(true))
    let careerFormData = store.getState().career.careerAdminForm
    var currentError;
    if(controller)
      controller.abort()
    
    
    controller  = new AbortController();
    const url = `/api/v1/vacancies`;
    baseService({
      method: 'POST',
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      url: url,
      data: careerFormData,
      headers:{
        'Content-Type': 'application/json'
      },
      signal: controller.signal
      }).then((data) => {
        if(data?.status === 200)   
        {
          let skip = (store.getState().career.careerListPagination.currentPage-1)*PAGINATION.take
          getVacancies(skip)
          store.dispatch(setAdminFormMessage(false,CAREERS_LIST_ADMIN_FORM.create.message.success))
          resolve(true)
        }   
        else
        {
          store.dispatch(setAdminFormMessage(true,CAREERS_LIST_ADMIN_FORM.create.message.error))
          resolve(false)
        }
      })
      .catch( error => {
        if (error.response.status === 422) {
          store.dispatch(setAdminFormMessage(true, error.response.data))
        } else {
          store.dispatch(setAdminFormMessage(true, CAREERS_LIST_ADMIN_FORM.create.message.error))
        }
        currentError = error;
      })
      .finally(()=>{
        if(!currentError) {
        store.dispatch(setAdminFormDefault())
        store.dispatch(setAdminFormMessage(false,''))
        }
      })
      
    } catch (error) {
      store.dispatch(setAdminFormMessage(true,CAREERS_LIST_ADMIN_FORM.create.message.error))
      resolve(false);
    }
  })
  }
//PUT
const putEditVacancy = async (id) => {
  return new Promise((resolve, reject) => {
  try {
    // store.dispatch(setApplyLoading(true))
    let careerFormData = store.getState().career.careerAdminForm
    var currentError;
    if(controller)
      controller.abort()
    
    
    controller  = new AbortController();

    const url = `/api/v1/vacancies/${id}`;
    baseService({
      method: 'PUT',
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      url: url,
      data: careerFormData,
      headers:{
        'Content-Type': 'application/json'
      },
      signal: controller.signal
      }).then((data) => {
        if(data?.status === 200)   
        {
          let skip = (store.getState().career.careerListPagination.currentPage-1)*PAGINATION.take
          getVacancies(skip)
          store.dispatch(setAdminFormMessage(false,CAREERS_LIST_ADMIN_FORM.edit.message.success))
          resolve(true)
        }   
        else
        {
          store.dispatch(setAdminFormMessage(true,CAREERS_LIST_ADMIN_FORM.edit.message.error))
          resolve(false)
        }
        
      })
      .catch( error => {
        if (error.response.status === 422) {
          store.dispatch(setAdminFormMessage(true, error.response.data))
        } else {
          store.dispatch(setAdminFormMessage(true,CAREERS_LIST_ADMIN_FORM.edit.message.error))
        }
        currentError = error;
      })
      .finally(()=>{
        if(!currentError) {
        store.dispatch(setAdminFormDefault())
        store.dispatch(setAdminFormMessage(false,''))

        store.dispatch(setLoadingCareerListHistory(false))
        }
      })
      
    } catch (error) {
      store.dispatch(setAdminFormMessage(true,CAREERS_LIST_ADMIN_FORM.edit.message.error))
      resolve(false);
    }
  })
  }
//DELETE
const deleteVacancy = async (id) => {
  try {
    // store.dispatch(setApplyLoading(true))
  
    if(controller)
      controller.abort()
    
    
    controller  = new AbortController();

    const url = `/api/v1/vacancies/${id}`;
    baseService({
      method: 'DELETE',
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      url: url,
      headers:{
        'Content-Type': 'application/json'
      },
      signal: controller.signal
      }).then((data) => {
        if(data?.status === 200)   
        {
          let skip = (store.getState().career.careerListPagination.currentPage-1)*PAGINATION.take
          if(store.getState().career.careerList.length === 1 && store.getState().career.careerListPagination.currentPage > 1) 
            store.dispatch(setCareerListPaginationCurrent(store.getState().career.careerListPagination.currentPage-1))
          else 
            getVacancies(skip)
          
          store.dispatch(setAdminFormMessage(false,CAREERS_LIST_ADMIN_FORM.delete.message.success))
        }   
        else
        {
          store.dispatch(setAdminFormMessage(true,CAREERS_LIST_ADMIN_FORM.delete.message.error))
        }

      }).finally(()=>{
        store.dispatch(setAdminFormDefault())
        store.dispatch(setAdminFormMessage(false,''))

          // store.dispatch(setLoadingCareerListHistory(false))
      })
      .catch( error => {
        store.dispatch(setAdminFormMessage(true,CAREERS_LIST_ADMIN_FORM.delete.message.error))
      })
      
    } catch (error) {
      store.dispatch(setAdminFormMessage(true,CAREERS_LIST_ADMIN_FORM.delete.message.error))
    }
  
  }
export {getVacanciesByIdAdmin,getVacancies,getVacanciesById,getHistory,postVacancyApply,postCreateVacancy,putEditVacancy,deleteVacancy}