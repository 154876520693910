import React from "react";
import { Link } from "react-router-dom";

const UnderConstructionPage = () => {
    const pageStyles = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "1",
        width: "100%",
        textAlign: "center"
    };

    const linkColor = {
        color: "#008da9"
    };

    return (
        <>
            <div style={pageStyles}>
                <h1 className="mb-3">Page is under construction.</h1>
                <p className="mb-3">If you faced any problem with login, please contact us through our <Link to="/" style={linkColor}>main page</Link>.</p>
                <p>We will do our best to help you.</p>
            </div>
        </>
    );
};

export default UnderConstructionPage;