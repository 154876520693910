import {useRef,useEffect,useState} from 'react'
import ReactDOMServer from 'react-dom/server';
//components
import PropertyIcon from '../../../../images/property_pin.png'
import MapInformationCard from './map-information-card'

//images
import MapLoading from '../../../../images/map/map.jpg'

//utils 
import {getPropertiesMap} from '../../../../utils/search-properties'
import {getGeometryByGoogle} from '../../../../utils/google-search'
//styles
import '../../styles/map/map-by-location.css'

const MapByLocation = ({dataIsLoaded,data,setSelectedProperty,selectedProperty,currentListTop,radiusInMiles,mode,mapCurrentZoom,markers,location,setMarkers,setMapCurrentZoom,setMode,setClearLocationField,map,setMap}) => {
  const prevOpenMarker = useRef(null)
  const prevZoom = useRef(null)
  const settingBounds = useRef(false)
  const refMap = useRef()
  const propertyiconCustom = {
    url: PropertyIcon,
    scaledSize: new window.google.maps.Size(25,36),
   
  }
  const [mapCurrentCenter,setMapCurrentCenter] = useState(null)
  const [currentOpenMarker, setCurrentOpenMarker] = useState(null)
  const [loading, setLoading] = useState(true); 

  const closeAllDialogInfo = data => {
    markers.forEach(marker =>
      {
        marker.dialog.close()
      }  
    )
  }
  
  const getNewPropertiesMap = (center,zoom) => {
    if(center?.lat() && center?.lng())
      {
        setClearLocationField(true)
        let geometry = `Latitude=${center.lat()}&Longitude=${center.lng()}`
        setMode('map', geometry.toString())
    
        setMapCurrentCenter(center)
        setMapCurrentZoom(zoom)
        getPropertiesMap(geometry,true)
      }
  }
  const handleUserInteraction = type => {
    let center = map.getCenter()
    if(center)
    {
      if(type === 'dragend')
        {
          getNewPropertiesMap(map.getCenter(),map.getZoom())
        }
      else{
        if(map.getZoom() != prevZoom.current && prevZoom.current != null && !settingBounds.current)
        {  
          getNewPropertiesMap(map.getCenter(),map.getZoom())
        }
        settingBounds.current = false
      }
      
    }
    prevZoom.current = map.getZoom()
  }

  const cleanMarkers = () => {
    markers.forEach(({marker}) => marker.setMap(null))
    setMarkers([])
  }
  useEffect(() => {
    if(mode.value)
      {
        setMapCurrentCenter(map.getCenter())
        let newMap = new window.google.maps.Map(refMap.current,{
          zoom:mapCurrentZoom,
          center: map.getCenter()
        })
        cleanMarkers()
        setMap(newMap)
      }
  }, [])
  useEffect(() => {
    if(prevOpenMarker.current)
      prevOpenMarker.current.close()
    prevOpenMarker.current = currentOpenMarker
  }, [currentOpenMarker])
  
  const setMapMarkers = _ =>{
      let bounds = new window.google.maps.LatLngBounds();
      let information = new window.google.maps.InfoWindow();    
      let newMarkers = []
      data.data.forEach(property => {  
        const {latitude,longitude,id,metisId,imageUrl,formattedAddress} = property
        let foundArray = markers.filter(marker => marker.id === id) 
        if(foundArray.length <= 0)
        {
          let contentInfoWindow = ReactDOMServer.renderToString(<MapInformationCard images={imageUrl} name={`Property #${metisId}`} place={formattedAddress} id={metisId}/>)
          let marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(latitude, longitude),
            map: map,
            icon: propertyiconCustom,
            });

          bounds.extend(marker.position);

          window.google.maps.event.addListener(marker, 'click', (function(marker) {
            return function() {
              information.setContent(contentInfoWindow);
              information.setOptions({minWidth:300}); 
              information.open(map, marker);
              setCurrentOpenMarker(information)
            }
          })(marker));
          newMarkers.push({id:id,dialog: information,content: contentInfoWindow,marker})
        }
    })
    if(newMarkers.length > 0)
      {
        setMarkers([...markers,...newMarkers])
        // if(mode.type !== 'map')
        // {
        //   settingBounds.current = true
        //   map.fitBounds(bounds);
        //   settingBounds.current = false
        // }
      }
  }



  const setCustomMap = async _ => {
    let response = await getGeometryByGoogle(location.id)
    let center
    if(mode.type === 'map')
      center = typeof map?.getCenter === 'function' ? map.getCenter() : (response.results[0].geometry.location)
    else
      center = response?.results[0]?.geometry?.location
    cleanMarkers()
    setMap(new window.google.maps.Map(refMap.current,{
      minZoom: 3,
      center: center,
      zoom:mapCurrentZoom,
      mapTypeControl: false,
      streetViewControl: false,
    }))
  }
  useEffect(() => {
    if(window.google)
    {    
      if(data.type === 'noRefresh')
      {
        closeAllDialogInfo('drag map')
        setMapMarkers()
      }
      else{
        setCustomMap()
      }
      
    }
  }, [data])
  
  useEffect(() => {
    
    if(Object.keys(map).length > 0 && dataIsLoaded)
    {
      setMapMarkers()
      setLoading(false); 
    }

  }, [map,dataIsLoaded])

  useEffect(() => {
    if(markers.length > 0)
    {
      if(data.type === 'noRefresh')
        {
          map.setCenter(mapCurrentCenter)
          map.setZoom(mapCurrentZoom)
        }
    }
  }, [markers])
  

  useEffect(() => {
   
    let zoomChanged = handleUserInteraction.bind(null,'zoomChanged')

    if(Object.keys(map).length > 0)
    {
      window.google.maps.event.addListener(map,'dragend', _ => handleUserInteraction('dragend'))
     
      window.google.maps.event.addListener(map,'zoom_changed',zoomChanged) 
    }
    return () => {
      window.google.maps.event.clearListeners(map,'dragend',handleUserInteraction) 
      
      window.google.maps.event.clearListeners(map,'zoom_changed',zoomChanged) 
    }
   }, [map])
   

  return (
    <div className="map-container">
      {loading && <img className="map-container__loading-image" src={MapLoading} alt="Loading..." />}
      <div ref={refMap} className={`map ${loading ? 'map--loading' : ''}`}></div>
    </div>
  )
}

export default MapByLocation