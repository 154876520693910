import {
    ADD_NEWS,
    DELETE_NEWS,
    UPDATE_NEWS,
    FETCH_NEWS_REQUEST,
} from '../constant/news';

export const addNews = (newArticle) => {
    return {
        type: ADD_NEWS,
        payload: newArticle
    };
};

export const deleteNews = (id) => {
    return {
        type: DELETE_NEWS,
        payload: id
    };
};

export const updateNews = (updatedArticle) => {
    return {
        type: UPDATE_NEWS,
        payload: updatedArticle
    };
};

export const fetchNewsRequest = (data) => {
    return {
        type: FETCH_NEWS_REQUEST,
        payload: data
    };
};