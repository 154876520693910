import {
    SET_CURRENCY_TYPES,
    SET_FLOOR_PLAN_TYPES,
    SET_AMENITIES,
    SET_ALL_AMENITIES,
    SET_DICT_BUILDING_TYPES
} from "../constant/dictionary";

const defaultState = {
    currencyTypes: [],
    floorPlanTypes: [],
    amenities: [],
    allAmenities: [],
    dictBuildingTypes: []
};

export default function propertyDetailsReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_CURRENCY_TYPES:
            return {
                ...state,
                currencyTypes: action.payload
            };
        case SET_FLOOR_PLAN_TYPES:
            return {
                ...state,
                floorPlanTypes: action.payload
            };
        case SET_AMENITIES:
            return {
                ...state,
                amenities: action.payload
            };
        case SET_ALL_AMENITIES:
            return {
                ...state,
                allAmenities: action.payload
            };
        case SET_DICT_BUILDING_TYPES:
            return {
                ...state,
                dictBuildingTypes: action.payload
            };
        default:
            return state;
    }
}