import React from "react";
import ImageGrid from "./ImageGrid";
import "../styles/image-grid.css";

const FeaturedCitiesContainer = () => {
    return (
        <section id="section-featured-cities" className="featured-cities-container">
            <h3 className="text-center text-uppercase" style={{ fontSize: '30px', margin: '70px 0px 30px 0px' }}>
                Featured Cities
            </h3>
            <ImageGrid />
        </section>
    )
}

export default FeaturedCitiesContainer;