import {
    SET_PROPERTY_DETAILS,
    SET_LOADING,
    SET_FLOOR,
    SET_RECOMMENDED_PROPERTIES,
    SET_LOADING_RECOMMENDED_PROPERTIES,
    CHECK_IF_PROPERTY_ADDED_TO_CART
} from "../constant/property-details";

export const setPropertyDetailsData = data => ({
    type: SET_PROPERTY_DETAILS,
    payload: data
});

export const setIfPropertyAddedToCart = data => ({
    type: CHECK_IF_PROPERTY_ADDED_TO_CART,
    payload: data
});

export const setRecommendedPropertiesData = data => ({
    type: SET_RECOMMENDED_PROPERTIES,
    payload: data
});

export const setLoading = data => ({
    type: SET_LOADING,
    payload: data
});

export const setLoadingRecommendedProperties = data => ({
    type: SET_LOADING_RECOMMENDED_PROPERTIES,
    payload: data
});

export const setFloor = data => ({
    type: SET_FLOOR,
    payload: data
});
