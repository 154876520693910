import {
    addNews,
    deleteNews,
    updateNews,
    fetchNewsRequest
} from "../actions/news"
import store from "../store"

function addNewArticle(newArticle) {
    store.dispatch(addNews(newArticle));
};

function deleteArticle(id) {
    store.dispatch(deleteNews(id));
};

function updateArticle(updatedArticle) {
    store.dispatch(updateNews(updatedArticle));
};

export const handleArticleCreated = (newArticle) => addNewArticle(newArticle);
export const handleArticleDeleted = (id) => deleteArticle(id);
export const handleArticleUpdated = (newUpdatedArticle) => updateArticle(newUpdatedArticle);

export function newsRequest(data) {
    store.dispatch(fetchNewsRequest(data));
};