import {useState,useEffect} from 'react'
import 'devextreme/dist/css/dx.light.css';
import {useParams} from 'react-router-dom'

import CheckBox from 'devextreme-react/check-box';

import '../styles/checkbox.css'
const Checkbox = ({id,checkValue,onChange,label}) => {

  const onChangeValue = e => {
      onChange(id,e.value)
  }

  return (
    <div className='custom-checkbox'>
      <CheckBox
        value={checkValue ? true : false}
        text={label}
        onValueChanged={e => onChangeValue(e)}
      />
    </div>
  )
}

export default Checkbox