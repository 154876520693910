import {
    verifyLoading,
    verifyTimeElapsed,
    verifyRequest,
    verifyCall,
} from "../actions/verify-account"
import baseService from "../services/base.service";
import store from "../store"

export function verifyAccountRequest() {
    store.dispatch(verifyRequest());
};

export function verifyAccountLoading() {
    store.dispatch(verifyLoading());
};

export function verifyAccountTimeElapsed() {
    store.dispatch(verifyTimeElapsed());
};

export function verifyAccountCall() {
    store.dispatch(verifyCall());
};

export const useVerifyAccountService = (navigate, id, username) => {
    const post = async () => {
        try {
            await baseService({
                method: "POST",
                baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/accounts/verify/${id}`,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            }).then((response) => {
                if (response.status === 200 && response.data.isSuccess === true && response.data.errorMessage === null) {
                    get();
                } else if (response.status === 200 && response.data.isSuccess === false && response.data.errorMessage !== null) {
                    navigate("/account/already-exists");
                };
                return response;
            }).catch((error) => {                
                if (error.response.status === 500) {
                    navigate("/account/already-exists");
                };
            }).finally(() => {
                verifyAccountLoading({ loading: false });
                verifyAccountRequest({ request: true });
                verifyAccountCall({ makeCall: true });
            }, 1000);
        } catch (error) {
            console.log(error);
        }
    };
    const get = async () => {
        try {
            await baseService({
                method: "GET",
                baseURL: `${process.env.REACT_APP_USER_MANAGEMENT}/api/v1/accounts/exist/${username}`,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            }).then((response) => {
                if (response.status === 200) {
                    navigate("/account/verification-success")
                } else {
                    navigate("/account/verification-failed");
                };
                verifyAccountLoading({ loading: false });
            }).catch((error) => console.log(error));
        } catch (error) {
            console.log(error);
        }
    };
    return { post, get };
};