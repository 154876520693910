import {useEffect,useState} from 'react'
import {connect} from 'react-redux'
import notify from 'devextreme/ui/notify';

//components
import ContentWrapper from '../assets/elements/content-wrapper'
import Title from '../assets/elements/section-title'
import Card from '../assets/controls/property-card'
import Pagination from '../assets/controls/pagination-alt'
import Loading from '../assets/controls/loading'
import BannerMessage from '../assets/elements/custom-alert'


//actions
import {setFavorites} from '../../actions/favorites'

//utils
import {getFavoriteProperties,deleteFavorite} from '../../utils/favorites'

//styles
import './styles/my-favorites.css'
const MyFavoritesContent = ({favorites,favoritesTotal,loading,clearFavorites}) => {
    const [skipPages, setSkipPages] = useState(0)

    const unLikeAProperty = async id => {
        const isDeleted = await deleteFavorite(id)
        if(isDeleted)
        {
            if(skipPages > 0 && favorites.length === 1)
                setSkipPages(skipPages - 20)
            else    
                getFavoriteProperties(skipPages)
        }
        else{
            notify('Something went wrong try again later', "error" , 3000);
        }
    }
    
    useEffect(() => {
      getFavoriteProperties(skipPages)
    }, [skipPages])

    useEffect(() => {   
      return () => {
        clearFavorites()
      }
    }, [])
    
    return (
        <ContentWrapper>
            <div className='favorites-header'>
                <Title>my favorites</Title>
                <Loading isVisble={loading} size={24}/>
            </div>
            
            <div>
                {favorites.map((favorite,index) => <Card data={favorite} key={index} unLikeAProperty={unLikeAProperty} reverseType={true} addToCart={true} hideDistance={true}/>)}
            </div>
            {
                favorites.length > 0 &&
                <div className='favorites-pagination'>
                    <Pagination totalPages={favoritesTotal} skip={skipPages} setSkip={setSkipPages} take={20}/>
                </div>
            }
            {
                (favorites.length <= 0 && !loading) ? 
                <div className='mt-5'>
                    <BannerMessage>
                        No properties have been added to your Favorites yet
                    </BannerMessage> 
                </div>
                    : null
            }
        </ContentWrapper>
    );
}
function mapStateToProps({ favorites }) {
    return {
        favorites:favorites.favorites,
        favoritesTotal: favorites.favoritesTotal,
        loading: favorites.loading
    };
  }
function mapDispatchToProps(dispatch){
    return {
        clearFavorites: _ => dispatch(setFavorites([]))
    }
}
  export default connect(mapStateToProps,mapDispatchToProps)(MyFavoritesContent);