import {
    SET_LOADING_QUICK_REQUEST,
    SET_DEFAULT_QUICK_REQUEST,
    SET_CLEAR_LOCATION_FIELD_QUICK_REQUEST,
    
    //FIELDS
    SET_LOCATION_QUICK_REQUEST,
    
    SET_MILES_QUICK_REQUEST,
    SET_FLOOR_PLAN_QUICK_REQUEST,
    SET_CURRENCY_TYPE_QUICK_REQUEST,
    SET_CURRENCY_ERROR_QUICK_REQUEST,
    SET_BUILDING_TYPES_QUICK_REQUEST,
    SET_NOTES_QUICK_REQUEST,
    
    SET_BUDGET_MIN_QUICK_REQUEST,
    SET_BUDGET_MAX_QUICK_REQUEST,
    
    SET_QTY_ADULTS_QUICK_REQUEST,
    SET_QTY_CHILDREN_QUICK_REQUEST,
    
    SET_IS_PETS_QUICK_REQUEST,
    SET_QTY_PETS_QUICK_REQUEST,
    SET_PET_DETAILS_QUICK_REQUEST,
    
    SET_MOVE_IN_QUICK_REQUEST,
    SET_MOVE_OUT_QUICK_REQUEST,
    SET_DATE_RANGE_QUICK_REQUEST,
    
    SET_SELECTED_GUEST_ID_QUICK_REQUEST,
    SET_GUEST_USER_QUICK_REQUEST,
    SET_GUEST_USER_EMPTY_QUICK_REQUEST,
    SET_GUEST_PHONE_QUICK_REQUEST,
    SET_IS_GUEST_PHONE_VALID_STATE_QUICK_REQUEST,
    SET_IS_GUEST_PHONE_VALID_MESSAGE_QUICK_REQUEST,
    SET_GUEST_EMAIL_QUICK_REQUEST,
    SET_IS_EXISTING_USER_QUICK_REQUEST,
    SET_IS_GUEST_CHECKBOX_SELECTED_QUICK_REQUEST,
    SET_DATA_KEYS_AMENITIES_QUICK_REQUEST,
    SET_SELECTED_DATA_AMENITIES_QUICK_REQUEST,
    SET_MAID_SERVICE_QUICK_REQUEST,
    SET_PARKING_SLOTS_QUICK_REQUEST
} from "../constant/quick-request";


export const setMaidService = data => ({
    type: SET_MAID_SERVICE_QUICK_REQUEST,
    payload: data
});
export const setParkingSlots = data => ({
    type: SET_PARKING_SLOTS_QUICK_REQUEST,
    payload: data
});

export const setClearLocationField = data => ({
    type: SET_CLEAR_LOCATION_FIELD_QUICK_REQUEST,
    payload: data
});

export const setLoading = value => ({
    type: SET_LOADING_QUICK_REQUEST,
    payload: value 
});

export const setLocation = value => ({
    type: SET_LOCATION_QUICK_REQUEST,
    payload: value 
});

export const setMiles = value => ({
    type: SET_MILES_QUICK_REQUEST,
    payload: value 
});

export const setFloorPlan = value => ({
    type: SET_FLOOR_PLAN_QUICK_REQUEST,
    payload: value 
});

export const setCurrencyType = value => ({
    type: SET_CURRENCY_TYPE_QUICK_REQUEST,
    payload: value 
});


export const setCurrencyError = value => ({
    type: SET_CURRENCY_ERROR_QUICK_REQUEST,
    payload: value 
});

export const setBuildingTypes = value => ({
    type: SET_BUILDING_TYPES_QUICK_REQUEST,
    payload: value 
});

export const setNotes = value => ({
    type: SET_NOTES_QUICK_REQUEST,
    payload: value 
});

export const setBudgetMin = value => ({
    type: SET_BUDGET_MIN_QUICK_REQUEST,
    payload: value 
});


export const setBudgetMax = value => ({
    type: SET_BUDGET_MAX_QUICK_REQUEST,
    payload: value 
});

export const setQtyAdults = value => ({
    type: SET_QTY_ADULTS_QUICK_REQUEST,
    payload: value 
});

export const setQtyChildren = value => ({
    type: SET_QTY_CHILDREN_QUICK_REQUEST,
    payload: value 
});


export const setIsPets = value => ({
    type: SET_IS_PETS_QUICK_REQUEST,
    payload: value 
});

export const setQtyPets = value => ({
    type: SET_QTY_PETS_QUICK_REQUEST,
    payload: value 
});
export const setPetDetails = value => ({
    type: SET_PET_DETAILS_QUICK_REQUEST,
    payload: value 
});

export const setMoveIn = value => ({
    type: SET_MOVE_IN_QUICK_REQUEST,
    payload: value 
});
export const setMoveOut = value => ({
    type: SET_MOVE_OUT_QUICK_REQUEST,
    payload: value 
});
export const setDateRange = value => ({
    type: SET_DATE_RANGE_QUICK_REQUEST,
    payload: value 
});

export const setGuestUser = value => ({
    type: SET_GUEST_USER_QUICK_REQUEST,
    payload: value 
});

export const setGuestUserEmpty = value => ({
    type: SET_GUEST_USER_EMPTY_QUICK_REQUEST,
    payload: value 
});

export const setGuestPhone = value => ({
    type: SET_GUEST_PHONE_QUICK_REQUEST,
    payload: value 
});

export const setIsGuestPhoneValidMessage = value => ({
    type: SET_IS_GUEST_PHONE_VALID_MESSAGE_QUICK_REQUEST,
    payload: value 
});

export const setIsGuestPhoneValidState = value => ({
    type: SET_IS_GUEST_PHONE_VALID_STATE_QUICK_REQUEST,
    payload: value 
});

export const setGuestEmail = value => ({
    type: SET_GUEST_EMAIL_QUICK_REQUEST,
    payload: value 
});

export const setIsExistingUser = value => ({
    type: SET_IS_EXISTING_USER_QUICK_REQUEST,
    payload: value 
});

export const setIsGuestCheckboxSelected = value => ({
    type: SET_IS_GUEST_CHECKBOX_SELECTED_QUICK_REQUEST,
    payload: value 
});

export const setDataKeysAmenities = value => ({
    type: SET_DATA_KEYS_AMENITIES_QUICK_REQUEST,
    payload: value 
});

export const setSelectedDataAmenities = value => ({
    type: SET_SELECTED_DATA_AMENITIES_QUICK_REQUEST,
    payload: value 
});

export const setSelectedGuestId = value => ({
    type: SET_SELECTED_GUEST_ID_QUICK_REQUEST,
    payload: value 
});

export const setDefaultQuickRequest = _ => ({
    type: SET_DEFAULT_QUICK_REQUEST
});