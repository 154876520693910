import {useCallback,useState,useEffect} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {getIsAdmin} from '../utils/admin'

const AdminRoute = ({ children}) => {
	const location = useLocation();
	const [isAdmin, setIsAdmin] = useState(getIsAdmin())
	const storageChanged = useCallback(e => {
		setIsAdmin(getIsAdmin())
	  },[])
	
	  useEffect(() => {
		window.addEventListener("storage", storageChanged);
		return () => window.removeEventListener("storage", storageChanged)
	  }, []) 
	  
	return (isAdmin ? children : <Navigate to="/" state={{ from: location }} replace />);
}

export default AdminRoute;