
//components
import UserLoginBox from '../assets/elements/input/UserLoginBox'
import ContentWrapper from '../assets/elements/content-wrapper'
import Title from '../assets/elements/section-title'
import Subtitle from '../assets/elements/section-subtitle'
import QuickRequestRequirements from './quick-request-requirements'

//styles
import './styles/quick-request-content.css'

//utils
import {isLoggedIn} from '../../utils/user'

const QuickRequestContent = () => {
  const isUserLoggedIn = isLoggedIn()
  return (
    <ContentWrapper>
        <Title>Quick Request</Title>
        {!isUserLoggedIn && <UserLoginBox />}
        <div className={isUserLoggedIn ? '' : 'quick-request--muted'} style={{marginBottom:'10px'}}>
            <Subtitle>Requirements For Quick Request</Subtitle>
            <QuickRequestRequirements disabled={!isUserLoggedIn}/>
        </div>
    </ContentWrapper>
  )
}

export default QuickRequestContent