import React from "react";
import { Link } from "react-router-dom";
import "./create-account/styles/layer-mask-styles.css";

const VerificationError = () => {
    return (
        <div className="layer-mask">
            <div className="msg-container-error">
                <div className="msg-box">
                    <div className="msg-box-header">
                        <h4 className="text-center fc-account-error"><span className="fw-bold">Something went wrong!</span>&nbsp;Please, try again.</h4>
                    </div>
                    <div className="msg-box-body text-center">
                        <div className="d-flex justify-content-center align-items-center mx-auto mt-4">
                            <Link to="/account/create" className="btn-account-error">Go back to registration page</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificationError;