import React from "react";
import { Link } from "react-router-dom";
import serverIcon from "../../images/server-icon.png";
import "./styles/maintenance-styles.css";

const MaintenanceComponent = () => {
    return (
        <div>
            <div className="container mt-5 maintenance-container">
                <div className="row">
                    <div className="col-xs-1 col-sm-1 col-md-1 justify-content-center align-items-center mx-auto text-center">
                        <div className="server-icon-container">
                            <img src={serverIcon} alt="server icon" className="server-icon mb-3" />
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-12 col-md-11 justify-content-center align-items-center">
                        <p className="p-0 m-0">
                            Oasis is down for maintenance. We expect to be back soon.<br /> If your request is urgent, you may send us an email at <span className="maintenance-link">&#60;odyssey.admin.prod@staywithoasis.com&#62;</span>
                        </p>
                        <Link to="/" className="maintenance-link">
                            Go to Landing Page
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceComponent;