import React from 'react';
import { Container } from 'react-bootstrap';
import userManager from "../utils/user-manager";

const Login = props => {

	userManager.signinRedirect();

	return (
		<div className="section-content-wrapper">
			<Container fluid className='p-5' style={{ margin: '5rem auto' }}>
				Redirecting to login page...
			</Container>
		</div>
	)
}

export default Login;