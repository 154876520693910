import React from 'react';
import NumberBox from 'devextreme-react/number-box';

const TotalAdultsNumberBox = ({ totalAdults, updateTotalAdults }) => {
    const handleValueChange = (e) => {
        const value = parseInt(e.value);
        if (value <= 0) {
            e.component.option('value', 1);
            updateTotalAdults(1);
        } else {
            updateTotalAdults(value);
        }
    };

    const handleFocusOut = (e) => {
        const value = parseInt(e.component.option('value'));
        if (isNaN(value) || value <= 0) {
            e.component.option('value', 1);
            updateTotalAdults(1);
        }
    };

    return (
        <NumberBox
            className="w-50"
            defaultValue=""
            value={totalAdults === 0 ? null : totalAdults}
            onValueChanged={handleValueChange}
            onFocusOut={handleFocusOut}
            max={20}
        />
    );
};

export default TotalAdultsNumberBox;