import { Navigate, useLocation } from 'react-router-dom';

//components
import MyRequestDetailsMain from "../components/my-requests/options/details-main";

//utils
import { isLoggedIn } from "../utils/user";

const MyRequestDetails = () => {
    const location = useLocation();
    return (
        isLoggedIn()  ? <MyRequestDetailsMain/> : <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default MyRequestDetails;