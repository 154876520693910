import { useState, useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { getUserId } from "../../utils/user";
import { appendCompletedTransaction } from "../../actions/transaction-integration"
import store from "../../store";
import NotificationsService from '../../services/notifications.service'

const IntegrationHub = () => {
    const [connection, setConnection] = useState(null);

    useEffect(() => {

        const newConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_API_ENDPOINT}/hubs/integration`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(async result => {
                    console.log("Connect", getUserId());

                    try {
                        var group = getUserId() ? getUserId() : "00000000-0000-0000-0000-000000000000";
                        await connection.invoke("BindConnectionToUserGroup", group);
                    }
                    catch (err) {
                        console.error("Error to connect to group", err);
                    }

                    connection.on('SendCompletedTransactionInfo', transaction => {
                        store.dispatch(appendCompletedTransaction(transaction));
                    });

                    connection.on('SendFailedTransactionInfo', transaction => {                        
                        NotificationsService.showErrorNotification(transaction.errorMessage);

                        store.dispatch(appendCompletedTransaction(transaction));
                    });
                    
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);
};

export default IntegrationHub