import {
    SET_REQUEST_PARTNERSHIP,
    SET_LOADING,
    SET_DEFAULT
} from "../constant/become-a-partner";

const defaultState = {
    requestPartnership:{
        status: false,
        message: ''
    },
    loading: false,
};

export default function requestPartnershipReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_REQUEST_PARTNERSHIP:
            return {
                ...state,
                requestPartnership: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case SET_DEFAULT:
            return {
                requestPartnership:{
                    status: false,
                    message: ''
                },
                loading: false,
            };
        default:
            return state;
    }
}