import React from 'react'
import LoaderIndicator from './load_indicator.gif'

const LoaderComponent = () => {
    return (
        <div>
            <img src={LoaderIndicator} alt="Loading..." className='loader' />
        </div>
    )
}

export default LoaderComponent;