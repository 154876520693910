import {
    SET_PROPERTY_SENT,
    SET_LOADING,
    SET_DEFAULT
} from "../constant/list-a-property";

export const setPropertySended = data => ({
    type: SET_PROPERTY_SENT,
    payload: data
});

export const setLoading = data => ({
    type: SET_LOADING,
    payload: data
});

export const setDefault = _ => ({
    type: SET_DEFAULT
})