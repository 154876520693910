import { LoadIndicator } from 'devextreme-react/load-indicator';

//styles
import '../styles/loading.css'
const Loading = ({isVisble,size}) => {
  return (
    <div className="loading">
        <LoadIndicator
        visible={isVisble}
        height={size ? size : 40}
        width={size ? size : 40}
        />
    </div> 
  )
}

export default Loading