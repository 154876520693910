import {useState,useEffect} from 'react'
//components
import Subtitle from '../assets/elements/section-subtitle'
import PopupGallery from '../assets/elements/custom-popup-gallery'
import Slider from '../assets/controls/slider'
// import Carousel from '../assets/controls/images-carousel'
import { getPropertyImage } from '../../utils/files'

const FloorPlans = ({data}) => {
  const [visible,setVisible] = useState(false)
  const [indexImage,setIndexImage] = useState(0)
  const [formattedImages,setFormattedImages] = useState([])
  const showPopUp = (index) => {
    setVisible(!visible)
    !isNaN(index) && setIndexImage(index)
  }
  useEffect(() => {
      let arrayImages = data.map(item => getPropertyImage(item.imageId))
    // let example = [...arrayImages,...arrayImages,...arrayImages,...arrayImages,...arrayImages,...arrayImages]
    setFormattedImages(arrayImages)
  }, [data])
  
  return (
    <div>
        <Subtitle>Floor Plans</Subtitle>
        <Slider data={formattedImages} action={showPopUp} arrowHeight={30} arrowWidth={30}/>
        <PopupGallery 
          visible={visible}
          onHiding={showPopUp}
          images={formattedImages} defaultImage={indexImage}
        />
    </div>
  )
}

export default FloorPlans