import Subtitle from '../../assets/elements/section-subtitle'
import RenovationTag from './renovation-tag'
const Renovation = ({rdate,rdetails}) => {
  return (
    <div>
        <Subtitle>Renovation</Subtitle>
        <div className='renovation-content'>
          <RenovationTag rdate={rdate}/>
          <p>
            {rdetails}
          </p>
        </div>
    </div>
  )
}

export default Renovation