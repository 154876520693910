import React, { useEffect, useState } from 'react'
import moment from "moment"
//styles
import './styles/requests-table.css'

//constants
import {REQUESTS_TABLE} from '../../constant/received-requests'
import {MONTH_NAMES} from '../../constant/dictionary'

//components
import ShowButton from '../assets/elements/accordion/accordion-button'

//images
import {ReactComponent as TrashIcon} from '../../images/admin-icons/trash-icon.svg'
import {ReactComponent as ClipIcon} from '../../images/clip_icon.svg'
import DownloadIcon from '../../images/download-icon.png'
import FileIcon from '../../images/file-icon.png'
import RequestHelper from '../../utils/helper'
//utils
const RequestsTable = ({data,onClickDelete,onClickProcessed}) => {
  const [formattedData, setFormattedData] = useState([])
  
  const changeShowButtonActive = (id,e) => {
    e.stopPropagation()
    let result = formattedData.map(item => {
      if(item.id === id)
      {
        return {...item,
          showButtonState: {
            title: !item.showButtonState.active ? 'Less' : 'More',
            active: !item.showButtonState.active
          }
        }
      }
      else return {...item}
    })
    setFormattedData(result)
  }
  useEffect(() => {
    setFormattedData(data.map(item => {
      let date = new Date(item.dateAdded)
      let month = date.getMonth()
      let day = date.getDate()
      let year = date.getFullYear()
      let hour = date.getHours()
      let minutes = date.getMinutes()
      let amOrPm = hour >= 12 ? 'PM' : 'AM'
      let extra_data = {}
      if(item.specialRequirements)
      {
        let formatTags = item.specialRequirements.replace(/&lt;/g,'<').replace(/&gt;/g,'>')
        extra_data = {
          specialRequirements: formatTags
        }
      }
      extra_data = {
        ...extra_data,
        dateAdded: `${MONTH_NAMES[month]} ${day}, ${year}, ${hour}:${minutes < 10 ? `0${minutes}` : minutes} ${amOrPm}`,
        showButtonState: {
          title: 'More',
          active: false
        }
      }
      return ({
        ...item,
        ...extra_data
      })
    }))
  }, [data])
  
  return (
    <div className='requests-table'>
      <div className='requests-table__header'>
       {REQUESTS_TABLE.headers.map( header =>
        <div key={header} className='requests-table__header__item'>{header}</div>
        )}
      </div>
      <div className='requests-table__body'>
        {formattedData.map(item =>
          <div key={item.id} className={`requests-table__body__item ${!item.isProcessed && item.isActive ? 'requests-table__body__item--active' : ''}`} onClick={!item.isProcessed && item.isActive ? () => onClickProcessed(item.id,item.requestsCardTitle) : () => {}}>
                <div className='requests-table__body__item__date'>
                    {moment(item.dateAdded).format(RequestHelper.getLocalDateTimeFormat())}
            </div>
            <div className='requests-table__body__item__main-content'>
              <div className='main-content__title'>{item.requestsCardTitle}</div>
              <div className='main-content__email'>{item.emailAddress || item.primaryContactEmail || item.email}</div>
              <div className={`main-content__info ${item.showButtonState.active ? 'main-content__info--show' : ''}`}>
                <hr />
                {Object.keys(item).map((key,index) => 
                key !== 'requestsCardTitle' && key !== 'vacancy' && key !== 'dateAdded' && key !== 'id' && key !== 'isActive' && key !== 'imagesIds' && key !== 'isProcessed' && key !== 'showButtonState' && key !== 'natureOfInquiryType' &&
                <div key={index} className='main-content__info__item'>
                    <div className='main-content__info__name'>
                      {key.replace(/([A-Z])/g,' $1').trim().charAt(0).toUpperCase() + key.replace(/([A-Z])/g,' $1').trim().slice(1)}:
                    </div>
                    {
                      key === 'Resume /CV attached' ?
                        <a href={item[key]} className='main-content__info__value--download' onClick={e=>{e.stopPropagation()}}><img src={FileIcon} alt=""/><span>Download file</span><img src={DownloadIcon} alt=""/></a>
                      :
                        <div className='main-content__info__value' dangerouslySetInnerHTML={{__html: item[key]}}/>
                    }
                  </div>)
                }
              </div>
            </div>
            <div className='requests-table__body__item__actions'>
              {item['Resume /CV attached'] && <ClipIcon/>}
              <div className='requests-table__body__item__actions--more'><ShowButton title={item.showButtonState.title} active={item.showButtonState.active} changeActive={e=>changeShowButtonActive(item.id,e)}/></div>
              {item.isActive && <div className='requests-table__body__item__actions--delete' onClick={e=> onClickDelete(item.id,item.requestsCardTitle,e)}><TrashIcon style={{fill:'#d9534f'}}/> Delete</div>}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default RequestsTable