import React, { useCallback, useEffect, useState } from "react";
import "../../components/navbar/styles/navbar-styles.css";
import LogoBlue from "./Sub-Components/LogoBlue";
import LogoWhite from "./Sub-Components/LogoWhite";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { List } from "react-bootstrap-icons";
import AuthNav from "../auth-nav";
import { getCurrencyTypes, getFloorPlanTypes, getAmenities, getBuildingTypes } from '../../utils/dictionary'
import { getIsUser, isLoggedIn } from "../../utils/user";
const NavbarComponent = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        window.dispatchEvent(new Event('scroll'));
    }, []);
    useEffect(() => {
        scrollToTop()
    }, [location, scrollToTop])

    // const [isLoggedIn, setIsLoggedIn] = useState(getIsUser());

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setIsLoggedIn(getIsUser());
    //     }, 1000);
    //     return () => clearInterval(intervalId);
    // }, []);

    const [expanded, setExpanded] = useState(false);
    const handleNavLinkClick = () => {
        navigate("/");
        scrollToTop();
        setExpanded(false);
    };

    const [logo, setLogo] = useState(<LogoWhite />);
    const updateLogo = useCallback(() => {
        const scrollY = window.scrollY;
        const logo = (scrollY >= 695) ? <LogoBlue /> : <LogoWhite />;
        setLogo(logo);
    }, []);

    const [navLinkColor, setNavLinkColor] = useState("text-white fw-bold");
    const setNavLinkColorOnScroll = useCallback(() => {
        const scrollY = window.scrollY;
        const newColor = (scrollY >= 695) ? "text-dark fw-bold" : "text-white fw-bold";
        setNavLinkColor(newColor);
    }, []);

    const [navbarClass, setNavbarClass] = useState("bg-dark-transparent box-shadow");
    const setNavbarClassOnScroll = useCallback(() => {
        const scrollY = window.scrollY;
        const newClass = (scrollY >= 695) ? "bg-light box-shadow" : "bg-dark-transparent box-shadow";
        setNavbarClass(newClass);
    }, []);

    const [togglerIconColor, setTogglerIconColor] = useState("text-dark");
    const setTogglerIconColorOnScroll = useCallback(() => {
        const scrollY = window.scrollY;
        const newColor = (scrollY >= 695) ? "text-dark" : "text-white";
        setTogglerIconColor(newColor);
    }, []);

    useEffect(() => {
        updateLogo();
        setNavbarClassOnScroll();
        setNavLinkColorOnScroll();
        setTogglerIconColorOnScroll();
        const handleScroll = () => {
            updateLogo();
            setNavbarClassOnScroll();
            setNavLinkColorOnScroll();
            setTogglerIconColorOnScroll();
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [updateLogo]);
    useEffect(() => {
        getCurrencyTypes()
        getFloorPlanTypes()
        getAmenities()
        getBuildingTypes()
    }, [])
    
    return (
    <Navbar sticky="top" collapseOnSelect expand="xl" expanded={expanded} className={location.pathname === "/" ? navbarClass : 'bg-light box-shadow'}>
        <div className="container-fluid nav-bar-styles">
            <div className="navbar-brand" onClick={handleNavLinkClick} style={{ cursor: "pointer" }}>
                { location.pathname === "/" ? logo : <LogoBlue />}
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded((prevExpanded) => !prevExpanded)}>
                <List width="27px" height="27px" className={location.pathname === "/" ? togglerIconColor : 'text-dark'} />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto navbar-pages">
                    <div className={`navbar-tab-container ${location.pathname === '/property-search' ? 'activeTab' : ''}`}>
                        <Nav.Link as={Link} to="/property-search" className={location.pathname === "/" ? navLinkColor : 'text-dark fw-bold'} onClick={handleNavLinkClick}>
                            PROPERTY SEARCH
                        </Nav.Link>
                    </div>
                    <div className={`navbar-tab-container ${location.pathname === '/list-your-property' ? 'activeTab' : ''}`}>
                        <Nav.Link as={Link} to="/list-your-property" className={location.pathname === "/" ? navLinkColor : 'text-dark fw-bold'} onClick={handleNavLinkClick}>
                            LIST YOUR PROPERTY
                        </Nav.Link>
                    </div>
                    <div className={`navbar-tab-container ${location.pathname === '/careers' ? 'activeTab' : ''}`}>
                        <Nav.Link as={Link} to="/careers" className={location.pathname === "/" ? navLinkColor : 'text-dark fw-bold'} onClick={handleNavLinkClick}>
                            CAREERS
                        </Nav.Link>
                    </div>
                    <div className={`navbar-tab-container ${location.pathname === '/news' ? 'activeTab' : ''}`}>
                        <Nav.Link as={Link} to="/news" className={location.pathname === "/" ? navLinkColor : 'text-dark fw-bold'} onClick={handleNavLinkClick}>
                            NEWS
                        </Nav.Link>
                    </div>
                    {isLoggedIn() && 
                        <div className={`navbar-tab-container ${location.pathname === '/my-requests' ? 'activeTab' : ''}`}>
                            <Nav.Link as={Link} to="/my-requests" className={location.pathname === "/" ? navLinkColor : 'text-dark fw-bold'} onClick={handleNavLinkClick}>
                                MY REQUESTS
                            </Nav.Link>
                        </div>
                    }
                    
                    {/* {isLoggedIn && (
                        <Nav.Link as={Link} to="/my-requests" className={"text-dark fw-bold"} onClick={handleNavLinkClick}>
                            MY REQUESTS
                        </Nav.Link>
                    )} */}
                </Nav>
                <AuthNav pathName={location.pathname}/>
            </Navbar.Collapse>
        </div>
    </Navbar>
    )
}

export default NavbarComponent;