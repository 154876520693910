//components
import DeleteButton from '../../assets/elements/button/delete-button'
import CancelButton from '../../assets/elements/button/secondary-action-button'

//styles
import '../styles/custom-confirmation-popup.css'
const ConfirmationPopUp = ({onHiding,onConfirm,labelConfirm,titleConfirm, message}) => {
  return (
    <div className='custom-confirmation-popup'>
        <div className='custom-confirmation-popup__title'>
            {titleConfirm}
        </div>
        <div className='custom-confirmation-popup__info'>
            {message}
        </div>
        <div className='custom-confirmation-popup__buttons'>
            <CancelButton action={onHiding}>Close</CancelButton>
            <DeleteButton action={onConfirm}>{labelConfirm}</DeleteButton>
        </div>
    </div>
  )
}

export default ConfirmationPopUp