import {
    SET_ALL_REQUESTS,
    SET_REQUESTS_DATA,
    SET_PREVIOUS_REQUESTS_URL,
    SET_ALL_REQUESTS_IS_LOADED,
    SET_REQUESTS_LOADING,
    SET_REQUESTS_MESSAGE_NO_DATA
} from "../constant/received-requests";

export const setAllRequests = data => ({
    type: SET_ALL_REQUESTS,
    payload: data
});

export const setRequestsData = data => ({
    type: SET_REQUESTS_DATA,
    payload: data
});

export const setPreviousRequestsUrl = data => ({
    type: SET_PREVIOUS_REQUESTS_URL,
    payload: data
});

export const setAllRequestsIsLoaded = data => ({
    type: SET_ALL_REQUESTS_IS_LOADED,
    payload: data
});


export const setRequestsLoading = data => ({
    type: SET_REQUESTS_LOADING,
    payload: data
});

export const setRequestsMessageNoData = data => ({
    type: SET_REQUESTS_MESSAGE_NO_DATA,
    payload: data
});