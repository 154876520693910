import {
    FETCH_FAQ_REQUEST,
    FETCH_FAQ_SUCCESS,
    FETCH_FAQ_ERROR
} from '../constant/faq'

export const fetchDataRequest = (data) => ({
    type: FETCH_FAQ_REQUEST,
    payload: data
})

export const fetchDataSuccess = (data) => ({
    type: FETCH_FAQ_SUCCESS,
    payload: data
})

export const fetchDataError = (error) => ({
    type: FETCH_FAQ_ERROR,
    payload: error
})