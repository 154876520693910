import { Navigate, useLocation } from 'react-router-dom';

//components
import MyRequestsComponent from "../components/my-requests/MyRequestsComponent";

//utils
import { isLoggedIn } from "../utils/user";

const MyRequests = () => {
    const location = useLocation();
    return (
        isLoggedIn()  ? <MyRequestsComponent /> : <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default MyRequests;