import React from "react";

const PrevArrow = (props) => {
    const [isHovered, setIsHovered] = React.useState(false);

    const toggleClass = () => setIsHovered(!isHovered);

    return (
        <div
            className={isHovered ? "left-active" : "left-passive"}
            onClick={props.onClick}
            onMouseEnter={toggleClass}
            onMouseLeave={toggleClass}
        />
    )
}

export default PrevArrow;