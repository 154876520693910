import {memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-input-alt.css'

//components
import { NumberBox } from 'devextreme-react/number-box';

import {
    Validator,
    RequiredRule
  } from 'devextreme-react/validator';

const CustomNumber = ({label, value, setValue,requiredValue,isDisabled,min,max,inputIcon}) => {

  return (
    <div className='custom-input-alt'>
        <div className='custom-input-alt__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
            <div className={`custom-input-alt__container ${inputIcon ? '' : 'custom-input-alt__container--noIcon'}`}>
            <NumberBox
                  value={value}
                  disabled={isDisabled}
                  valueChangeEvent="input"
                  onValueChange={setValue}
                  min={min}
                  max={max}
            >
            {requiredValue &&
                <Validator>
                <RequiredRule message={`${label} is required`} />
                </Validator>
            }
            </NumberBox>
            <div className='custom-input-alt__container__icon'><img src={inputIcon}/></div>
          
          </div>
      </div>
  )
}

export default memo(CustomNumber)