import {
    UPDATE_FORM_EMAIL,
    UPDATE_FORM_USERNAME,
    UPDATE_FORM_FIRSTNAME,
    UPDATE_FORM_LASTNAME,
    UPDATE_FORM_PHONENUMBER,
    UPDATE_FORM_PASSWORD,
    UPDATE_FORM_CONFIRM_PASSWORD,
    CLEAR_FORM_DATA,
    SET_LENGTH_VALID,
    SET_UPPER_VALID,
    SET_LOWER_VALID,
    SET_NUMBER_VALID,
    SET_SPECIAL_VALID,
    SET_CAPTCHA_CHECKED,
    SET_CAPTCHA_ERROR,
    SET_CAPTCHA_EXPIRED,
    SET_PHONE_VALID_STATE,
    SET_PHONE_VALID_MESSAGE,
} from "../constant/create-account"

const initialState = {
    email: "",
    userName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    lengthValid: false,
    upperValid: false,
    lowerValid: false,
    numberValid: false,
    specialValid: false,
    isCaptchaChecked: false,
    isCaptchaErrorVisible: false,
    isCaptchaExpired: false,
    isPhoneValidState: true,
    isPhoneValidMessage: "",
}

export default function formDataReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_FORM_EMAIL:
            return {
                ...state,
                email: action.payload,
            }
        case UPDATE_FORM_USERNAME:
            return {
                ...state,
                userName: action.payload,
            }
        case UPDATE_FORM_FIRSTNAME:
            return {
                ...state,
                firstName: action.payload,
            }
        case UPDATE_FORM_LASTNAME:
            return {
                ...state,
                lastName: action.payload,
            }
        case UPDATE_FORM_PHONENUMBER:
            return {
                ...state,
                phoneNumber: action.payload,
            }
        case UPDATE_FORM_PASSWORD:
            return {
                ...state,
                password: action.payload,
            }
        case UPDATE_FORM_CONFIRM_PASSWORD:
            return {
                ...state,
                confirmPassword: action.payload,
            }
        case CLEAR_FORM_DATA:
            return {
                ...state,
                email: "",
                userName: "",
                firstName: "",
                lastName: "",
                phoneNumber: "",
                password: "",
                confirmPassword: "",
                lengthValid: false,
                upperValid: false,
                lowerValid: false,
                numberValid: false,
                specialValid: false,
            }
        case SET_LENGTH_VALID:
            return {
                ...state,
                lengthValid: action.payload,
            }
        case SET_UPPER_VALID:
            return {
                ...state,
                upperValid: action.payload,
            }
        case SET_LOWER_VALID:
            return {
                ...state,
                lowerValid: action.payload,
            }
        case SET_NUMBER_VALID:
            return {
                ...state,
                numberValid: action.payload,
            }
        case SET_SPECIAL_VALID:
            return {
                ...state,
                specialValid: action.payload,
            }
        case SET_CAPTCHA_CHECKED:
            return {
                ...state,
                isCaptchaChecked: action.payload,
            }
        case SET_CAPTCHA_ERROR:
            return {
                ...state,
                isCaptchaErrorVisible: action.payload,
            }
        case SET_CAPTCHA_EXPIRED:
            return {
                ...state,
                isCaptchaExpired: action.payload,
            }
        case SET_PHONE_VALID_STATE:
            return {
                ...state,
                isPhoneValidState: action.payload,
            }
        case SET_PHONE_VALID_MESSAGE:
            return {
                ...state,
                isPhoneValidMessage: action.payload,
            }
        default:
            return state
    }
}