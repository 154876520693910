import { Link } from 'react-router-dom'
import moment from 'moment'
import { useState, useEffect } from 'react'
//constants
import { REQUEST_STATUS, OPTION_STATUS } from "../../../constant/request.status"
import { REASONS_DECLINE } from "../../../constant/dictionary"

//components
import ActionButton from '../../assets/elements/button/action-button'
import CancelButton from '../../assets/elements/button/secondary-action-button'

import CustomPopup from '../../assets/elements/custom-popup'
import CustomRadio from '../../assets/elements/input/custom-radio'
import CustomDate from '../../assets/elements/input/custom-date'
import CustomTextarea from '../../assets/elements/input/custom-textarea'


//images
import ViewIcon from '../../../images/admin-icons/eye-icon.svg'
import {ReactComponent as TrashIcon} from '../../../images/admin-icons/trash-icon.svg'

//utils
import RequestHelper from '../../../utils/helper';
import { postReserveOption, postDeclineOption, putCancelBooking, postRequestExtension, putCancelReservation } from "../../../utils/my-requests"
//styles
import '../styles/request-properties-card-options.css'

const RequestPropertiesCard = ({ option_id, request_id, request_status, property_id, property_name, property_address, unit_size, distance, parking, property_grade, final_rate, floor_plan, dates_available, checkInDate, length_of_stay, floor_level, maid_service, telephone, ntv_requirement, option_status, currency_code,hiddeActions,bookedFilter,checkOutDate,extensions }) => {
    const [visibleDeclinedPopup, setVisibleDeclinedPopup] = useState(false)
    const [visibleCancelReservationPopup, setVisibleCancelReservationPopup] = useState(false)
    const [visibleCancelBookingPopup, setVisibleCancelBookingPopup] = useState(false)
    const [visibleRequestExtension, setVisibleRequestExtension] = useState(false)
    const [requestExtensionInfo, setRequestExtensionInfo] = useState(null)
    const [declinedReason, setDeclinedReason] = useState(null)
    const [commentDeclinedReason, setCommentDeclinedReason] = useState("")
    const [endDateRequestExtension, setEndDateRequestExtension] = useState(null)

    const [commentRequestExtensionReason, setCommentRequestExtensionReason] = useState("")

    const Missing = () => {
        return (<span className='missing-text'>Missing</span>)
    }

    const reserveOption = async() => {
        await postReserveOption(option_id, request_id);
        setCurrentStatus({ requestStatus: REQUEST_STATUS.ReservationCreated, optionStatus: OPTION_STATUS.PendingManager });
    }

    const declineOption = async e => {
        e.preventDefault()

            await postDeclineOption(request_id,option_id, declinedReason?.id, commentDeclinedReason);
            setCurrentStatus({ requestStatus: REQUEST_STATUS.Quoted, optionStatus: OPTION_STATUS.DeclinedByRecipient });
            setVisibleDeclinedPopup(false)
    }
    const cancelDeclineOption = _ => {
        setVisibleDeclinedPopup(false)
        setDeclinedReason(null)
        setCommentDeclinedReason("")
    }
    const cancelReservation = async () => {
        await putCancelReservation(request_id, option_id);
        setCurrentStatus({ requestStatus: REQUEST_STATUS.Quoted, optionStatus: OPTION_STATUS.Quoted })
    }

    const cancelBooking = async () => {
        await putCancelBooking(request_id, option_id);
        setCurrentStatus({ requestStatus: REQUEST_STATUS.PendingManager, optionStatus: OPTION_STATUS.Booked })
    }


    const [currentStatus, setCurrentStatus] = useState({});
    const requestExtension = async e =>{
        e.preventDefault()
        let response = await postRequestExtension(request_id,new Date(checkOutDate).toISOString(),new Date(endDateRequestExtension).toISOString(),commentRequestExtensionReason)
        if(response)
        {
            let data= {
                startDate: moment(new Date(checkOutDate).toISOString()).format(RequestHelper.getLocalDateFormat()),
                endDate: moment(new Date(endDateRequestExtension).toISOString()).format(RequestHelper.getLocalDateFormat()),
                comment: commentRequestExtensionReason
            }
            setRequestExtensionInfo(data)
        }
        setVisibleRequestExtension(false)
        setEndDateRequestExtension(null)
        setCommentRequestExtensionReason("")
    }
    const cancelRequestExtension =  _ => {
        setVisibleRequestExtension(false)
        setEndDateRequestExtension(null)
        setCommentRequestExtensionReason("")
    }
    useEffect(() => {
        setCurrentStatus({ requestStatus: request_status, optionStatus: option_status });
    }, [request_id])

    const OptionActionBtn = () => {

        if ((currentStatus.optionStatus === OPTION_STATUS.Quoted && currentStatus.requestStatus === REQUEST_STATUS.Quoted) || (currentStatus.requestStatus === REQUEST_STATUS.ReservationCreated && (currentStatus.optionStatus === OPTION_STATUS.New || currentStatus.optionStatus === OPTION_STATUS.Quoted))) {
            return (<>
                <div className='request-properties-card-options__buttons__content__new'>
                    <div className='request-properties-card-options__buttons__content__new_content-wrapper'>
                        <div>
                            <ActionButton action={reserveOption} disabled={currentStatus.requestStatus === REQUEST_STATUS.ReservationCreated}>Reserve</ActionButton>
                        </div>
                        {currentStatus.requestStatus === REQUEST_STATUS.ReservationCreated && 
                            <div className='request-properties-card-options__buttons__content__new__blocked-message'>
                                <span>*</span>You should cancel current reservation, if you want to proceed with this option
                            </div>
                        }
                    </div>
                    
                </div>
                <div className='request-properties-card-options__buttons__content__new btn-cancel'>
                    <ActionButton action={_=>setVisibleDeclinedPopup(true)}>Decline</ActionButton>
                </div>

            </>)
        } else if (currentStatus.optionStatus === OPTION_STATUS.DeclinedByRecipient || (REQUEST_STATUS.ReservationCreated === currentStatus.requestStatus && currentStatus.optionStatus !== OPTION_STATUS.PendingManager)) {
            return (<></>)
        } else if (currentStatus.requestStatus === REQUEST_STATUS.ReservationCreated && currentStatus.optionStatus === OPTION_STATUS.PendingManager) {
            return (<>
                <div className='request-properties-card-options__buttons__content__new btn-cancel'>
                    <ActionButton action={_ => setVisibleCancelReservationPopup(true)}>Cancel Reservation</ActionButton>
                </div>
            </>)
        } else if (currentStatus.requestStatus === REQUEST_STATUS.Booked && currentStatus.optionStatus === OPTION_STATUS.Booked) {
            if (moment(checkInDate).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).isAfter(moment())) {
                return (<>
                    <div className='request-properties-card-options__buttons__content__new btn-cancel'>
                        <ActionButton action={_ => setVisibleCancelBookingPopup(true)}>Cancel Booking</ActionButton>
                    </div>
                </>)
            } else {
                if(!requestExtensionInfo)
                    return (<>
                        <div className='request-properties-card-options__buttons__content__new'>
                            <ActionButton action={_=>setVisibleRequestExtension(process.env.REACT_APP_TESTING_COMPONENT === 'testing' ? true : false)}>Request Extension</ActionButton>
                            <ActionButton action={cancelReservation}>Request Termination</ActionButton>
                        </div>
                    </>)
                else
                   return(<>
                    <div className='request-properties-card-options__message__pending-extension'>
                        <div className='request-properties-card-options__message__pending-extension__title'>Pending Extension:</div>
                        <div className='request-properties-card-options__message__pending-extension__dates'>{requestExtensionInfo.startDate} - {requestExtensionInfo.endDate}</div>
                        <div className='request-properties-card-options__message__pending-extension__comment'>{requestExtensionInfo.comment}</div>
                        <div className='request-properties-card-options__message__pending-extension__cancel'><TrashIcon/><span>Cancel</span></div>
                    </div>
                   </>)
            }
        } 
    }

    const getStatusLabel = (statusId) => {
        switch (statusId) {
            case OPTION_STATUS.Quoted:
                return "new";
            case OPTION_STATUS.PendingSupplier:
            case OPTION_STATUS.PendingManager:
                return "pending manager";
            case OPTION_STATUS.Booked:
                return "booked";
            case OPTION_STATUS.DeclinedByRecipient:
                return "declined";
            default:
                return "error";
        }
    }
    useEffect(() => {
      if(extensions.length > 0 )
        {
            let data = null
            extensions.forEach(extension => {
                if(extension.statusId !== 5)
                {
                    data= {
                        ...extension,
                        startDate: moment(extension.startDate).format(RequestHelper.getLocalDateFormat()),
                        endDate: moment(extension.endDate).format(RequestHelper.getLocalDateFormat()),
                    }
                }
            })
            setRequestExtensionInfo(data)
        }
    }, [extensions])
    
  return (
    <div className='request-properties-card-options' style={bookedFilter ? currentStatus.optionStatus === 7 ? {} : {display:'none'} : {}}>
          <div className='request-properties-card-options__img'><img src={`${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/files/main-property-image/${property_id}`} alt="" /></div>
        <div className='request-properties-card-options__information'>
            <div className='request-properties-card-options__information__content'>
                <div className='request-properties-card-options__information__left'>
                    <div className='request-properties-card-options__information__field request-properties-card-options__information__field__option-id'>
                        {/*<div className='request-properties-card-options__information__field__name'>Option Id:</div>*/}
                        {/*<div className='request-properties-card-options__information__field__value'>{option_id}</div>*/}
                          <div className='request-properties-card-options__field-status'><span className={`request-properties-card-options__status__tag ${currentStatus.optionStatus === 2 ? 'request-properties-card-options__status__tag--new' : 'request-properties-card-options__status__tag--reserved'}`}>{getStatusLabel(currentStatus.optionStatus)}</span></div>
                    </div>
                    <div className='request-properties-card-options__information__field request-properties-card-options__information__field__property-name'>
                        <div className='request-properties-card-options__information__field__name'>Property #:</div>
                        <Link className='request-properties-card-options__information__field__value request-properties-card-options__information__field__value--link' to={`/property/dashboard/${property_id}`} target='_blank'>{property_id}</Link>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Property Address:</div>
                        <div className='request-properties-card-options__information__field__value'>{property_address}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                          <div className='request-properties-card-options__information__field__name'>Unit Size:</div>
                          <div className='request-properties-card-options__information__field__value'>{unit_size ?? <Missing />}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Distance:</div>
                        <div className='request-properties-card-options__information__field__value'>{distance} miles</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Parking:</div>
                        <div className='request-properties-card-options__information__field__value'>{parking}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Property Grade:</div>
                          <div className='request-properties-card-options__information__field__value'>{property_grade ?? <Missing />}</div>
                    </div>
                </div>
                <div className='request-properties-card-options__information__right'>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Final Rate:</div>
                        <div className='request-properties-card-options__information__field__value'>
                            <span>{`${final_rate} ${currency_code}`}</span> 
                            <div className='request-properties-card-options__information__field__value__tax'> 
                                <span className='request-properties-card-options__information__field__value__tax-icon'>i</span>
                                <span>Plus Tax</span>
                            </div>
                        </div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Floor Plan:</div>
                        <div className='request-properties-card-options__information__field__value'>{floor_plan}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Dates Available:</div>
                        <div className='request-properties-card-options__information__field__value'>{dates_available}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Length Of Stay:</div>
                        <div className='request-properties-card-options__information__field__value'>{length_of_stay}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Floor Level:</div>
                          <div className='request-properties-card-options__information__field__value'>{floor_level ?? <Missing />}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Maid Service:</div>
                        <div className='request-properties-card-options__information__field__value'>{maid_service}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Telephone:</div>
                          <div className='request-properties-card-options__information__field__value'>{telephone ?? <Missing />}</div>
                    </div>
                    <div className='request-properties-card-options__information__field'>
                        <div className='request-properties-card-options__information__field__name'>Ntv Requirement (Days):</div>
                        <div className='request-properties-card-options__information__field__value'>{ntv_requirement}</div>
                    </div>
                </div>
            </div>
            
        </div>
        <div className='request-properties-card-options__title'>
            <div className='request-properties-card-options__information__field'>
                <div className='request-properties-card-options__information__field__name'>Option Id:</div>
                <div className='request-properties-card-options__information__field__value'>{option_id}</div>
                  <div className='request-properties-card-options__field-status'><span className={`request-properties-card-options__status__tag ${currentStatus.optionStatus === 2 ? 'request-properties-card-options__status__tag--new' : 'request-properties-card-options__status__tag--reserved'}`}>{getStatusLabel(currentStatus.optionStatus)}</span></div>
             </div>
            <div className='request-properties-card-options__information__field'>
                <div className='request-properties-card-options__information__field__name'>Property Name:</div>
                <div className='request-properties-card-options__information__field__value'>{property_name}</div>
            </div>
        </div>
          <div className='request-properties-card-options__status'><span className={`request-properties-card-options__status__tag ${currentStatus.optionStatus === 2 ? 'request-properties-card-options__status__tag--new' : 'request-properties-card-options__status__tag--reserved'}`}>{getStatusLabel(currentStatus.optionStatus)}</span></div>
        <div className='request-properties-card-options__buttons'>
            <div className='request-properties-card-options__buttons__content'>
                {/* <Link target='_blank' to={`/property/dashboard/${id}`} className='request-properties-card-options__buttons__content__view'><img src={ViewIcon}/><span>View</span></Link> */}
                <Link className='request-properties-card-options__buttons__content__view' to={`/my-requests/option/${request_id}/${option_id}`} target='_blank'><img src={ViewIcon}/><span>Option Preview</span></Link>
                <Link className='request-properties-card-options__buttons__content__view' to={`/property/dashboard/${property_id}`} target='_blank'><img src={ViewIcon}/><span>Property Preview</span></Link>
                {/* <div className='request-properties-card-options__buttons__content__delete'><img src={DeleteIcon}/><span>Delete</span></div> */}
                {/*
                      currentStatus.optionStatus === 2 && currentStatus.requestStatus === 2 ? 
                    <div className='request-properties-card-options__buttons__content__new'>
                              <ActionButton action={reserveOption}>Reserve</ActionButton>
                        <div className='request-properties-card-options__buttons__content__decline'>Decline</div>
                    </div>
                    :
                    <div className='request-properties-card-options__buttons__content__cancel'>
                        <span>X</span><span>Cancel</span>
                          </div>
  */
                    !hiddeActions && <OptionActionBtn />
                    // <OptionActionBtn />
                }
            </div>
        </div>
        <div className='request-properties-card-options__divisor'></div>
        <CustomPopup visible={visibleDeclinedPopup} onHiding={_=>setVisibleDeclinedPopup(false)}>
            <form onSubmit={declineOption} className='request-properties-card-options__popup'>
                <div className='request-properties-card-options__popup__title'>DECLINE THE OPTION #{option_id}</div>
                <div className='request-properties-card-options__popup__message'>
                    To help us provide better option for you, please choose the reason why this option does not fit for you. 
                </div>
                <div>
                    <CustomRadio labelSize='133px' label='Reason to decline' value={declinedReason} setValue={setDeclinedReason} requiredValue={true} items={REASONS_DECLINE} type={'vertical'}/>
                </div>
                <div>
                    <CustomTextarea labelSize='133px' label='Your Comment' value={commentDeclinedReason} setValue={setCommentDeclinedReason} requiredValue={false}/>
                </div>
                <div className='request-properties-card-options__popup__buttons'>
                    <div className='request-properties-card-options__buttons__content__new btn-declined'>
                        <ActionButton>Decline</ActionButton>
                    </div>
                    <CancelButton action={cancelDeclineOption}>Cancel</CancelButton>
                </div>
            </form>
          </CustomPopup>
          <CustomPopup visible={visibleCancelReservationPopup} onHiding={_ => setVisibleCancelReservationPopup(false)}>
              <form onSubmit={cancelReservation} className='request-properties-card-options__popup'>
                  <div className='request-properties-card-options__popup__title'>CANCEL RESERVATION FOR THE OPTION #{option_id}</div>
                  <div className='request-properties-card-options__popup__message'>
                      After you cancel reservation you will be able to reserve another option.
                  </div> 
                  <div className='request-properties-card-options__popup__buttons'>
                      <div className='request-properties-card-options__buttons__content__new btn-declined'>
                          <ActionButton>Cancel Reservation</ActionButton>
                      </div>
                      <CancelButton action={_ => setVisibleCancelReservationPopup(false)}>Cancel</CancelButton>
                  </div>
              </form>
          </CustomPopup>
          <CustomPopup visible={visibleCancelBookingPopup} onHiding={_ => setVisibleCancelBookingPopup(false)}>
              <form onSubmit={cancelBooking} className='request-properties-card-options__popup'>
                  <div className='request-properties-card-options__popup__title'>REQUEST BOOKING CANCELLATION</div>
                  <div className='request-properties-card-options__popup__message'>
                      Our managers are required to approve booking cancellation. 
                  </div>
                  <div className='request-properties-card-options__popup__buttons'>
                      <div className='request-properties-card-options__buttons__content__new btn-declined'>
                          <ActionButton>Cancel Booking</ActionButton>
                      </div>
                      <CancelButton action={_ => setVisibleCancelBookingPopup(false)}>Close</CancelButton>
                  </div>
              </form>
          </CustomPopup>
          <CustomPopup visible={visibleRequestExtension} onHiding={cancelRequestExtension}>
              <form onSubmit={requestExtension} className='request-properties-card-options__popup'>
                  <div className='request-properties-card-options__popup__title'>CREATE EXTENSION</div>
                  <div className='request-properties-card-options__popup__message'>
                    If you want to extend your request, please choose the preferred end date.
                  </div>
                  <div>
                    <CustomDate 
                        labelSize='133px'
                        label='End Date'
                        value={endDateRequestExtension}
                        setValue={setEndDateRequestExtension}
                        requiredValue={true}
                        dateFormat={RequestHelper.getLocalDateFormat() }
                        min={(new Date(new Date(checkOutDate).getTime() + (24*60*60*1000))).toISOString()}
                    />
                </div>
                <div>
                    <CustomTextarea labelSize='133px' label='Your Comment' value={commentRequestExtensionReason} setValue={setCommentRequestExtensionReason} requiredValue={false}/>
                </div>
                  <div className='request-properties-card-options__popup__buttons'>
                      <ActionButton>Send</ActionButton>
                      <CancelButton action={cancelRequestExtension}>Cancel</CancelButton>
                  </div>
              </form>
          </CustomPopup>
    </div>
  )
}

export default RequestPropertiesCard