import {
    UPDATE_FORM_EMAIL,
    UPDATE_FORM_USERNAME,
    UPDATE_FORM_FIRSTNAME,
    UPDATE_FORM_LASTNAME,
    UPDATE_FORM_PHONENUMBER,
    UPDATE_FORM_PASSWORD,
    UPDATE_FORM_CONFIRM_PASSWORD,
    CLEAR_FORM_DATA,
    SET_LENGTH_VALID,
    SET_UPPER_VALID,
    SET_LOWER_VALID,
    SET_NUMBER_VALID,
    SET_SPECIAL_VALID,
    SET_CAPTCHA_CHECKED,
    SET_CAPTCHA_ERROR,
    SET_CAPTCHA_EXPIRED,
    SET_PHONE_VALID_STATE,
    SET_PHONE_VALID_MESSAGE,
} from '../constant/create-account'

export const updateFormEmail = (data) => ({
    type: UPDATE_FORM_EMAIL,
    payload: data
})

export const updateFormUserName = (data) => ({
    type: UPDATE_FORM_USERNAME,
    payload: data
})

export const updateFormFirstName = (data) => ({
    type: UPDATE_FORM_FIRSTNAME,
    payload: data
})

export const updateFormLastName = (data) => ({
    type: UPDATE_FORM_LASTNAME,
    payload: data
})

export const updateFormPhoneNumber = (data) => ({
    type: UPDATE_FORM_PHONENUMBER,
    payload: data
})

export const updateFormPassword = (data) => ({
    type: UPDATE_FORM_PASSWORD,
    payload: data
})

export const updateFormConfirmPassword = (data) => ({
    type: UPDATE_FORM_CONFIRM_PASSWORD,
    payload: data
})

export const clearFormData = () => ({
    type: CLEAR_FORM_DATA,
    payload: {}
})

/* RegEx Validation */
export const setLengthValidation = (isValid) => ({
    type: SET_LENGTH_VALID,
    payload: isValid
})

export const setUpperValidation = (isValid) => ({
    type: SET_UPPER_VALID,
    payload: isValid
})

export const setLowerValidation = (isValid) => ({
    type: SET_LOWER_VALID,
    payload: isValid
})

export const setNumberValidation = (isValid) => ({
    type: SET_NUMBER_VALID,
    payload: isValid
})

export const setSpecialValidation = (isValid) => ({
    type: SET_SPECIAL_VALID,
    payload: isValid
})

/* Recaptcha */
export const setCaptchaChecked = (isValid) => ({
    type: SET_CAPTCHA_CHECKED,
    payload: isValid
})

export const setCaptchaError = (isValid) => ({
    type: SET_CAPTCHA_ERROR,
    payload: isValid
})

export const setCaptchaExpired = (isValid) => ({
    type: SET_CAPTCHA_EXPIRED,
    payload: isValid
})

/* Phone Validation */
export const setPhoneValidState = (isValid) => ({
    type: SET_PHONE_VALID_STATE,
    payload: isValid
})

export const setPhoneValidMessage = (isValid) => ({
    type: SET_PHONE_VALID_MESSAGE,
    payload: isValid
})
