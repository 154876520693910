import {useEffect,useRef} from 'react'
import {connect} from 'react-redux'

//components
import Loading from '../../assets/controls/loading'
import RequestItem from './request-list-item'
import Pagination from '../../assets/controls/pagination-alt'
import BannerMessage from '../../assets/elements/custom-alert'

//constant
import {DEFAULT_VALUES} from '../../../constant/pagination'

//actions
import {setRequestsDefault,setRequestsSkip} from '../../../actions/my-requests'

//utils
import {getAllRequests,getSelectedRequestByType} from '../../../utils/my-requests'
const RequestList = ({type,requests,loading,allRequest,skip,setRequestsSkip}) => {
  const refTopList = useRef(null)
//   const [skip, setSkip] = useState(0)

  useEffect(() => {
    getAllRequests()
  }, [])
  
  useEffect(() => {
    setRequestsSkip(0)
    // setRequestsDefault()
  }, [type])
  useEffect(() => {
    switch (type) {
        case 'active-request':
            getSelectedRequestByType(skip,'active')
            break;
        case 'past-request':
            getSelectedRequestByType(skip,'past')
            break;
        case 'cancelled-request':
            getSelectedRequestByType(skip,'cancel')
            break;
        default:
            break;
    }
  }, [type,allRequest,skip])

  
  return (
    <div ref={refTopList}>
        {requests.data.length > 0 && 
            <div style={{display: 'flex', flexDirection:'column', gap: '10px'}}>
                {requests.data.map((request,index) => 
                    <RequestItem key={index} request={request} hiddeActions={type !== 'active-request' || (request.statusId !== 1 && request.statusId !== 3 && request.statusId !== 2 && request.statusId !== 5 && request.statusId !== 7)}/>)
                }
            </div>
        }
        {requests.totalCount > 0 && <div style={{display:'flex', gap: '5px',margin: '20px 0'}}>
             <Pagination 
                totalPages={requests.totalCount}
                skip={skip}
                take={DEFAULT_VALUES.take}
                setSkip={setRequestsSkip}
                type='redux'
            />
            <Loading isVisble={loading} size={30}/>
        </div> }
        {requests.totalCount <= 0 && !loading &&
            <BannerMessage>
                You don't have any requests
            </BannerMessage>
        }
    </div>
  )
}

function mapStateToProps({ requests }) {
    return {
        requests: requests.requests,
        allRequest: requests.allRequest,
        loading: requests.loading,
        skip: requests.skip
    };
  }
function mapDispatchToProps(dispatch){
    return{
        setRequestsDefault: () => dispatch(setRequestsDefault()),
        setRequestsSkip: data => dispatch(setRequestsSkip(data))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(RequestList)