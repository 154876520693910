import { useEffect, useRef, useState } from 'react'
import { useParams, useNavigationType, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

//styles
import './property-details/styles/property-details.css'

//images
import LocationImage from '../images/location.png'

//componentes
import ContentWrapper from './assets/elements/content-wrapper'
import Loading from './assets/controls/loading'
import Gallery from './property-details/gallery'
import FloorPlans from './property-details/floor-plans'
import Renovation from './property-details/renovation/renovation'
import Information from './assets/elements/information'
import Amenities from './property-details/amenities/amenities'
import BasketForm from './property-details/basket-form'
import Location from './property-details/location'
import Recommendations from './property-details/recommendations'
import Title from './property-details/title'
import Message from './assets/elements/message'
//utils
import { setPropertyDetails, setRecommendedProperties, checkIfInCart } from '../utils/property-details'

//action
import { setRecommendedPropertiesData, checkIfPropertyAddedToCart } from '../actions/property-details'

//constant
import { MESSAGE_ERROR, MESSAGE_NO_DATA } from '../constant/messages'
const PropertyDetails = ({ recommendedProperties, propertyDetails, loading, loadingRecommendedProperties, setRecommendedPropertiesData, isPropertyAddedToCart }) => {
  const navigate = useNavigate();
  const browserHistoryType = useNavigationType()
  const [prevParams, setPrevParams] = useState(null)
  const [updateRecommendations, setUpdateRecommendations] = useState(false)
  const params = useParams()
  const location_area = useRef()
  const { id, name, companyName, address, buildingTypeName, isOasisCertified, description, recentlyRenovated, dateOfRenovation, renovationDetails, amenities, petsAllowed, terminationPolicy, floorPlanImages, availableFloorPlans, propertyImages } = propertyDetails
  const scrollToElement = reference => window.scrollTo(0, reference.current.offsetTop - 90)
  // useEffect(() => {
  //   let imagesArray = propertyDetails?.propertyImages.map(image => `${process.env.REACT_APP_IMG_BASE_URL}${image.imageUrl}`)
  //   let floorArray = propertyDetails?.floorPlanImages.map(floor => `${process.env.REACT_APP_IMG_BASE_URL}${floor.imageUrl}`)
  //   let all = [
  //     ... imagesArray,
  //     ... floorArray
  //   ] 
  //   setAllImages(all)
  // }, [propertyDetails])  

  useEffect(() => {
    const BeforeUnloadHandler = e => {
      e.preventDefault()
      sessionStorage.setItem('isRefreshingDetails', true)
    }
    window.addEventListener('beforeunload', BeforeUnloadHandler)
    return () => {
      window.removeEventListener('beforeunload', BeforeUnloadHandler)
    }
  }, [])

  useEffect(() => {
    if (browserHistoryType === 'PUSH' || sessionStorage.getItem('isRefreshingDetails') === 'true' || (prevParams && prevParams !== params.id) || Object.keys(propertyDetails).length <= 0) {
      // setPropertyDetails(params.id)
        setPropertyDetails(params.id, navigate)
        checkIfInCart(params.id)
      setUpdateRecommendations(true)
      sessionStorage.getItem('isRefreshingDetails') && sessionStorage.setItem('isRefreshingDetails', false)
      setRecommendedPropertiesData()
    }
    window.history.scrollRestoration = 'manual'
    setPrevParams(params.id)
  }, [params.id])
  useEffect(() => {
    if (address) {
      if (updateRecommendations || recommendedProperties.length <= 0) {
        setRecommendedProperties(address.latitude, address.longitude, params.id)
        setUpdateRecommendations(false)
      }
    }
  }, [address])

  return (
    <ContentWrapper>
      {Object.keys(propertyDetails).length > 0 && !loading &&
        <div>
          <div>
            <Title id={id} name={name} companyName={companyName} type={buildingTypeName} renovated={recentlyRenovated} renovatedDate={dateOfRenovation} isOasisCertified={isOasisCertified} />
            {address && address.formattedAddress && <a className='property-details-address' onClick={() => scrollToElement(location_area)}><img src={LocationImage} alt="" />{address.formattedAddress} (<span>Show on map</span>)</a>}
          </div>
          <Gallery images={[...propertyImages, ...floorPlanImages]} floorPlanSize={floorPlanImages.length} propertiesSize={propertyImages.length} />
          <div className='property-details-multi-section'>
            <div>
              <Information title='Description'>{description ? description : MESSAGE_NO_DATA}</Information>
              {recentlyRenovated && <Renovation rdate={dateOfRenovation} rdetails={renovationDetails} />}
            </div>
                      <BasketForm floors={availableFloorPlans} propertyId={propertyDetails.id} isAddedToCart={isPropertyAddedToCart} />
          </div>
          <Amenities data={amenities} petsAllowed={petsAllowed} />

          <div>
            <Information title='Terms'>{terminationPolicy ? terminationPolicy : "Provided on Request"}</Information>

            {floorPlanImages.length > 0 && <FloorPlans data={floorPlanImages} />}
            <div ref={location_area}>
              <Location latitude={address.latitude} longitude={address.longitude} name={name} />
            </div>
            {recommendedProperties.length > 0 && <Recommendations data={recommendedProperties} />}
          </div>
        </div>
      }
      {Object.keys(propertyDetails).length <= 0 && !loading && !loadingRecommendedProperties &&
        <Message>{MESSAGE_ERROR}</Message>
      }
      <div className='property-details-loading'><Loading isVisble={loading || loadingRecommendedProperties} /></div>
    </ContentWrapper>
  )
}
function mapStateToProps({ details }) {
  return {
    propertyDetails: details.propertyDetails,
    loading: details.loading,
    loadingRecommendedProperties: details.loadingRecommendedProperties,
    recommendedProperties: details.recommendedProperties,
      isPropertyAddedToCart: details.isPropertyAddedToCart
  };
}
function mapDispatchToProps(dispatch) {
  return {
      setRecommendedPropertiesData: () => dispatch(setRecommendedPropertiesData([])),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails)