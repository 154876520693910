import Subtitle from '../assets/elements/section-subtitle'
import Map from '../assets/controls/map/map-one-location'
import Wrapper from '../assets/controls/google-wrapper'
const Location = ({latitude,longitude,name}) => {
  return (
    <div>
      <Subtitle>Location</Subtitle>
      <Wrapper>
        <Map latitude={latitude} longitude={longitude} name={name}/>
      </Wrapper>
      {/* <Map></Map> */}
    </div>
  )
}

export default Location