import {
    SET_MY_REQUESTS,
    SET_ALL_REQUESTS,
    SET_MY_REQUESTS_LOADING,
    SET_MY_REQUESTS_DEFAULT,
    SET_MY_REQUESTS_OPTION_DETAILS,
    SET_MY_REQUESTS_OPTION_AMENITIES,
    SET_MY_REQUESTS_OPTION_LOADING,
    SET_MY_REQUESTS_STATUS,
    SET_MY_REQUESTS_OPTION_STATUS,
    SET_MY_REQUESTS_SKIP
} from "../constant/my-requests";

const defaultState = {
    optionDetails: {},
    requests: {
        totalCount: 0,
        data: []
    },
    allRequest: [],
    loading: false,
    optionLoading: false,
    skip: 0
};

export default function requestsReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_MY_REQUESTS:
            return {
                ...state,
                requests: action.payload
            };
        case SET_MY_REQUESTS_SKIP:
            return {
                ...state,
                skip: action.payload
            };
        case SET_ALL_REQUESTS:
            return {
                ...state,
                allRequest: action.payload
            };
        case SET_MY_REQUESTS_STATUS:
            return {
                ...state,
                allRequest:state.allRequest.map(item => {
                        if(item.id === action.payload.requestId)
                        {
                            return {
                                ...item,
                                statusId: action.payload.statusId
                            }
                        }
                        else
                            return item
                    })
                ,
                requests: {
                    ...state.requests,
                    data: state.requests.data.map(item => {
                        if(item.id === action.payload.requestId)
                        {
                            return {
                                ...item,
                                statusId: action.payload.statusId
                            }
                        }
                        else
                            return item
                    })
                }
            };
        case SET_MY_REQUESTS_OPTION_STATUS:
            return {
                ...state,
                allRequest:state.allRequest.map(item => {
                        if(item.id === action.payload.requestId)
                        {
                            return {
                                ...item,
                                propertyOptions: item.propertyOptions.map(option => {
                                    if(option.id === action.payload.optionId)
                                    {
                                        return {
                                            ...option,
                                            statusId: action.payload.statusId
                                        }
                                    }
                                    else 
                                        return option
                                })
                            }
                        }
                        else
                            return item
                    })
                ,
                requests: {
                    ...state.requests,
                    data: state.requests.data.map(item => {
                        if(item.id === action.payload.requestId)
                        {
                            return {
                                ...item,
                                propertyOptions: item.propertyOptions.map(option => {
                                    if(option.id === action.payload.optionId)
                                    {
                                        return {
                                            ...option,
                                            statusId: action.payload.statusId
                                        }
                                    }
                                    else 
                                        return option
                                })
                            }
                        }
                        else
                            return item
                    })
                }
            };
        
        case SET_MY_REQUESTS_OPTION_DETAILS:
            return {
                ...state,
                optionDetails: action.payload
            };
        case SET_MY_REQUESTS_OPTION_AMENITIES:
            return {
                ...state,
                optionDetails: {
                    ...state.optionDetails,
                    option:{
                        ...state.optionDetails.option,
                        formattedOptionAmenities: state.optionDetails.option.formattedOptionAmenities.map(item =>{
                            if(item.title === action.payload.title)
                            {
                                return {
                                    ...item,
                                    activeMoreButton: action.payload.value
                                } 
                            }
                            else{
                                return item
                            }
                        })
                    }
                }
            };
        case SET_MY_REQUESTS_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case SET_MY_REQUESTS_OPTION_LOADING:
            return {
                ...state,
                optionLoading: action.payload
            };
        case SET_MY_REQUESTS_DEFAULT:
            return defaultState;
        default:
            return state;
    }
}