import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigationType } from 'react-router-dom'
//components
import ContentWrapper from './assets/elements/content-wrapper'
import PropertyFilter from './property-search/property-filter/property-filter'
import PropertyData from './property-search/property-data/property-data'
import PropertyMap from './assets/controls/map/map-by-location'
import SectionTitle from './assets/elements/section-title'
import Wrapper from './assets/controls/google-wrapper'
import SectionSubtitle from './assets/elements/section-subtitle'
import FloatingButton from './property-search/property-search-floating-button'

//actions
import { clearData, changeLocation, setPropertyFromMap, setPropertyFromList,setCardType,setSwitchLocationSearch,setClearLocationField,setCurrentMap,setMapLoading,setCurrentMapZoom,setCurrentMapMarkers,setCurrentMapCenter} from '../actions/property-search'

//constants
import { LOCATION_DEFAULT } from '../constant/default-properties'

//images
import { ReactComponent as LineImg } from "../images/line.svg";
import { ReactComponent as SquareImg } from "../images/square.svg";


const PropertySearch = ({dataIsLoaded,clearLocationField,switchLocationSearch,propertySearchView, cardType,location, filterTotalProperties, propertyMap, changeLocation,currentMapCenter, setPropertyFromMap, selectedPropertyFromList, selectedPropertyFromMap,currentMap,loadingMap,currentMapZoom,currentMapMarkers,setCurrentMapMarkers,setCurrentMap,setCurrentMapCenter,setCurrentMapZoom,setMapLoading, setCardType,miles,setSwitchLocationSearch,setClearLocationField }) => {
  const browserHistoryType = useNavigationType()

  const params = useParams()
  const dataContainer = useRef()
  // const [clearLocationField, setClearLocationField] = useState()
  useEffect(() => {
      if(params.id && params.address)
        {
          setSwitchLocationSearch('field',null)
          changeLocation({
            id: params.id,
            value: params.address
          })
        }
      else{
          if(!(location.id && location.value && switchLocationSearch.type === 'field'))
            changeLocation({
              id: LOCATION_DEFAULT.id,
              value: LOCATION_DEFAULT.value
            })
      }
  }, [params])

 
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    window.scrollTo(0, 0)
  }, [])


  return (
    <ContentWrapper>
      <div className='space-between'>
        <SectionTitle>Property Search</SectionTitle>
      </div>
      <PropertyFilter clearLocationField={clearLocationField}  setClearLocationField={setClearLocationField} mode={switchLocationSearch} setMode={setSwitchLocationSearch}/>
      <div className='property-search'>
        <div className={`property-search-content`}>
          <div className='property-search__list__header'>
            <div className='results-container'>
              <SectionSubtitle>
                Search results
              </SectionSubtitle>
              <div className='results-container-description'>(find {filterTotalProperties} properties)</div>
            </div>
            <div className={`property-search__card-type`} style={propertySearchView === 'grid' ? {display:'flex'} : {display:'none'}}>
                <span className={`property-search__card-type__btn-line ${cardType === 'line' ? 'property-search__card-type__btn-line--active' : ''}`} onClick={_=>setCardType('line')}><LineImg /></span>
                <span className={`property-search__card-type__btn-square ${cardType === 'square' ? 'property-search__card-type__btn-square--active' : ''}`} onClick={_=>setCardType('square')}><SquareImg/></span>
            </div>
          </div>
          <div ref={dataContainer} className={`property-search-list ${propertySearchView === 'grid' ? 'property_search_view--active' : 'property_search_view--inactive'}`}>
            <PropertyData refList={dataContainer} />
          </div>
        </div>
        <div className={`property-search-map ${propertySearchView === 'map' ? 'property_search_view--active' : 'property_search_view--inactive'}`} style={{paddingBottom:'20px'}}>
            <Wrapper>
              <PropertyMap 
                data={propertyMap} 
                dataIsLoaded={!dataIsLoaded}
                // setSelectedProperty={setPropertyFromMap} 
                // selectedProperty={selectedPropertyFromList} 
                // currentListTop={selectedPropertyFromMap} 
                location={location}
                markers={currentMapMarkers}
                setMarkers={setCurrentMapMarkers}
                mapCurrentZoom={currentMapZoom}
                setMapCurrentZoom={setCurrentMapZoom}
                mapCurrentCenter={currentMapCenter}
                setMapCurrentCenter={setCurrentMapCenter}
                radiusInMiles={miles}
                map={currentMap}
                loading={loadingMap}
                mode={switchLocationSearch}
                setMap={setCurrentMap}
                setLoading={setMapLoading}
                setMode={setSwitchLocationSearch}
                setClearLocationField={setClearLocationField}
              />
            </Wrapper>
        </div>
      </div>
      <FloatingButton />
    </ContentWrapper>
  )
}
function mapStateToProps({ search }) {
  return {
    propertyMap: search.propertyMap,
    propertySearchView: search.propertySearchView,
    location: search.location,
    miles: search.miles,
    cardType: search.cardType,
    filterTotalProperties: search.filterTotalProperties,
    selectedPropertyFromList: search.selectedPropertyFromList,
    selectedPropertyFromMap: search.selectedPropertyFromMap,
    filterPropertyData: search.filterProperties,
    switchLocationSearch: search.switchLocationSearch,
    clearLocationField: search.clearLocationField,
    currentMap: search.currentMap,
    currentMapZoom: search.currentMapZoom,
    currentMapMarkers: search.currentMapMarkers,
    dataIsLoaded: search.loading,
    loadingMap: search.loadindMap,
    currentMapCenter: search.currentMapCenter,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearData: () => dispatch(clearData()),
    changeLocation: data => dispatch(changeLocation(data)),
    setPropertyFromMap: id => dispatch(setPropertyFromMap(id)),
    setCardType: value => dispatch(setCardType(value)),
    clearPropertyFromList: () => dispatch(setPropertyFromList('')),
    setSwitchLocationSearch: (type,value) => dispatch(setSwitchLocationSearch(type,value)),
    setClearLocationField: (value) => dispatch(setClearLocationField(value)),
    setMapLoading: value => dispatch(setMapLoading(value)),
    setCurrentMap: value => dispatch(setCurrentMap(value)),
    setCurrentMapZoom: value => dispatch(setCurrentMapZoom(value)),
    setCurrentMapCenter: value => dispatch(setCurrentMapCenter(value)),
    setCurrentMapMarkers: value => dispatch(setCurrentMapMarkers(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertySearch)