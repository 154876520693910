import {useState,useEffect} from 'react'
import {connect} from 'react-redux'
//components
import SectionTitle from '../assets/elements/section-title'
import Pagination from '../assets/controls/pagination'
import Loading from '../assets/controls/loading'
import Alert from '../assets/elements/custom-alert'
import SectionHeader from '../assets/elements/section-header'
import ContentWrapper from '../assets/elements/content-wrapper'
import CareerTable from './career-table'
//utils
import {getVacancies} from '../../utils/career'

//actions
import {setDefaultCareerList} from '../../actions/career'


//constants
import {HEADER,CAREERS_LIST,PAGINATION} from '../../constant/career'
//styles
import './styles/career-list.css'

const CareerList = ({loading,careerList,careerListTotal,setDefaultCareerList,message}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState()
  
  useEffect(() => {
    getVacancies((currentPage-1)*PAGINATION.take)
  }, [currentPage])
  useEffect(() => {
    if(careerListTotal > 0)
    {
        setTotalPages(Math.ceil(careerListTotal/PAGINATION.take))
    }
  }, [careerListTotal])
  useEffect(() => {
    return () => {
      setDefaultCareerList()
    }
  }, [])
  return (
    <div className="section-content-wrapper">
      <SectionHeader title={HEADER.title} subtitle={HEADER.subtitle} image={HEADER.img}/>
          <ContentWrapper>
            <div className='career-list'>
                <p>{CAREERS_LIST.description}</p>
                <div className='career-list__section'>
                    <SectionTitle>{CAREERS_LIST.title}</SectionTitle>
                        
                          <div className={careerListTotal > 0 ? 'career-list__pagination' : 'career-list__pagination--center'}>
                            {careerListTotal > 0 && <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>}
                            <Loading isVisble={loading} size={26}/>
                          </div>
                          {careerList.length > 0 &&
                            <div className='career-list__section__table'>
                              <CareerTable headers={CAREERS_LIST.positionHeaders} content={careerList}/>
                            </div>
                          }
                          {message.content && <Alert type={message.error? 'error' : ''}><div className='career-list__message'>{message.content}</div></Alert>}
                </div>
            </div>
          </ContentWrapper>
     </div>
  )
}
function mapStateToProps({ career }) {
    return {
      careerList: career.careerList,
      careerListTotal: career.careerListTotal,
      loading: career.loadingCareerList,
      message: career.messageCareerList
    };
  }
function mapDispatchToProps(dispatch){
    return{
        setDefaultCareerList: () => dispatch(setDefaultCareerList())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CareerList)