import store from "../store";
import baseService from "../services/base.service";
import { getPropertyImage } from '../utils/files'

//actions
import { setLoadingRecommendedProperties, setPropertyDetailsData, setLoading, setRecommendedPropertiesData, setIfPropertyAddedToCart } from '../actions/property-details'

//utils
import {getFavorites} from './favorites'
import {isLoggedIn} from './user'
import {getAllBasket} from './my-basket'

//devextreme
import notify from 'devextreme/ui/notify';

let controller;

const setPropertyDetails = async (id, navigate) => {
  try {
    await store.dispatch(setLoading(true))
    if (controller)
      controller.abort()

    controller = new AbortController();
    const url = `${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/dashboard/${id}/details`;
    await baseService.get(url, {
      method: 'GET',
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': `${process.env.PUBLIC_URL}`,
        'Content-Type': 'application/json',
      },
      signal: controller.signal
    }).then(({ data }) => {
      if (!data) {
        navigate("/property-not-found", { state: { id } });
      } else {
        
        store.dispatch(setPropertyDetailsData(data))
      }
    }).finally(() => {
      store.dispatch(setLoading(false))
    })
      .catch(error => {
        store.dispatch(setPropertyDetailsData([]))
        store.dispatch(setLoading(false))
      })
  } catch (error) {
    store.dispatch(setPropertyDetailsData([]))
    store.dispatch(setLoading(false))
  }
}

const checkIfInCart = async (propertyId) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart/check-if-added/${propertyId}`;
    await baseService.get(url, {
        method: 'GET',
        withCredentials: false,
        headers: {
           'Access-Control-Allow-Origin': `${process.env.PUBLIC_URL}`,
            'Content-Type': 'application/json',
        }
    }).then(({ data }) => {
        store.dispatch(setIfPropertyAddedToCart(data))
    })
}

const setRecommendedProperties = async (latitude, longitude, currentId) => {
  try {
    if (controller)
      controller.abort()

    controller = new AbortController();
    store.dispatch(setLoadingRecommendedProperties(true))

    let radius = `&Radius=20`
    let geometry = `Latitude=${latitude}&Longitude=${longitude}`
    let pagination = `&skip=0&take=5`
    let dataFavorites = await getFavorites()
    const url = `${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/search/list?${geometry}${radius}${pagination}`;
    await baseService.get(url, {
      method: 'GET',
      //mode: 'no-cors',
      withCredentials: false,
      headers: {
        //'Access-Control-Allow-Origin': true,
        'Content-Type': 'application/json',
      },
      signal: controller.signal
    }).then(({ data: { data } }) => {
      let favoriteIds = dataFavorites.map(item => item.id)

      let recommendedProperties = data.filter(item => item.metisId.toString() !== currentId).filter((item, index) => index < 4).map(
        property=>{
        let likeId = dataFavorites.filter(item => item.propertyId === property.id)
        return {
          ...property,
            isLiked: favoriteIds.includes(property.id),
            likeId: likeId[0]?.id,
            imageUrl: getPropertyImage(property.imageId)
        }
      })
      store.dispatch(setRecommendedPropertiesData(recommendedProperties))
    }).finally(() => {
      store.dispatch(setLoadingRecommendedProperties(false))
    })
      .catch(error => {
        store.dispatch(setRecommendedPropertiesData([]))
        store.dispatch(setLoadingRecommendedProperties(false))
      })
  } catch (error) {
    store.dispatch(setRecommendedPropertiesData([]))
    store.dispatch(setLoadingRecommendedProperties(false))
  }

}
const postPropertyToCart = async (floorId,propertyId) => {
  try {
    if(isLoggedIn())
    {
      if(floorId && propertyId)
      {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart`;
        let formData = new FormData()
        formData.append('PropertyId',propertyId)
        formData.append('FloorPlanTypes',floorId)
      
        return await baseService.post(url,formData,{
          headers: {
          'accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
          },
        }
        )
        .then(data => {
          notify('Property added to cart', "success" , 3000)
            getAllBasket()
          return true
        })
        .catch(error => {
          if(error.response.data === "Property already added to cart by user")
            notify('Property already added to cart', "error" , 3000);
          else
                notify('Something went wrong try again later', "error", 3000);
            return false
        })
      }
      else
        notify('You need to select a floor plan', "error" , 3000);
    }
    else
      notify('You need to be logged in', "error" , 3000);
  } catch (error) {
    notify('Something went wrong try again later', "error" , 3000);
  }
}

export { setPropertyDetails, setRecommendedProperties, postPropertyToCart, checkIfInCart }