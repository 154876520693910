import { useEffect, useState } from 'react';
import { useParams, Navigate, useLocation } from "react-router-dom";
import baseService from "../../services/base.service";
import './styles/gallery.css';
import './styles/image-download.css';
import { getPropertyImage } from '../../utils/files';
const ImageDownload = () => {
    const { fileName } = useParams();
    const [url, setUrl] = useState(null);
    useEffect(() => {

        if (fileName) {
            let backImageUrl = '';
            const fileNameSplited = fileName.split('.');
            if (fileNameSplited.length > 1) {
                backImageUrl = getPropertyImage(fileNameSplited[0]);
            } else {
                backImageUrl = getPropertyImage(fileName);
            }
            // window.location.href=backImageUrl;
            setUrl(backImageUrl);
        }
    }, []);

    return (
        <div id='imageDownload'>
            <div className='image'>
                <div className='image__download'>
                    <img src={url}></img>
                </div>
            </div>
        </div>
    );
}

export default ImageDownload;