import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import isEmpty from "lodash/isEmpty";

const PrivateRoute = ({ children, isAuthenticated }) => {
	const location = useLocation();
	
	return (isAuthenticated ? children : <Navigate to="/login" state={{ from: location }} replace />);
}

const mapStateToProps = ({ auth }) => {
	return {
		profile: auth.profile,
		isAuthenticated: !isEmpty(auth.profile)
	}
};

function mapDispatchToProps(dispatch) {
	return {
		dispatch
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);