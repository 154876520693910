import { useState } from "react"

//components
import AccordionButton from '../../assets/elements/accordion/accordion-button'

//styles
import '../styles/amenities/amenities-list.css'

import { getPropertyImage } from '../../../utils/files'

const AmenitiesList = ({title,data}) => {
  const [show,setShow] = useState({
    visible: false,
    message: 'Show more'
  })
  const showMore = () => {
    setShow({
      visible: !show.visible,
      message : !show.visible ? 'Show less' : 'Show more'
    })
  }
  return (
        <div className='amenities-list'>
          <div>
            {title}
          </div>
          <div className='amenities-list__items'>
            {data.map((item,index)=>
                
                  index < 8 ? 
                  <div key={index} className='amenities-list__item'>
                        <img src={getPropertyImage(item.iconId)} width={24} height={22}/>
                        <div>
                            {item.name}
                        </div>
                  </div> 
                  :
                  <div key={index} className={show.visible ? 'amenities-list__item':'amenities-list__item--hidden'}>
                        <img src={getPropertyImage(item.iconId)} width={24} height={22}/>
                        <div>
                            {item.name}
                        </div>
                  </div> 
                
            )}
            {data.length > 8 && <AccordionButton title={show.message} active={show.visible} changeActive={showMore}/>}
          </div>
      </div>
  )
}

export default AmenitiesList