import {useCallback,useEffect,useState,memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-uploader.css'

//components
import FileUploader from 'devextreme-react/file-uploader';

import {
    Validator,
    RequiredRule,
  } from 'devextreme-react/validator';

const CustomUploader = ({label, value, setValue,requiredValue,message,labelSize,notifyLabel,allowedExtensions,maxFiles}) => {
  // const allowedFileExtensions = ['.jpeg', '.png', '.gif'];
  const [isValid, setIsValid] = useState(false)
  const onChangeValue = useCallback((data) => {
    setValue(data)
  }, []);
  
  useEffect(() => {
    let invalidFields = document.querySelectorAll('.dx-fileuploader-invalid')
    invalidFields?.forEach(item=>item?.querySelector('.dx-fileuploader-cancel-button').click())
    let allFiles = document.querySelectorAll('.dx-fileuploader-file-container')
    allFiles.forEach((item,index)=> {
      index > (maxFiles - 1) && item?.querySelector('.dx-fileuploader-cancel-button').click()
    })
    if(invalidFields.length > 0)
     {
      setIsValid(false)
     } 
    else
     {
      allFiles.length > 0 && setIsValid(true)
     } 
  }, [value])

  return (
    <div className='custom-uploader' style={labelSize ? {gridTemplateColumns: `${labelSize} calc(100% - ${labelSize} - 20px)`} : {}}>
        <div className='custom-uploader__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
        <div>
          <FileUploader 
              className={`custom-uploader__input ${value.length > 0 && value.length < maxFiles && isValid ? 'custom-uploader__input--another': (value.length === maxFiles  ? 'custom-uploader__input--hide':'') }`}
              accept={allowedExtensions}
              allowedFileExtensions={allowedExtensions.split(',')}
              multiple={true}
              // onUploadStarted={onChange}
              labelText={`or Drop ${notifyLabel} here`}
              selectButtonText={value.length > 0 && value.length < maxFiles && isValid ? `+ Another ${notifyLabel}`:`Choose ${notifyLabel}`}
              uploadMode="useButtons"
              value={value}
              onValueChange={onChangeValue}
              validationMessagePosition="left"
          >
            {requiredValue &&
              <Validator>
                <RequiredRule message={`${label} is required`} />
              </Validator>
            }
          </FileUploader>
          <div className='custom-uploader__message'>{message}</div>
        </div>
        
    </div>
  )
}

export default memo(CustomUploader)