import {useCallback,memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-input.css'

//components
import { RadioGroup } from 'devextreme-react/radio-group';

import {
    Validator,
    RequiredRule,
  } from 'devextreme-react/validator';

const CustomRadio = ({label, value, setValue,requiredValue,items,labelSize,type}) => {
  const onChangeValue = useCallback((data) => {
      setValue(data)
  }, []);
  return (
    <div className={`custom-input ${type === 'vertical' ? 'custom-input--vertical' : ''}`} style={labelSize ? {gridTemplateColumns: `${labelSize} calc(100% - ${labelSize} - 20px)`} : {}}>
        <div className='custom-input__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
        <RadioGroup
                dataSource={items}
                value={value}
                onValueChange={onChangeValue}
                displayExpr="name"
                layout={type === 'vertical' ? 'vertical' : 'horizontal'}
         >
            {requiredValue &&
                <Validator>
                    <RequiredRule message={`${label} is required`} />
                </Validator>
            }
        </RadioGroup>
    </div>
  )
}

export default memo(CustomRadio)