import Banner from '../images/career_bg.png'
export const SET_CAREER_LIST = 'SET_CAREER_LIST'
export const SET_CAREER_LIST_PAGINATION_TOTAL = 'SET_CAREER_LIST_PAGINATION_TOTAL'
export const SET_CAREER_LIST_PAGINATION_CURRENT = 'SET_CAREER_LIST_PAGINATION_CURRENT'

export const SET_LOADING_CAREER_LIST = 'SET_LOADING_CAREER_LIST'
export const SET_DEFAULT_CAREER_LIST = 'SET_DEFAULT_CAREER_LIST'
export const SET_CAREER_LIST_TOTAL = 'SET_CAREER_LIST_TOTAL'
export const SET_MESSAGE_CAREER_LIST = 'SET_MESSAGE_CAREER_LIST'

export const SET_CAREER_LIST_HISTORY = 'SET_CAREER_LIST_HISTORY'
export const SET_LOADING_CAREER_LIST_HISTORY = 'SET_LOADING_CAREER_LIST_HISTORY' 
export const SET_DEFAULT_CAREER_LIST_HISTORY = 'SET_DEFAULT_CAREER_LIST_HISTORY'
export const SET_MESSAGE_CAREER_LIST_HISTORY = 'SET_MESSAGE_CAREER_LIST_HISTORY'
export const SET_CAREER_LIST_TOTAL_HISTORY = 'SET_CAREER_LIST_TOTAL_HISTORY'

export const SET_CAREER_LIST_BY_ID = 'SET_CAREER_LIST_BY_ID'
export const SET_MESSAGE_CAREER_BY_ID = 'SET_MESSAGE_CAREER_BY_ID'
export const SET_LOADING_CAREER_BY_ID = 'SET_LOADING_CAREER_BY_ID'
export const SET_DEFAULT_CAREER_BY_ID = 'SET_DEFAULT_CAREER_BY_ID'

export const SET_APPLY_EMAIL = 'SET_APPLY_EMAIL'
export const SET_APPLY_COVERLETTER = 'SET_APPLY_COVERLETTER'
export const SET_APPLY_FIRSTNAME = 'SET_APPLY_FIRSTNAME'
export const SET_APPLY_HEAR_US = 'SET_APPLY_HEAR_US'
export const SET_APPLY_IS_PHONE_VALID_MESSAGE = 'SET_APPLY_IS_PHONE_VALID_MESSAGE'
export const SET_APPLY_IS_PHONE_VALID_STATE = 'SET_APPLY_IS_PHONE_VALID_STATE'
export const SET_APPLY_LASTNAME = 'SET_APPLY_LASTNAME'
export const SET_APPLY_PHONE = 'SET_APPLY_PHONE'
export const SET_APPLY_RESUME = 'SET_APPLY_RESUME'
export const SET_APPLY_LOADING = 'SET_APPLY_LOADING'
export const SET_APPLY_MESSAGE = 'SET_APPLY_MESSAGE'
export const SET_APPLY_DEFAULT = 'SET_APPLY_DEFAULT'
export const SET_APPLY_DEFAULT_MESSAGE = 'SET_APPLY_DEFAULT_MESSAGE'

export const SET_ADMIN_FORM_JOB = 'SET_ADMIN_FORM_JOB'
export const SET_ADMIN_FORM_LOCATION = 'SET_ADMIN_FORM_LOCATION'
export const SET_ADMIN_FORM_DESCRIPTION = 'SET_ADMIN_FORM_DESCRIPTION'
export const SET_ADMIN_FORM_RESPONSABILITIES = 'SET_ADMIN_FORM_RESPONSABILITIES'
export const SET_ADMIN_FORM_QUALIFICATION = 'SET_ADMIN_FORM_QUALIFICATION'
export const SET_ADMIN_FORM_COMPENSATION = 'SET_ADMIN_FORM_COMPENSATION'
export const SET_ADMIN_FORM_DEFAULT = 'SET_ADMIN_FORM_DEFAULT'
export const SET_ADMIN_FORM_MESSAGE = 'SET_ADMIN_FORM_MESSAGE'

export const HEADER = {
    title: 'CAREERS',
    subtitle: 'An exciting opportunity awaits you!',
    img: Banner
}

export const CAREERS_LIST = {
    title:'OPEN POSITIONS',
    description:'Oasis is one of the fastest growing companies with a vision to be the undisputed leader in the relocation industry.\nWe firmly believe that Values and Ethics form the strong foundation upon which our organization has built itself. We also believe in fair and performance based career growth thus ensuring that our employees are treated fairly and equitably.\nWe are constantly on the look out for talented, enthusiastic and passionate individuals who are determined to deliver results and grow with us and share in our success. We are looking for those individuals who are confident about realizing our Vision and execute our every Mission with an unwavering commitment and utmost passion for excellence. Every applicant should come bearing an undeniable positive attitude and excitement to be part of our growing team that will reshape the corporate housing industry and bring the highest level of value to our clients.',
    positionHeaders: [
        'Job Title',
        'Job Location'
    ],
    messages:{
        error: 'Something went wrong. Please try again later',
        nodata: "We really appreciate your interest for our companany, but at the moment we haven't any open positions."
    }
}

export const CAREERS_LIST_HISTORY = {
    messages:{
        error: 'Something went wrong. Please try again later',
        nodata: "No data"
    }
}

export const CAREERS_LIST_ADMIN_FORM = {
    create:{
        title: 'ADD NEW VACANCY',
        button: 'Create',
        disable:false,
        message:{
            error: 'Something went wrong. Please try again later',
            success: 'The vacancy was successfully added'
        }
    },
    view:{
        title: 'VIEW VACANCY',
        disable: true
    },
    edit:{
        title: 'EDIT VACANCY',
        button: 'Edit',
        disable: false,
        message:{
            error: 'Something went wrong. Please try again later',
            success: 'The vacancy was successfully updated'
        }
    },
    delete:{
        title: 'DELETE VACANCY',
        button: 'Delete',
        disable: true,
        message:{
            error: 'Something went wrong. Please try again later',
            success: 'The vacancy was successfully deleted'
        }
    },
    closeButton: 'Cancel'
}

export const CAREER_DETAILS = {
   sections:{
        role: 'Roles and Responsibilities:',
        qualify: 'Qualifications:',
        salary: 'Compensation'
    },
    messages:{
        error: 'Something went wrong. Please try again later',
        nodata: 'No data available'
    },
    buttonName: 'Apply Now'
}
export const CAREER_APPLY = {
    title: 'APPLY NOW',
    subtitle: 'Thank you for your interest in a career at Oasis',
    img: Banner,
    form: {
        firstname: 'First Name',
        lastname: 'Last Name',
        email: 'Email',
        phone: 'Phone number',
        pdf: {
            title: 'Resume/CV',
            notifyLabel: 'file',
            allowedExtensions: '.pdf,.docx,.doc,.rtf',
            maxFiles: 1,
            message: 'Please upload a file of your resume* (pdf, docx, doc, rtf)'
        },
        cover: 'Cover Letter',
        hearUs: 'How did you hear about this job'
    },
    messages: {
        success: 'Your application was sent',
        error: 'Something went wrong try again later'
    },
    buttonName: 'Submit'
}
export const CAREER_ADMIN = {
    title: 'CAREERS',
    navbar: {
        firstTab: 'Active',
        secondTab: 'History'
    },
    buttonCreate: 'Add new vacancy'
}
export const PAGINATION = {
    take: 20
}