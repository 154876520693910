import {useEffect} from 'react'
import {connect} from 'react-redux'

//components
import CustomText from '../../assets/elements/input/custom-text'
import CustomHtmlEditor from '../../assets/elements/input/custom-html-editor'
import ActionButton from '../../assets/elements/button/action-button'

import SecondaryButton from '../../assets/elements/button/secondary-action-button'
import SectionTitle from '../../assets/elements/section-title'
//constant
import {CAREERS_LIST_ADMIN_FORM} from '../../../constant/career'
//actions
import {setAdminFormJob,setAdminFormCompensation,setAdminFormDescription,setAdminFormLocation,setAdminFormQualification,setAdminFormResponsabilities,setAdminFormDefault} from '../../../actions/career'

//utils
import {postCreateVacancy,putEditVacancy,deleteVacancy,getVacanciesByIdAdmin} from '../../../utils/career'
//styles
import '../styles/career-list-form.css'
const CareerListForm = ({id,type,onHiding,careerAdminForm,setAdminFormJob,setAdminFormCompensation,setAdminFormDescription,setAdminFormLocation,setAdminFormQualification,setAdminFormResponsabilities,setAdminFormDefault}) => {
  const onAction = async e => {
    e.preventDefault()
    switch (type) {
      case 'create':
        await postCreateVacancy()
        break;
      case 'edit':
       await putEditVacancy(id)
        break;
      case 'delete':
        deleteVacancy(id)
        break;
      default:
        break;
    }
    onHiding()
  }
  useEffect(() => {
    id && getVacanciesByIdAdmin(id)
    return () => setAdminFormDefault()
  }, [])
  
  return (
    <form onSubmit={onAction} className='career-list-form'>
      <div className='d-flex justify-content-center'><SectionTitle>{CAREERS_LIST_ADMIN_FORM[type]?.title}</SectionTitle></div>
          <CustomText 
            label='Job title' 
            value={careerAdminForm.jobTitle} 
            setValue={setAdminFormJob} 
            requiredValue={true} 
            isDisabled={CAREERS_LIST_ADMIN_FORM[type]?.disable}/>
          <CustomText 
            label='Location' 
            value={careerAdminForm.location} 
            setValue={setAdminFormLocation} 
            requiredValue={true} 
            isDisabled={CAREERS_LIST_ADMIN_FORM[type]?.disable}/>
          <CustomHtmlEditor 
            label='Short Description' 
            value={careerAdminForm.shortDescription} 
            setValue={setAdminFormDescription} 
            requiredValue={true} 
            isDisabled={CAREERS_LIST_ADMIN_FORM[type]?.disable}
          />
          <CustomHtmlEditor 
            label='Roles and Responsibilities' 
            value={careerAdminForm.responsibilities} 
            setValue={setAdminFormResponsabilities} 
            requiredValue={true} 
            isDisabled={CAREERS_LIST_ADMIN_FORM[type]?.disable}/>
          <CustomHtmlEditor 
            label='Qualifications' 
            value={careerAdminForm.qualifications} 
            setValue={setAdminFormQualification} 
            requiredValue={true} 
            isDisabled={CAREERS_LIST_ADMIN_FORM[type]?.disable}/>
          <CustomHtmlEditor 
            label='Compensation' 
            value={careerAdminForm.compensation} 
            setValue={setAdminFormCompensation} 
            requiredValue={true} 
            isDisabled={CAREERS_LIST_ADMIN_FORM[type]?.disable}/>
          <div className='career-list-form__buttons'>
            {CAREERS_LIST_ADMIN_FORM[type]?.button && <ActionButton>{CAREERS_LIST_ADMIN_FORM[type].button}</ActionButton>}
            <SecondaryButton action={onHiding}>{CAREERS_LIST_ADMIN_FORM.closeButton}</SecondaryButton>
          </div>
    </form>
  )
}
function mapStateToProps({ career }) {
  return {
    careerAdminForm: career.careerAdminForm,
  };
}
function mapDispatchToProps(dispatch){
  return{
    setAdminFormJob: data => dispatch(setAdminFormJob(data)),
    setAdminFormLocation: data => dispatch(setAdminFormLocation(data)),
    setAdminFormDescription: data => dispatch(setAdminFormDescription(data)),
    setAdminFormResponsabilities: data => dispatch(setAdminFormResponsabilities(data)),
    setAdminFormQualification: data => dispatch(setAdminFormQualification(data)),
    setAdminFormCompensation: data => dispatch(setAdminFormCompensation(data)),
    setAdminFormDefault: () => dispatch(setAdminFormDefault())
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(CareerListForm)