import {useState,useRef} from 'react'
import {useParams} from 'react-router-dom'

import {connect} from 'react-redux'

//components
import ActionButton from '../assets/elements/button/action-button'
import ContentWrapper from '../assets/elements/content-wrapper'
import EmailInput from '../assets/elements/input/custom-email-alt'
import Loading from '../assets/controls/loading'

//constant
import {TYPE} from '../../constant/forgot-reset'

//images
import EmailIcon from "../../images/messages.svg";

//utils
import {postEmail} from '../../utils/forgot-reset'

//styles
import './styles/forgot-reset.css'

const ForgotPassword = ({loading}) => {
  const params = useParams()
  const [email, setEmail] = useState(null)
  const refEmailInputValidator = useRef(null)

  const sendEmail = async e =>  {
    e.preventDefault()
    await postEmail(params.type,email)
  }
    return (
    <ContentWrapper>
      { Object.keys(TYPE).filter(item => item === params.type).length > 0 &&
        <form onSubmit={sendEmail} className='forgot-reset'>
          <div className='forgot-reset__container'>
              <div className='forgot-reset__container__title'>
                <span>ENTER YOUR EMAIL ADDRESS, PLEASE</span>
                <Loading isVisble={loading} size={20}/>
              </div>
              <div className='forgot-reset__container__input'>
                  <EmailInput 
                    value={email}
                    reference={refEmailInputValidator}
                    isDisabled={loading}
                    setValue={setEmail}
                    requiredValue={true}
                    inputIcon={EmailIcon}
                    iconSize={18}
                    placeholder='Email Address'
                    validateFocus={true}/>
              </div>
              <ActionButton disabled={loading}>{TYPE[params.type].buttonLabel}</ActionButton>
          </div>
        </form>
      }
    </ContentWrapper>
  )
}

function mapStateToProps({ forgotReset }) {
  return {
      loading: forgotReset.loading
  };
}

export default connect(mapStateToProps)(ForgotPassword)