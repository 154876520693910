export const SET_BASKET = "SET_BASKET";
export const SET_LOADING_BASKET = "SET_LOADING_BASKET";
export const SET_LOADING_SEND_REQUEST = "SET_LOADING_SEND_REQUEST"
export const SET_BASKET_DEFAULT = "SET_BASKET_DEFAULT"
export const SET_DATA_TO_CHECK = "SET_DATA_TO_CHECK";
export const SET_ALL_BASKET = "SET_ALL_BASKET"
export const SET_CLEAR_LOCATION_FIELD = "SET_CLEAR_LOCATION_FIELD"
export const SET_NO_MATCH = "SET_NO_MATCH"

//FIELDS
export const SET_LOCATION_BASKET = "SET_LOCATION_BASKET"

export const SET_MILES = "SET_MILES"
export const SET_FLOOR_PLAN = "SET_FLOOR_PLAN"
export const SET_CURRENCY_TYPE = "SET_CURRENCY_TYPE"
export const SET_CURRENCY_ERROR = "SET_CURRENCY_ERROR"
export const SET_BUILDING_TYPES = "SET_BUILDING_TYPES"
export const SET_NOTES = "SET_NOTES"

export const SET_BUDGET_MIN = "SET_BUDGET_MIN"
export const SET_BUDGET_MAX = "SET_BUDGET_MAX"

export const SET_QTY_ADULTS = "SET_QTY_ADULTS"
export const SET_QTY_CHILDREN = "SET_QTY_CHILDREN"

export const SET_IS_PETS = "SET_IS_PETS"
export const SET_QTY_PETS = "SET_QTY_PETS"
export const SET_PET_DETAILS = "SET_PET_DETAILS"
// export const SET_PET_TYPE = "SET_PET_TYPE"
export const SET_PET_WEIGHT = "SET_PET_WEIGHT"
export const SET_PET_WEIGHT_TYPE = "SET_PET_WEIGHT_TYPE"

export const SET_MOVE_IN = "SET_MOVE_IN"
export const SET_MOVE_OUT = "SET_MOVE_OUT"
export const SET_DATE_RANGE = "SET_DATE_RANGE"


export const SET_SELECTED_GUEST_ID = "SET_SELECTED_GUEST_ID"
export const SET_GUEST_USER = "SET_GUEST_USER"
export const SET_GUEST_USER_EMPTY = "SET_GUEST_USER_EMPTY"
export const SET_GUEST_PHONE = "SET_GUEST_PHONE"
export const SET_IS_GUEST_PHONE_VALID_STATE = "SET_IS_GUEST_PHONE_VALID_STATE"
export const SET_IS_GUEST_PHONE_VALID_MESSAGE = "SET_IS_GUEST_PHONE_VALID_MESSAGE"
export const SET_GUEST_EMAIL = "SET_GUEST_EMAIL"
export const SET_IS_EXISTING_USER = "SET_IS_EXISTING_USER"
export const SET_IS_GUEST_CHECKBOX_SELECTED = "SET_IS_GUEST_CHECKBOX_SELECTED"
export const SET_DATA_KEYS_AMENITIES = "SET_DATA_KEYS_AMENITIES"
export const SET_SELECTED_DATA_AMENITIES = "SET_SELECTED_DATA_AMENITIES"
export const SET_MY_BASKET_TIMER = "SET_MY_BASKET_TIMER"
export const SET_MAID_SERVICE = "SET_MAID_SERVICE"
export const SET_PARKING_SLOTS = "SET_PARKING_SLOTS"


export const PET_WEIGHT = [
    {id: 2, name: 'kg'},
    {id: 1, name: 'lb'}
]


