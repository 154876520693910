import {
    RECEIVE_PROFILE_DATA,
    RECEIVE_USER_PROFILE_DATA,
    RECEIVE_ACCESS_TOKEN,
    RECEIVE_REFRESH_TOKEN,
    RECEIVE_ID_TOKEN,
    LOGOUT
} from "../constant/auth";

const defaultState = {
    profile: null,
    userProfile: null,
    accessToken: null,
    refreshToken: null,
    idToken: null
};

export default function authReducer(state = defaultState, action) {
    switch (action.type) {
        case RECEIVE_PROFILE_DATA:
            return {
                ...state,
                profile: action.payload.profile
            };
        case RECEIVE_USER_PROFILE_DATA:
            return {
                ...state,
                userProfile: action.payload.userProfile
            };
        case RECEIVE_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload.accessToken
            };
        case RECEIVE_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.payload.refreshToken
            };
        case RECEIVE_ID_TOKEN:
            return {
                ...state,
                idToken: action.payload.idToken
            };
        case LOGOUT:
            return defaultState;
        default:
            return state;
    }
}