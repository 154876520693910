import '../../styles/button/secondary-action-button.css'
import 'devextreme/dist/css/dx.light.css';
import { Button } from 'devextreme-react/button';

const SecondaryActionButton = ({children,action,disabled}) => {
  return (
    <Button
      className='secondary-action-button'
      disabled={disabled}
      text={children}
      useSubmitBehavior={false}
      onClick={action} />
  )
}

export default SecondaryActionButton