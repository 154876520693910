import {useState} from 'react'
import { connect } from 'react-redux'

//action
import {setMyRequestOptionAmenities} from '../../../../../actions/my-requests'

//images
import ShowMoreIcon from '../../../../../images/show more.svg'

//styles
import '../../../styles/amenities-list.css'
const AmenitiesList = ({list,setMyRequestOptionAmenities}) => {
  return (
    <>{list && list.length > 0 &&
      <div className='my-request__option__amenities-list'>
        {list.map((item,index) =>
          <div key={`amenities-list${index}`} className={`my-request__option__amenities-list__list`}>
            <div className='my-request__option__amenities-list__list__title'>{item.title}:</div>

            {
              item.amenities.map((amenity,index) =>
               <div key={`amenity-item${index}`} className={`my-request__option__amenities-list__amenity ${index > 2 ? (item.activeMoreButton ? 'my-request__option__amenities-list__list--show' : 'my-request__option__amenities-list__list--hidden') : ''}`} >
                  <div className='my-request__option__amenities-list__amenity__container'>
                    <img src={amenity.icon} height={20} width={20}/>
                    <span>{amenity.name}</span>
                  </div>
               </div>  
              )
            }
            {item.moreButton && <div className='my-request__option__amenities-list__amenity__more-button' onClick={_=>setMyRequestOptionAmenities(item.title,!item.activeMoreButton)}>{item.activeMoreButton ? 'Less' : 'More'}<img className={item.activeMoreButton ? 'my-request__option__amenities-list__amenity__more-icon--active' : 'my-request__option__amenities-list__amenity__more-icon--inactive'} src={ShowMoreIcon}/></div>}
          </div>
        )
        }
      </div>
      }
    </> 
  )
}

// function mapStateToProps({ requests }) {
//   return {
//     optionDetails: requests.optionDetails,
//   };
// }
  function mapDispatchToProps(dispatch) {
    return {
      setMyRequestOptionAmenities: (title,value) => dispatch(setMyRequestOptionAmenities(title,value))
    }
  }
export default connect(null,mapDispatchToProps)(AmenitiesList)