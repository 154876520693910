import {useCallback,memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-input-alt.css'

//components
import { TextBox } from 'devextreme-react/text-box';

import {
    Validator,
    RequiredRule,
  } from 'devextreme-react/validator';

const CustomText = ({label, value, setValue,requiredValue,placeholder,isDisabled,inputIcon,inputIconCode}) => {
  const onChangeValue = useCallback((data) => {
      setValue(data)
  }, []);
  const onKeyValidation = useCallback(e=>{
    let regex = /[<>]/
    regex.test(e.event.key) && e.event.preventDefault()
  },[])
  return (
    <div className='custom-input-alt'>
      {label && <div className='custom-input-alt__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>}
        <div className={`custom-input-alt__container ${inputIcon || inputIconCode ? '' : 'custom-input-alt__container--noIcon'}`}>
          <TextBox
            placeholder={placeholder}
            value={value}
            disabled={isDisabled}
            valueChangeEvent="input"
            onValueChange={onChangeValue}
            onKeyDown={onKeyValidation}
          >
            {requiredValue &&
              <Validator>
                <RequiredRule message={`${label} is required`} />
              </Validator>
            }
          </TextBox>
          <div className='custom-input-alt__container__icon'>{inputIcon && <img src={inputIcon}/>}{inputIconCode && inputIconCode}</div>
        </div>
    </div>
  )
}

export default memo(CustomText)