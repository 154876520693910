import Avatar from '../images/SteveFrey.jpg';
export const HEADER_TITLE = 'BECOME A PARTNER'
export const HEADER_SUBTITLE = 'Interested in becoming a serviced apartment provider? Complete the following form to apply!'

export const TITLE = 'OASIS PREFERRED PARTNER'
export const TITLE_PARTNER = 'PARTNER LEVELS'
export const TITLE_INTERESTED = 'INTERESTED?'

export const DESCRIPTION = "Thank you for your consideration in becoming a preferred partner with Oasisl. This summary provides an overview of our various levels of partnership, the requirements and how each level will benefit your organization. \n \n As partners dedicated to upholding Oasis' quality standards we ae able to provide our clients the highest quality corporate housing experience at the lowest posible rate."

export const INFORMATION_CARDS = [
    {
        title: "Driving client value",
        description: "We categories our partners based on the quality level of properties offered, commitment to service excellence and price. These factors are the focus of our supply chain strategy and drive true value to our clients"
    },
    {
        title: "What's in it for you?",
        description: "As the highest-level partner in your territory. Oasis will present that your commitment to us is reciprocated in our commitment to drive volume to your company."
    },
    {
        title: "Introducing Metis",
        description: "Metis is the Greek goddess of cunning wisdom and knowledge. Metis, our cloud based supply chain management platform, is designed to build wisdom and knowledge into sourcing and selection of corporate housing acommodations. As a preferred partner, we will add you into Metis."
    }
]
export const PARTNER_LEVELS = [
    {
        type: 0,
        title: "PLATINUM",
        points: [
            "Average response time with options for Oasis requests <strong> &#60; 1 hour </strong>",
            "Average of <strong> 3 options </strong> for each Oasis request",
            "Average pricing <strong> no more than 15% higher </strong> than average of all like options provided",
            "<strong> 90%+ perfect move-in's* </strong> on a minimum of 10 bookings per year",
            "<strong> No requirement </strong> to complete Oasis quality control checklist",
            "Direct assess to your <strong> 24/7 guest service support </strong> to address issues with an inmediate sense of urgency",
            "A willingness to &#34; do the right thing &#34; to make any service deficiencies right in the eyes of the quest and client <strong> with no requirement </strong> for quality control financial guarantees"
        ],
        button: {
          required: false,
          title: '[INVITATION ONLY]'
        }
    },
    {
        type: 1,
        title: "GOLD",
        points: [
            "Average response time with options for Oasis requests <strong> &#60; 1,5 hour </strong>",
            "Average of <strong> 2 options </strong> for each Oasis request",
            "Average pricing <strong> no more than 15% higher </strong> than average of all like options provided",
            "<strong> 80%+ perfect move-in's* </strong> on a minimum of 10 bookings per year",
            "Complete Oasis quality control checklist <strong> 24 business hours prior</strong> to request arrival",
            "Direct assess to your <strong> 24/7 guest service support </strong> to address issues with an inmediate sense of urgency",
            "A willingness to &#34; do the right thing &#34; to make any service deficiencies right in the eyes of the quest and client <strong> with an agreement </strong> for quality control financial guarantees"
        ],
        button: {
          required: true,
          title: 'GOLD'
        }
    },
    {
        type: 2,
        title: "SILVER",
        points: [
            "Average response time with options for Oasis requests <strong> &#60; 2 hour </strong>",
            "Average of <strong> 2 options </strong> for each Oasis request",
            "Average pricing <strong> no more than 15% higher </strong> than average of all like options provided",
            "<strong> 80%+ perfect move-in's* </strong> on a minimum of 10 bookings per year",
            "Complete Oasis quality control checklist <strong> 24 business hours prior</strong> to request arrival",
            "Direct assess to your <strong> 24/7 guest service support </strong> to address issues with an inmediate sense of urgency",
            "A willingness to &#34; do the right thing &#34; to make any service deficiencies right in the eyes of the quest and client <strong> without an agreement </strong> for quality control financial guarantees"
        ],
        button: {
          required: true,
          title: 'SILVER'
        }
    },
    {
        type: 3,
        title: "BRONZE",
        points: [
            "Average response time with options for Oasis requests <strong> &#60; 2 hour </strong>",
            "Average of <strong> 2 options </strong> for each Oasis request",
            "Average pricing <strong> no more than 15% higher </strong> than average of all like options provided",
            "<strong> 80%+ perfect move-in's* </strong> on a minimum of 10 bookings per year",
            "Complete Oasis quality control checklist <strong> preferred but not required </strong>",
            "Direct assess to your <strong> 24/7 guest service support </strong> to address issues with an inmediate sense of urgency",
            "A willingness to &#34; do the right thing &#34; to make any service deficiencies right in the eyes of the quest and client <strong> with an agreement </strong> for quality control financial guarantees"
        ],
        button: {
          required: true,
          title: 'BRONZE'
        }
    }
]

export const CEO_WORDS = {
    image: Avatar,
    description: 'It is our hope that with your partnership, we will continue to hold ourselves to the highest standards within our industry, drive growth for both of our companies, and enjoy our time together as partners. After all, if we are going to work together, we should enjoy it! \n \nThank you in advance for your partnership',
    personal: '<strong>Steve Frey</strong>\nCEO, Oasis Corporate Housing\nsmf@oasiscorporatehousing.com\n813-489-5144'
}

export const SET_REQUEST_PARTNERSHIP = 'SET_REQUEST_PARTNERSHIP'
export const SET_LOADING = 'SET_LOADING'
export const SET_DEFAULT = 'SET_DEFAULT'

