import {
    SET_CAREER_LIST,
    SET_CAREER_LIST_PAGINATION_TOTAL,
    SET_CAREER_LIST_PAGINATION_CURRENT,
    SET_LOADING_CAREER_LIST,
    SET_CAREER_LIST_TOTAL,
    SET_DEFAULT_CAREER_LIST,
    SET_MESSAGE_CAREER_LIST,

    SET_CAREER_LIST_HISTORY,
    SET_LOADING_CAREER_LIST_HISTORY,
    SET_DEFAULT_CAREER_LIST_HISTORY,
    SET_MESSAGE_CAREER_LIST_HISTORY,
    SET_CAREER_LIST_TOTAL_HISTORY,

    SET_CAREER_LIST_BY_ID,
    SET_LOADING_CAREER_BY_ID,
    SET_DEFAULT_CAREER_BY_ID,
    SET_MESSAGE_CAREER_BY_ID,

    SET_APPLY_COVERLETTER,
    SET_APPLY_EMAIL,
    SET_APPLY_FIRSTNAME,
    SET_APPLY_HEAR_US,
    SET_APPLY_IS_PHONE_VALID_MESSAGE,
    SET_APPLY_IS_PHONE_VALID_STATE,
    SET_APPLY_LASTNAME,
    SET_APPLY_PHONE,
    SET_APPLY_RESUME,
    SET_APPLY_MESSAGE,
    SET_APPLY_LOADING,
    SET_APPLY_DEFAULT,
    SET_APPLY_DEFAULT_MESSAGE,

    SET_ADMIN_FORM_JOB,
    SET_ADMIN_FORM_LOCATION,
    SET_ADMIN_FORM_DESCRIPTION,
    SET_ADMIN_FORM_RESPONSABILITIES,
    SET_ADMIN_FORM_QUALIFICATION,
    SET_ADMIN_FORM_COMPENSATION,
    SET_ADMIN_FORM_DEFAULT,
    SET_ADMIN_FORM_MESSAGE,
} from "../constant/career";

export const setCareerList = data => ({
    type: SET_CAREER_LIST,
    payload: data
});
export const setCareerListPaginationCurrent = data =>({
    type: SET_CAREER_LIST_PAGINATION_CURRENT,
    payload:data
})
export const setCareerListPaginationTotal = data =>({
    type: SET_CAREER_LIST_PAGINATION_TOTAL,
    payload:data
})
export const setCareerListTotal = data => ({
    type: SET_CAREER_LIST_TOTAL,
    payload: data
});
export const setLoadingCareerList = data => ({
    type: SET_LOADING_CAREER_LIST,
    payload: data
});
export const setMessageCareerList = (error,content) => ({
    type: SET_MESSAGE_CAREER_LIST,
    payload: {error,content}
});
export const setDefaultCareerList = _ => ({
    type: SET_DEFAULT_CAREER_LIST
})

export const setCareerListHistory = data => ({
    type: SET_CAREER_LIST_HISTORY,
    payload: data
});
export const setCareerListTotalHistory = data => ({
    type: SET_CAREER_LIST_TOTAL_HISTORY,
    payload: data
});
export const setLoadingCareerListHistory = data => ({
    type: SET_LOADING_CAREER_LIST_HISTORY,
    payload: data
});
export const setMessageCareerListHistory = (error,content) => ({
    type: SET_MESSAGE_CAREER_LIST_HISTORY,
    payload: {error,content}
});
export const setDefaultCareerListHistory = _ => ({
    type: SET_DEFAULT_CAREER_LIST_HISTORY
})

export const setCareerListById = data => ({
    type: SET_CAREER_LIST_BY_ID,
    payload: data
});
export const setLoadingCareerById = data => ({
    type: SET_LOADING_CAREER_BY_ID,
    payload: data
});
export const setMessageCareerById = (error,content) => ({
    type: SET_MESSAGE_CAREER_BY_ID,
    payload: {error,content}
});
export const setDefaultCareerById = _ => ({
    type: SET_DEFAULT_CAREER_BY_ID
});

export const setCoverLetter = data => ({
    type: SET_APPLY_COVERLETTER,
    payload: data
});
export const setEmail = data => ({
    type: SET_APPLY_EMAIL,
    payload: data
});
export const setFirstname = data => ({
    type: SET_APPLY_FIRSTNAME,
    payload: data
});
export const setHearUs = data => ({
    type: SET_APPLY_HEAR_US,
    payload: data
});
export const setIsPhoneValidMessage = data => ({
    type: SET_APPLY_IS_PHONE_VALID_MESSAGE,
    payload: data
});
export const setIsPhoneValidState = data => ({
    type: SET_APPLY_IS_PHONE_VALID_STATE,
    payload: data
});
export const setLastname = data => ({
    type: SET_APPLY_LASTNAME,
    payload: data
});
export const setPhone = data => ({
    type: SET_APPLY_PHONE,
    payload: data
});
export const setResume = data => ({
    type: SET_APPLY_RESUME,
    payload: data
});

export const setApplyLoading = data => ({
    type: SET_APPLY_LOADING,
    payload: data
});

export const setApplyMessage = data => ({
    type: SET_APPLY_MESSAGE,
    payload: data
});

export const setDefaultApply = _ => ({
    type: SET_APPLY_DEFAULT
})

export const setDefaultApplyMessage = _ => ({
    type: SET_APPLY_DEFAULT_MESSAGE
})

export const setAdminFormJob = data => ({
    type: SET_ADMIN_FORM_JOB,
    payload: data
});

export const setAdminFormLocation = data => ({
    type: SET_ADMIN_FORM_LOCATION,
    payload: data
});

export const setAdminFormDescription = data => ({
    type: SET_ADMIN_FORM_DESCRIPTION,
    payload: data
});

export const setAdminFormResponsabilities = data => ({
    type: SET_ADMIN_FORM_RESPONSABILITIES,
    payload: data
});
  
export const setAdminFormQualification = data => ({
    type: SET_ADMIN_FORM_QUALIFICATION,
    payload: data
});

export const setAdminFormCompensation = data => ({
    type: SET_ADMIN_FORM_COMPENSATION,
    payload: data
});

export const setAdminFormDefault = _ => ({
    type: SET_ADMIN_FORM_DEFAULT
});
export const setAdminFormMessage = (error,content) => ({
    type: SET_ADMIN_FORM_MESSAGE,
    payload: {error,content}
}); 
