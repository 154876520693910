import React from "react";

import SearchContainer from './landing-page/Search/search-container'
import Scrollbar from "./landing-page/Scrollbar/Scrollbar";
import CtaComponent from './landing-page/Cta/CtaComponent'
import OurSolutions from './landing-page/our-solutions/our-solutions-component'
import OurAwards from './landing-page/our-awards/ourAwards'
import FeaturedCities from './landing-page/FeaturedCities/FeaturedCities'
import ContactForm from "./landing-page/ContactForm/ContactForm";

export default function LandingPage() {
	return (
		<div className="landing-page">
				<SearchContainer />
				<Scrollbar />
				<CtaComponent />
				<OurSolutions />
				<OurAwards />
				<FeaturedCities />
				<ContactForm />
		</div>
	);
}