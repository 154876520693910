import {useState,useEffect} from 'react'
import { connect } from 'react-redux'

//components
import Pagination from '../assets/controls/pagination-alt'
import PropertyCard from '../assets/controls/property-card-actions'
import Loading from '../assets/controls/loading'
import Message from '../assets/elements/custom-alert'

//utils
import {getBasketPerPage,deleteBasketProperty} from '../../utils/my-basket'

//constant
import {DEFAULT_VALUES as PAGINATION_DEFAULT} from '../../constant/pagination'

//styles
import './styles/list-properties.css'

const ListProperties = ({basketData,loading,dataToCheck}) => {
  const [skip,setSkip] = useState(0)
  const {data,totalCount} = basketData
  
  useEffect(() => {
    getBasketPerPage(skip,dataToCheck)
  }, [skip])

  useEffect(() => {
    if((dataToCheck.city && dataToCheck.city !== 'not_found') || (dataToCheck.propertyType?.length > 0 && !dataToCheck.propertyType?.includes('all')) || dataToCheck.floor || dataToCheck.pet)
      getBasketPerPage(skip,dataToCheck)
  }, [dataToCheck])

  const onDeleteFromBasket = async id => {
    let response = await deleteBasketProperty(id)
    if(response)
    {
      if(data.length === 1 && skip > 0)
      {
        setSkip(skip - PAGINATION_DEFAULT.take)
      }
      else{
        getBasketPerPage(skip,dataToCheck)
      }
    }
  }
  return (
    <div>
    {
     data && data.map((item,index) => 
        <PropertyCard data={item} key={index} onDelete={onDeleteFromBasket}/>
        )
    }
    {
      !loading && data && data.length <= 0 && 
              <Message>No properties added</Message>  
    }
    { data && data.length > 0 &&
      <div className='list-properties__pagination'>
        <Pagination totalPages={totalCount} skip={skip} setSkip={setSkip} take={PAGINATION_DEFAULT.take}/>
        <Loading isVisble={loading} size={30}/>
      </div>
    }
    </div>
    
  )
}

function mapStateToProps({ basket }) {
    return {
      basketData: basket.basket,
      loading: basket.loading,
      dataToCheck: basket.dataToCheck
    };
  }
  
  export default connect(mapStateToProps)(ListProperties)