import {useState,useEffect} from 'react'
import { Tooltip } from 'devextreme-react/tooltip';

//styles
import '../styles/tooltip-icon.css'
const TooltipIcon = ({id,image,label,isHtml}) => {
  const[visible,setVisible] = useState(false)
  const toggleVisible = value => {
    if(value === 'in')
      setVisible(true)
    else
      setVisible(false)
  }
 
  
  return (
    <>
          <Tooltip
            target={`#${id}`}
            visible={visible}
            hideOnOutsideClick={false}
            // height={10}
            position="top"
            className={`custom-tooltip ${isHtml ? 'custom-tooltip__isHtml':''}`}
          >
            {isHtml ?
              <div dangerouslySetInnerHTML={{__html: label}}/>
              :
              <div className='custom-tooltip__text'>{label}</div>
            }
          </Tooltip>
          {image && 
        <img
            className='custom-tooltip-img'
            id={id}
            // width={width}
            // height={heigth}
            src={image}
            onMouseEnter={()=>toggleVisible('in')}
            onMouseLeave={()=>toggleVisible('out')}
          />
        }
    </>
  )
}

export default TooltipIcon