import {useCallback,useRef,useEffect} from 'react'
import 'devextreme/dist/css/dx.light.css';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import { List } from "devextreme-react/list";

//style
import '../../styles/input/dropdown.css'
const Dropdown = ({values,value,onChangeValue,disable,classCustom}) => {
  const dropDownBoxRef = useRef(null);
  const changeDropDownBoxValue = useCallback((arg) => {
      onChangeValue(arg.addedItems[0].id);
      dropDownBoxRef.current.instance.close();
  }, []);

  return (
    <div className='dropdown-custom'>
        <DropDownBox
        className={classCustom}
        value={[value]}
        ref={dropDownBoxRef}
        placeholder="Choose option"
        valueExpr="id"
        displayExpr="name"
        dataSource={values}
        disabled={disable}
        >
          <List
              className='dropdown-custom__list'
              selectionMode="single"
              dataSource={values}
              valueExpr="id"
              displayExpr="name"
              keyExpr="id"
              selectedItemKeys={[value]}
              onSelectionChanged={changeDropDownBoxValue}
          />
        </DropDownBox>
    </div>
   
  )
}
export default Dropdown