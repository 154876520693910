import { Wrapper} from "@googlemaps/react-wrapper";

const GoogleWrapper = ({children}) => {
  return (
    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={["places"]} language={'en'}>
        {children}
    </Wrapper>
  )
}

export default GoogleWrapper