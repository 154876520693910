//styles
import '../../../styles/amenities-tags.css'

const AmenitiesTags = ({tags}) => {
  return (
    <>{tags && tags.length > 0 &&
      <div className='my-request__option__amenities-tags'>
          {
              tags.map((tag,index) =>
                  <div key={`amenities-tag${index}`} className='my-request__option__amenities-tags__tag'>
                      <img src={tag.icon}/>
                      <span>{tag.name}</span>
                  </div>
              )
          }
      </div>}
    </>
    
  )
}

export default AmenitiesTags