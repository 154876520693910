import {useParams} from 'react-router-dom'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

//devextreme
import notify from 'devextreme/ui/notify';

//images
import DownloadIcon from '../../../../images/file-download.svg'

//styles
import '../../styles/download-button.css'

const DownloadButton = ({pageRef}) => {
    const params = useParams()
    
    const handleDownload = async() => {
        const downloadButton = document.querySelector('.request-option-download-button')
        try {
        downloadButton.style.display = 'none'
        const page = pageRef.current;
        if (!page) return;
        
        // Fetch images with src URLs and convert to base64 data URLs
        const images = page.querySelectorAll('img[src^="http"]');
        const promises = Array.from(images).map(async (image) => {
        const response = await fetch(image.src);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
            resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });
        });
        
        const imageDataUrls = await Promise.all(promises);

        // Replace src URLs with base64 data URLs
        images.forEach((image, index) => {
        image.src = imageDataUrls[index];
        });

        Promise.all(promises).then(_ =>{
            // Capture the current page display as an image
            html2canvas(page,{
                logging:false,
            }).then(canvas => {
            
                const imageData = canvas.toDataURL('image/png')            
                let pdf = new jsPDF({
                    'orientation': canvas.width / 96 > canvas.height / 96 ? 'landscape' : 'portrait', 
                    'unit': 'in', 
                    'format': [canvas.width / 96, canvas.height / 96]
                });

                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const aspectRatio = canvas.width/ canvas.height;
                let imgWidth = pdfWidth - 0.2;
                let imgHeight = pdfWidth / aspectRatio;

                if (imgHeight > pdfHeight - 0.4) { imgHeight = pdfHeight - 0.4; imgWidth = (pdfHeight) * aspectRatio; }
                const marginX = (pdfWidth - imgWidth) / 2;
                const marginY = 0.1; 
                
            
                pdf.addImage(imageData,'PNG', marginX, marginY, imgWidth, imgHeight); // Center the image
                downloadButton.style.display = 'flex'

                pdf.save(`request${params.requestId}_option${params.optionId}.pdf`)
            }).catch(_=>{
                downloadButton.style.display = 'flex'
                notify('Something went wrong try again later', "error" , 3000);
            });
        }).catch(_=>{
            downloadButton.style.display = 'flex'
            notify('Something went wrong try again later', "error" , 3000);
        })
        } catch (error) {  
            downloadButton.style.display = 'flex'
            notify('Something went wrong try again later', "error" , 3000);
        }
        

        
    };
  return (
    <div className='request-option-download-button' onClick={handleDownload}>
        <img src={DownloadIcon} alt=""/>
        <span>Download PDF</span>
    </div>
  )
}

export default DownloadButton