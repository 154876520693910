import {useCallback,memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-textarea-alt.css'

//components
import { TextArea } from 'devextreme-react/text-area';
import {
    Validator,
    RequiredRule
  } from 'devextreme-react/validator';

const CustomTextarea = ({label, value, setValue,requiredValue,isDisabled,onClickLabel,inputSize}) => {
  const onChangeValue = useCallback((data) => {
      // let filteredData = data.replace(/[<>=]/,'')
      setValue(data)
  }, []);
  const onKeyValidation = useCallback(e=>{
    let regex = /[<>]/
    regex.test(e.event.key) && e.event.preventDefault()
  },[])
  return (
    <div className='custom-textarea-alt'>
        <div 
          className='custom-textarea-alt__label' 
          style={onClickLabel ? {cursor: 'pointer'} : {}}
          onClick={onClickLabel ? onClickLabel : _=>{}}
        >
          {label}:{requiredValue ? <span>*</span> : ''}
        </div>
        <TextArea
          value={value}
          disabled={isDisabled}
          valueChangeEvent="input"
          onValueChange={onChangeValue}
          height={inputSize ? inputSize : 120}
          maxLength={1000}
          onKeyDown={onKeyValidation}
        >
          {requiredValue &&
            <Validator>
              <RequiredRule message={`${label} is required`} />
            </Validator>
          }
        </TextArea>
    </div>
  )
}

export default memo(CustomTextarea)