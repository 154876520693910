import store from "../store";
import baseService from "../services/base.service";

//utils
import {getAllBasket} from './my-basket'

//devextreme
import notify from 'devextreme/ui/notify';


const postPropertyToCart = async (floorId,propertyId) => {
  try {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/propertycart`;
        let formData = new FormData()
        formData.append('PropertyId',propertyId)
        formData.append('FloorPlanTypes',floorId)
      
        baseService.post(url,formData,{
          headers: {
          'accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
          },
        }
        )
        .then(data => {
          notify('Property added to cart', "success" , 3000)
          getAllBasket()
        })
        .catch(error => {
            notify(error.response.data, "error" , 3000);
        })
    }
  catch (error) {
    notify('Something went wrong try again later', "error" , 3000);
  }
}

export { postPropertyToCart }