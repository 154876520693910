export const SET_COMPANY = "SET_COMPANY";
export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const SET_LAST_NAME = "SET_LAST_NAME";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_EMAIL_ADDRESS = "SET_EMAIL_ADDRESS";
export const SET_DESTINATION_CITY = "SET_DESTINATION_CITY";
export const SET_ACCOMMODATION_LEVEL = "SET_ACCOMMODATION_LEVEL";
export const SET_PROPERTY_NAME = "SET_PROPERTY_NAME";
export const SET_NUMBER_OF_BEDROOMS = "SET_NUMBER_OF_BEDROOMS";
export const SET_MAXIMUM_COMMUTE_DISTANCE = "SET_MAXIMUM_COMMUTE_DISTANCE";
export const SET_MAXIMUM_COMMUTE_DISTANCE_TYPE = "SET_MAXIMUM_COMMUTE_DISTANCE_TYPE";
export const SET_NIGHTLY_BUDGET = "SET_NIGHTLY_BUDGET";
export const SET_BUDGET_CURRENCY_ID = "SET_BUDGET_CURRENCY_ID";
export const SET_CHECK_IN_DATE = "SET_CHECK_IN_DATE";
export const SET_CHECK_OUT_DATE = "SET_CHECK_OUT_DATE";
export const SET_DATES_IS_FLEXIBLE = "SET_DATES_IS_FLEXIBLE";
export const SET_TOTAL_ADULTS = "SET_TOTAL_ADULTS";
export const SET_TOTAL_CHILDREN = "SET_TOTAL_CHILDREN";
export const SET_SPECIAL_REQUIREMENTS = "SET_SPECIAL_REQUIREMENTS";
export const RESET_QUOTE_FORM = "RESET_QUOTE_FORM";
export const SET_LOCATION = "SET_LOCATION";

export const accomodationLevelItems = [
    { value: 1, name: "Budget" },
    { value: 2, name: "Standard" },
    { value: 3, name: "VIP" }
];

export const unitItems = [
    { text: "miles", value: 1 },
    { text: "kilometers", value: 2 }
];