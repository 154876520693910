import {useCallback,memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-input.css'

//components
import { TextBox } from 'devextreme-react/text-box';

import {
    Validator,
    RequiredRule,
  } from 'devextreme-react/validator';

const CustomText = ({label, value, setValue,requiredValue,labelSize,isDisabled}) => {
  const onChangeValue = useCallback((data) => {
      setValue(data)
  }, []);
  const onKeyValidation = useCallback(e=>{
    let regex = /[<>]/
    regex.test(e.event.key) && e.event.preventDefault()
  },[])
  return (
    <div className='custom-input' style={labelSize ? {gridTemplateColumns: `${labelSize} calc(100% - ${labelSize} - 20px)`} : {}}>
        <div className='custom-input__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
        <TextBox
          value={value}
          disabled={isDisabled}
          valueChangeEvent="input"
          onValueChange={onChangeValue}
          onKeyDown={onKeyValidation}
        >
          {requiredValue &&
            <Validator>
              <RequiredRule message={`${label} is required`} />
            </Validator>
          }
        </TextBox>
    </div>
  )
}

export default memo(CustomText)