// import React from "react";
// import QuickRequestLoggedIn from "./quick-request/QuickRequestLoggedIn";
// import QuickRequestFormLoggedOut from "./quick-request/QuickRequestLoggedOut";
import QuickRequestContent from './quick-request/quick-request-content'
// import { getIsUser } from "../utils/user";
// import { getIsAdmin } from "../utils/admin";

const QuickRequest = () => {
    // const [isLoggedIn, setIsLoggedIn] = React.useState(getIsUser() || getIsAdmin());

    // React.useEffect(() => {
    //     const checkUserStatus = () => {
    //         const currentStatus = getIsUser() || getIsAdmin();
    //         if (currentStatus !== isLoggedIn) {
    //             setIsLoggedIn(currentStatus);
    //         }
    //     };
    //     const interval = setInterval(checkUserStatus, 10);
    //     return () => clearInterval(interval);
    // }, [isLoggedIn]);
    // return isLoggedIn ? <QuickRequestLoggedIn /> : <QuickRequestFormLoggedOut />;
    return <QuickRequestContent />
};

export default QuickRequest;