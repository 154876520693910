import {
    SET_CAREER_LIST,
    SET_CAREER_LIST_PAGINATION_TOTAL,
    SET_CAREER_LIST_PAGINATION_CURRENT,
    SET_LOADING_CAREER_LIST,
    SET_DEFAULT_CAREER_LIST,
    SET_MESSAGE_CAREER_LIST,
    SET_CAREER_LIST_TOTAL,

    SET_CAREER_LIST_HISTORY,
    SET_LOADING_CAREER_LIST_HISTORY,
    SET_DEFAULT_CAREER_LIST_HISTORY,
    SET_MESSAGE_CAREER_LIST_HISTORY,
    SET_CAREER_LIST_TOTAL_HISTORY,

    SET_CAREER_LIST_BY_ID,
    SET_LOADING_CAREER_BY_ID,
    SET_MESSAGE_CAREER_BY_ID,
    SET_DEFAULT_CAREER_BY_ID,


    SET_APPLY_COVERLETTER,
    SET_APPLY_EMAIL,
    SET_APPLY_FIRSTNAME,
    SET_APPLY_HEAR_US,
    SET_APPLY_IS_PHONE_VALID_MESSAGE,
    SET_APPLY_IS_PHONE_VALID_STATE,
    SET_APPLY_LASTNAME,
    SET_APPLY_PHONE,
    SET_APPLY_RESUME,
    SET_APPLY_MESSAGE,
    SET_APPLY_LOADING,
    SET_APPLY_DEFAULT,
    SET_APPLY_DEFAULT_MESSAGE,

    SET_ADMIN_FORM_JOB,
    SET_ADMIN_FORM_LOCATION,
    SET_ADMIN_FORM_DESCRIPTION,
    SET_ADMIN_FORM_RESPONSABILITIES,
    SET_ADMIN_FORM_QUALIFICATION,
    SET_ADMIN_FORM_COMPENSATION,
    SET_ADMIN_FORM_DEFAULT,
    SET_ADMIN_FORM_MESSAGE,
} from "../constant/career";

const defaultState = {
    careerList:[],
    careerListPagination: {
        currentPage:1,
        totalPages: null
    },
    careerListTotal: 0,
    loadingCareerList: false,
    messageCareerList: {
        error: false,
        content: ''
    },

    careerListHistory:[],
    careerListTotalHistory: 0,
    loadingCareerListHistory: false,
    messageCareerListHistory: {
        error: false,
        content: ''
    },

    loadingCareerListById: false,
    messageCareerListById: {
        error: false,
        content: ''
    },
    careerListById: {},

    careerApply: {
        firstname: '',
        lastname: '',
        email: '',
        hearUs: '',
        phone: '',
        isPhoneValidState: true,
        isPhoneValidMessage: '',
        coverLetter: '',
        resume: [],
        loading: false,
        message: {
            status: false,
            description: ''
        }
    },

    careerAdminForm: {
        jobTitle: '',
        location: '',
        qualifications: '',
        responsibilities: '',
        compensation: '',
        shortDescription: ''
    },
    careerAdminFormMessage: {
        error: false,
        content: ''
    },
};

export default function getCareerList(state = defaultState, action) {
    switch (action.type) {
        case SET_CAREER_LIST:
            return {
                ...state,
                careerList: action.payload
            };
        case SET_CAREER_LIST_PAGINATION_TOTAL:
            return {
                ...state,
                careerListPagination: {
                   ...state.careerListPagination,
                   totalPages: action.payload
                }
            };
        case SET_CAREER_LIST_PAGINATION_CURRENT:
            return {
                ...state,
                careerListPagination: {
                    ...state.careerListPagination,
                    currentPage: action.payload
                }
            };
        case SET_CAREER_LIST_TOTAL:
            return {
                ...state,
                careerListTotal: action.payload
            };
        case SET_LOADING_CAREER_LIST:
            return {
                ...state,
                loadingCareerList: action.payload
            };
        case SET_MESSAGE_CAREER_LIST:
            return {
                ...state,
                messageCareerList: {
                    error: action.payload.error,
                    content: action.payload.content
                }
            };
        case SET_DEFAULT_CAREER_LIST:
            return {
                ...state,
                careerList:[],
                careerListTotal: 0,
                loadingCareerList: false,
                messageCareerList: {
                    error: false,
                    content: ''
                }
            };

        case SET_CAREER_LIST_HISTORY:
            return {
                ...state,
                careerListHistory: action.payload
            };
        case SET_CAREER_LIST_TOTAL_HISTORY:
            return {
                ...state,
                careerListTotalHistory: action.payload
            };
        case SET_LOADING_CAREER_LIST_HISTORY:
            return {
                ...state,
                loadingCareerListHistory: action.payload
            };
        case SET_MESSAGE_CAREER_LIST_HISTORY:
            return {
                ...state,
                messageCareerListHistory: {
                    error: action.payload.error,
                    content: action.payload.content
                }
            };
        case SET_DEFAULT_CAREER_LIST_HISTORY:
            return {
                ...state,
                careerListHistory:[],
                careerListTotalHistory: 0,
                loadingCareerListHistory: false,
                messageCareerListHistory: {
                    error: false,
                    content: ''
                }
            };

            
        case SET_CAREER_LIST_BY_ID:
            return {
                ...state,
                careerListById: action.payload
            };
        case SET_LOADING_CAREER_BY_ID:
            return {
                ...state,
                loadingCareerListById: action.payload
            };
        case SET_MESSAGE_CAREER_BY_ID:
            return {
                ...state,
                messageCareerListById: {
                    error: action.payload.error,
                    content: action.payload.content
                }
            };

        case SET_APPLY_FIRSTNAME:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    firstname: action.payload
                }
            };
        case SET_APPLY_LASTNAME:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    lastname: action.payload
                }
            };
        case SET_APPLY_EMAIL:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    email: action.payload
                }
            };
        case SET_APPLY_HEAR_US:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    hearUs: action.payload
                }
            };
        case SET_APPLY_PHONE:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    phone: action.payload
                }
            };
        case SET_APPLY_IS_PHONE_VALID_STATE:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    isPhoneValidState: action.payload
                }
            };
        case SET_APPLY_IS_PHONE_VALID_MESSAGE:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    isPhoneValidMessage: action.payload
                }
            };
        case SET_APPLY_COVERLETTER:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    coverLetter: action.payload
                }
            };   
        case SET_APPLY_RESUME:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    resume: action.payload
                }
            }; 
        case SET_APPLY_LOADING:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    loading: action.payload
                }
            }; 
        case SET_APPLY_MESSAGE:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    message: action.payload
                }
            };     
        
        case SET_APPLY_DEFAULT:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    firstname: '',
                    lastname: '',
                    email: '',
                    hearUs: '',
                    phone: '',
                    isPhoneValidState: true,
                    isPhoneValidMessage: '',
                    coverLetter: '',
                    resume: [],
                }
            };
        case SET_APPLY_DEFAULT_MESSAGE:
            return {
                ...state,
                careerApply: {
                    ...state.careerApply,
                    message: {
                        status: false,
                        description: ''
                    }
                }
            };
        case SET_DEFAULT_CAREER_BY_ID:
            return {
                ...state,
                loadingCareerListById: false,
                messageCareerListById: {
                    error: false,
                    content: ''
                },
                careerListById: {},
            };
        
        case SET_ADMIN_FORM_JOB:
            return {
                ...state,
                careerAdminForm: {
                    ...state.careerAdminForm,
                    jobTitle: action.payload
                }
            };
        case SET_ADMIN_FORM_LOCATION:
            return {
                ...state,
                careerAdminForm: {
                    ...state.careerAdminForm,
                    location: action.payload
                }
            };
        case SET_ADMIN_FORM_QUALIFICATION:
            return {
                ...state,
                careerAdminForm: {
                    ...state.careerAdminForm,
                    qualifications: action.payload
                }
        };
        case SET_ADMIN_FORM_RESPONSABILITIES:
            return {
                ...state,
                careerAdminForm: {
                    ...state.careerAdminForm,
                    responsibilities: action.payload
                }
            };
        case SET_ADMIN_FORM_COMPENSATION:
            return {
                ...state,
                careerAdminForm: {
                    ...state.careerAdminForm,
                    compensation: action.payload
                }
            };
        case SET_ADMIN_FORM_DESCRIPTION:
            return {
                ...state,
                careerAdminForm: {
                    ...state.careerAdminForm,
                    shortDescription: action.payload
                }
            };
        case SET_ADMIN_FORM_DEFAULT:
            return{
                ...state,
                careerAdminForm: {
                    jobTitle: '',
                    location: '',
                    qualifications: '',
                    responsibilities: '',
                    compensation: '',
                    shortDescription: ''
                }
            };
        case SET_ADMIN_FORM_MESSAGE:
            return {
                ...state,
                careerAdminFormMessage: {
                    error: action.payload.error,
                    content: action.payload.content
                }
            };
        default:
        return state;
    }
}