import React from 'react'
import NewsComponent from '../components/news/NewsComponent'
import NewsAdminComponent from '../components/news/NewsAdminComponent'
import { getIsAdmin } from '../utils/admin'

const News = () => {
    const isAdmin = getIsAdmin()

    return (isAdmin ? <NewsAdminComponent /> : <NewsComponent />);
}

export default News