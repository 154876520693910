import {
    SET_PROPERTY_DETAILS,
    SET_LOADING,
    SET_FLOOR,
    SET_RECOMMENDED_PROPERTIES,
    SET_LOADING_RECOMMENDED_PROPERTIES,
    CHECK_IF_PROPERTY_ADDED_TO_CART
} from "../constant/property-details";

const defaultState = {
    propertyDetails:{},
    recommendedProperties: [],
    loading: true,
    loadingRecommendedProperties: true,
    floor: '',
    baseUrl: process.env.REACT_APP_IMG_BASE_URL,
    isPropertyAddedToCart: false
};

export default function propertyDetailsReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_PROPERTY_DETAILS:
            return {
                ...state,
                propertyDetails: action.payload
            };
        case CHECK_IF_PROPERTY_ADDED_TO_CART:
            return {
                ...state,
                isPropertyAddedToCart: action.payload
            };
        case SET_RECOMMENDED_PROPERTIES:
            return {
                ...state,
                recommendedProperties: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case SET_LOADING_RECOMMENDED_PROPERTIES:
            return {
                ...state,
                loadingRecommendedProperties: action.payload
            }
        case SET_FLOOR:
            return {
                ...state,
                floor: action.payload
            };
        default:
            return state;
    }
}