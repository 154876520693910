export const CHANGE_LOCATION = "CHANGE_LOCATION";
export const CHANGE_GEOMETRY = "CHANGE_GEOMETRY"
export const CHANGE_CHECKIN = "CHANGE_CHECKIN";
export const CHANGE_CHECKOUT = "CHANGE_CHECKOUT";
export const CHANGE_FLOOR = "CHANGE_FLOOR";
export const CHANGE_MILES = "CHANGE_MILE";
export const CHANGE_BUILDING_TYPES = "CHANGE_BUILDING_TYPES";
export const CHANGE_PETS_FRIENDLY = "CHANGE_PETS_FRIENDLY";
export const CHANGE_LOADING_FILTER = "CHANGE_LOADING_FILTER";
export const CHANGE_PAGES = "CHANGE_PAGES";
export const CHANGE_MESSAGE = "CHANGE_MESSAGE";
export const PREVIOUS_URL = "PREVIOUS_URL";
export const CHANGE_FILTER_TOTAL_PROPERTIES = "CHANGE_FILTER_TOTAL_PROPERTIES";
export const CLEAR_DATA = "CLEAR_DATA";
export const CLEAR_PAGES = "CLEAR_PAGES";
export const SET_PROPERTY_MAP = "SET_PROPERTY_MAP";
export const SET_FILTER_PROPERTIES = "SET_FILTER_PROPERTIES";
export const SET_PREVIOUS_STATE = "SET_PREVIOUS_STATE"
export const SET_PROPERTY_FROM_MAP = "SET_PROPERTY_FROM_MAP"
export const SET_PROPERTY_FROM_LIST = "SET_PROPERTY_FROM_LIST"
export const SET_CARD_TYPE = "SET_CARD_TYPE"
export const SET_PROPERTY_SEARCH_VIEW = "SET_PROPERTY_SEARCH_VIEW"
export const SET_SWITCH_LOCATION_SEARCH = "SET_SWITCH_LOCATION_SEARCH"
export const SET_CLEAR_LOCATION_FIELD = "SET_CLEAR_LOCATION_FIELD"


export const SET_MAP_LOADING = "SET_MAP_LOADING"
export const SET_CURRENT_MAP = "SET_CURRENT_MAP"
export const SET_CURRENT_MAP_ZOOM = "SET_CURRENT_MAP_ZOOM"
export const SET_CURRENT_MAP_CENTER = "SET_CURRENT_MAP_CENTER"

export const SET_CURRENT_MAP_MARKERS = "SET_CURRENT_MAP_MARKERS"

