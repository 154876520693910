import React from 'react'
import './styles/our-solutions-styles.css'

const ourSolutionsComponent = () => {
    return (
        <section id='section-our-solutions' className='our-solutions'>
            <div className='our-solutions-container'>
                <h3 className='text-center text-uppercase mx-auto'>Our Solutions</h3>
                <div className='row'>
                    {/* left column */}
                    <div className='col-xl-4'>
                        <div className='col mb-5'>
                            <div className='our-solutions__img-circle'>
                                <img src={require('../../../images/landing/icons-landing/project.png')} alt='project' />
                            </div>
                            <h6 className='text-center text-uppercase mt-3 fw-bold'>projects &amp; assignments</h6>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                In today's business environment, more companies are assigning projects that require temporary housing arrangements for single or group placement. Oasis understands the need for comfort while on the road or while transitioning to a new location. Our experienced staff personalizes each stay to create that home away from home feel.
                            </p>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                Our dedicated account coordinators work with large infrastructure projects, construction companies and sub contractors, large retail chain developments and specialized groups like entertainment and production companies on location.
                            </p>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                &#42;Tax considerations or scenario-based reimbursements may factor into your situation. We will help you plan a successful move of any size group for a special project/assignment to a required destination.
                            </p>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                &#42;Always consult with a tax professional or CPA for advice.
                            </p>
                        </div>
                        <div className='col mb-5'>
                            <div className='our-solutions__img-circle'>
                                <img src={require('../../../images/landing/icons-landing/traveling.png')} alt='traveling' />
                            </div>
                            <h6 className='text-center text-uppercase mt-3 fw-bold'>traveling healthcare</h6>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                Healthcare professionals acting as locum tenens or traveling medical practitioners have unique circumstances. Oasis provides special packages for healthcare related, seasonal contracts, specific budget and amenity needs.
                            </p>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                Traveling nurses, doctors and other professionals find our services exceptional for multiple city assignments throughout the United States. Our property relationships make transitioning between assignments easy.
                            </p>
                        </div>
                    </div>
                    {/* middle column */}
                    <div className='col-xl-4'>
                        <div className='col mb-5'>
                            <div className='our-solutions__img-circle'>
                                <img src={require('../../../images/landing/icons-landing/relocation.png')} alt='relocation' />
                            </div>
                            <h6 className='text-center text-uppercase mt-3 fw-bold'>relocation</h6>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                Congratulations on solidifying your internship, you earned it! Oasis wants to set you up for success. We provide all-inclusive temporary housing solutions so you can perform at your best. Whether moving to a new city permanently or relocating for a few weeks, we have housing solutions to meet your every need. Relax and enjoy your new destination!
                            </p>
                        </div>
                        <div className='col mb-5'>
                            <div className='our-solutions__img-circle'>
                                <img src={require('../../../images/landing/icons-landing/insurance.png')} alt='insurance' />
                            </div>
                            <h6 className='text-center text-uppercase mt-3 fw-bold'>insurance / ale</h6>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                Oasis has account managers eager to assist with any emergency, ranging from large-scale natural disasters to more minor incidents that displace individuals. We strive to give every policyholder a smooth and reliable transition. Our wide range of solutions and knowledgeable staff will work with both you and your insurance provider to give you the peace of mind you deserve.
                            </p>
                        </div>
                        <div className='col mb-5'>
                            <div className='our-solutions__img-circle'>
                                <img src={require('../../../images/landing/icons-landing/entertainment.png')} alt='entertainment' />
                            </div>
                            <h6 className='text-center text-uppercase mt-3 fw-bold'>entertainment</h6>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                Oasis is here for the entertainment industry. Movies, Music, Production, Tours... We have housing solutions that rock! Our experienced staff will arrange top-tier, convenient accommodations in any city. Simply name the venue and we'll provide all-inclusive housing that takes the stress out of life on the road. Relax, whether weeks or days on a set we will provide the housing solutions you need.
                            </p>
                        </div>
                    </div>
                    {/* right column */}
                    <div className='col-xl-4'>
                        <div className='col mb-5'>
                            <div className='our-solutions__img-circle'>
                                <img src={require('../../../images/landing/icons-landing/government.png')} alt='government' />
                            </div>
                            <h6 className='text-center text-uppercase mt-3 fw-bold'>
                                government &amp; gov't contractors
                            </h6>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                Oasis continues to provide relocation services to government workers and contractors since our inception in 2003. Our account coordinators have extensive experience with military-presence locations around the globe. We understand government per-diems and the flexibility needed to obtain the most advantageous terms.
                            </p>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                From stateside to bases throughout Europe, the Middle East or Far East, we have procured vital partnerships to ensure the best accommodations for your needs. In many cases, our team members are local which facilitates the best placement situation for both military service members or associated groups.
                            </p>
                        </div>
                        <div className='col mb-5'>
                            <div className='our-solutions__img-circle'>
                                <img src={require('../../../images/landing/icons-landing/internship.png')} alt='internship' />
                            </div>
                            <h6 className='text-center text-uppercase mt-3 fw-bold'>Internships</h6>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                Opportunity can take you almost anywhere! If you are managing a talent pipeline for your company or relocating for an internship, we provide high-value, economical packages for fully furnished accommodations. Our creative solutions will help ensure you address a temporary housing situation and hang on to world-class talent.
                            </p>
                            <p className='text-start' style={{ fontSize: '18px' }}>
                                We provide options! For instance, if needed for a group, we will set up roommate pairing via a private, invite-only introduction on social media, or find temporary housing so an intern can take advantage of a unique work opportunity. All-inclusive pricing means you can focus on your new employment  and relocation without worrying about a place to stay in the interim.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ourSolutionsComponent