import React, { useState, useEffect, useRef } from "react";
import "../styles/input/custom-dropdown-input.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Button, DropDownBox, TextBox } from "devextreme-react";
import Validator, { RequiredRule } from "devextreme-react/validator";
import { List } from "devextreme-react/list";

import '../styles/input/dropdown-input.css'
import { initial } from "lodash";

const CustomDropDownInput = ({ messageLabel, initialUsers,value,setValue, onSelectUser, onNewUser, isEmpty, setIsEmpty,isExistingUser,setIsExistingUser }) => {
    const dropDownBoxRef = useRef(null);
    const textBoxValidatorRef = useRef(null);
    // const [value, setValue] = useState(null);
    const [selectedItemKeys, setSelectedItemKeys] = useState([]);
    const [users, setUsers] = useState(initialUsers);
    const [newUser, setNewUser] = useState("");
    const [isNewUser,setIsNewUser] = useState(false)
    const [validationTriggered, setValidationTriggered] = useState(false);

    useEffect(() => {
        
        // if(!isNewUser)
        // {
            setSelectedItemKeys([value]);
            const user = users.find((user) => user.id === value);
            if (user) {
                onSelectUser(user);
            }
        // }
    }, [value,users,initialUsers]);
    // }, [value, users, onSelectUser]);

    useEffect(() => {
      setUsers(initialUsers)
    }, [initialUsers])
    

    useEffect(() => {
        if(value)
        {
          setIsEmpty && setIsEmpty(false)
        }
      }, [value])

    const onValueChanged = (e) => setValue(e.value);

    const onEnterKey = () => {
        // validation for TextBox
        setIsNewUser(true)
        if (newUser.trim() === "") {
            if (!validationTriggered) {
                textBoxValidatorRef.current.instance.validate();
                setValidationTriggered(true);
            }
            return; // prevent new guest, if name empty or contains only whitespace
        } else {
            // reset validator if the user has entered a valid name
            textBoxValidatorRef.current.instance.reset();
            setValidationTriggered(false);
        }

        const names = newUser.split(" ");
        const newUserObject = {
            id: users.length + 1,
            firstName: names[0],
            lastName: names[1] || "",
        };
        onNewUser(newUserObject); // call when new user is created

        setValue(newUserObject.id);
        setUsers([...users, newUserObject]);
        setNewUser("");

        // onSelectUser(newUserObject); // pass new user to parent component
        // Close the DropDownBox after adding a new item
        if (dropDownBoxRef.current !== null) {
            dropDownBoxRef.current.instance.close();
        }
    };

    const onItemClick = (e) => {
        setIsNewUser(false)
        setValue(e.itemData.id);
        // Close the DropDownBox after selecting an item
        if (dropDownBoxRef.current !== null) {
            dropDownBoxRef.current.instance.close();
        }
    };

    const contentRender = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center p-1">
                    <TextBox
                        value={newUser}
                        onValueChanged={(e) => setNewUser(e.value)}
                        onEnterKey={onEnterKey}
                        placeholder="Add a New Guest..."
                    >
                        <Validator ref={textBoxValidatorRef}>
                            <RequiredRule message="Guest name is required" />
                        </Validator>
                    </TextBox>
                    <Button text="Add" onClick={onEnterKey} className="btn-add-guest" />
                </div>
                <List
                    dataSource={initialUsers}
                    keyExpr="id"
                    displayExpr={(item) =>
                        item ? `${item.firstName} ${item.lastName}` : ""
                    }
                    onItemClick={onItemClick}
                    selectionMode="single"
                    selectedItemKeys={selectedItemKeys}
                    className="drop-down-list"
                />
            </>
        );
    };

    return (
        <>
            <div className={`w-100 ${isEmpty ? 'dropdown-input__container--error' : ''}`}>
                <DropDownBox
                    ref={dropDownBoxRef}
                    value={value}
                    dataSource={users}
                    valueExpr="id"
                    displayExpr={(item) =>
                        item ? `${item.firstName} ${item.lastName}` : ""
                    }
                    placeholder="Select a Guest..."
                    contentRender={contentRender}
                    onValueChanged={onValueChanged}
                />
                {isEmpty && <div className='dropdown-input__container__message--error'>{messageLabel} required</div>}
            </div>
        </>
    );
};

export default CustomDropDownInput;