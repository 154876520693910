export const SET_MY_REQUESTS = "SET_MY_REQUESTS";
export const SET_MY_REQUESTS_SKIP = "SET_MY_REQUESTS_SKIP";

export const SET_ALL_REQUESTS = "SET_ALL_REQUESTS";


export const SET_MY_REQUESTS_LOADING = "SET_MY_REQUESTS_LOADING";
export const SET_MY_REQUESTS_OPTION_LOADING = "SET_MY_REQUESTS_OPTION_LOADING";

export const SET_MY_REQUESTS_DEFAULT = "SET_MY_REQUESTS_DEFAULT";
export const SET_MY_REQUESTS_OPTION_DETAILS = "SET_MY_REQUESTS_OPTION_DETAILS";
export const SET_MY_REQUESTS_OPTION_AMENITIES = "SET_MY_REQUESTS_OPTION_AMENITIES";

export const SET_MY_REQUESTS_STATUS = "SET_MY_REQUESTS_STATUS";
export const SET_MY_REQUESTS_OPTION_STATUS = "SET_MY_REQUESTS_OPTION_STATUS";


