import '../styles/amenities/amenities-tag.css'
const AmenitiesTag = ({imagen,name}) => {
  return (
    <div className='amenities-tag'>
      <img src={imagen}/>
      <span>{name}</span>
    </div>
  )
}

export default AmenitiesTag