import {useCallback,useEffect,useState,memo,useRef} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-input.css'

//components
import { TextBox } from 'devextreme-react/text-box';
import {
    Validator,
    RequiredRule,
    CustomRule
  } from 'devextreme-react/validator';

const SearchStreet = ({label, value, setValue,requiredValue,nameField,geometryCity,labelSize,countryISOCode,localityCity, placeId, setPlaceId}) => {
  const validatorRef = useRef()
  let autocompleteservice = {}
  let autocomplete = {}
  const [isFocus, setisFocus] = useState()
  const [hasError, sethasError] = useState(false)
  // const [placeId, setPlaceId] = useState()
  const [options, setOptions] = useState({
    types: ['geocode']
  })
  
  const getPlaceId = (value) => {
    autocompleteservice = new window.google.maps.places.AutocompleteService()
    if(value)
    {      
      autocompleteservice.getPlacePredictions({ 'input': value, ...options, componentRestrictions:{country: countryISOCode}},async(prediction,status)=>{
        let place = []
        if(prediction)
        {
          place = prediction.filter(result => {
            return result.description.toLowerCase().trim().normalize('NFD').replace(/\p{Diacritic}/gu, "") === value.toLowerCase().trim().normalize('NFD').replace(/\p{Diacritic}/gu, "")
          })
        }
        if(place.length === 1)
        {
          let response = await getIsAValidStreet(place[0].place_id)
          if(response)
          {
            setPlaceId(place[0].place_id)
          }
          else
            setPlaceId('')
        }
        else
          setPlaceId('')
        // setPlaceId(place.length === 1 ? place[0].place_id : '')
      })
    } 
    else  
      setPlaceId()
  }
  const getIsAValidStreet = async (placeId) => {
    let geocoder = new window.google.maps.Geocoder();
    let city, administative1, administrative2, town, sublocality

    await geocoder.geocode({ placeId: placeId }, (results) => {
      if(results.length > 0)
        results[0].address_components.forEach(component => {
          if(component.types.includes('locality'))
            city = component.long_name
          if(component.types.includes('administrative_area_level_1'))
            administative1 = component.long_name
          if(component.types.includes('administrative_area_level_2'))
            administrative2 = component.long_name
          if(component.types.includes('postal_town'))
            town = component.long_name
          if(component.types.includes('sublocality'))
            sublocality = component.long_name
        })
    });

    if(city === localityCity || administative1 === localityCity || administrative2 === localityCity || town === localityCity || sublocality === localityCity)
    {
      return true
    }
    else
    {
      let valueArray = document.querySelector(`#${nameField}`)?.value.split(',')
      let trimValueArray = valueArray.map(value => value.trim())
      return trimValueArray.includes(localityCity)
    }
  }
  const onFocusOutStreet = _ => {
    getPlaceId(document.querySelector(`#${nameField}`)?.value)
    setisFocus(false)
  }
  const onPlaceChanged = e => {
    sethasError(false)
    setValue(document.querySelector(`#${nameField}`)?.value)
  } 
  const onChangeValue = useCallback((data) => {
      validatorRef?.current?.instance?.option('isValid',true)
      setValue(data)
  }, []);
  
  const onKeyValidation = useCallback(e=>{
    e.event.key.toLowerCase() === 'enter' && e.event.preventDefault()
    let regex = /[<>]/
    regex.test(e.event.key) && e.event.preventDefault()
  },[])

  const getValidPlaceId = _ => {
    return !isFocus ? placeId : !hasError
  }
  const onValidated = useCallback(e => {
      let inputHeight = document.querySelector(`#${nameField}`).offsetHeight
      
      let validationPosition = inputHeight + 35
      let overlay = e.element?.querySelector(".dx-invalid-message-content")
      if(overlay){
        overlay.style.top=`-${validationPosition}px`
        sethasError(true)
      }
      else{
        sethasError(false)
      }
        
  },[])
  useEffect(() => {
    autocomplete = new window.google.maps.places.Autocomplete(document.querySelector(`#${nameField}`), options);
    autocomplete.addListener('place_changed',onPlaceChanged) 

    const pacContainer = document.querySelectorAll('.pac-container:not(.pac-container-city)')
    pacContainer.forEach(item => item?.classList?.add('pac-container-street'))
    // pacContainer?.classList?.add('strictClass')
    // validatorRef.current.instance.reset()
  }, [options])
  
  useEffect(() => {
    if(geometryCity)
    {
      let defaultBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(geometryCity.southwest.lat, geometryCity.southwest.lng),
      new window.google.maps.LatLng(geometryCity.northeast.lat, geometryCity.northeast.lng))
      setOptions({
        ...options,
        bounds: defaultBounds,
        strictBounds: true
      })
    }
    else
    {
      setValue('')
      validatorRef.current.instance.reset()
    }
  }, [geometryCity])

  useEffect(() => {
    countryISOCode && setOptions({
      ...options,
      componentRestrictions:{country: countryISOCode}
    })
  }, [countryISOCode])
  useEffect(() => {
    sethasError(false)
  }, [value])
  
  return (
    <div className='custom-input' style={labelSize ? {gridTemplateColumns: `${labelSize} calc(100% - ${labelSize} - 20px)`} : {}}>
        <div className='custom-input__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
        <div>
          <TextBox
            inputAttr={{
              id: nameField,
              class: 'street-autocomplete'
            }}
            placeholder=''
            value={value}
            valueChangeEvent="input"
            onValueChange={onChangeValue}
            disabled={!geometryCity?.northeast || !geometryCity?.southwest || !countryISOCode || !localityCity}
            onKeyDown={onKeyValidation}
            onFocusIn={_=>setisFocus(true)}
            onFocusOut={onFocusOutStreet}
          >
            {requiredValue &&
              <Validator 
                ref={validatorRef}
                onValidated={onValidated}
              >
                <RequiredRule message={`${label} is required`} />
                <CustomRule
                    validationCallback={getValidPlaceId}
                    message={`${label} is not valid`}
                />
              </Validator>
            }
          </TextBox>
        </div>
    </div>
  )
}

export default memo(SearchStreet)