import moment from "moment";
import { REQUESTED_PROPERTY_DECLINE_REASON } from "../constant/requested-poperty-decline-reason"

export default class RequestHelper {
    static getSupplierImgSourceAndTitleByRatingId = (supplierRatingId) => {
        switch (supplierRatingId) {
            case 1:
                return {
                    src: `${process.env.PUBLIC_URL}/Content/Image/Rating/bronze.svg`,
                    title: "Bronze"
                }
            case 2:
                return {
                    src: `${process.env.PUBLIC_URL}/Content/Image/Rating/silver.svg`,
                    title: "Silver"
                }
            case 3:
                return {
                    src: `${process.env.PUBLIC_URL}/Content/Image/Rating/gold.svg`,
                    title: "Gold"
                }
            case 4:
                return {
                    src: `${process.env.PUBLIC_URL}/Content/Image/Rating/platinum.svg`,
                    title: "Platinum"
                }
            default:
                return {
                    src: `${process.env.PUBLIC_URL}/Content/Image/Rating/not-rated.svg`,
                    title: "Not Rated"
                }
        }
    }

    //static getBadgeClassByRequestStatus = (requestStatus) => {
    //    switch (requestStatus) {
    //        case REQUEST_OPTION_STATUS.SUPPLIER.New:
    //            return 'badge-new';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.Booked:
    //            return 'badge-booked';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.Quoted:
    //            return 'badge-quoted';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.PendingSupplier:
    //            return 'badge-pending-supplier';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.PaperworkInProgress:
    //            return 'badge-paperwork-in-progress';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.NotBooked:
    //            return 'badge-not-booked';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.DeclinedByRecipient:
    //            return 'badge-declined-by-recipient';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.DeclinedByClient:
    //            return 'badge-declined-by-client';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.DeclinedBySupplier:
    //            return 'badge-declined-by-supplier';
    //        default:
    //            return 'badge-none';
    //    }
    //}

    //static getBadgeColorByRequestStatus = (requestStatus) => {
    //    switch (requestStatus) {
    //        case REQUEST_OPTION_STATUS.SUPPLIER.New:
    //            return '#e76134';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.Booked:
    //            return '#42c361';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.Quoted:
    //            return '#f4c92c';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.PendingSupplier:
    //            return '#32a0c7';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.PaperworkInProgress:
    //            return '#32c7c3';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.NotBooked:
    //            return '#e3e3e3';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.DeclinedByRecipient:
    //            return '#e9322c';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.DeclinedByClient:
    //            return '#e3e3e3';
    //        case REQUEST_OPTION_STATUS.SUPPLIER.DeclinedBySupplier:
    //            return '#e3e3e3';
    //        default:
    //            return '#e3e3e3';
    //    }
    //}

    //static getRequestNameByRequestStatus = (requestStatus) => {
    //    switch (requestStatus) {
    //        case REQUEST_STATUS.CLIENT.Open:
    //            return 'Open';
    //        case REQUEST_STATUS.CLIENT.Booked:
    //            return 'Booked';
    //        case REQUEST_STATUS.CLIENT.Quoted:
    //            return 'Quoted';
    //        case REQUEST_STATUS.CLIENT.PendingSupplier:
    //            return 'Pending Supplier';
    //        case REQUEST_STATUS.CLIENT.PaperworkInProgress:
    //            return 'Paperwork In Progress';
    //        case REQUEST_STATUS.CLIENT.NotBooked:
    //            return 'Not Booked';
    //        case REQUEST_STATUS.CLIENT.DeclinedByRecipient:
    //            return 'Declined By Recipient';
    //        case REQUEST_STATUS.CLIENT.DeclinedByClient:
    //            return 'Declined By Client';
    //        case REQUEST_STATUS.CLIENT.DeclinedBySupplier:
    //            return 'Declined By Supplier';
    //        case REQUEST_STATUS.CLIENT.Closed:
    //            return 'Closed';
    //        case REQUEST_STATUS.CLIENT.Expired:
    //            return 'Expired';
    //        case REQUEST_STATUS.CLIENT.PendingTermination:
    //            return 'Pending Termination';
    //        case REQUEST_STATUS.CLIENT.Reopen:
    //            return 'Re-open';
    //        case REQUEST_STATUS.CLIENT.Paperwork:
    //            return 'Paperwork';
    //        case REQUEST_STATUS.CLIENT.PendingExtension:
    //            return 'Pending Extension';
    //        case REQUEST_STATUS.CLIENT.ReservationCreated:
    //            return 'Reservation Created';
    //        default:
    //            return '';
    //    }
    //}

    //static getRequestBadgeColorByRequestStatus = (requestStatus) => {
    //    switch (requestStatus) {
    //        case REQUEST_STATUS.CLIENT.Open:
    //        case REQUEST_STATUS.CLIENT.Reopen:
    //        case REQUEST_STATUS.CLIENT.PendingExtension:
    //        case REQUEST_STATUS.CLIENT.PendingTermination:
    //        case REQUEST_STATUS.CLIENT.ReservationCreated:
    //            return '#1ab06b';
    //        case REQUEST_STATUS.CLIENT.Booked:
    //        case REQUEST_STATUS.CLIENT.Quoted:
    //        case REQUEST_STATUS.CLIENT.PendingSupplier:
    //        case REQUEST_STATUS.CLIENT.Paperwork:
    //            return '#fff';
    //        case REQUEST_STATUS.CLIENT.NotBooked:
    //        case REQUEST_STATUS.CLIENT.Closed:
    //            return '#808080';
    //        case REQUEST_STATUS.CLIENT.DeclinedByRecipient:
    //            return '#e9322c';
    //        case REQUEST_STATUS.CLIENT.DeclinedByClient:
    //            return '#e9322c';
    //        case REQUEST_STATUS.CLIENT.DeclinedBySupplier:
    //            return '#e9322c';
    //        default:
    //            return '#fff';
    //    }
    //}

    //static getRequestBadgeBorderColorByRequestStatus = (requestStatus) => {
    //    switch (requestStatus) {
    //        case REQUEST_STATUS.CLIENT.Open:
    //        case REQUEST_STATUS.CLIENT.PendingExtension:
    //        case REQUEST_STATUS.CLIENT.PendingTermination:
    //        case REQUEST_STATUS.CLIENT.ReservationCreated:
    //        case REQUEST_STATUS.CLIENT.Reopen:
    //        case REQUEST_STATUS.CLIENT.Booked:
    //            return '#1ab06b';
    //        case REQUEST_STATUS.CLIENT.Quoted:
    //            return '#f4c92c';
    //        case REQUEST_STATUS.CLIENT.Paperwork:
    //            return '#32c7c3';
    //        case REQUEST_STATUS.CLIENT.PendingSupplier:
    //            return '#deb887';
    //        case REQUEST_STATUS.CLIENT.NotBooked:
    //        case REQUEST_STATUS.CLIENT.Closed:
    //            return '808080';
    //        case REQUEST_STATUS.CLIENT.DeclinedByRecipient:
    //            return '#e9322c';
    //        case REQUEST_STATUS.CLIENT.DeclinedByClient:
    //            return '#e9322c';
    //        case REQUEST_STATUS.CLIENT.DeclinedBySupplier:
    //            return '#e9322c';
    //        default:
    //            return '#e3e3e3';
    //    }
    //}
    //static getRequestBadgeBackgroundColorByRequestStatus = (requestStatus) => {
    //    switch (requestStatus) {
    //        case REQUEST_STATUS.CLIENT.Open:
    //        case REQUEST_STATUS.CLIENT.PendingExtension:
    //        case REQUEST_STATUS.CLIENT.ReservationCreated:
    //        case REQUEST_STATUS.CLIENT.PendingTermination:
    //        case REQUEST_STATUS.CLIENT.Closed:
    //            return '#fff'
    //        case REQUEST_STATUS.CLIENT.Booked:
    //            return '#1ab06b';
    //        case REQUEST_STATUS.CLIENT.Reopen:
    //        case REQUEST_STATUS.CLIENT.Quoted:
    //            return '#f4c92c';
    //        case REQUEST_STATUS.CLIENT.Paperwork:
    //            return '#32c7c3';
    //        case REQUEST_STATUS.CLIENT.PendingSupplier:
    //            return '#deb887';
    //        case REQUEST_STATUS.CLIENT.NotBooked:
    //            return '#e3e3e3';
    //        case REQUEST_STATUS.CLIENT.DeclinedByRecipient:
    //            return '#e9322c';
    //        case REQUEST_STATUS.CLIENT.DeclinedByClient:
    //            return '#e9322c';
    //        case REQUEST_STATUS.CLIENT.DeclinedBySupplier:
    //            return '#e9322c';
    //        default:
    //            return '#e3e3e3';
    //    }
    //}

    static getDurationString = (startDate, endDate) => {
        let start = moment(startDate);
        let end = moment(endDate);

        let nights = end.diff(start, 'days');
        let days = nights + 1; //include arrival day in days count

        let formatStr = 'MM/DD/YYYY';
        if (moment().utcOffset() >= 0) {
            formatStr = 'DD.MM.YYYY';
        }

        return `${start.format(formatStr)} - ${end.format(formatStr)} (${days} day${days > 1 ? 's' : ''} / ${nights} night${nights > 1 ? 's ' : ''})`
    }

    static getArrivalDepartureString = (startDate, endDate) => {
        let start = moment(startDate);
        let end = moment(endDate);

        let formatStr = 'hh:mm A';
        if (moment().utcOffset() >= 0) {
            formatStr = 'HH:mm';
        }
        return `Arrival on or after  ${start.format(formatStr)} / Departure on or before  ${end.format(formatStr)}`
    }

    static getLenghtOfStayString = (startDate, endDate, rateTypeId) => {
        let start = moment.utc(startDate).startOf('day');
        let end = moment.utc(endDate).startOf('day');

        let nights = end.diff(start, 'days');
        let days = nights + 1; //include arrival day in days count

        if (rateTypeId !== 1) {
            return `${days} day${days > 1 ? 's' : ''} `
        }

        return `${nights} night${nights > 1 ? 's ' : ''})`
    }

    static getLengthOfStay = (startDate, endDate, rateTypeId) => {
        let start = moment.utc(startDate).startOf('day');
        let end = moment.utc(endDate).startOf('day');

        let los = end.diff(start, 'days');

        if (rateTypeId !== 1) {
            los = los + 1;
        }

        return los;
    }

    static getRequestPropertyStatus = (statusId) => {
        switch (statusId) {
            case REQUESTED_PROPERTY_DECLINE_REASON.DeclinedByGuest:
                return "Cancelled by Guest";
            case REQUESTED_PROPERTY_DECLINE_REASON.DeclinedByManager:
                return "Unavailable";
            case REQUESTED_PROPERTY_DECLINE_REASON.DeclinedBySUpplier:
                return "Unavailable";
            default:
                return "Pending Confirmation";
        }
    }

    static getLocalDateFormat = () => {
        let formatStr = 'MM/DD/YYYY';
        if (moment().utcOffset() >= 0) {
            formatStr = 'DD.MM.YYYY';
        }
        return formatStr;
    }

    static getLocalDateTimeFormat = () => {
        let formatStr = 'MM/DD/YYYY hh:mm A';
        if (moment().utcOffset() >= 0) {
            formatStr = 'DD.MM.YYYY HH:mm';
        }
        return formatStr;
    }

    static getLocalTimeFormat = () => {
        let formatStr = 'hh:mm A';
        if (moment().utcOffset() >= 0) {
            formatStr = 'HH:mm';
        }
        return formatStr;
    }

    static toCurrencyString = (num, currencyCode) => {
        try {
            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currencyCode,
                useGrouping: false
            });

            return formatter.format(+num);
        } catch (e) {
            return num;
        }
    }

    static getDatePickerDateFormat = () => {
        let formatStr = 'MM/dd/yyyy';
        if (moment().utcOffset() >= 0) {
            formatStr = 'dd.MM.yyyy';
        }
        return formatStr;
    }

    static getPhoneString = (phoneNumber, phoneNumberExtension) => {
        return phoneNumber ? `${phoneNumber} ${phoneNumberExtension ? `(ext.:${phoneNumberExtension})` : ''}` : 'Missing';
    }

    static getColorByRelevancePercentage = (relevancePercentage) => {
        if (relevancePercentage < 30) {
            return "danger";
        } else if ((relevancePercentage > 30) && (relevancePercentage < 60)) {
            return "warning";
        } else if ((relevancePercentage > 60)) {
            return "success";
        }
    }
}