import {useEffect,useRef,useState} from 'react'

import DateRangeBox from 'devextreme-react/date-range-box';
import {
  Validator,
  RequiredRule,
  CustomRule
} from 'devextreme-react/validator';

import '../../styles/input/custom-input-alt.css'
import '../../styles/input/custom-date-range-alt.css'
import RequestHelper from '../../../../utils/helper';

const CustomDateRangeAlt = ({ label, value, setValue, requiredValue, isDisabled, inputIcon, dateFormat }) => {
  const onValueChanged = e=>setValue(e.component.option('value'))
  const getValidMoveOut = _ => {
    return new Date(value[0]) < new Date(value[1])
  }
  return (
    <div className='custom-input-alt custom-date-range-alt'>
      <div className='custom-input-alt__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
        <div className={`custom-input-alt__container custom-date-range-alt__container ${inputIcon ? '' : 'custom-input-alt__container--noIcon'}`}>
          <DateRangeBox
              disabled={isDisabled}
                  labelMode='hidden'
                  displayFormat={RequestHelper.getDatePickerDateFormat()}
              useMaskBehavior={true}
                startDatePlaceholder={dateFormat}
                endDatePlaceholder={dateFormat} 
              min={new Date()}
              defaultValue={value}
              onClosed={onValueChanged}
          >
            {requiredValue &&
                <Validator>
                    <RequiredRule message={`${label} is required`} />
                    <CustomRule
                      validationCallback={getValidMoveOut}
                      message={`The date of move in and move out cannot be the same`}
                    />
                </Validator>
            }
          </DateRangeBox>
          <div className='custom-input-alt__container__icon'><img src={inputIcon}/></div>
        </div>
    </div>
  )
}

export default CustomDateRangeAlt