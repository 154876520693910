import {useEffect} from 'react'
import '../styles/custom-popup.css'

const CustomPopup = ({visible,onHiding,children}) => {

  useEffect(() => {
        document.body.style.overflowY = visible ? 'hidden':'auto'
  }, [visible])
  
  return (
    <>
        
        <div className={visible ? 'custom-popup custom-popup--active' : 'custom-popup'} onClick={onHiding}>
          {visible && 
            <div className='custom-popup__content' onClick={e=>e.stopPropagation()}>
                {children}
            </div>}
        </div>
    </>
  )
}

export default CustomPopup