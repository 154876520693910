import {
    SET_PROPERTY_SENT,
    SET_LOADING,
    SET_DEFAULT
} from "../constant/list-a-property";

const defaultState = {
    propertySent:{
        status: false,
        message: ''
    },
    loading: false,
};

export default function listAPropertyReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_PROPERTY_SENT:
            return {
                ...state,
                propertySent: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case SET_DEFAULT:
            return defaultState
        default:
            return state;
    }
}