import axios from "axios";
import { request, response } from "./shared/interceptors";

const baseService = axios.create(
    {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });

baseService.interceptors.response.use(response.onFulfilled, response.onReject);

baseService.interceptors.request.use((c) => request.onFulfilled(c), request.onReject);

export default baseService
