import React from "react";
import Cta from "./Sub-Components/Cta";
import "./styles/cta-styles.css";

const CtaComponent = () => {
    return (
        <Cta />
    )
}

export default CtaComponent;