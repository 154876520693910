import {useParams} from 'react-router-dom'
import baseService from "../services/base.service";
import notify from 'devextreme/ui/notify';

//components
import ContentWrapper from './assets/elements/content-wrapper'
import Button from './assets/elements/button/action-button'

//constant
import {ADMINISTRATION_TYPES} from '../constant/administration'

//images
import EyeIcon from '../images/admin-icons/eye-small.svg'

//styles
import './administration/styles/administration.css'

//utils
import { getIsAdmin } from '../utils/admin'

const Administration = () => {
  const params = useParams()
  const onClickAdministrationType = (endpoint,message) =>{

        try {
            if(getIsAdmin())
            {
                const url = `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`;
      
                baseService.put(url, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
                }).then(({data}) =>{
                    if(data)
                        notify(message, "success" , 3000);
                    else
                        notify('Something went wrong', "error" , 3000);
                }).catch(error =>{
                    notify('Something went wrong', "error" , 3000);
                })
            }
            else{
                notify('Unauthorize', "error" , 3000);
            }
        }
        catch (error) {
            notify('Something went wrong', "error" , 3000);
        }
  }
  return (
    <ContentWrapper>
        { Object.keys(ADMINISTRATION_TYPES).filter(key => key === params.name).length > 0 &&
            <div className='administration_wrapper'>
                <div className='administration_title'>{ADMINISTRATION_TYPES[params.name].title}</div>
                <div className='administration_table'>
                    <div className='administration_table__header'>
                        <div>{ADMINISTRATION_TYPES[params.name].columnName}</div>
                        <div className='administration_table__division'>Actions</div>
                    </div>
                    
                    {ADMINISTRATION_TYPES[params.name].list?.map((type,index) => 
                        <div className='administration_table__row' key={`${type}_${index}`}>
                            <div className='administration_table__row__cell'>{type.name}</div>
                            <div className='administration_table__row__cell administration_table__division'>
                                <Button action={_=>onClickAdministrationType(type.url,type.message)}>{type.buttonName}</Button>
                                <div><a href={`${process.env.REACT_APP_API_ENDPOINT}/hangfire/recurring`} target="_blank"><img src={EyeIcon} alt="eye_icon" /></a></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        }
        
    </ContentWrapper>
  )
}

export default Administration