import {
    CHANGE_LOCATION,
    CHANGE_GEOMETRY,
    CHANGE_CHECKIN,
    CHANGE_CHECKOUT,
    CHANGE_FLOOR,
    CHANGE_MILES,
    CLEAR_PAGES,
    CHANGE_BUILDING_TYPES,
    CHANGE_PETS_FRIENDLY,
    CHANGE_PAGES,
    CHANGE_MESSAGE,
    CHANGE_LOADING_FILTER,
    CHANGE_FILTER_TOTAL_PROPERTIES,
    CLEAR_DATA,
    PREVIOUS_URL,
    SET_PROPERTY_MAP,
    SET_FILTER_PROPERTIES,
    SET_PROPERTY_FROM_MAP,
    SET_PROPERTY_FROM_LIST,
    SET_CARD_TYPE,
    SET_PROPERTY_SEARCH_VIEW,
    SET_SWITCH_LOCATION_SEARCH,
    SET_CLEAR_LOCATION_FIELD,
    SET_MAP_LOADING,
    SET_CURRENT_MAP,
    SET_CURRENT_MAP_ZOOM,
    SET_CURRENT_MAP_CENTER,
    SET_CURRENT_MAP_MARKERS
} from "../constant/property-search";


export const setMapLoading = value => ({
    type: SET_MAP_LOADING,
    payload: value
});
export const setCurrentMapCenter = value => ({
    type: SET_CURRENT_MAP_CENTER,
    payload: value
});
export const setCurrentMap = value => ({
    type: SET_CURRENT_MAP,
    payload: value
});

export const setCurrentMapZoom = value => ({
    type: SET_CURRENT_MAP_ZOOM,
    payload: value
});

export const setCurrentMapMarkers = value => ({
    type: SET_CURRENT_MAP_MARKERS,
    payload: value
});

export const changeLocation = locationValue => ({
    type: CHANGE_LOCATION,
    payload: locationValue
});
export const setSwitchLocationSearch = (type,value) => ({
    type: SET_SWITCH_LOCATION_SEARCH,
    payload: {type,value}
}); 
export const setClearLocationField = (value) => ({
    type: SET_CLEAR_LOCATION_FIELD,
    payload: value
});

export const setPropertyFromMap = id => ({
    type: SET_PROPERTY_FROM_MAP,
    payload: id
});

export const setPropertySearchView = value => ({
    type: SET_PROPERTY_SEARCH_VIEW,
    payload: value
});

export const setPropertyFromList = id => ({
    type: SET_PROPERTY_FROM_LIST,
    payload: id
});

export const changeGeometry = geometry => ({
    type: CHANGE_GEOMETRY,
    payload: geometry
});

export const changeCheckIn = checkIn => ({
    type: CHANGE_CHECKIN,
    payload: checkIn 
});

export const changeCheckOut = checkOut => ({
    type: CHANGE_CHECKOUT,
    payload: checkOut 
});

export const changeFloor = floor => ({
    type: CHANGE_FLOOR,
    payload: floor 
});

export const changeMiles = miles => ({
    type: CHANGE_MILES,
    payload: miles 
});

export const changeBuildingTypes = data => ({
    type: CHANGE_BUILDING_TYPES,
    payload: data 
});

export const changePetsFriendly = value => ({
    type: CHANGE_PETS_FRIENDLY,
    payload: value 
});

export const changeLoading = value => ({
    type: CHANGE_LOADING_FILTER,
    payload: value
});

export const changeFilterTotalProperties = value => ({
    type: CHANGE_FILTER_TOTAL_PROPERTIES,
    payload:value
})

export const setFilterProperties = data => ({
    type: SET_FILTER_PROPERTIES,
    payload: data
});


export const changeMessage = value => ({
    type: CHANGE_MESSAGE,
    payload: value
});

export const clearData = () => ({
    type: CLEAR_DATA,
});

export const changePages = (skip,take) => ({
    type: CHANGE_PAGES,
    payload: {
        skip: skip,
        take: take
    }
});
export const changePreviousUrl = value => ({
    type: PREVIOUS_URL,
    payload: value
});
export const clearPages = () => ({
    type: CLEAR_PAGES,
});


export const setPropertyMap = (type,data) => ({
    type: SET_PROPERTY_MAP,
    payload: {type,data}
});

export const setCardType = data => ({
    type: SET_CARD_TYPE,
    payload: data
});
