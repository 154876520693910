// Towar of Excellence Awards
export const TowerOfExcellenceAward2016 = require("../images/landing/awards/award5.jpg");
export const TowerOfExcellenceAward2013 = require("../images/landing/awards/award9.jpg");

// Service Partner Awards
export const ServicePartnerAward2016 = require("../images/landing/awards/award8.jpg");
export const ServicePartnerAward2015 = require("../images/landing/awards/award4.jpg");
export const ServicePartnerAward2013 = require("../images/landing/awards/award2.jpg");

// Service Partner Best Over All Awards
export const ServicePartnetAwardBestOverAll2015 = require("../images/landing/awards/award3.jpg");
export const ServicePartnetAwardBestOverAll2014 = require("../images/landing/awards/award1.jpg");

// EMEA Emmas Awards    
export const EmeaEmmasAward2015 = require("../images/landing/awards/award10.jpg");
export const EmmasAward2015 = require("../images/landing/awards/award6.jpg");

// America Emmas Awards
export const AmericaEmmasAward = require("../images/landing/awards/award7.jpg");


 
/******* Same Awards ( no background )  *****/

// Towar of Excellence Awards
export const TowerOfExcellenceAward2016V2 = require("../images/landing/awards_v2/award5.png");
export const TowerOfExcellenceAward2013V2 = require("../images/landing/awards_v2/award9.png");

// Service Partner Awards
export const ServicePartnerAward2016V2 = require("../images/landing/awards_v2/award8.png");
export const ServicePartnerAward2015V2 = require("../images/landing/awards_v2/award4.png");
export const ServicePartnerAward2013V2 = require("../images/landing/awards_v2/award2.png");

// Service Partner Best Over All Awards
export const ServicePartnetAwardBestOverAll2015V2 = require("../images/landing/awards_v2/award3.png");
export const ServicePartnetAwardBestOverAll2014V2 = require("../images/landing/awards_v2/award1.png");

// EMEA Emmas Awards    
export const EmeaEmmasAward2015V2 = require("../images/landing/awards_v2/award10.png");
export const EmmasAward2015V2 = require("../images/landing/awards_v2/award6.png");

// America Emmas Awards
export const AmericaEmmasAwardV2 = require("../images/landing/awards_v2/award7.png");
