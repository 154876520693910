import React from "react";
import { processSilentRenew } from "redux-oidc";

const AuthSilentRenew = props => {
    processSilentRenew();
    return (
        <div>Silent renew authentication</div>
    );
}

export default AuthSilentRenew;