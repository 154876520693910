import {useState,useEffect,useRef} from 'react'
import Title  from '../../elements/section-subtitle'
import CustomTreelist from '../../elements/custom-treelist-alt'
import CustomText from '../../elements/input/custom-text-alt'

import  '../../styles/accordion/treelist-group.css'

const AccordionTreelist = ({title,data,selectedData,dataKeys,setSelectedData,setDataKeys}) => {
  const [filterValue, setFilterValue] = useState('')
  const [isAccordionActive, setIsAccordionActive] = useState(false)
  // const updateCurrentSelectedKeys = (typeId,keys) => {
  //   let newSelectedKeys = dataKeys.map(item => {
  //     if(item.id === typeId)
  //     {
  //       return {...item,keys: keys}
  //     }
  //     else
  //       return item
  //   })
  //   setDataKeys(newSelectedKeys)
    // }
    
    const formattedData = [];
    const formatDataToExpand = (data) => {
        data.map(p => {
            if (p.children != null) {
                formattedData.push(...p.children);
            }
            formattedData.push(p)

        })
    }
    formatDataToExpand(data)

    const getParentIds = (data) => {
        let parents = data.filter(r => r.children != null)
        let parentIds = []
        parents.map(p => parentIds.push(p.id))
        return parentIds
    }

    const expandedKeys = getParentIds(data)
  const deleteFromCurrentSelectedData = idToDelete => {
    let deletedData
    let idsToDelete = []
    selectedData.forEach(data => {
      if(data.id === idToDelete)
      {
        deletedData = data
      }
    })
    //data to delete
    idsToDelete.push(deletedData.id)

    //if its parent
    let parentIds = data.filter(item => !item.parentId).map(item => item.id)

    if(parentIds.includes(deletedData.parentId))
    {
        deletedData.children.forEach(data =>{
        idsToDelete.push(data.id)
      })
    }
    else{
      let foundParent = selectedData.filter(data => data.id === deletedData.parentId)
      let allChildrenIds = foundParent?.[0].children?.map(item => item.id)
      let selectedDataIds = selectedData.map(item => item.id).filter(item => item !== idToDelete)
      let foundCommonElement = allChildrenIds.some(item => selectedDataIds.includes(item))
      
      if(!foundCommonElement)
      {
        idsToDelete.push(foundParent[0].id)
      }
      // if(foundParent.length > 0)
      // {
      //   idsToDelete.push(foundParent[0].id)
      // }
    }
    let filteredData = selectedData.filter(data => !idsToDelete.includes(data.id))
    let deleteDuplicates = Array.from(new Set(filteredData.map(obj => obj.id))).map(id => {
      return filteredData.find(obj => obj.id === id)
    })
    setSelectedData(deleteDuplicates)
  }
  const onSelectionChanged = (e) => {
      //selectedRowKeys, currentSelectedRowKeys, currentDeselectedRowKeys, selectedRowsData
    
    
    setDataKeys(e.selectedRowKeys)
    // updateCurrentSelectedKeys(typeId,e.selectedRowKeys)
    if(e.currentSelectedRowKeys.length > 0)
    {  
      // let dataByType = data.filter(item => item.id === typeId)[0]
        const currentData = formattedData.filter(item => item.id === e.currentSelectedRowKeys[0])[0]
      // let currentIds = []
      let dataOfCurrentIds = []

        let parentIds = formattedData.filter(item => !item.parentId).map(item => item.id)

      if(parentIds.includes(currentData.parentId))
      {
        dataOfCurrentIds = [currentData,...currentData.children]
      }
      else
      {
        let currentKeys = selectedData.map(item => item.id)
        let parentData = formattedData.filter(item => item.id === currentData.parentId)[0]

        let parentChildrenIds = parentData.children.map(item => item.id)

        let allKeys = [currentData.id,...currentKeys]
        let foundKeys =  allKeys.filter(item => parentChildrenIds.includes(item))
        // if(foundKeys.length === parentChildrenIds.length)
        // {
          dataOfCurrentIds = [currentData,parentData]
        // }
        // else{
        //   dataOfCurrentIds = [currentData]
        // }
      }   
      let allData = [...selectedData,...dataOfCurrentIds]
      let deleteDuplicates = Array.from(new Set(allData.map(obj => obj.id))).map(id => {
        return allData.find(obj => obj.id === id)
      })
 
      setSelectedData(deleteDuplicates)
    }
    else{
      deleteFromCurrentSelectedData(e.currentDeselectedRowKeys[0])
    }
  }

  const onDeleteTag = (id,dataReceived) => {
    deleteFromCurrentSelectedData(id)
    const keysByType = [...dataKeys]
    const dataByType = [...selectedData]
    if(keysByType.includes(id))
    {
      let index = keysByType.indexOf(id)
      keysByType.splice(index,1)
      
      setDataKeys(keysByType)
      // updateCurrentSelectedKeys(typeId,keysByType)
    }
    else{
      keysByType.includes(dataReceived.parentId)
      {
        let indexInKeys = keysByType.indexOf(dataReceived.parentId)
        if(indexInKeys < 0)
        {
          
          let  foundInSelectedData = selectedData.filter(item => item.id === dataReceived.id)
          if(foundInSelectedData.length > 0)
            {
              let childrenIds = foundInSelectedData[0].children.map(item => item.id)
              childrenIds.forEach(item =>
                {
                  const index = keysByType.indexOf(item)
                  if(index !== -1)
                    keysByType.splice(index,1)
                }  
              )
              setDataKeys(keysByType)
            }
        }
        else{
        keysByType.splice(indexInKeys,1)
        let keysByParent = dataByType.filter(item => item.id === dataReceived.parentId)[0]?.children?.map(item => item.id)
        let indexInChildren = keysByParent?.indexOf(id)
        keysByParent?.splice(indexInChildren,1)
        setDataKeys([...keysByType,...keysByParent])
        // updateCurrentSelectedKeys(typeId,[...keysByType,...keysByParent])
        }
      }
    }
   
  }
 
  return (
    <>
      <div className={isAccordionActive ? 'accordion-treelist' : ''}>
      <div className='accordion-treelist__searcher'>
          <div className='accordion-treelist__searcher__content'>
          {isAccordionActive ?
            <div className='accordion-treelist__title--active' onClick={_=>setIsAccordionActive(!isAccordionActive)}>
              {title}
            </div> 
            :
            <div className='accordion-treelist__title--inactive' onClick={_=>setIsAccordionActive(!isAccordionActive)}>
              + {title}
            </div>
          }
          {isAccordionActive && 
              <CustomText 
                placeholder='Search'
                value={filterValue} 
                setValue={setFilterValue} 
                inputIconCode='&#xf002;'
              />
          }
          </div>
        </div>
      <div className={`accordion-treelist__content ${isAccordionActive ? 'accordion-treelist__content--active': 'accordion-treelist__content--inactive'}`}>
            <div className='accordion-treelist__boxes'>
              <div className='accordion-treelist__box'>
                {/* <div className='accordion-treelist__box__title'>{item.name}</div> */}
                <div className='accordion-treelist__box__content'>
                {/* <CustomTreelist 
                  id={item.id}
                  name={item.name}
                  data={item.data}
                  keys={[...item.keys]}
                  filterValue={['name','contains',filterValue]}
                  onSelectionChanged={onSelectionChanged}
                /> */}
                  <CustomTreelist 
                    // id={item.id}
                    name='name'
                    data={formattedData}
                    keys={dataKeys}
                    expandedKeys={expandedKeys}
                    // filterValue={['name','contains',filterValue]}
                    filterValue={filterValue}
                    onSelectionChanged={onSelectionChanged}
                  />
                </div>
              </div>

            </div>
            {
              selectedData.length > 0 && 
              <div className='accordion-treelist__tags' >
              <div className='accordion-treelist__tags__container'>
                {
                   selectedData.map((data,index) =>
                  <div key={`tag_${index}`} className='accordion-treelist__tag'>
                    <div className='accordion-treelist__tag__delete' onClick={_ => onDeleteTag(data.id,data)}>x</div>
                    <div className='accordion-treelist__tag__title'>{data.name}</div>
                  </div>
                  )
                }
                </div>
             </div>
            }
      </div>
      </div>
    </>
   
  )
}

export default AccordionTreelist