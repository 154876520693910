import React from "react";
import "../styles/confirm-delete-modal-styles.css";
import 'devextreme/dist/css/dx.light.css';
import TrashCan from "../../../images/admin-icons/trash-modal-delete.svg";
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import baseService from "../../../services/base.service";

const types = ['error', 'info', 'success', 'warning'];
const getToastWidth = () => {
    const viewportWidth = window.innerWidth;
    return viewportWidth <= 480 ? viewportWidth * 0.9 : viewportWidth <= 720 ? viewportWidth * 0.8 : viewportWidth <= 1080 ? viewportWidth * 0.7 : 735;
};

const showMessage = (type, message) => {
    notify(
        {
            message,
            width: getToastWidth(),
            position: {
                my: "bottom",
                at: "bottom",
                of: window,
                offset: { y: -10 },
            }
        },
        types[type], 3000
    );
};

const ConfirmDeleteModal = ({ closeModal, id, articleTitle, onArticleDeleted }) => {
    const handleDelete = async () => {
        try {
            await baseService({
                method: "DELETE",
                baseURL: process.env.REACT_APP_API_ENDPOINT,
                url: `/api/v1/news/${id}`,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            showMessage(2, `Your News "${articleTitle.substring(0, 50)}" has been successfully deleted`);
            onArticleDeleted(id);
            closeModal();
        } catch (error) {
            closeModal();
            showMessage(0, `Error deleting your News "${articleTitle.substring(0, 50)}"`);
        }
    };

    return (
        <>
            <div className="confirm-delete-modal__overlay">
                <div className="confirm-delete-modal">
                    <div className="row">
                        <h1 className="comfirn-delete-modal__title">delete news</h1>
                        <p className="confirm-delete-modal__message">
                            Are you sure you want to delete your news "{articleTitle.substring(0, 50)}"?
                        </p>
                        <div className="d-flex justify-content-start align-items-center">
                            <Button className="confirm-delete-modal__btn-confirm" onClick={handleDelete}>
                                <img src={TrashCan} alt="trash-icon" className="trash-icon-delete-modal" />&nbsp;
                                Delete
                            </Button>
                            <Button className="confirm-delete-modal__btn-cancel" onClick={closeModal}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmDeleteModal;