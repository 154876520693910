import {
    SET_FAVORITES,
    SET_PROPERTY_ID,
    SET_FAVORITES_TOTAL,
    SET_PAGES_FAVORITES,
    SET_LOADING_FAVORITES
} from "../constant/favorites";

export const setFavorites = favorites => ({
    type: SET_FAVORITES,
    payload: favorites
});

export const setPropertyId = propertyId => ({
    type: SET_PROPERTY_ID,
    payload: propertyId 
});

export const setCurrentPage = page => ({
    type: SET_PAGES_FAVORITES,
    payload: page 
});

export const setFavoritesTotal = total => ({
    type: SET_FAVORITES_TOTAL,
    payload: total 
});

export const setLoadingFavorites = value => ({
    type: SET_LOADING_FAVORITES,
    payload: value 
});