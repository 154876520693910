import React from "react";
import "../../styles/input/login-msg.css";
import User_Warn_Icon from "../../../../images/user_warn.png";
import { Link, useLocation } from "react-router-dom";
import userManager from "../../../../utils/user-manager";

const UserLoginBox = () => {
    const location = useLocation();
    const handleRedirect = (e) => {
        e.preventDefault();
        userManager.signinRedirect({ state: { redirectTo: location.pathname } });
    };
    const fullBorder = window.innerWidth >= 390 && window.innerWidth <= 991;
    const sideBorders = window.innerWidth >= 390 && window.innerWidth <= 991;


    return (
        <>
            <div className="login-msg-container container">
                <div className={fullBorder ? "row login-msg-full-border" : "row"}>
                    <div className={sideBorders ? "col-xs-1 col-sm-12 col-md-12 col-lg-1 p-3 login-msg-color" : "col-xs-1 col-sm-12 col-md-12 col-lg-1 p-3 login-msg-color login-msg-border-left"}>
                        <div className="d-flex justify-content-center">
                            <img src={User_Warn_Icon} alt="user login icon" />
                        </div>
                    </div>
                    <div className={sideBorders ? "col-xs-1 col-sm-12 col-md-12 col-lg-9 p-3 login-msg-color" : "col-xs-1 col-sm-12 col-md-12 col-lg-9 p-3 login-msg-color login-msg-border-right"}>
                        <div className="row quick-request-disabled-text-box">
                            <span className="login-msg-text">
                                Our talented and responsive specialists are ready to assist you every step. All you need to start this way is our Oasis Account. Please Sign In or Register to receive the best options for yourself.
                            </span>
                        </div>
                        <div className="d-flex">
                            <Link to="/login" onClick={handleRedirect} className="quick-request-login-btn">
                                Sign-In
                            </Link>
                            <Link to="/account/create" className="mx-2 quick-request-register-btn">Register</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserLoginBox;