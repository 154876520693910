import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import DefaultAvatar from "../../../images/default-avatar.png";
import 'devextreme/dist/css/dx.light.css';
import { Button } from 'devextreme-react/button';
import ActionButton from '../../assets/elements/button/action-button'
import SecondaryButton from '../../assets/elements/button/secondary-action-button'

import baseService from "../../../services/base.service";
import { INPUT_LABELS } from "../../../constant/editPhotoModal";
import notify from 'devextreme/ui/notify';

const EditPhotoModal = ({ onClose, id, profileImage }) => {
    const [showModal, setShowModal] = useState(true);
    const [showOverlay, setShowOverlay] = useState(true);
    const [selectedImage, setSelectedImage] = useState(profileImage || DefaultAvatar);
    const [selectedFile, setSelectedFile] = useState(null);
    const [hasImageChanged, setHasImageChanged] = useState(false);

    const closeModal = () => {
        setShowModal(!showModal);
        setShowOverlay(!showOverlay);
        onClose();
    };

    const reloadPage = () => window.location.reload();

    const isMaxFileSizeValid = () => {
        const imageSize = selectedFile.size;
        if (imageSize > INPUT_LABELS.images.maxFileSize) {
            notify(INPUT_LABELS.images.invalidFileSizeMessage, "error", 3000);
            return false;
        }
        return true;
    }

    const savePhoto = async () => {
        if (!hasImageChanged) {
            closeModal();
            return;
        };

        if (!isMaxFileSizeValid())
            return;

        const formData = new FormData();
        formData.append("logo", selectedFile);

        await baseService({
            method: "PUT",
            baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
            url: `/api/v1/accounts/${id}/logo`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        }).then(res => {
            closeModal();
            reloadPage();
            return res;
        }).catch(res => {
            return res;
        });
    };

    const handleImageChange = (e) => {
        const selected = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(selected);
        setSelectedFile(selected);
        setHasImageChanged(true);
    };

    return (
        <div className="user-form__container__modal">
            <div className="user-form__container__modal__title">Edit Photo</div>
            <div className="user-form__container__modal__body">
                <div className="user-form__container__modal__body_img">
                    {selectedImage ? (
                        <img src={selectedImage} alt="Avatar" />
                    ) : (
                        <img src={DefaultAvatar} alt="Avatar" />
                    )}
                </div>
                <input type="file" onChange={handleImageChange} accept="image/*"/>
                <div className="user-form__container__modal__body_description">
                    Photos cannot be more than 7mb in size. Only, JPG, GIF and PNG image types are allowed. You will need to refresh the page to see the new avatar.
                </div>
            </div>
            <div className="user-form__container__modal__footer">
                <ActionButton action={savePhoto} disableSubmit={true}>Save</ActionButton>
                <SecondaryButton action={closeModal}>Cancel</SecondaryButton>
                {/* <Button text="Save" className="custom-modal-save-btn" onClick={savePhoto} /> */}
                {/* <Button text="Cancel" className="custom-modal-cancel-btn" onClick={closeModal} /> */}
            </div>
            {/* <section className="edit_photo_modal" style={{ display: showOverlay ? "block" : "none" }}>
                <div className="modal show" style={{ display: showModal ? "block" : "none", position: "initial" }}>
                    <Modal.Dialog>
                        <Modal.Header>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="custom-modal-image-container__outer-layer">
                                <div className="custom-modal-image-container__inner-layer">
                                    {selectedImage ? (
                                        <img src={selectedImage} alt="Avatar" />
                                    ) : (
                                        <img src={DefaultAvatar} alt="Avatar" />
                                    )}
                                </div>
                            </div>
                            <input type="file" onChange={handleImageChange} />
                            <p className="mt-3">
                                Photos cannot be more than 7mb in size. Only, JPG, GIF and PNG image types are allowed. You will need to refresh the page to see the new avatar.
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button text="Save" className="custom-modal-save-btn" onClick={savePhoto} />
                            <Button text="Cancel" className="custom-modal-cancel-btn" onClick={closeModal} />
                        </Modal.Footer>
                    </Modal.Dialog>
                </div>
            </section> */}
        </div>
        
    );
};

export default EditPhotoModal;