import {useEffect} from 'react'
//components
import NoPhoto from '../../../../images/no_property_photo.png'
//styles
import React from '../../styles/map/map-information-card.css'
const MapInformationCard = ({images,name,place,id}) => {
  
  return (
    <div className='map-information'>
        <div>
            {/* IMAGE */}
            {/* <ImagesCarousel images={images}/> */}
           { images ?
              <img src={images} alt="" width={90} height={90}/>
              :
              <img src={NoPhoto} alt="" width={90} height={90}/>
           }
        </div>
        <div className='map-information__description'>
            <div className='map-information__description__title'>{name}</div>
            <div className='map-information__description__subtitle'>{place}</div>
            <a href={`/property/dashboard/${id}`} target='_blank'>See Details</a>
        </div>
    </div>
  )
}

export default MapInformationCard