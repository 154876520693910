import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import authReducer from './auth';
import dictionaryReducer from './dictionary';
import landingReducer from './landing'
import searchReducer from './property-search'
import forgotResetReducer from './forgot-reset'
import favoritesReducer from './favorites'
import careerReducer from './career'
import propertyDetails from './property-details'
import listAProperty from './list-a-property'
import becomeAPartner from './become-a-partner';
import faqReducer from './faq'
import newsReducer from './news';
import formDataReducer from './create-account';
import userFormReducer from './user-form';
import myRequestReducer from './my-requests'
import verifyAccountReducer from './verify-account';
import propertyRequestReducer from './property-request';
import quickRequestReducer from "./quick-request";
import loadIndicatorReducer from './load-indicator';
import receivedRequests from './received-requests'
import basketReducer from './my-basket'
import transactionIntegrationReducer from './transaction-integration'

const reducer = combineReducers(
    {
        auth: authReducer,
        routing: routerReducer,
        landing: landingReducer,
        search: searchReducer,
        faq: faqReducer,
        news: newsReducer,
        formData: formDataReducer,
        userForm: userFormReducer,
        details: propertyDetails,
        verifyAccount: verifyAccountReducer,
        listProperty: listAProperty,
        requests: myRequestReducer,
        propertyRequest: propertyRequestReducer,
        quickRequest: quickRequestReducer,
        becomeAPartner: becomeAPartner,
        career: careerReducer,
        loadIndicator: loadIndicatorReducer,
        receivedRequests: receivedRequests,
        dictionary: dictionaryReducer,
        favorites: favoritesReducer,
        basket: basketReducer,
        forgotReset: forgotResetReducer,
        transactions: transactionIntegrationReducer
    }
);

export default reducer;