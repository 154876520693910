
import {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useNavigationType} from 'react-router-dom'
//components
import Card from '../../../assets/controls/property-card'
import NotLoggedMessage from '../../../assets/elements/not_logged_message'

//styles
import '../../styles/property-cards.css'

//actions
import {setPropertyFromList} from '../../../../actions/property-search'

//constant
import {SET_NOT_LOGGED} from '../../../../constant/favorites'

//utils
import {postFavorite,deleteFavorite,getFavorites} from '../../../../utils/favorites'
import {isLoggedIn} from '../../../../utils/user'

const PropertyCards = ({cardType,filterData,reference,selectedPropertyFromMap,refList,setPropertyFromList}) => {
    const browserHistoryType = useNavigationType()
    const [isWarningVisible, setIsWarningVisible] = useState(false)
    const [resetWarningVisible,setResetWarningVisible] = useState(false)
    const [properties, setProperties] = useState([])
    
    const onHover = id =>{
        setPropertyFromList(id)
    }
    const likeAProperty = async (propertyId,metisId) => {
      if(isLoggedIn())
      {
        postFavorite(propertyId,metisId)
      }
      else{
        setResetWarningVisible(!resetWarningVisible)
        setIsWarningVisible(true)
      }
    }
    const unLikeAProperty = async id => {
      if(isLoggedIn())
      {
        deleteFavorite(id)
      }
      else{
        setResetWarningVisible(!resetWarningVisible)
        setIsWarningVisible(true)
      }
    }

    const setFavorites = async (data) => {
        let dataFavorites = await getFavorites()
        let favoriteIds = dataFavorites.map(item => item.id)
        let formattedData = data.map(property=>{
          let likeId = dataFavorites.filter(item => item.propertyId === property.id)
          return {
            ...property,
              isLiked: favoriteIds.includes(property.id),
              likeId: likeId[0]?.id,
          }
        })
        setProperties(formattedData)
    }
    
    useEffect(() => {
        if(selectedPropertyFromMap)
        {
            window.scrollTo(0,0)
            refList.current.scrollTop = 0
        }    
    }, [selectedPropertyFromMap])
    
    useEffect(() => {
        let formattedProperties
        if(selectedPropertyFromMap)
        {
            const propertyFounded = filterData.filter(item => item.id === selectedPropertyFromMap)
            formattedProperties = filterData.filter(item => item.id !== selectedPropertyFromMap)
            propertyFounded.length > 0 && formattedProperties.unshift(propertyFounded[0])
            // setProperties(formattedProperties)
        }
        else
        {
            formattedProperties = filterData
            // setProperties(filterData)
        }
        setProperties(formattedProperties)
    }, [selectedPropertyFromMap,filterData])
    useEffect(() => {
      if(filterData.length > 0 && browserHistoryType !== 'PUSH')
      {
        setFavorites(filterData)
      }
    }, [])
    
  return (
    <>
      <div className={`property-cards-container ${cardType === 'square' ? 'property-cards-container--square' : ''}`}> 
          {
              properties.length > 0 && properties.map((item,index) => 
              properties.length === index+1 ?
              <Card gallery={true} cardType={cardType} reference={reference} data={item} key={index} isSelected={item.id === selectedPropertyFromMap} onHover={onHover} likeAProperty={likeAProperty} unLikeAProperty={unLikeAProperty} addToCartPopup={true}/>
              :
              <Card gallery={true} cardType={cardType} data={item} key={index} isSelected={item.id === selectedPropertyFromMap} onHover={onHover} likeAProperty={likeAProperty} unLikeAProperty={unLikeAProperty} addToCartPopup={true}/>
              ) 
          }
      </div>
      <NotLoggedMessage 
        message='to add property in Favourite List'
        visible={isWarningVisible}
        setVisible={setIsWarningVisible}
        resetTrigger={resetWarningVisible}
      />
    </>
      
  )
}
function mapStateToProps({ search }) {
    return {
        selectedPropertyFromMap:search.selectedPropertyFromMap,
        cardType: search.cardType
    };
  }
  function mapDispatchToProps(dispatch) {
    return {
      setPropertyFromList: id => dispatch(setPropertyFromList(id))
    };
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(PropertyCards)