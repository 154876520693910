import React from "react";
import "../styles/admin-article-modal-styles.css";
import Form, { GroupItem, SimpleItem, Label, RequiredRule } from "devextreme-react/form";
import Validator from "devextreme-react/validator";
import TextBox from "devextreme-react/text-box";
import FileUploader from "devextreme-react/file-uploader";
import { Button } from "devextreme-react/button";
import baseService from "../../../services/base.service";
import CustomEditor from '../../assets/elements/input/custom-html-editor'

const AdminArticleModal = ({ onClose, onArticleCreated }) => {
    const [Title, setTitle] = React.useState("");
    const [TextOfNews, setTextOfNews] = React.useState("");
    const [Photo, setPhoto] = React.useState(null);
    const formRef = React.useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault()
        const UserProfile = JSON.parse(localStorage.getItem('ODYSSEY.USER.AUTHENTICATION'));
        const UserProfileId = UserProfile?.profile?.sub;
        const formInstance = formRef.current.instance;
        const validationResult = formInstance.validate();
        if (!validationResult.isValid) return;

        const formData = new FormData();
        formData.append('Title', Title);
        formData.append('TextOfNews', TextOfNews);
        formData.append('Photo', Photo);
        formData.append('UserProfileId', UserProfileId);

        await baseService({
            method: "POST",
            baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
            url: "/api/v1/news",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "Authorization": `Bearer ${UserProfile.token}`,
            },
            withCredentials: true,
        }).then((response) => {
            if (response.status === 200) {
                onArticleCreated(response.data.data);
                onClose();
                return response;
            }
            return response;
        }).catch((res) => { return res; });
    };

    const handleFileUpload = (e) => {
        const file = e.value[0];
        file ? setPhoto(file) : setPhoto(null);
    };

    return (
        <>
            <div className="admin-article-modal-overlay">
                <div className="admin-article-modal">
                    <div className="container">
                        <div className="row">
                            <h4 className="text-center text-capitalize mt-5">add news</h4>
                        </div>
                        <div className="row">
                            <form onSubmit={handleSubmit}>
                                <Form ref={formRef} className="form-padding">
                                    <GroupItem>
                                        <SimpleItem cssClass=" modal-label" isRequired={true}>
                                            <Label text="News Title" />
                                            <TextBox className="w-100" value={Title} onValueChanged={(e) => setTitle(e.value)}>
                                                <Validator>
                                                    <RequiredRule message="News Title is required" />
                                                </Validator>
                                            </TextBox>
                                        </SimpleItem>
                                        <SimpleItem cssClass="modal-label mb-5" isRequired={false}>
                                            <Label text="Upload Picture" />
                                            <FileUploader
                                                className="file-uploader" multiple={false} accept="image/*"
                                                uploadMode="useForm"
                                                onValueChanged={handleFileUpload}
                                            ></FileUploader>
                                        </SimpleItem>
                                        <SimpleItem cssClass="modal-label" isRequired={true}>
                                            <Label text="Text of News" />
                                            <CustomEditor value={TextOfNews} setValue={setTextOfNews}
                                                requiredValue={true} messageName='Text of News' height={250} />
                                        </SimpleItem>
                                    </GroupItem>
                                    <GroupItem>
                                        <SimpleItem cssClass="mt-3">
                                            <Button className="oasis-btn" text="Add News" useSubmitBehavior={true} />
                                            <Button className="oasis-cancel-btn" text="Cancel" onClick={onClose} />
                                        </SimpleItem>
                                    </GroupItem>
                                </Form>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminArticleModal;