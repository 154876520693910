export const SET_LOADING_QUICK_REQUEST = "SET_LOADING_QUICK_REQUEST";
export const SET_DEFAULT_QUICK_REQUEST = "SET_DEFAULT_QUICK_REQUEST"
export const SET_CLEAR_LOCATION_FIELD_QUICK_REQUEST = "SET_CLEAR_LOCATION_FIELD_QUICK_REQUEST"

//FIELDS
export const SET_LOCATION_QUICK_REQUEST = "SET_LOCATION_QUICK_REQUEST"

export const SET_MILES_QUICK_REQUEST = "SET_MILES_QUICK_REQUEST"
export const SET_FLOOR_PLAN_QUICK_REQUEST = "SET_FLOOR_PLAN_QUICK_REQUEST"
export const SET_CURRENCY_TYPE_QUICK_REQUEST = "SET_CURRENCY_TYPE_QUICK_REQUEST"
export const SET_CURRENCY_ERROR_QUICK_REQUEST = "SET_CURRENCY_ERROR_QUICK_REQUEST"
export const SET_BUILDING_TYPES_QUICK_REQUEST = "SET_BUILDING_TYPES_QUICK_REQUEST"
export const SET_NOTES_QUICK_REQUEST = "SET_NOTES_QUICK_REQUEST"

export const SET_BUDGET_MIN_QUICK_REQUEST = "SET_BUDGET_MIN_QUICK_REQUEST"
export const SET_BUDGET_MAX_QUICK_REQUEST = "SET_BUDGET_MAX_QUICK_REQUEST"

export const SET_QTY_ADULTS_QUICK_REQUEST = "SET_QTY_ADULTS_QUICK_REQUEST"
export const SET_QTY_CHILDREN_QUICK_REQUEST = "SET_QTY_CHILDREN_QUICK_REQUEST"

export const SET_IS_PETS_QUICK_REQUEST = "SET_IS_PETS_QUICK_REQUEST"
export const SET_QTY_PETS_QUICK_REQUEST = "SET_QTY_PETS_QUICK_REQUEST"
export const SET_PET_DETAILS_QUICK_REQUEST = "SET_PET_DETAILS_QUICK_REQUEST"

export const SET_MOVE_IN_QUICK_REQUEST = "SET_MOVE_IN_QUICK_REQUEST"
export const SET_MOVE_OUT_QUICK_REQUEST = "SET_MOVE_OUT_QUICK_REQUEST"
export const SET_DATE_RANGE_QUICK_REQUEST = "SET_DATE_RANGE_QUICK_REQUEST"


export const SET_SELECTED_GUEST_ID_QUICK_REQUEST = "SET_SELECTED_GUEST_ID_QUICK_REQUEST"
export const SET_GUEST_USER_QUICK_REQUEST = "SET_GUEST_USER_QUICK_REQUEST"
export const SET_GUEST_USER_EMPTY_QUICK_REQUEST = "SET_GUEST_USER_EMPTY_QUICK_REQUEST"
export const SET_GUEST_PHONE_QUICK_REQUEST = "SET_GUEST_PHONE_QUICK_REQUEST"
export const SET_IS_GUEST_PHONE_VALID_STATE_QUICK_REQUEST = "SET_IS_GUEST_PHONE_VALID_STATE_QUICK_REQUEST"
export const SET_IS_GUEST_PHONE_VALID_MESSAGE_QUICK_REQUEST = "SET_IS_GUEST_PHONE_VALID_MESSAGE_QUICK_REQUEST"
export const SET_GUEST_EMAIL_QUICK_REQUEST = "SET_GUEST_EMAIL_QUICK_REQUEST"
export const SET_IS_EXISTING_USER_QUICK_REQUEST = "SET_IS_EXISTING_USER_QUICK_REQUEST"
export const SET_IS_GUEST_CHECKBOX_SELECTED_QUICK_REQUEST = "SET_IS_GUEST_CHECKBOX_SELECTED_QUICK_REQUEST"
export const SET_DATA_KEYS_AMENITIES_QUICK_REQUEST = "SET_DATA_KEYS_AMENITIES_QUICK_REQUEST"
export const SET_SELECTED_DATA_AMENITIES_QUICK_REQUEST = "SET_SELECTED_DATA_AMENITIES_QUICK_REQUEST"

export const SET_MAID_SERVICE_QUICK_REQUEST = "SET_MAID_SERVICE_QUICK_REQUEST"
export const SET_PARKING_SLOTS_QUICK_REQUEST = "SET_PARKING_SLOTS_QUICK_REQUEST"