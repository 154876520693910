import React from 'react'
import CreateAccountForm from './create-account/CreateAccount'


const createAccount = () => {
    return (
        <CreateAccountForm />
    )
}

export default createAccount