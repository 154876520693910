import React, { useEffect } from "react";
import "./create-account/styles/layer-mask-styles.css";
import { connect } from "react-redux";

const RegisteredAccount = ({ email }) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <div className="layer-mask">
            <div className="msg-container-success">
                <div className="msg-box">
                    <div className="msg-box-header">
                        <h3 className="text-center fw-bold">Congratulations!</h3>
                    </div>
                    <div className="msg-box-body text-center">
                        <p className="fw-bold">Your account has been successfully created!</p>
                        <p>
                            So, to continue working, verify your email address&nbsp;
                            <span className="fw-bold">{email}</span>&nbsp;and activate your account.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        email: state.formData.email,
    };
}

export default connect(mapStateToProps)(RegisteredAccount);