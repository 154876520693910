const faqData = [
    {
        "id": "1",
        "question": "Are Pets Welcome?",
        "answer": "Oasis loves pets! Please let us know if your animals will travel with you as communities often maintain various pet policies due to local rules and regulations."
    },
    {
        "id": "2",
        "question": "What Is Included In An Oasis Apartment?",
        "html": "<div class=\"p-3\"><p class=\"text-dark lead\">Included in your daily rate is:<\/p><p class=\"text-dark lead fw-bold\">All Furniture:<\/p><div class=\"row\"><div class=\"col d-flex list\"><ul><li class=\"text-dark lead\">Kitchen<\/li><li class=\"text-dark lead\">Living Room<\/li><li class=\"text-dark lead\">Dining Room<\/li><\/ul><ul class=\"mx-5\"><li class=\"text-dark lead\">Bedroom(s)<\/li><li class=\"text-dark lead\">Bathroom(s)<\/li><\/ul><ul class=\"mx-5\"><li class=\"text-dark lead\">Decor<\/li><li class=\"text-dark lead\">Linens<\/li><li class=\"text-dark lead\">Housewares<\/li><\/ul><\/div><\/div><p class=\"text-dark lead fw-bold\">All Utilities:<\/p><div class=\"row\"><div class=\"col d-flex list\"><ul><li class=\"text-dark lead\">Water<\/li><li class=\"text-dark lead\">Gas<\/li><li class=\"text-dark lead\">Electric<\/li><\/ul><ul class=\"mx-5\"><li class=\"text-dark lead mx-5\">Phone Services<\/li><li class=\"text-dark lead mx-5\">Digital Cable<\/li><li class=\"text-dark lead mx-5\">High Speed Internet<\/li><\/ul><\/div><\/div><\/div>",
        "answer": "In addition to these amenities, you may request any of our various upgrades at any point in time. These include but are not limited to expanded cable packages including HBO and additional furnishings such as work desk and chair.\nIt is our mission to make our guests feel at home. Please let us know if you request is not on the above aforementioned list. Oasis provides hospitality that goes above and beyond a list."
    },
    {
        "id": "3",
        "question": "How Do I Make A Reservation?",
        "answer": "You can call an Oasis representative today to help make a reservation at 1-800 578-0296 or email us at info@oasiscorporatehousing.com"
    },
    {
        "id": "4",
        "question": "What Types Of Housing Does Oasis Offer?",
        "answer": "Oasis offers a wide varierty of housing options ranging from hotels, apartments, town homes, and single-family homes."
    },
    {
        "id": "5",
        "question": "Where Are Oasis Apartments Located?",
        "answer": "We have accommodations around the world! That's right, Oasis has a global presence. If you are unable to locate a particular destination please contact us."
    },
    {
        "id": "6",
        "question": "Do I Have To Pay For Utilities?",
        "answer": "No, all utilities are covered by Oasis. We do, however, put a generous cap on how much utilities we will cover. This is almost never reached and is used to keep use within reason."
    },
    {
        "id": "7",
        "question": "Is Hbo Included In The Rent?",
        "answer": "No, but with a written request we would be more than happy to add extended cable. This typically takes place within a few short business days. We do not charge any premiums on these upgrades and will pass through the exact cost of the service from the service provider."
    },
    {
        "id": "8",
        "question": "How Can You Make Your Property Available For Our Oasis Clients?",
        "answer": "Please select the \"List your Property\" link at the top of our homepage and follow the prompts."
    }
]

export default faqData