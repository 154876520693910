import React from "react";
// import { Button } from "devextreme-react/button";
import ActionButton from '../../assets/elements/button/action-button'
import SecondaryButton from '../../assets/elements/button/secondary-action-button'

const ShowSaveButtons = ({ handleCancel }) => {
    return (
        <>
        <div>
            <ActionButton>Save</ActionButton>
        </div>
            <SecondaryButton action={handleCancel}>Cancel</SecondaryButton>
        </>
    );
};

export default ShowSaveButtons;