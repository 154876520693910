import React from "react";
import "../styles/cta-styles.css";

const CtaVideo = () => {
    return (
        <div className="cta-video">
            <video
                poster={require('../../../../images/landing/video1-poster.png')}
            >
                <source
                    src={require('../../../../images/video/oasis-video--hd-copy.mp4')}
                    type="video/mp4"
                />
            </video>
        </div>
    )
}

export default CtaVideo;