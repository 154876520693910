import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
    const pageStyles = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
        textAlign: "center"
    };

    const linkColor = {
        color: "#008da9"
    };

    return (
        <>
            <div style={pageStyles}>
                <h1 className="mb-3">Page Not Found!</h1>
                <p className="mb-3">Sorry, the page you are looking for does not exist.</p>
                <Link to="/" style={linkColor}>Go Back To Home Page</Link>
            </div>
        </>
    );
};

export default NotFoundPage;