const {
    REACT_APP_STS_CLIENT_ID,
    REACT_APP_STS_AUTHORITY,
} = process.env;

const getIsUser = _ => {
    return JSON.parse(localStorage.getItem('ODYSSEY.USER.AUTHENTICATION'))?.profile?.role === 'Odyssey.User'
}

const isLoggedIn = _ => {
    const user = JSON.parse(localStorage.getItem(`oidc.user:${REACT_APP_STS_AUTHORITY}:${REACT_APP_STS_CLIENT_ID}`));
	

    return user != null && new Date(user.expires_at * 1000) > new Date(); 
}
const getUserId = _ =>{
    return JSON.parse(localStorage.getItem('ODYSSEY.USER.AUTHENTICATION'))?.profile?.sub
}
export { getIsUser, isLoggedIn,getUserId }