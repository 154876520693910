import {useState,useEffect} from 'react'
import './styles/career-table.css'
import EyeIcon from '../../images/admin-icons/eye-icon.svg'
import PencilIcon from '../../images/admin-icons/pencil-icon.svg'
import {ReactComponent as TrashIcon} from '../../images/admin-icons/trash-icon.svg'

import {Link} from 'react-router-dom'
const CareerTable = ({headers,content,haveActions, action}) => {
    const [columnsStyle, setcolumnsStyle] = useState('')
    useEffect(() => {
            const amountColumns = headers.length + (haveActions ? 1 : 0)
            let columnGrid = ''
            for(let i = 0 ; i < amountColumns; i++)
            {
                columnGrid = columnGrid + ' 1fr'
            }
            setcolumnsStyle(columnGrid)
    }, [])
    
  return (
    <div className='career-list__table'>
        <div className='career-list__table__header' style={{gridTemplateColumns: columnsStyle}}>
            {headers.map((header,index)=>
                <div key={index}>{header}</div>
            )}
            {haveActions && 
                <div>Actions</div>
            }
        </div>
        <div className='career-list__table__content'>
            {content.map((position,index) =>
                <div key={index} className='career-list__table__content__row' style={{gridTemplateColumns: columnsStyle}}>
                    <div className='career-list__table__content__column'><Link to={`/careers/details/${position.id}`}>{position.jobTitle}</Link></div>  
                    <div className='career-list__table__content__column'>{position.location}</div> 
                    {haveActions && 
                        <div className='career-list__table__content__column career-list__table__content__column--actions'>
                            <span onClick={()=>action('view',position.id)}><img src={EyeIcon}/> View</span> 
                            <span onClick={()=>action('edit',position.id)}> <img src={PencilIcon}/> Edit</span> 
                            <span onClick={()=>action('delete',position.id)} className='career-list__table__content__column--actions__delete'><TrashIcon/> Delete</span>
                        </div>
                    }
                </div>
            )}
        </div>
    </div>
  )
}

export default CareerTable