import React,{useEffect,useState,useRef,useCallback} from 'react'
import {connect} from 'react-redux'

//components
import PropertyCards from './Sub-Components/property-cards'
import Loading from '../../assets/controls/loading'
import Message from '../../assets/elements/message'

//actions
import {setFilterProperties,changeLoading,changePages} from '../../../actions/property-search'

//constant
import {DEFAULT_VALUES} from '../../../constant/pagination'
import {MESSAGE_ERROR} from '../../../constant/messages'

//utils
import {getSearchProperties} from '../../../utils/search-properties'

const PropertyData = ({changePages,pages,filterTotalProperties,filterPropertyData,loading,message,refList}) => {
 
  const [allPropertiesLoaded,setAllPropertiesLoaded] = useState(true)
  const observer = useRef()
  const lastProperty = useCallback(element=>{
    if (loading)
      return

    if(observer.current)
      observer.current.disconnect()

    observer.current = new IntersectionObserver(entries=>{
      if(entries[0].isIntersecting && allPropertiesLoaded && message !== MESSAGE_ERROR)
      {
        changePages(pages.skip+DEFAULT_VALUES.take,DEFAULT_VALUES.take)
      }  
    })

    if(element)
      observer.current.observe(element)
  },[loading,allPropertiesLoaded])
  
  useEffect(() => {
    if(pages.skip >= DEFAULT_VALUES.take)
      getSearchProperties(pages.skip,pages.take)
  }, [pages])  

  useEffect(() => {
    setAllPropertiesLoaded(!(filterPropertyData.length === filterTotalProperties))
  }, [filterPropertyData])
  
return (
    <div> 
        <Loading isVisble={loading && pages.skip === 0}/>
        {(!loading || pages.skip >= DEFAULT_VALUES.take) && filterPropertyData.length > 0 && <PropertyCards filterData={filterPropertyData} reference={lastProperty} refList={refList}/>}
        {/* {!loading && filterPropertyData.length <= 0 && filterTotalProperties <= 0 && <Message>{message}</Message>} */}
        {!loading && message && <Message>{message}</Message>}
        <Loading isVisble={loading && pages.skip >= DEFAULT_VALUES.take}/>
    </div>
  )
}
function mapStateToProps({ search }) {
  return {
      pages: search.pages,
      // geometry: search.geometry,
      miles: search.miles,
      checkIn: search.checkIn,
      checkOut: search.checkOut,
      filterTotalProperties: search.filterTotalProperties,
      filterPropertyData: search.filterProperties,
      loading:search.loading,
      message: search.message
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setFilterProperties: data => dispatch(setFilterProperties(data)),
    changeLoading: value => dispatch(changeLoading(value)),
    changePages: (skip,take) => dispatch(changePages(skip,take)),
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(PropertyData)