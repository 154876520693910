import React from "react";
import "../components/property-request/styles/property-request-styles.css";
import { Link } from "react-router-dom";

const PropertyRequestSuccessMessage = () => {
    const scrollToTop = () => window.scrollTo(0, 0);

    React.useEffect(() => scrollToTop());

    return (
        <>
            <div className="property-request__success-message">
                <div className="property-request__success-message-container">
                    <h4 className="text-center mt-2 mb-2">Request Successfully Sent!</h4>
                    <p className="text-center">
                        We will contact you as soon as possible!
                    </p>
                    <Link to="/property-search" className="success-message-color">Back to the Search Page</Link>
                    <Link to="/" className="success-message-color mb-3">Go to the Main Page</Link>
                </div>
            </div>
        </>
    );
};

export default PropertyRequestSuccessMessage;