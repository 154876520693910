import React from "react";
import "./quick-request/styles/quick-request-success-styles.css";

const QuickRequestSuccessMessage = () => {
    const emailAddress = "john.doe@gmail.com";

    return (
        <>
            <div className="layer-mask__quick-request">
                <div className="msg-container-success__quick-request">
                    <div className="d-flex justify-content-center align-items-center m-0 p-0">
                        <h3 className="text-center fw-bold m-0 p-0 mt-2" style={{ fontSize: "18px" }}>
                            Congratulations!
                        </h3>
                    </div>
                    <div className="d-flex justify-content-center align-items-center m-0 p-0">
                        <div className="text-center">
                            <p className="fw-bold m-0 p-0">Your Quick Request has been successfully created!</p>
                            <p>So, to continue working, please verify your email address <span className="fw-bold">{emailAddress}</span> and<br /> activate your account</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuickRequestSuccessMessage;