import React from "react";
import "../styles/CustomImagePreview.css";

const CustomImagePreview = ({ photoPreview, removePhoto, fileName }) => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const textMobile = windowWidth >= 320 && windowWidth <= 567 ? "image-preview text-lowercase" : "image-preview mx-2 text-lowercase";

    return (
        <div className="image-preview-container container">
            <div className="image-preview-container__inner">
                <div className="image-preview__container">
                    <img src={`${process.env.REACT_APP_API_ENDPOINT}/api/v1/files/${photoPreview}`}
                        alt="preview" className="image-preview__img" />
                </div>
                <span className="image-preview__text pointer" onClick={removePhoto}>
                    <span className={textMobile}>{fileName}</span>
                    <span className="text-oasis-danger">&times;</span>
                </span>
            </div>
        </div>
    );
};

export default CustomImagePreview;