import {useState,useEffect} from 'react'
import {connect} from 'react-redux'

//components
import Pagination from '../../assets/controls/pagination'
import Loading from '../../assets/controls/loading'
import Alert from '../../assets/elements/custom-alert'
import CareerTable from '../career-table'

//constants
import {CAREERS_LIST,PAGINATION} from '../../../constant/career'

//utils
import {getVacancies} from '../../../utils/career'

//actions
import {setDefaultCareerList,setCareerListPaginationCurrent,setCareerListPaginationTotal} from '../../../actions/career'

const CareerListAdminActive = ({action,loading,careerList,careerListPaginationTotal,careerListPaginationCurrent,careerListTotal,setDefaultCareerList,setCareerListPaginationCurrent,setCareerListPaginationTotal,message}) => {
  useEffect(() => {
    getVacancies((careerListPaginationCurrent-1)*PAGINATION.take)
  }, [careerListPaginationCurrent])
  useEffect(() => {
    if(careerListTotal > 0)
    {
      setCareerListPaginationTotal(Math.ceil(careerListTotal/PAGINATION.take))
    }
  }, [careerListTotal])
  useEffect(() => {
    return () => {
      setDefaultCareerList()
    }
  }, [])
  return (
    <div>
        <div className={`mb-2 ${careerListTotal > 0 ? 'career-list__pagination' : 'career-list__pagination--center'}`}>
          {careerListTotal > 0 &&
            <Pagination totalPages={careerListPaginationTotal} currentPage={careerListPaginationCurrent} setCurrentPage={setCareerListPaginationCurrent}/>
          }
          <Loading isVisble={loading} size={26}/>
        </div>
        {careerList.length > 0 &&
            <CareerTable headers={CAREERS_LIST.positionHeaders} content={careerList} haveActions={true} action={action}/>
        }
        {message.content && <Alert type={message.error? 'error' : ''}><div className='career-list__message'>{message.content}</div></Alert>}
        
    </div>
  )
}
function mapStateToProps({ career }) {
  return {
    careerList: career.careerList,
    careerListPaginationTotal: career.careerListPagination.totalPages,
    careerListPaginationCurrent: career.careerListPagination.currentPage,
    careerListTotal: career.careerListTotal,
    loading: career.loadingCareerList,
    message: career.messageCareerList
  };
}
function mapDispatchToProps(dispatch){
  return{
      setDefaultCareerList: () => dispatch(setDefaultCareerList()),
      setCareerListPaginationTotal: data => dispatch(setCareerListPaginationTotal(data)),
      setCareerListPaginationCurrent: data => dispatch(setCareerListPaginationCurrent(data))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(CareerListAdminActive)