import {connect} from 'react-redux'

//styles
import "./styles/my-requests-styles.css";

//components
import RequestTabs from "./Sub-Components/RequestTabs";
import Loading from '../assets/controls/loading'

const MyRequestsComponent = ({loading}) => {
    return (
        <section className="my-requests">
            <div className="container-fluid" style={{ width: "88%", height: "auto" }}>
                <div className='row'>
                    <h4 className="text-uppercase fw-bold mt-4 request-title"><span style={{display: 'flex', alignItems: 'center', gap:'5px'}}>my request list <Loading isVisble={loading} size={30}/></span></h4>
                </div>
                <div style={{marginTop: '10px'}}>
                    <RequestTabs />
                </div>
            </div>
        </section>
    );
};

function mapStateToProps({ requests }) {
    return {
        loading: requests.loading
    };
  }

export default connect(mapStateToProps)(MyRequestsComponent)