export const SET_ALL_REQUESTS = 'SET_ALL_MESSAGE_REQUESTS'
export const SET_REQUESTS_DATA = 'SET_REQUESTS_DATA'
export const SET_PREVIOUS_REQUESTS_URL = 'SET_PREVIOUS_REQUESTS_URL'
export const SET_ALL_REQUESTS_IS_LOADED = 'SET_ALL_REQUESTS_IS_LOADED'
export const SET_REQUESTS_LOADING = 'SET_REQUESTS_LOADING'
export const SET_REQUESTS_MESSAGE_NO_DATA = 'SET_REQUESTS_MESSAGE_NO_DATA'

export const REQUEST_TYPES = {
    'contact-us': {
        title: 'CONTACT US RECEIVED REQUESTS',
        path: 'contactus',
        totalActive: 'numberOfContactUsMessages'
    },
    'list-your-properties': {
        title: 'LIST YOUR PROPERTY RECEIVED REQUESTS',
        path: 'share-properties',
        totalActive: 'numberOfListAPropertyMessages'
    },
    'became-a-partner': {
        title: 'BECAME A PARTNER RECEIVED REQUESTS',
        path: 'request-partnership',
        totalActive: 'numberOfBecameAPartnerMessages'
    },
    'request-a-property': {
        title: 'REQUEST A PROPERTY RECEIVED REQUESTS',
        path: 'quotes',
        totalActive: 'numberOfRequestAPropertyMessages'
    }
    ,
    'careers': {
        title: 'CAREER RECEIVED REQUESTS',
        path: 'vacancies/Candidates',
        totalActive: 'numberOfVacanciesApplications'
    }
}

export const PAGINATION_DEFAULT = {
    skip: 0,
    take: 20
}

export const REQUESTS_TABLE = {
    headers: [
        'Date&time',
        'Subject',
        'Actions'
    ]
}



export const NO_DATA = 'No requests found to display'
