/* Form Fields */
export const UPDATE_FORM_EMAIL = 'UPDATE_FORM_EMAIL'
export const UPDATE_FORM_USERNAME = 'UPDATE_FORM_USERNAME'
export const UPDATE_FORM_FIRSTNAME = 'UPDATE_FORM_FIRSTNAME'
export const UPDATE_FORM_LASTNAME = 'UPDATE_FORM_LASTNAME'
export const UPDATE_FORM_PHONENUMBER = 'UPDATE_FORM_PHONENUMBER'
export const UPDATE_FORM_PASSWORD = 'UPDATE_FORM_PASSWORD'
export const UPDATE_FORM_CONFIRM_PASSWORD = 'UPDATE_FORM_CONFIRM_PASSWORD'
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA'
/* Validation Icons Regex */
export const SET_LENGTH_VALID = 'SET_LENGTH_VALID'
export const SET_UPPER_VALID = 'SET_UPPER_VALID'
export const SET_LOWER_VALID = 'SET_LOWER_VALID'
export const SET_NUMBER_VALID = 'SET_NUMBER_VALID'
export const SET_SPECIAL_VALID = 'SET_SPECIAL_VALID'
/* Google Recaptcha */
export const SET_CAPTCHA_CHECKED = 'SET_CAPTCHA_CHECKED'
export const SET_CAPTCHA_ERROR = 'SET_CAPTCHA_ERROR'
export const SET_CAPTCHA_EXPIRED = 'SET_CAPTCHA_EXPIRED'
/* Phone Validation */
export const SET_PHONE_VALID_STATE = 'SET_PHONE_VALID_STATE'
export const SET_PHONE_VALID_MESSAGE = 'SET_PHONE_VALID_MESSAGE'