const FixedScrollbarNav = () => {
    const scrollbar = document.querySelector(".scrollbar-nav");
    const ctaTitle = document.querySelector(".cta-title");
    const pageHeight = window.pageYOffset;

    if (pageHeight >= 694) {
        scrollbar.classList.add("fixed-scrollbar");
        ctaTitle.classList.add("cta-title-p-0");
    } else {
        scrollbar.classList.remove("fixed-scrollbar");
        ctaTitle.classList.remove("cta-title-p-0");
    };
    // eslint-disable-next-line
};

export default FixedScrollbarNav;