import React from 'react';
import NumberBox from 'devextreme-react/number-box';

const TotalChildrenNumberBox = ({ totalChildren, updateTotalChildren }) => {
    const handleValueChange = (e) => {
        const value = parseInt(e.value);
        if (isNaN(value) || value <= 0) {
            e.component.option('value', 0);
            updateTotalChildren(0);
        } else {
            updateTotalChildren(value);
        }
    };

    const handleFocusOut = (e) => {
        const value = parseInt(e.component.option('value'));
        if (isNaN(value) || value <= 0) {
            e.component.option('value', 0);
            updateTotalChildren(0);
        }
    };

    return (
        <NumberBox
            className="w-50"
            defaultValue=""
            value={totalChildren === 0 ? null : totalChildren}
            onValueChanged={handleValueChange}
            onFocusOut={handleFocusOut}
            max={20}
        />
    );
};

export default TotalChildrenNumberBox;