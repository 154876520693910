import {
    VERIFY_LOADING,
    VERIFY_TIME_ELAPSED,
    VERIFY_REQUEST,
    VERIFY_CALL,
} from '../constant/verify-account';

export const verifyLoading = (data) => ({
    type: VERIFY_LOADING,
    payload: data
});

export const verifyTimeElapsed = (data) => ({
    type: VERIFY_TIME_ELAPSED,
    payload: data
});

export const verifyRequest = (data) => ({
    type: VERIFY_REQUEST,
    payload: data
});

export const verifyCall = (data) => ({
    type: VERIFY_CALL,
    payload: data
});