import {useEffect,useState} from 'react'

//components
import PopupGallery from '../../../assets/elements/custom-popup-gallery'

//images
import NoPropertyPhoto from '../../../../images/no_property_photo.png'

//styles
import '../../styles/property-images.css'
const PropertyImages = ({images,propertySize,floorSize}) => {
    const [visible, setVisible] = useState(false)

    const showPopUp = _ => {
      if (images.length > 0) {
        setVisible(!visible)
      }
    }
  return (
    <div className='request-option__property-image'>
      {
        propertySize > 0 ?
         <img onClick={() => showPopUp(0)} className='request-option__property-image__img' src={images[0].imageUrl} alt={images[0].name} />
        :
         <img className='request-option__property-image__img' src={NoPropertyPhoto} alt='no_property_photo.png'/>
      }
      {(propertySize > 1 || floorSize > 0) &&
          <div className='request-option__property-image__more' onClick={() => showPopUp(0)}>
              {propertySize > 1 && <div>+{propertySize} {propertySize > 2 ? 'Photos': 'Photo'}</div>}
              {floorSize > 0 && <div className='request-option__property-image__more--smaller'>+{floorSize} floor plan</div>}
          </div>
      }
      <PopupGallery
          visible={visible}
          onHiding={showPopUp}
          images={images.map(item => item.imageUrl)}
          defaultImage={0}
      />
    </div>
  )
}

export default PropertyImages