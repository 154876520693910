import React from "react";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import ImageCard from "./ImageCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/image-slider-styles.css";
import {
    TowerOfExcellenceAward2016V2,
    TowerOfExcellenceAward2013V2,
    ServicePartnetAwardBestOverAll2015V2,
    ServicePartnetAwardBestOverAll2014V2,
    ServicePartnerAward2016V2,
    ServicePartnerAward2015V2,
    ServicePartnerAward2013V2,
    EmmasAward2015V2,
    EmeaEmmasAward2015V2,
    AmericaEmmasAwardV2
} from "../../../../constant/imageSlider";

const ImageSlider = () => {
    const sliderRef = React.useRef(null);
    const nextSlide = () => sliderRef.current.slickNext();
    const prevSlide = () => sliderRef.current.slickPrev();

    const settings = {
        className: "slider-container",
        dots: false,
        nextArrow: <NextArrow onClick={nextSlide} />,
        prevArrow: <PrevArrow onClick={prevSlide} />,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 5,
        swipeToSlide: true,
        // afterChange: function (index) {
        //     console.log(`Slider Changed to: ${index + 1}`);
        // },
        responsive: [
            {
                breakpoint: 570,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    nextArrow: false,
                    prevArrow: false,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    nextArrow: false,
                    prevArrow: false,
                }
            },
            {
                breakpoint: 1290,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    nextArrow: false,
                    prevArrow: false,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    nextArrow: false,
                    prevArrow: false,
                }
            }
        ]
    };

    return (
        <Slider {...settings} ref={sliderRef}>
            <ImageCard
                image={TowerOfExcellenceAward2016V2}
                title={<>2016 Tower of Excellence Award Company of the Year</>}
                alt={"2016 Tower of Excellence Award"}
            />
            <ImageCard
                image={ServicePartnerAward2016V2}
                title={<>2016 Service Partner Value Award "Being There"</>}
                alt={"2016 Service Partner Value Award"}
            />
            <ImageCard
                image={EmmasAward2015V2}
                title={<>2015 emmas Housing Provider of the Year</>}
                alt={"2015 emmas"}
            />
            <ImageCard
                image={AmericaEmmasAwardV2}
                title={<>2015 The AMERICAS emmas Housing Provider of the Year </>}
                alt={"2015 The AMERICAS emmas"}
            />
            <ImageCard
                image={ServicePartnerAward2015V2}
                title={<>2015 Service Partner Value Award</>}
                alt={"2015 Service Partner Value Award"}
            />
            <ImageCard
                image={EmeaEmmasAward2015V2}
                title={<>2015 The EMEA emmas Housing Provider of the Year</>}
                alt={"2015 The EMEA emmas"}
            />
            <ImageCard
                image={ServicePartnetAwardBestOverAll2015V2}
                title={<>2015 Service Partner Value Award Best Overall - USA Service Partner of the Year</>}
                alt={"2015 Service Partner Value Award"}
            />
            <ImageCard
                image={ServicePartnetAwardBestOverAll2014V2}
                title={<>2014 Service Partner Value Award Best Overall - USA Service Partner of the Year</>}
                alt={"2014 Service Partner Value Award"}
            />
            <ImageCard
                image={TowerOfExcellenceAward2013V2}
                title={<>2013 Tower of Excellence Award Company of the Year</>}
                alt={"2013 Tower of Excellence Award "}
            />
            <ImageCard
                image={ServicePartnerAward2013V2}
                title={<>2013 Service Partner Value Award "Caring"</>}
                alt={"2013 Service Partner Value Award"}
            />
        </Slider>
    );
};

export default ImageSlider;