import React from "react";
import "./styles/news-section-styles.css";
import NewsSectionArticle from "./Sub-Components/NewsSectionArticle";
import baseService from "../../services/base.service";
import { connect } from "react-redux";
import { newsRequest } from "../../utils/news";
import { newsSectionImage } from '../../utils/files'

const NewsComponent = ({ newsData }) => {
    React.useEffect(() => {
        const minTake = 1000;
        async function fetchData() {
            let takeValue = minTake;
            if (Array.isArray(newsData) && newsData.length > 0) {
                takeValue = Math.max(newsData.length, minTake);
            }

            await baseService({
                method: "GET",
                baseURL: process.env.REACT_APP_API_ENDPOINT,
                url: `/api/v1/news?take=${takeValue}`,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(res => {
                newsRequest(res.data.data);
                return res;
            }).catch(res => {
                return res;
            });
        }
        fetchData();
    }, [newsData.length]);

    return (
        <>
            <section className="news-section">
                <div className="news-section-bg-container">
                    <div className="news-section-bg">
                        <div className="news-section-title">
                            <h1 className="text-uppercase">news</h1>
                            <h3 className="mt-3">See What's New.</h3>
                        </div>
                    </div>
                </div>
                <div className="news-section-content">
                    <div className="container-fluid" style={{ width: "88%", height: "auto" }}>
                        <div className="news-section-articles mt-5 mb-5">
                            <>
                                {newsData && newsData.length > 0 ? (
                                    newsData.map((article, index) => (
                                        <NewsSectionArticle
                                            key={index}
                                            id={article.id}
                                            image={newsSectionImage(article.photoId)}
                                            alt={"news-article-image"}
                                            title={article.title}
                                            date={new Intl.DateTimeFormat('en-US', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                            }).format(new Date(article.publicationDate))}
                                            time={new Date().toLocaleTimeString()}
                                            author={article.addedBy}
                                            summary={article.textOfNews}
                                        />
                                    ))
                                ) : null}
                            </>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        newsData: state.news.newsData,
    };
};

export default connect(mapStateToProps)(NewsComponent);