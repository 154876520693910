import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { APPLICATION_STATE } from '../constant/session'

const SafeRoute = () => {
	var location = useLocation();
	var isInRunning = sessionStorage.getItem(APPLICATION_STATE) === null || sessionStorage.getItem(APPLICATION_STATE) === 'IsRunning';

	return (isInRunning ? <Outlet /> : <Navigate to="/maintenance" state={{ from: location }} replace />);
}

export default SafeRoute;