import React,{useRef} from "react";
import { connect } from "react-redux";

// Dev-Extreme Components
import "devextreme/dist/css/dx.light.css";
// import Form, { SimpleItem, Label } from "devextreme-react/form";

// import TextBox from "devextreme-react/text-box";
// import CustomDropdownInputBackup from "./dropdown-input";
import "../styles/custom-guest-info.css";

import Checkbox from '../elements/checkbox'
import Title from '../elements/section-subtitle'
import CustomPhone from '../elements/input/custom-phone-alt'
import CustomEmail from '../elements/input/custom-email-alt'
import CustomDropDownInput from '../elements/input/custom-dropdown-input'

//utils
import {getListGuests} from '../../../utils/user-form'
import {isLoggedIn} from '../../../utils/user'

// Mock Data
// import { initialUsers } from "../../quick-request/data/mock-users";
// Redux State
// import { setSelectedUser, setGuestEmail, setGuestPhone, setIsExistingUser, setIsCheckBoxSelected } from "../../../utils/quick-request";
// Services
import baseService from "../../../services/base.service";
// import { setGuestUser } from "../../../actions/my-basket";

//images
import EmailIcon from '../../../images/guest-info-icons/mail.svg'
import PhoneIcon from '../../../images/guest-info-icons/phone.svg'
import UserIcon from '../../../images/guest-info-icons/user.svg'


const CustomGuestInfo = ({guests,guestUser,selectedGuestId,setSelectedGuestId, guestEmail, guestPhone,isPhoneValidState,isPhoneValidMessage, isExistingUser, isCheckBoxSelected,setSelectedUser, setGuestEmail, setGuestPhone, setIsExistingUser, setIsCheckBoxSelected,setIsPhoneValidMessage,setIsPhoneValidState,messageLabel,isEmpty, setIsEmpty,isReadOnly,readOnlyName,readOnlyPhone,readOnlyEmail, readOnlyTitle }) => {
    // const [isCheckBoxPrevSelected, setIsCheckBoxPrevSelected] = React.useState(false);
    const refEmail = useRef(null)
    const handleSelectUser = (user) => {
        setSelectedUser(user);
        if (user.email && user.phoneNumber) {
            setIsPhoneValidState(true)
            setIsPhoneValidMessage('')
            setIsExistingUser(true);
            setGuestEmail(user.email);
            setGuestPhone(user.phoneNumber);
        } 
        else {
            setIsExistingUser(false);
            setIsPhoneValidState(true)
            setIsPhoneValidMessage('')
            setGuestEmail("");
            setGuestPhone("");
            refEmail?.current?.instance?.option('isValid') === false && refEmail?.current?.instance?.reset()
        }
    };

    const handleNewUser = (user) => {
        setSelectedUser(user);
        setIsExistingUser(false);
        setIsPhoneValidState(true)
        setIsPhoneValidMessage('')
        setGuestEmail("");
        setGuestPhone("");
        refEmail?.current?.instance?.option('isValid') === false && refEmail?.current?.instance?.reset()
    };
    
    const handleEmailChange = (value) => setGuestEmail(value);
    const handlePhoneChange = (value) => setGuestPhone(value);

    const [profileFirstName, setProfileFirstName] = React.useState("");
    const [profileLastName, setProfileLastName] = React.useState("");
    const [profileEmail, setProfileEmail] = React.useState("");
    const [profilePhoneNumber, setProfilePhoneNumber] = React.useState("");
    React.useEffect(() => {
        if(isLoggedIn())
        {   
            async function fetchUserData() {
                const UserProfile = JSON.parse(localStorage.getItem('ODYSSEY.USER.AUTHENTICATION'));
                const id = UserProfile?.profile?.sub;
                await baseService({
                    method: "GET",
                    baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
                    url: `/api/v1/accounts/${id}`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(res => {
                    setProfileFirstName(res.data.firstName);
                    setProfileLastName(res.data.lastName);
                    setProfileEmail(res.data.email);
                    setProfilePhoneNumber(res.data.phoneNumber);
                    // setGuestEmail(res.data.email);
                    // setGuestPhone(res.data.phoneNumber);
                    // setSelectedUser({
                    //     firstName: res.data.firstName,
                    //     lastName: res.data.lastName,
                    //     email: res.data.email,
                    // });
                })
            };
            fetchUserData();
            getListGuests()
        }
        
    }, []);

    React.useEffect(() => {
            if (isCheckBoxSelected) {
                guestEmail && setGuestEmail("");
                guestPhone && setGuestPhone("");
                setSelectedUser({})
                setSelectedGuestId('')
                setIsExistingUser(false)
                setIsPhoneValidState(true)
                setIsPhoneValidMessage('')
                refEmail?.current?.instance?.option('isValid') === false && refEmail?.current?.instance?.reset()
            }
        }, [isCheckBoxSelected]);
    return (
        <>
            <div className={`custom-guest-info ${isCheckBoxSelected ? 'custom-guest-info--selected' : ''}`}>
                <Title>{readOnlyTitle ? readOnlyTitle : 'Guest Info'}</Title>
                    <div className="custom-guest-info__container">
                        {!isCheckBoxSelected &&
                        <div className="custom-guest-info__label-container">
                            <div className="custom-guest-info__label-field">
                                    <div><img src={UserIcon}/></div>
                                    <span>{isReadOnly ? (readOnlyName ? readOnlyName: 'none' ) : `${profileFirstName} ${profileLastName}`}</span>
                            </div>
                            <div className="custom-guest-info__label-field">
                                <div><img src={PhoneIcon}/></div>
                                {
                                    isReadOnly ?
                                    <span>{readOnlyPhone ? readOnlyPhone : 'none'}</span>
                                    :
                                    <CustomPhone 
                                        labelMode={true}
                                        isDisabled={true}
                                        value={profilePhoneNumber} 
                                    />
                                }
                                
                            </div>
                            <div className="custom-guest-info__label-field">
                                <div><img src={EmailIcon}/></div>
                                <span>{isReadOnly ? (readOnlyEmail ? readOnlyEmail : 'none') : profileEmail}</span>
                            </div>
                        </div>
                        }
                        {!isReadOnly && 
                            <Checkbox 
                                id='isCheckedGuest'
                                checkValue={isCheckBoxSelected}
                                onChange={(id,value)=>setIsCheckBoxSelected(value)}
                                label='Book for another person'
                            />
                        }
                        
                        {isCheckBoxSelected &&
                        <div className="custom-guest-info__input-container">
                            <CustomDropDownInput 
                                 label='First & Last Name'
                                 requiredValue={true}
                                 initialUsers={guests} 
                                 onSelectUser={handleSelectUser} 
                                 onNewUser={handleNewUser}
                                 messageLabel={messageLabel}
                                 isEmpty={isEmpty}
                                 setIsEmpty={setIsEmpty}
                                 value={selectedGuestId}
                                 setValue={setSelectedGuestId}
                                 />
                                <CustomPhone 
                                    label='Phone'
                                    isDisabled={isExistingUser}
                                    value={guestPhone} 
                                    setValue={handlePhoneChange} 
                                    requiredValue={true}
                                    isValid={isPhoneValidState}
                                    setIsValid={setIsPhoneValidState}
                                    validMessage={isPhoneValidMessage}
                                    setValidMessage={setIsPhoneValidMessage}
                                />
                                <CustomEmail 
                                    label='Email'
                                    reference={refEmail}
                                    isDisabled={isExistingUser}
                                    value={guestEmail} 
                                    setValue={handleEmailChange} 
                                    requiredValue={true}
                                />
                        </div>
                        }
                    </div>
            </div>
        </>
    );
};

const mapStateToProps = ({userForm}) => {
    return {
        guests: userForm.listGuests
    };
};

export default connect(mapStateToProps) (CustomGuestInfo);