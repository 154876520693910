import { useState } from 'react'
import {Link,useLocation, useMatch} from 'react-router-dom'

//styles
import '../styles/navbar-dropdown.css'


const ReceivedRequests = ({iconColor}) => {
  const location = useLocation()
  const matchLocation = useMatch('/administration/:name')
  const [active, setActive] = useState(false)
  
  return (
    <div className={`navbar-message navbar-message--square ${active ? 'navbar-message--active':''} ${matchLocation ? 'activeTab' : ''}`} onMouseLeave={()=>setActive(false)}>
        <div onClick={()=>setActive(!active)}>
              <div className='fw-bold nav-link navbar-message__title' style={{ color: iconColor === 'text-white' ? '#fff' : '#000' }}>ADMINISTRATION</div>
        </div>
        <div className={`navbar-message__dropdown ${active ? 'navbar-message__dropdown--active':''}`}>
            <Link to='/administration/jobs' className={location.pathname === '/administration/jobs' ? 'navbar-message__dropdown__item--active':'navbar-message__dropdown__item'}><span>JOBS</span></Link>
            <Link to='/administration/reports' className={location.pathname === '/administration/reports' ? 'navbar-message__dropdown__item--active':'navbar-message__dropdown__item'}><span>REPORTS</span></Link>
            <Link to='/administration/statistics' className={location.pathname === '/administration/statistics' ? 'navbar-message__dropdown__item--active':'navbar-message__dropdown__item'}><span>STATISTICS</span></Link>
        </div>
    </div>
  )
}

export default ReceivedRequests