import TreeList, {
  Column, HeaderFilter, Selection, SearchPanel,
} from 'devextreme-react/tree-list';

import '../styles/custom-treelist-alt.css'

const Treelist = ({ id, name, data, keys, filterValue, onSelectionChanged, expandedKeys }) => {
  
  return (
    // keys.length > 0 && 
    <TreeList
      className='custom-treelist-alt'
      filterMode='none'
      dataSource={data}
      selectedRowKeys={keys}
      autoExpandAll={true}
      showBorders={true}
      showColumnHeaders={false}
      // filterValue={filterValue}
      keyExpr="id"
      parentIdExpr="parentId"
      // defaultExpandedRowKeys={expandedKeys}
      // height={300}
      // ref={`treelist${item.id}`}
      onSelectionChanged={onSelectionChanged}
    >
      <SearchPanel 
        visible={false} 
        text={filterValue}
      />
      <HeaderFilter visible={false} />
      <Selection mode="multiple" recursive={true}/>
      <Column 
        dataField="name" 
        allowSorting={false} 
        caption={name} 
        dataType="string"
        // cellRender={(data) => {
        //   const cellText = data.data.name.toString();
        //   const parts = cellText.split(new RegExp(`(${filterValue})`, 'gi'));
  
        //   return (
        //     <span>
        //       {parts.map((part, index) => (
        //         part.toLowerCase() === filterValue.toLowerCase() ? (
        //           <span key={index} style={{ backgroundColor: 'rgba(0, 141, 169, 0.2)' }}>
        //             {part}
        //           </span>
        //         ) : (
        //           <span key={index}>{part}</span>
        //         )
        //       ))}
        //     </span>
        //   );
        // }}
        />
    </TreeList>
  )
}

export default Treelist