import {useState} from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'

//actions
import { setPropertySearchView } from '../../actions/property-search'

//images
import RequestIcon from '../../images/request_volt.svg'
import GridIcon from '../../images/grid-icon.svg'
import MapIcon from '../../images/map-icon.svg'

//styles
import './styles/property-search-floating-button.css'

const PropertySearchFloatingButton = ({propertySearchView,setPropertySearchView}) => {
  const [switchBanner, setSwitchBanner] = useState(false)
  const changeBannerStatus = _ =>{
    setSwitchBanner(!switchBanner)
  }
  const changePropertyView = _ => {
    if(propertySearchView === 'grid')
    {
      setPropertySearchView('map')
    }
    else{
      setPropertySearchView('grid')
    }
  }
  return (
    <div className={`banner-button-wrapper ${switchBanner ? 'banner-button-wrapper--close' : 'banner-button-wrapper--open'}`}>
      <div className='banner-button-wrapper__switch-view'>
          <div className='banner-button-wrapper__switch-view__view-button' onClick={changePropertyView}>{propertySearchView === 'map' ?  <div><img src={GridIcon} alt="" /> <span>Grid View</span></div>  : <div><img src={MapIcon} alt="" /> <span>Map View</span></div>}</div>
      </div>
      <div className='banner-button-wrapper__quick-request'>
          <div className={`banner-button-container ${switchBanner ? 'banner-button-container__close' : 'banner-button-container__open'}`} onClick={switchBanner ? changeBannerStatus : ()=>{}}>
              {
                  switchBanner 
                  ?
                  <img src={RequestIcon} alt="" />
                  :
                  <>
                      <div className='banner-button-container__content'>
                          <span>Looking for an option which perfectly matches your expectations? Let our managers to assess your unique needs and find an ideal housing for you.</span>
                          <Link to="/quick-request" className='banner-button-container__content__action-button'>Create quick request</Link>
                      </div>
                      <div className='banner-button-container__close-button' onClick={changeBannerStatus}><span>x</span></div>
                  </>
              }
          </div>
        </div>
        
    </div>
  )
}
function mapStateToProps({ search }) {
  return {
    propertySearchView: search.propertySearchView,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPropertySearchView: value => dispatch(setPropertySearchView(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertySearchFloatingButton)
