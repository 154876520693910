import { useEffect } from 'react';
import './style.css';

const PrivacyPage = () => {

    useEffect(() => {
    }, []);

    return (
        <div className="privacy-container">
            <div id="ContentArea_C001_Col00" className="sf_colsIn" data-sf-element="Banner Area" data-placeholder-label="Banner Area">
                <div className="backgroung-img-block">
                    <div className="content">
                        <h1>Privacy Policy</h1>
                        <h2></h2>
                    </div>
                </div>
            </div>
            <div className="main-content">
                <div>
                    <h2 align="center">
                        <strong>
                            <em>
                                <p align="center">
                                    <strong>
                                        <span lang="EN-GB">PRIVACY NOTICE</span>
                                    </strong>
                                </p>
                                <span lang="EN-GB">Oasis Corporate Housing (Oasis) is a global provider of temporary furnished and all-inclusive rentals (Corporate Housing) for relocations, assignments and projects, entertainment companies on location, ALE / Insurance housing and individuals.</span>
                            </em>
                        </strong>
                        <em>
                        </em>
                    </h2>
                </div>
                <div>
                    <p>
                        <span lang="EN-GB">At Oasis, we are committed to working tirelessly towards maintaining our highly regarded and respected quality of service.</span>
                    </p>
                    <p>
                        <span lang="EN-GB">Our EU Representative / <span>Data Protection Officer</span>
                        </span> / <span>GDPR Owner</span> and data protection representatives can be contacted directly here;
                    </p>
                </div>

                <ul>
                    <li>
                        <span lang="EN-GB">gdpr@oasiscorporatehousing.com</span>
                    </li>
                    <li>
                        <span lang="EN-GB">1-800-578-0256</span>
                    </li>
                </ul>
                <div>
                    <p>
                        <span lang="EN-GB">This site is owned and operated by Oasis Corporate Housing. Your privacy is very important to us. We respect the privacy of every individual who visits this Web site or responds to Oasis Corporate Housing&rsquo;s interactive advertisements. To help protect your privacy, we adhere to the following guidelines. Note: This policy applies only to this Web site. The following discloses our information gathering activities for this Web site only.</span>
                    </p>
                    <p>
                        <span lang="EN-GB">Oasis is committed to ensuring that your privacy is protected, and our use of your personal information is governed by this Privacy Notice. On this site, "Oasis", "our" and "we" each mean all the Oasis Offices, in the United States of America, Paris and London, which collect and use your information in the manner set out in this Privacy Notice. </span>
                    </p>
                    <p>
                        <span>Any reference to "you" or "your" refers to anyone whose personal information we process.</span>
                    </p>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB">1. Introduction</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB">Our use of your personal data will be governed by this Privacy Notice.</span>
                    </p>
                    <p>
                        <span lang="EN-GB">Oasis is a Controller for the personal information that it collects and uses about you and a Processor for some data referred to us by a Third Party who you would have allowed or enabled to use your data for a lawful purpose. We will always treat your personal information as confidential and in accordance with applicable data protection legislation in all the countries that we operate in. Your personal information will only be shared with others in accordance with this Privacy Notice.</span>
                    </p>
                    <div>
                        <p>
                            <strong>
                                <span lang="EN-GB">This Privacy Notice explains:</span>
                            </strong>
                        </p>
                        <ul type="disc">
                            <li>
                                <span lang="EN-GB" >What personal information is</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >How we collect your personal information</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >The types of personal information we collect</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >How we use your personal information</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >The legal basis for processing your personal information</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >How we share your personal information</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >How long we keep your personal information</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >How we keep your personal information secure</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >Overseas transfers of your personal information</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >Your rights in relation to your personal information</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >How to make complaints and how to contact us</span>
                            </li>
                        </ul>
                        <p>
                            <span lang="EN-GB" >If we need to change the way in which your personal information is used by us, this Privacy Notice will be updated and if the changes are significant, we will advise you.</span>
                        </p>
                    </div>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB">2. What is personal information?</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB" >Personal information is any information that tells us something about you. This could include information such as name, contact details, date of birth, bank account details or any information about your needs or circumstances which would allow us to identify you.</span>
                    </p>
                    <p>
                        <span lang="EN-GB" >Some personal information is classified as "special" data under data protection legislation. This includes information relating to health, racial or ethnic origin, religious beliefs or political opinions and sexual orientation. This information is more sensitive, and we need to have further justifications for collecting, storing and using this type of personal information. There are also additional restrictions on the circumstances in which we are permitted to collect and use criminal conviction data.</span>
                    </p>
                    <p>
                        <span lang="EN-GB" >Any references to personal information in this privacy notice will include personal data, and where relevant, special categories of personal data.</span>
                    </p>
                </div>

                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB" >3. What personal information do we collect?</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB" >We will collect your full name and contact details (such as phone number, email address and postal address) from you when you initially engage with us.</span>
                    </p>
                    <p>
                        <span lang="EN-GB" >In addition, if we are providing a service to you, or on your behalf, where we are required to do so by law, we will collect information relating to your identity, which will be at least one form of photographic identification (such as a passport, a driving licence or an identification card) and one form of documentation with proof of your place of residence (such as a recent utility bill).</span>
                    </p>
                    <p>
                        <span lang="EN-GB" >We may also confirm whether you have any relatives or close connections working at Oasis so that we can consider potential conflict of interests and if identified how to manage these in accordance with any statutory and regulatory obligations.</span>
                    </p>
                    <p>
                        <span lang="EN-GB" >Depending on the service that we provide to you (or on your behalf) we may also collect additional personal information as detailed below;</span>
                    </p>
                </div>

                <div>
                    <p >
                        <strong>
                            <span lang="EN-GB" >Buy, Sell, Let, Rent</span>
                        </strong>
                    </p>
                    <p >
                        <span lang="EN-GB" >If you wish to rent or lease a property we are listing;</span>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >Specific access      requirements so that we can find a property that is suitable for your      needs;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >Your bank /      building / similar account details to allow us to manage an approved      tenancy deposit account for you and to set up your rental payments;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >We may collect      details of a Biometric residence card, your immigration status document      and/or your birth certificate to carry out our required checks under this      scheme.</span>
                        </li>
                    </ul>
                </div>

                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB">Property Management</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB">Where we engage with you to manage property on your behalf we will ask for additional emergency contact information in case we need to get in touch with you outside of working hours or in the event there is a matter requiring your urgent attention. Where you engage us for portfolio management services we will share your personal information with third party lenders as is necessary to allow us to fulfil our obligations to you and to properly manage and advise you on your property investments.</span>
                    </p>
                </div>

                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB">Investment Management</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB">Where you engage with Oasis or one of its subsidiaries as an individual investor we will collect information required to identify you to meet anti-money laundering, fraud and counter terrorist financing requirements. If the investor is an entity such as a company, trust or charity, we may need to collect personal information about the controllers and the beneficiaries of the entity.</span>
                    </p>
                </div>

                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB">Other</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB">If you subscribe to receive research, news or other market updates from us, then in addition to the above we will collect details about the sector in which you work and the type of content that you would like to receive.</span>
                    </p>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB">4. How we use your personal information</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB">What is the legal basis of our data processing?</span>
                    </p>
                    <p>
                        <span lang="EN-GB">Under data protection legislation we are only permitted to use your personal information if we have a legal basis for doing so. We rely on the following legal bases to use your information;</span>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB">For employment-related purposes;</span>
                        </li>
                        <li>
                            <span lang="EN-GB">Where we need      information to perform the contract we have with you or to provide a      service for you;</span>
                        </li>
                        <li>
                            <span lang="EN-GB">Where we are      complying with a legal obligation;</span>
                        </li>
                        <li>
                            <span lang="EN-GB">Where processing      is required to carry out activities which are in Oasis' legitimate      interests (or those of a third party) to do so and provided that your      interests and fundamental rights do not override those interests,      including:</span>
                        </li>
                    </ul>
                    <ul type="disc">
                        <ul type="circle">
                            <li>
                                <span lang="EN-GB" >Processing that is needed for us to promote our business, brands and products and       measure the reach and effectiveness of our campaigns. This could include       sending marketing information from time to time after you have engaged us       to provide services or received services from us which may be similar and       of interest to you or where you have expressly indicated that you would       like to receive such information. You will always have a transparent right       to opt out of receiving this information at any time.</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >Processing that is necessary to improve our knowledge of the real estate sector -       this will include undertaking market analysis and research so that we       better understand trends in our business sector and provide better       knowledge along with more tailored and relevant services for our       customers in the future.</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >Processing necessary for us to operate the administrative and technical aspects of       our business efficiently and effectively &ndash; this will include: verifying       the accuracy of information that we hold about you and create a better       understanding of you as a customer; processing for administrative       efficiency purposes such as where we outsource certain administrative       functions to third parties who are specialise in such services;       processing for network and information security purposes i.e. in order       for us to take steps to protect your information against loss, damage,       theft or unauthorised access or to comply with a request from you in       connection with the exercise of any of your rights outlined below.</span>
                            </li>
                        </ul>
                    </ul>
                    <p>
                        <span lang="EN-GB" >In more limited circumstances we may also rely on the following legal bases:</span>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB">Where we need to      protect your interests (or someone else's interests); and/or</span>
                        </li>
                        <li>
                            <span lang="EN-GB">Where it is      needed in the public interest or for official purposes</span>
                        </li>
                    </ul>
                    <p>
                        <span lang="EN-GB" >We may process special categories of personal information and criminal conviction information in the following limited circumstances:</span>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >With your      explicit consent, in which case we will explain the purpose for which the      information will be used at the point where we ask for your consent.</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB" >How we use your personal information</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB" >For specific information on how your personal information will be used for the services you have requested from us, please select from any of the options below;</span>
                    </p>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB" >Let, Rent</span>
                        </strong>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >We use your      personal information for the purposes of fulfilling our obligations to you      to let or rent a property for, or to, you. If you are a prospective      tenant, tenant, or landlord, we may use your data for the purposes of      arranging a contract to let, lease or rent to you, or for you;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >If you are      renting a property we are acting as letting agents of, we may share your      personal data with third party referencing agencies for the purposes of      the prevention and detection of crime, fraud and/or money laundering and      checking your suitability to rent that property.</span>
                        </li>
                    </ul>

                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB" >Property Management</span>
                        </strong>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >Where we are      managing property on your behalf we may need to pass your personal      information from time to time on to third party contractors whom we have      engaged to carry out maintenance on your properties;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >A list of these      can be provided upon request by a data subject.</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB" >Investment</span>
                        </strong>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >Where you engage      us for portfolio management services we will share your personal      information with third party lenders as is necessary to allow us to fulfil      our obligations to you and to properly manage and advise you on your      property investments;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >Where you engage      with Oasis we will collect and hold your personal data to maintain a      register of investment holdings to comply with our Financial regulatory requirements      in the respective country. </span>
                        </li></ul>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB" >Other uses of your personal information</span>
                        </strong>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >We will share      personal information with our regulators, governmental or      quasi-governmental organisations, law enforcement authorities and with      courts, tribunals and arbitrators as may be required from time to time to      comply with our regulatory and legal obligations;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >Where we use      third party services providers who process personal information on our      behalf to provide services to us. This includes IT systems providers and      IT contractors as well as third party referencing or screening agencies      for the purposes of the prevention and detection of crime;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >We may share your      personal information with any person working within the Oasis on a need to      know basis to ensure we are able to perform our obligations to you;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >We will hold your      personal information on our client management systems and use this to      provide you with marketing information about similar services offered by Oasis      to those which you have engaged us to provide which we feel you might find      useful from time to time. You can opt out of receiving this information at      any time.</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB" >What happens if you do not provide information that we request?</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB" >We need some of your personal information to perform the services you have requested from us. For example:</span>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >where we are      selling a property on your behalf we need to know your contact information      so that we can update you with information on viewings, offers and the      progress of the sale;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >where you have      asked us to find you a property which meets certain access requirements,      we may need to know some further details about your circumstances so that      we can find you a suitable property.</span>
                        </li>
                    </ul>
                    <p>
                        <span lang="EN-GB" >We also need some information so that we can comply with our legal obligations. For example, we may need identity documents from you to meet our obligations to prevent fraud and money laundering.</span>
                    </p>
                    <p>
                        <span lang="EN-GB" >If you do not provide the information required for these purposes, we will not be able to perform our contract with you and may not be able to provide services to you or continue to provide certain services to you. We will explain when this is the case at the point where we collect information from you.</span>
                    </p>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB" >5. How we keep your information secure</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB">Whilst visiting our Site, certain information is automatically logged about how you are using our Site. This information includes the URL of the website which linked you to our Site, your IP address and the pages you visit while on our Site. The IP address indicates the location of your computer on the Internet.&nbsp; We keep a record of each user's activity by reference to the IP address of the Internet connection(s) they use to access our Site. These logs enable us to prevent users who breach our terms and conditions by refusing communications from their IP addresses to our Sites.</span>
                    </p>
                    <p>
                        <span lang="EN-GB" >The security of information is very important to us and we have measures in place which are designed to prevent unauthorised access to your personal information including but not limited to:</span>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >Our client files      are stored on our secure client management systems. Access is restricted      on our client systems to those within the business who are required to      have access to your information for legitimate business purposes;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >Hard copy      documentation is stored in locked cabinets.</span>
                        </li>
                    </ul>
                    <p>
                        <span lang="EN-GB" >All data is hosted in datacentres which have systems and protections in place to protect against both unauthorised access, and other external factors that could cause damage to, your personal data. There are strict access requirements in place and access is restricted to those necessary.</span>
                    </p>
                    <p>
                        <span lang="EN-GB" >We ensure access to personal information is restricted to Oasis employees and workers or other persons working within the Oasis Companies on a need to know basis. Training is provided to any of those Oasis employees and workers who need access to personal information.</span>
                    </p>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB" >6. For how long, do we keep your personal information</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB" >The period for which we will keep your personal information will depend on the type of service you have requested from us. The retention period may be longer than the period for which we are providing services to you where we have statutory or regulatory obligations to retain personal information for a longer period, or where we may need to retain the information in case of a legal claim.</span>
                    </p>
                </div>
                <div>
                    <p>
                        <strong>
                            <span lang="EN-GB" >7. Overseas transfers of your personal information</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB" >If you have provided your information in the UK, we may transfer, store, or process your personal information in locations outside the UK and the European Economic Area (EEA). Our Head Office is in the United States of America and is registered under the Privacy Shield Scheme. Where the countries to which your personal information is transferred do not offer an equivalent level of protection for personal information to the laws of the UK, we would always advise you and ensure that appropriate safeguards are put in place.</span>
                    </p>
                    <p>
                        <span lang="EN-GB" >We&rsquo;ll use one of these safeguards:</span>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >Transfer it to a      non-EEA country with privacy laws that give the same protection as the      EEA. Learn more on the European Commission Justice website;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >Put in place a      contract with the recipient that means they must protect it to the same      standards as the EEA. Read more about this here on the European Commission      Justice website;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >Transfer it to      organisations that are part of Privacy Shield. This is a framework that      sets privacy standards for data sent between the US and EU countries. It      makes sure those standards are like what is used within the EEA. You can      find out more about data protection on the European Commission Justice      website.</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <p >
                        <strong>
                            <span lang="EN-GB" >8. Your rights in relation to your personal information</span>
                        </strong>
                    </p>
                    <p>
                        <span lang="EN-GB">Oasis Corporate Housing does not collect "Personal Information" from individuals unless they provide it to us voluntarily and knowingly. We do not require you to register in order to use the Web site. We use the information supplied for the purpose of providing the services you've requested. This Web site will explicitly ask when it needs information that personally identifies our customers or allows it to contact our customers.&nbsp; This Web site may use Personal Information to operate the site, provide services, and to inform our customers of new features, services, and products.</span>
                    </p>
                    <p>
                        <span lang="EN-GB" >You have several rights in relation to your personal information, these include the right to:</span>
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >Be informed about      how we use your personal information;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >Obtain access to      your personal information that we hold;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >Request that your      personal information is corrected if you believe it is incorrect,      incomplete or inaccurate;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >Request that we      erase your personal information in the following circumstances:</span>
                        </li>
                    </ul>
                    <ul type="disc">
                        <ul type="circle">
                            <li>
                                <span lang="EN-GB" >If Oasis       is continuing to process personal information beyond the period when it       is necessary to do so for the purpose for which it was originally       collected;</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >If Oasis       is relying on consent as the legal basis for processing and you withdraw       consent;</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >If Oasis       is relying on legitimate interests as the legal basis for processing and       you object to this processing and there is no overriding compelling       ground which enables us to continue with the processing;</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >If the       personal information has been processed unlawfully (i.e. in breach of the       requirements of the data protection legislation);</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >If it is       necessary to delete the personal information to comply with a legal       obligation</span>
                            </li>
                        </ul>
                    </ul>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >Ask us to      restrict our data processing activities where you consider that:</span>
                        </li>
                    </ul>
                    <ul type="disc">
                        <ul type="circle">
                            <li>
                                <span lang="EN-GB" >Personal information is inaccurate;</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >Our processing of your personal information is unlawful;</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >Where we       no longer need the personal information, but you require us to keep it to       enable you to establish, exercise or defend a legal claim;</span>
                            </li>
                            <li>
                                <span lang="EN-GB" >Where you have raised an objection to our use of your personal information</span>
                            </li>
                        </ul>
                    </ul>
                    <ul type="disc">
                        <li>
                            <span lang="EN-GB" >Request a copy of      certain personal information that you have provided to us in a commonly      used electronic format. This right relates to personal information that      you have provided to us that we need to perform our agreement with you and      personal information where we are relying on consent to process your      personal information;</span>
                        </li>
                        <li>
                            <span lang="EN-GB" >Object to our      processing of your personal information where we are relying on legitimate      interests or exercise of a public interest task to make the processing      lawful. If you raise an objection we will carry out an assessment to      determine whether we have an overriding legitimate ground which entitles      us to continue to process your personal information; and</span></li><li><span lang="EN-GB" >Not be subject to      wholly automated decisions which produce legal effects, or which could      have a similarly significant effect on you.</span>
                        </li>
                    </ul>
                    <p >
                        <span lang="EN-GB" >If you would like to exercise any of your rights, please contact&nbsp;gdpr@odosltd.com</span>
                    </p>
                    <div>
                        <p >
                            <strong>
                                <span lang="EN-GB" >9. Complaints and Contact Details</span>
                            </strong>
                        </p>
                        <p >
                            <span >
                                <span lang="EN-GB" >Complaints</span>
                            </span>
                        </p>
                        <p >
                            <span lang="EN-GB" >If you have any complaints about the way we use your personal information, please contact the Customer Relations Officer at complaints who will try to resolve the issue. If we cannot resolve any issue, you have the right to complain to the data protection authority in your country (the Information Commissioner in the UK). If you need more information about how to contact your local data protection authority, please let us know by contacting gdpr@odosltd.com</span>
                        </p>
                        <p >
                            <span >
                                <span lang="EN-GB" >Contact details</span>
                            </span>
                        </p>
                        <p >
                            <span lang="EN-GB" >If you have any questions, comments or requests regarding any aspect of this Privacy Policy, please do not hesitate to contact us by sending an email to gdpr@odosltd.com or writing to the Oasis Corporate Housing, Data Protection Team, ODOS, Open Space, Willowfields Business Park, Malvern UK, WR13 6NN</span>
                        </p>
                    </div>
                    <div>
                        <p >
                            <strong>
                                <span lang="EN-GB" >10. The use of cookies by Oasis</span>
                            </strong>
                        </p>
                        <p>
                            <span lang="EN-GB">Our customers should also be aware that information and data may be automatically collected through the standard operation of our Internet servers and through the use of "cookies." A cookie is a small data file that is placed on your computer or other device to allow a website to recognize you as a user when you return to the website using the same computer and web browser, either for the duration of your visit (using a &lsquo;session cookie&rsquo;) or for repeat visits (a &lsquo;persistent cookie&rsquo;). Cookies are not programs that come onto a system and damage files. Generally, cookies work by assigning a unique number to each customer that has no meaning outside the assigning site. If you do not want information collected through the use of cookies, there is a simple procedure in most browsers that allows a customer to deny or accept the cookie feature; however, you should note that cookies may be necessary to provide customers with certain features. </span>
                        </p>
                        <p >
                            <span >
                                <span lang="EN-GB" >What is a cookie?</span>
                            </span>
                        </p>
                        <p >
                            <span lang="EN-GB" >A cookie is a small text file that is downloaded onto your computer when you visit certain websites and allows a website to recognise a user's computer. Cookies are used to help users navigate websites more efficiently and to perform certain functions, as well as to provide information to the owners of the website.</span>
                        </p>
                        <p >
                            <span >
                                <span lang="EN-GB" >What cookies do Oasis use?</span>
                            </span>
                        </p>
                        <p >
                            <span lang="EN-GB" >The table below lists the cookies used by this website and provides a description of how each cookie works, clicking the '+' symbol will expand the box to give further explanation on each cookie. Where further information about a cookie exists on an external website a link has been provided under 'More information'.</span>
                        </p>
                        <div>
                            <p >
                                <strong>
                                    <span lang="EN-GB" >How do I restrict or delete cookies?</span>
                                </strong>
                            </p>
                            <p >
                                <span lang="EN-GB" >Cookies that we use are essential for parts of the website to operate as effectively as possible and have already been set. You may delete and block all cookies from this website, but please be aware that restricting or deleting cookies may impact on the functionality of the website.</span>
                            </p>
                            <p >
                                <span lang="EN-GB" >Your web browser may allow you to restrict or delete cookies set by this website. The Help function within your browser should tell you how. </span>
                            </p>
                            <p >
                                <span lang="EN-GB" >Alternatively, you can visit&nbsp;</span>
                                <span lang="EN-GB">
                                    <span >www.allaboutcookies.org</span>
                                </span>
                                <span lang="EN-GB" >&nbsp;which provides general information about cookies and how you can manage cookies on your computer.</span>
                            </p>
                            <p>
                                <span lang="EN-GB">Our users are given the opportunity to 'opt-out' of having their information used for purposes not directly related to our site or not mentioned in this Web Site Privacy Policy at the point where we ask for information. In addition, users who no longer wish to receive our email communications may opt-out of receiving these communications by replying to unsubscribe information contained in the email. </span>
                            </p>
                            <p>
                                <span lang="EN-GB" >To opt out of being tracked by Google Analytics across all websites visit&nbsp;</span>
                                <span lang="EN-GB">
                                    <span>http://tools.google.com/dlpage/gaoptout</span>
                                </span>
                                <span lang="EN-GB" >.</span>
                            </p>
                            <p>
                                <span lang="EN-GB">We may make changes to this Cookie Policy at any time by sending you an e-mail with the modified terms or by posting a copy of them on our Sites. Any changes will take effect seven (7) days after the date of our e-mail or the date on which we post the modified terms, whichever is the earlier. Your continued use of our Sites after that period expires means that you agree to be bound by the modified policy.</span>
                            </p>
                        </div>
                        <div>
                            <p>
                                <strong>
                                    <span lang="EN-GB">Links</span>
                                </strong>
                            </p>
                            <p>
                                <span lang="EN-GB">Oasis Corporate Housing may provide links to websites that are owned or operated by others ("third-party websites").&nbsp; When you use a link online to visit a third-party website, you will be subject to that website`s privacy and security practices, which may differ from ours. You should familiarize yourself with the privacy policy, terms of use and security practices of the linked third-party website before providing any information on that website.</span>
                            </p>
                        </div>

                    </div>
                </div>
            </div>

           
        </div>

    );
}

export default PrivacyPage;

