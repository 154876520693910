import React,{useState,useEffect,useCallback} from 'react'
import {debounce} from 'lodash'
import 'devextreme/dist/css/dx.light.css';
import DateBox from 'devextreme-react/date-box';
import {useParams} from 'react-router-dom'

const InputCalendar = ({changeCheckIn,changeCheckOut,error}) => {

  //calendar-input
  const params = useParams()

  const minFrom = new Date(new Date().toString()).toISOString().split('T')[0]//new Date().toISOString().split("T")[0]
  const [minTo,setMinTo] = useState(minFrom)
  const [valueFrom,setValueFrom] = useState('')
  const [valueTo,setValueTo]= useState('')

  const onChangeCalendar = (calendarIn,calendarOut) => {
    if(!calendarIn)
    {
      setMinTo(minFrom)
    }

    if(!calendarIn && !calendarOut){
        changeCheckIn('')
        changeCheckOut('')
    }

    else if(calendarIn > calendarOut && calendarIn && calendarOut)
    {
      changeCheckIn(calendarIn)
      changeCheckOut(calendarIn)
      setValueTo(calendarIn)
    }
    else if(calendarOut < minFrom && calendarOut)
    {
      changeCheckOut(minFrom)
      setValueTo(minFrom)
    }
    else if(calendarIn < minFrom && calendarIn)
    {
      changeCheckIn(minFrom)
      changeCheckOut(calendarOut)
      setValueFrom(minFrom)
    }
    else{
      changeCheckIn(calendarIn)
      changeCheckOut(calendarOut)
    }
  }

  /*useEffect(() => {
    setValueFrom(checkIn)
    setValueTo(checkOut)
  }, [checkIn,checkOut])*/

  const onInputCalendar = useCallback(debounce((type,e) => {
    let value = e.event.target.value
    if(value && !Number.isNaN(new Date(value).getTime()))
    {
      if(type === 'from')    
          setValueFrom(new Date(value.toString()).toISOString().split("T")[0])
      else
          setValueTo(new Date(value.toString()).toISOString().split("T")[0])
    }
    else{
        if(type === 'from')    
            setValueFrom('')
        else
            setValueTo('')
            
        if(value)
          e.component.option("isValid", false);
    }
 
   },1000),[])
  
   useEffect(() => {
    setMinTo(valueFrom)
    onChangeCalendar(valueFrom,valueTo)
   }, [valueFrom,valueTo])


   useEffect(() => {
     setValueFrom(params.checkIn ? params.checkIn : '')
     setValueTo(params.checkOut ? params.checkOut : '')
   }, [params])
   
  return (
    <>
        {/*
            <input onChange={e=>setValueFrom(e.target.value)} min={minFrom} value={valueFrom} className={`calendar-input ${!checkIn && error && 'calendar-input--error'}`} type="date"/>
            <input onChange={e=>setValueTo(e.target.value)} min={minTo} value={valueTo} className={`calendar-input ${!checkOut && error && 'calendar-input--error'}`} type="date"/>
        */}
        <DateBox 
            className={`calendar-input ${!valueFrom && error && 'calendar-input--error'}`} 
            type="date"
            dateSerializationFormat="yyyy-MM-dd"
            min={minFrom}
            placeholder="CHECK IN"
            value={valueFrom}
            onInput={e=>onInputCalendar('from',e)}
            onValueChanged={e=>setValueFrom(e.value)}
        />
        <DateBox 
            className={`calendar-input ${!valueTo && error && 'calendar-input--error'}`} 
            type="date"
            dateSerializationFormat="yyyy-MM-dd"
            min={minTo}
            placeholder="CHECK OUT"
            value={valueTo}
            onInput={e=>onInputCalendar('to',e)}
            onValueChanged={e=>setValueTo(e.value)}
        />
    </>
  )
}



export default (InputCalendar)