import Subtitle from './section-subtitle'

//styles
import '../styles/information.css'
const Information = ({title,children}) => {
  return (
    <div>
      <Subtitle>{title}</Subtitle>
      <div className='information__content' dangerouslySetInnerHTML={{__html: children}} />
    </div>
  )
}

export default Information