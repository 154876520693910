import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';

const {
    REACT_APP_STS_CLIENT_ID,
    REACT_APP_STS_CLIENT_SCOPE,
    REACT_APP_STS_RESPONSE_TYPE,
    REACT_APP_STS_AUTHORITY,
    REACT_APP_STS_CALLBACK,
    REACT_APP_STS_POST_LOGOUT,
    REACT_APP_STS_SILENT_RENEW
} = process.env;

const userManagerConfig = {
    client_id: REACT_APP_STS_CLIENT_ID,
    redirect_uri: REACT_APP_STS_CALLBACK,
    response_type: REACT_APP_STS_RESPONSE_TYPE,
    scope: REACT_APP_STS_CLIENT_SCOPE,
    authority: REACT_APP_STS_AUTHORITY,
    post_logout_redirect_uri: REACT_APP_STS_POST_LOGOUT,
    silent_redirect_uri: REACT_APP_STS_SILENT_RENEW,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: localStorage })
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
