export const SET_LOADING_FORGOT = "SET_LOADING_FORGOT";


export const TYPE = {
    forgotpassword:{
        id: 1,
        message: 'A link to reset your password was sent to your email.',
        buttonLabel: 'SEND PASSWORD RESET EMAIL',
        url: ''
    },
    forgotusername:{
        id: 2,
        message: 'Your username was sent to your email',
        buttonLabel: 'SEND USERNAME EMAIL',
        url: ''
    }
}