import {useEffect,useState} from 'react'
import {useParams} from 'react-router-dom'
import {connect} from 'react-redux'
import notify from 'devextreme/ui/notify';

//components
import ContentWrapper from './assets/elements/content-wrapper'
import IconNumber from './assets/elements/icon-number'
import RequestsTable from './received-requests/requests-table'
import Pagination from './assets/controls/pagination-alt'
import RequestsFilter from './received-requests/requests-filter'
import CustomPopup from './assets/elements/custom-popup'
import RequestToDelete from './received-requests/requests-delete'
import Loading from './assets/controls/loading'
import CustomAlert from './assets/elements/custom-alert'
//styles
import './received-requests/styles/received-requests.css'

//constants
import {REQUEST_TYPES,PAGINATION_DEFAULT,NO_DATA} from '../constant/received-requests'

//actions
import {setRequestsData,setPreviousRequestsUrl,setAllRequestsIsLoaded} from '../actions/received-requests'

//utils
import {refreshRequests,getAllRequests,getRequestsDataByFilter,deleteRequest,processRequest} from '../utils/received-requests'

const ReceivedRequests = ({messageNoData,loading,allRequestsIsLoaded,requestsData,allRequests,clearRequestsData,clearPreviousUrl,setAllRequestsIsLoaded}) => {
  const params = useParams()
  const [skipPages, setSkipPages] = useState(PAGINATION_DEFAULT.skip)
  const [filterValue, setFilterValue] = useState('all')
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [idToDelete, setIdToDelete] = useState()
  const [titleToDelete, setTitleToDelete] = useState()
  const [visibleProcess, setVisibleProcess] = useState(false)
  const [idToProcess, setIdToProcess] = useState()
  const [titleToProcess, setTitleToProcess] = useState()
  const [prevParamsName,setPrevParamsName] = useState()
  useEffect(() => {
    if(params.name !== prevParamsName && allRequestsIsLoaded)
    {
      setSkipPages(0)
      setFilterValue('all')
      clearRequestsData()
      clearPreviousUrl()
      getRequestsDataByFilter(PAGINATION_DEFAULT.skip,REQUEST_TYPES[params.name].path,'')
    }
    setPrevParamsName(params.name)
  }, [params.name,allRequestsIsLoaded])
 
  useEffect(() => {
    allRequestsIsLoaded && getRequestsDataByFilter(skipPages,REQUEST_TYPES[params.name].path,filterValue)
  }, [skipPages,allRequestsIsLoaded])
  useEffect(() => {
    if(filterValue)
    {
      setSkipPages(0)
      allRequestsIsLoaded && getRequestsDataByFilter(PAGINATION_DEFAULT.skip,REQUEST_TYPES[params.name].path,filterValue)
    }
  }, [filterValue,allRequestsIsLoaded])
  //Delete Events
  const onHidingDelete = () => {
    setVisibleDelete(false)
  }
  const onClickDelete = (id,title,e) => {
    e.stopPropagation()
    setIdToDelete(id)
    setTitleToDelete(title)
    setVisibleDelete(true)
  }
  const onAcceptDelete = async() => {
    let path = REQUEST_TYPES[params.name].path

    let response = await deleteRequest(idToDelete,path)
    if(response === true)
    {
      // getRequestsDataByFilter(skipPages,REQUEST_TYPES[params.name].path,filterValue,true)
      let skip = requestsData?.data?.length > 1 ? skipPages : (skipPages > 0 ? (skipPages -  PAGINATION_DEFAULT.take) : skipPages)
      if(skip === skipPages)
        refreshRequests(skip,REQUEST_TYPES[params.name].path,filterValue)
      else
      {
        getAllRequests()
        setSkipPages(skip)
      }
      notify(`The request ${titleToDelete} was deleted`, "success", 3000)
    }
    else
    {
      notify('Something went wrong try again later', "error", 3000)
    }
    setVisibleDelete(false)
  }

  //Process Events
  const onHidingProcess = () => {
    setVisibleProcess(false)
  }
  const onClickProcessed = (id,title) => {
    setIdToProcess(id)
    setTitleToProcess(title)
    setVisibleProcess(true)
  }
  const onAcceptProcess = async() => {
    let path = REQUEST_TYPES[params.name].path

    let response = await processRequest(idToProcess,path)
    if(response === true)
    {
      refreshRequests(skipPages,REQUEST_TYPES[params.name].path,filterValue)
      notify(`The request ${titleToProcess} was processed`, "success", 3000)
    }
    else
    {
      notify('Something went wrong try again later', "error", 3000)
    }
    setVisibleProcess(false)
  }
  return (
    <ContentWrapper>
      <div className='received-requests'>
        <div className='received-requests__header'>
          <div className='received-requests__title'>
            <span>{REQUEST_TYPES[params.name].title}</span>
            <IconNumber number={allRequests[REQUEST_TYPES[params.name].totalActive]}/>
            <Loading isVisble={loading}  size={20}/>
          </div>
          <div>
            <RequestsFilter value={filterValue} setValue={setFilterValue}/>
          </div>
        </div>
        {requestsData?.data?.length > 0 &&
          <>
            <RequestsTable data={requestsData.data} onClickDelete={onClickDelete} onClickProcessed={onClickProcessed}/>
            <Pagination totalPages={requestsData.totalCount} skip={skipPages} setSkip={setSkipPages} take={PAGINATION_DEFAULT.take}/>
          </> 
        }
        {
          messageNoData && <div className='received-requests__message'><CustomAlert>{NO_DATA}</CustomAlert></div>
        }
        <CustomPopup visible={visibleDelete} onHiding={onHidingDelete}>
          <RequestToDelete title='DELETE REQUEST' message={`Are you sure you want to delete the request with the subject: "${titleToDelete}" ?`} onCancel={onHidingDelete} onAccept={onAcceptDelete} isDelete={true}/>
        </CustomPopup>

        <CustomPopup visible={visibleProcess} onHiding={onHidingProcess}>
          <RequestToDelete title='PROCESS REQUEST' message={`Are you sure you want to process the request with the subject: "${titleToProcess}" ?`} onCancel={onHidingProcess} onAccept={onAcceptProcess}/>
        </CustomPopup>

      </div>
    </ContentWrapper>
  )
}
function mapStateToProps({ receivedRequests }) {
  return {
    requestsData: receivedRequests.requestsData,
    allRequests: receivedRequests.allRequests,
    allRequestsIsLoaded: receivedRequests.allRequestsIsLoaded,
    loading: receivedRequests.loading,
    messageNoData: receivedRequests.messageNoData
  };
}
function mapDispatchToProps(dispatch){
  return{
    clearRequestsData: () => dispatch(setRequestsData({})),
    clearPreviousUrl: () => dispatch(setPreviousRequestsUrl('')),
    setAllRequestsIsLoaded: data => dispatch(setAllRequestsIsLoaded(data))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(ReceivedRequests)