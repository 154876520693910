import { useEffect } from "react";
import { useParams } from 'react-router-dom'
const {
    REACT_APP_OLD_PUBLIC_PORTAL
} = process.env;

const OldPortalPropertyDetails = () => {
    const params = useParams()
  
    useEffect(() => {
        window.location = `${REACT_APP_OLD_PUBLIC_PORTAL}/property-details/${params.id}`
    }, [])
  
    return (
        <div className="section-content-wrapper">
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center", flexGrow: 1 }}>Redirecting...</div>
        </div>
    );
};

export default OldPortalPropertyDetails;