import '../styles/content-wrapper.css'

const ContentWrapper = ({children}) => {
  return (
    <div className='page-main'>
        <div className='page-wrapper'>
            {children}
        </div>
    </div>
  )
}

export default ContentWrapper