import { useState,useEffect } from 'react'
import {connect} from 'react-redux'
import {Link, useMatch} from 'react-router-dom'
//components
import IconNumber from '../../assets/elements/icon-number'

//images
import {ReactComponent as CartIcon} from '../../../images/cart-icon.svg'

//styles
import '../styles/basket-cart.css'

//utils
import {getAllBasket} from '../../../utils/my-basket'
const BasketCart = ({allBasket,iconColor}) => {
  const matchLocation = useMatch('/my-cart-for-quotes')
  useEffect(() => {
    getAllBasket()
  }, [])
   
  return (
    <div className={`navbar-cart ${matchLocation ? 'activeTab' : ''}`}>
        <Link to='/my-cart-for-quotes' className={`navbar-cart__icon navbar-cart__icon--${iconColor}`}>
            <div>
                <CartIcon style={iconColor === 'text-white' ? {fill: '#fff'} : {fill: '#000'}}/> 
            </div>
            <div>
              <IconNumber number={allBasket.totalCount} hideZero={true}/>
            </div>
        </Link>
    </div>
  )
}

function mapStateToProps({ basket }) {
  return {
    allBasket: basket.allBasket
  };
}

export default connect(mapStateToProps)(BasketCart)