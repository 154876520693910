import {
    VERIFY_LOADING,
    VERIFY_TIME_ELAPSED,
    VERIFY_REQUEST,
    VERIFY_CALL,
} from '../constant/verify-account'

const initialState = {
    loading: true,
    timeElapsed: 0,
    request: true,
    makeCall: false
}

export default function verifyAccountReducer(state = initialState, action) {
    switch (action.type) {
        case VERIFY_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case VERIFY_TIME_ELAPSED:
            return {
                ...state,
                timeElapsed: action.payload
            }
        case VERIFY_REQUEST:
            return {
                ...state,
                request: action.payload
            }
        case VERIFY_CALL:
            return {
                ...state,
                makeCall: action.payload
            }
        default:
            return state
    }
}