import React from "react";

const NextArrow = (props) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const toggleClass = () => setIsHovered(!isHovered);

    return (
        <div
            className={isHovered ? "right-active" : "right-passive"}
            onClick={props.onClick}
            onMouseEnter={toggleClass}
            onMouseLeave={toggleClass}
        />
    )
}

export default NextArrow;