const getGeometryByGoogle = async(address_id) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${address_id}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  let result = await fetch(url)
  return await result.json()
}

const getDetailsByGoogle = async(place_id) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&fields=address_component,formatted_address,geometry,name,url&key=${process.env.REACT_APP_GOOGLE_API_KEY}&language=en`
  let result = await fetch(url)
  let data  = await result.json()

  let address = {
    street: null,
    streetNumber:null,
    city: null,//(required),
    state: null,//(required),
    postCode: null,
    country: null,//(required),
    latitude: null,//(required),
    longitude: null,//(required),
    formattedAddress: null,//(required),
    url: null,//(required)
    isValid: false,
    message: ''
  }
  address.formattedAddress =  data.results[0]?.formatted_address ? data.results[0].formatted_address : null
  address.latitude = data.results[0]?.geometry?.location?.lat ? data.results[0].geometry.location.lat : null
  address.longitude = data.results[0]?.geometry?.location?.lng ? data.results[0].geometry.location.lng : null
  address.url = address.longitude && address.latitude ? `https://www.google.com/maps/search/?api=1&query=${address.latitude},${address.longitude}` : null
  
  data.results[0]?.address_components.forEach(element => {
    if (element.types.includes('locality'))
    {
      address.city = element.long_name
    }
    if(element.types.includes('administrative_area_level_1') || element.types.includes('administrative_area_level_2') || element.types.includes('administrative_area_level_3'))
    {
      address.state = element.long_name
    }
    if(element.types.includes('postal_code'))
    {
      address.postCode = element.long_name
    }
    if(element.types.includes('route'))
    {
      address.street = element.long_name
    }
    if(element.types.includes('street_number'))
    {
      address.streetNumber = element.long_name
    }
    if(element.types.includes('country'))
    {
      address.country = element.long_name
    }
  });
    address.isValid = ( address.country && address.latitude && address.longitude && address.formattedAddress && address.url) ? true : false // removed City check
  address.message = address.isValid ? '' : 'Location required a valid country'
  
  return address
}

export {getGeometryByGoogle,getDetailsByGoogle}
