import store from "../store";
import baseService from "../services/base.service";

//actions
import {setPropertySended,setLoading} from '../actions/list-a-property'
//constant

let controller

const sendProperty = (data,images) => {

  try {
    const formData = new FormData()
    Object.keys(data).forEach(item=>formData.append(item,data[item]))
    for(let i=0; i < images.length; i++)
    {
        formData.append('Files',images[i])
    }
    store.dispatch(setLoading(true))
    if(controller)
      controller.abort()
    
    controller  = new AbortController();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/share-properties`;

    baseService.post(url,formData,{
    headers: {
        'accept': 'application/json',
        'Content-Type': 'multipart/form-data'
    },
    signal: controller.signal
    }).then(({data})=>{
        if(data)
          store.dispatch(setPropertySended({
            status: true,
            message: ''
          }))
        else
          store.dispatch(setPropertySended({
            status: false,
            message: 'Something went wrong. Please try again later.'
          }))
    }).finally(()=>{
        store.dispatch(setLoading(false))
    })
    .catch( error => {
    //   store.dispatch(setPropertyDetailsData([]))
      store.dispatch(setPropertySended({
        status: false,
        message: 'Something went wrong. Please try again later.'
      }))
      store.dispatch(setLoading(false))
    })
  } catch (error) {
    // store.dispatch(setPropertyDetailsData([]))
    store.dispatch(setPropertySended({
      status: false,
      message: 'Something went wrong. Please try again later.'
    }))
    store.dispatch(setLoading(false))
  }

}

export {sendProperty}