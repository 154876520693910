import React from "react";
import "../styles/view-full-story-styles.css";
import { Link, useParams } from "react-router-dom";
import SingleArticle from "./SingleArticle";
// import CommentForm from "./CommentForm";

const ViewFullStory = () => {
    const { id } = useParams();

    return (
        <>
            <section className="full-story">
                <div className="container">
                    <div className="row">
                        <Link to="/news" className="mt-3 mb-3 link-color">&lt;&nbsp;Back</Link>
                    </div>
                    <SingleArticle id={id} />
                    {/* <CommentForm /> */}
                </div>
            </section >
        </>
    );
};

export default ViewFullStory;