import React from "react";
import './create-account/styles/layer-mask-styles.css'
import { Link } from "react-router-dom";

const VerificationSuccess = () => {
    return (
        <div className="layer-mask">
            <div className="msg-container-success">
                <div className="msg-box">
                    <div className="msg-box-header"><h3 className="text-center fw-bold">Congratulations!</h3></div>
                    <div className="msg-box-body text-center">
                        <p className="fw-bold">Your account has been successfully created!</p>
                        <p>So, to continue working, please login with your credentials.</p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <Link to="/login" className="btn-account-success mx-3 text-white">Login</Link>
                        or
                        <Link to="/property-search" className="mx-3 fc-account-success">Go to the Property Search</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificationSuccess;