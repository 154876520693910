import React from "react";
import "./styles/contact-form-styles.css";

import ReCAPTCHA from "react-google-recaptcha";
import "devextreme/dist/css/dx.light.css";
import Form, { SimpleItem, GroupItem } from "devextreme-react/form";
import Validator, { RequiredRule, EmailRule } from "devextreme-react/validator";
import ValidationGroup from 'devextreme-react/validation-group';

import { Button } from 'devextreme-react/button';
import SelectBox from "devextreme-react/select-box";
import TextArea from "devextreme-react/text-area";
import TextBox from "devextreme-react/text-box";
import CustomPhone from '../../assets/elements/input/custom-phone'
import Loading from '../../assets/controls/loading'
import baseService from "../../../services/base.service";

const ContactForm = () => {
    const validateSelectBox = React.useRef(null);
    const validateFirstName = React.useRef(null);
    const validateLastName = React.useRef(null);
    const validateEmail = React.useRef(null);
    const validateTextArea = React.useRef(null);
    const [loading, setLoading] = React.useState(false)
    const [firstName, setFirstName] = React.useState("");
    const [natureOfInquiry, setNatureOfInquiry] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [emailAddress, setEmailAddress] = React.useState("");
    const [comments, setComments] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [isPhoneValidState, setIsPhoneValidState] = React.useState(true)
    const [isPhoneValidMessage, setIsPhoneValidMessage] = React.useState("");
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [isCaptchaChecked, setIsCaptchaChecked] = React.useState(false);
    const captchaRef = React.useRef(null);
    const [isCaptchaErrorVisible, setIsCaptchaErrorVisible] = React.useState(false);

    const onValidation = _ => {
        if (!phone) {
            setIsPhoneValidState(false)
            setIsPhoneValidMessage('The phone is required')
        };

        if (!isCaptchaChecked) {
            setIsCaptchaErrorVisible(true);
            return;
        } else {
            setIsCaptchaErrorVisible(false);
        };
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (phone && isCaptchaChecked) {
            const selectBoxValidation = validateSelectBox.current.instance;
            const firstNameValidation = validateFirstName.current.instance;
            const lastNameValidation = validateLastName.current.instance;
            const emailValidation = validateEmail.current.instance;
            const messageValidation = validateTextArea.current.instance;

            if (!isCaptchaChecked) {
                setIsCaptchaErrorVisible(true);
                return;
            } else {
                setIsCaptchaErrorVisible(false);
            };

            if (isPhoneValidState) {
                setLoading(true)
                const data = {
                    natureOfInquiry: natureOfInquiry,
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phone,
                    emailAddress: emailAddress,
                    comments: comments
                };
                baseService({
                    method: "POST",
                    baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
                    url: "/api/v1/contactus",
                    data: data,
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(response => {
                    if (response) {
                        setSuccessAlert(true)
                        setPhone('')
                        setFirstName('')
                        setLastName('')
                        setEmailAddress('')
                        setComments('')
                        firstNameValidation.reset()
                        selectBoxValidation.reset()
                        lastNameValidation.reset()
                        emailValidation.reset()
                        messageValidation.reset()
                        captchaRef.current.reset();
                        setIsCaptchaChecked(false);
                        setTimeout(() => { setSuccessAlert(false); }, 5000)
                    }
                }).finally(() => { setLoading(false) }).catch(res => res);
            }
        }
    }

    const selectBoxItems = [{ id: 1, name: "General Inquiry" }, { id: 2, name: "Request A Quote" }];
    const submitButtonOptions = { text: "Send" };

    return (
        <section id="section-contact-form" className="contact-form-bg">
            <div className="row">
                <h3 className="text-uppercase text-center">get in touch with us</h3>
            </div>
            <form onSubmit={handleSubmit}>
                <ValidationGroup>
                    {successAlert &&
                        <div className="container w-50 text-center alert alert-success" role="alert">
                            Thank you for your inqury. We will get back to you shortly.
                        </div>
                    }
                    <div><Loading isVisble={loading} /></div>
                    <Form className="contact-form-container">
                        <GroupItem cssClass="row" colCount={1}>
                            <SimpleItem cssClass="contact-form-input">
                                <SelectBox items={selectBoxItems} valueExpr="id" displayExpr="name" placeholder="Please Select The Nature Of Your Inquiry*" className="col-md-12" value={natureOfInquiry} onValueChanged={e => setNatureOfInquiry(e.value)}>
                                    <Validator
                                        ref={validateSelectBox}
                                    >
                                        <RequiredRule message="Nature of Inquiry is required" />
                                    </Validator>
                                </SelectBox>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem cssClass="row" colCount={2}>
                            <SimpleItem cssClass="contact-form-input">
                                <TextBox placeholder="First Name *" className="col-md-6 w-100" value={firstName} onValueChanged={e => setFirstName(e.value)}>
                                    <Validator
                                        ref={validateFirstName}
                                    >
                                        <RequiredRule message="First Name is required" />
                                    </Validator>
                                </TextBox>
                            </SimpleItem>
                            <SimpleItem cssClass="contact-form-input">
                                <TextBox placeholder="Last Name *" className="col-md-6 w-100" value={lastName} onValueChanged={e => setLastName(e.value)}>
                                    <Validator
                                        ref={validateLastName}
                                    >
                                        <RequiredRule message="Last Name is required" />
                                    </Validator>
                                </TextBox>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem cssClass="row" colCount={2}>
                            <SimpleItem cssClass="contact-form-input">
                                <CustomPhone
                                    value={phone}
                                    placeholder='Phone Number *'
                                    setValue={setPhone}
                                    requiredValue={true}
                                    isValid={isPhoneValidState}
                                    setIsValid={setIsPhoneValidState}
                                    validMessage={isPhoneValidMessage}
                                    setValidMessage={setIsPhoneValidMessage}
                                />
                            </SimpleItem>
                            <SimpleItem cssClass="contact-form-input">
                                <TextBox placeholder="Email *" cssClass="col-md-6 w-100" value={emailAddress} onValueChanged={e => setEmailAddress(e.value)}>
                                    <Validator
                                        ref={validateEmail}
                                    >
                                        <RequiredRule message="Email is required" />
                                        <EmailRule message="Email is invalid" />
                                    </Validator>
                                </TextBox>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem cssClass="row" colCount={1}>
                            <SimpleItem cssClass="contact-form-input">
                                <TextArea placeholder="Desired Location, Questions or Comments *" height={100} className="col-md-12 w-100 rounded" value={comments} onValueChanged={e => setComments(e.value)}>
                                    <Validator
                                        ref={validateTextArea}
                                    >
                                        <RequiredRule message="Message is required" />
                                    </Validator>
                                </TextArea>
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem cssClass="d-flex mx-auto mt-3 mb-3 justify-content-center align-items-center" colCount={1}>
                            <SimpleItem cssClass="contact-form-input">
                                {isCaptchaErrorVisible && <div className="text-danger text-center mb-3">Please verify the reCAPTCHA.</div>}
                                <ReCAPTCHA
                                    ref={captchaRef}
                                    sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`}
                                    className="g-recaptcha contact-form-recaptcha"
                                    onChange={() => setIsCaptchaChecked(true)}
                                />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem cssClass="row mx-auto mt-3 mb-3" colCount={1}>
                            <SimpleItem cssClass="contact-form-input last-input">
                                <Button
                                    className='contact-form-button'
                                    disabled={loading}
                                    text={submitButtonOptions.text}
                                    useSubmitBehavior={true}
                                    onClick={onValidation}
                                />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </ValidationGroup>
            </form>
        </section>
    );
};

export default ContactForm;