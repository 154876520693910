import '../../styles/button/main-button.css'
import 'devextreme/dist/css/dx.light.css';
import { Button } from 'devextreme-react/button';
const MainButton = ({children,action,disabled,size}) => {
  return (
    <>
       {/* <button 
        onClick={action} 
        className={`main-button ${size === 'small' ? 'main-button--small' : ''}`}    
        disabled={disabled} 
        >{children}</button> */}
      <Button 
        onClick={action} 
        className={`main-button ${size === 'small' ? 'main-button--small' : ''}`}    
        useSubmitBehavior={true} 
        disabled={disabled} 
        text={children} />
    </>
  )
}

export default MainButton