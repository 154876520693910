import {useCallback,memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-input.css'

//components
import DropdownInput from '../../controls/dropdown-input'


const CustomDropdownInput = ({label,requiredValue,isDisabled,initialUsers,value,setValue, onSelectUser, onNewUser,messageLabel,isEmpty, setIsEmpty}) => {
  
  
  return (
    <div className='custom-input-alt'>
        <div className='custom-input-alt__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
        <DropdownInput value={value} setValue={setValue} initialUsers={initialUsers} onSelectUser={onSelectUser} onNewUser={onNewUser} messageLabel={messageLabel} isEmpty={isEmpty} setIsEmpty={setIsEmpty}/>
    </div>
  )
}

export default memo(CustomDropdownInput)