import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import userManager from "../utils/user-manager";
import { logout } from "../actions/auth";

const PostLogout = ({ profile, logout }) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (!isEmpty(profile)) {
        userManager.signoutRedirectCallback().then(response => {
            logout();
            navigate('/');
        }).catch((err) => {
            console.warn("PostLogout", err);
        });

        return <div className="section-content-wrapper">Redirecting...</div>;
    } else {
        return <Navigate to="/" state={{ from: location }} replace />
    }
};

function mapStateToProps({ auth }) {
    return {
        profile: auth.profile
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PostLogout);
