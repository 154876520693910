
import {memo,useState,useEffect,useRef} from 'react'
import 'devextreme/dist/css/dx.light.css';
import {
    Validator,
    RequiredRule
  } from 'devextreme-react/validator';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import {List} from 'devextreme-react/list';

//styles
import '../../styles/input/custom-dropdown-alt.css'
import '../../styles/input/custom-dropdown-multiple-alt.css'

const DropdownMultiple = ({values,value,onChangeValue,disable,classCustom,label,requiredValue,inputIcon,selectAllText,noBorders,placeholder}) => {
  const dropDownBoxRef = useRef(null);
  const refValidator = useRef(null);

  const [displayValues, setDisplayValues] = useState([])
  const changeDropDownBoxValue = (arg) => {
    
    let selectedValues

    if(arg.addedItems.length > 0 && arg.removedItems.length <=0)
    {
      if(arg.addedItems.length > 1)
      {
        selectedValues = [...displayValues,...arg.addedItems.map(item => item.id)]
      }
      else
        selectedValues = [...displayValues,arg.addedItems[0].id]
    }
    else{
        if(arg.removedItems.length === values.length)
        {
          selectedValues = []
        }
        else{
          selectedValues = [...displayValues]
          selectedValues.splice(selectedValues.indexOf(arg.removedItems[0].id),1)
        }
    }
    // if(selectedValues.length === values.length)
    //   onChangeValue(['all'])
    // else
    onChangeValue(selectedValues)

    setDisplayValues(selectedValues)
  }
  const onBlur = () =>{
    if(dropDownBoxRef.current)
    {
      dropDownBoxRef.current.instance.close()
    }
  }
  
  useEffect(() => {
    dropDownBoxRef?.current?.instance?.option('isValid',true)
  }, [value,values,displayValues])

  
  useEffect(() => {
    
    if(values.length > 0)
    {
      if(value.includes('all'))
      {
        setDisplayValues(values.map(value => value.id))
        onChangeValue(values.map(value => value.id))
      }
      else{
        setDisplayValues(value)
      }
    }
  }, [values])
  
  return (
    <div className={`custom-dropdown-alt ${noBorders ? 'custom-dropdown-alt--no-borders' : ''}`}>
        {label && <div className='custom-dropdown-alt__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>}
        <div className={`custom-dropdown-alt-multiple custom-dropdown-alt__container ${values.length === displayValues.length ? 'custom-dropdown-alt-multiple--hideText' : ''} ${inputIcon ? '' : 'custom-dropdown-alt__container--noIcon'}`}>
          <DropDownBox
          ref={dropDownBoxRef}
          // validationStatus='invalid'
          className={classCustom}
          value={displayValues}
          valueExpr="id"
          displayExpr="name"
          placeholder={values.length === displayValues.length ? (selectAllText ? selectAllText : "All") : (placeholder ? placeholder : 'Choose option')}
          dataSource={values}
          disabled={disable}
          onBlur={onBlur}
          >
          {requiredValue &&
              <Validator
                ref={refValidator}
              >
                <RequiredRule message={`${label} is required`} />
              </Validator>
          }
          <List
              className='custom-dropdown-alt-multiple__list'
              dataSource={values}
              keyExpr="id"
              showSelectionControls={true}
              selectionMode="all"
              selectAllText={selectAllText ? selectAllText : "Select All"}
              valueExpr="id"
              displayExpr="name"
              onSelectionChanged={changeDropDownBoxValue}
              selectedItemKeys={displayValues}
          />
          </DropDownBox>
          <div className='custom-dropdown-alt__container__icon'><img src={inputIcon}/></div>
        </div>
    </div>
  )
}
export default memo(DropdownMultiple)