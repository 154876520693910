import {
    SET_COMPLETED_TRANSACTION_ITEMS
} from "../constant/transaction-integration";
import store from "../store";

const setCompletedTransactions = (items) => ({ type: SET_COMPLETED_TRANSACTION_ITEMS, payload: items })

const startTrackingCompletedTransaction = (originalTransactionId) => {
    var completedTransactions = store.getState().transactions.completedTransactionDict;

    return new Promise((resolve, reject) => {

        var intervalId = setInterval(() => {

            if (Object.keys(completedTransactions).includes(originalTransactionId)) {

                var transaction = completedTransactions[originalTransactionId]
                if (!transaction.failed) {
                    clearInterval(intervalId);

                    delete completedTransactions[originalTransactionId];
                    store.dispatch(setCompletedTransactions(completedTransactions));

                    resolve(transaction);
                }else  if (transaction.failed && transaction.isFinalRetry) {
                    clearInterval(intervalId);

                    delete completedTransactions[originalTransactionId];
                    store.dispatch(setCompletedTransactions(completedTransactions));

                    reject(transaction);
                }
            }

        }, 50);
    });
}

const appendCompletedTransaction = (transaction) => {

    var completedTransactions = store.getState().transactions.completedTransactionDict;

    completedTransactions[transaction.originalTransactionId] = transaction;
    store.dispatch(setCompletedTransactions(completedTransactions));
}

export { startTrackingCompletedTransaction, appendCompletedTransaction }