import React from "react";
import MaintenanceComponent from "./maintenance-page/maintenanceComponent";

const MaintenancePage = () => {
    return (
        <div className="maintenance-page-container">
            <MaintenanceComponent />
        </div>
    );
};

export default MaintenancePage;