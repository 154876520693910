const StickyScrollbarNav = () => {
    const scrollbar = document.querySelector(".scrollbar-nav");
    const ctaTitle = document.querySelector(".cta-title");
    const pageHeight = window.scrollY;

    if (pageHeight < 1) {
        scrollbar.classList.add("sticky-scrollbar");
        ctaTitle.classList.add("cta-title-p-1");
    } else {
        scrollbar.classList.remove("sticky-scrollbar");
        ctaTitle.classList.remove("cta-title-p-1");
    };
};

export default StickyScrollbarNav;