export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const SET_LAST_NAME = "SET_LAST_NAME";
export const SET_EMAIL = "SET_EMAIL";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_LOGO = "SET_LOGO";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";
export const SET_LOADING = "SET_LOADING";
export const SET_ACCOUNT_UPDATED = "SET_ACCOUNT_UPDATED";
export const SET_GUESTS = "SET_GUESTS"
export const SET_LIST_GUESTS = "SET_LIST_GUESTS"