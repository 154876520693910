import '../../styles/button/action-button.css'
import 'devextreme/dist/css/dx.light.css';
import { Button } from 'devextreme-react/button';

const ActionButton = ({children,action,disabled,disableSubmit}) => {
  return (
    <Button
      className='action-button'
      disabled={disabled}
      text={children}
      useSubmitBehavior={disableSubmit ? false : true}
      onClick={action} />
  )
}

export default ActionButton