import {
    SET_LOAD_INDICATOR_VISIBILITY
} from "../constant/load-indicator";

const defaultState = {
    isVisible: false
};

export default function loadIndicatorReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_LOAD_INDICATOR_VISIBILITY:
            return {
                isVisible: action.payload
            };
        default:
            return state;
    }
}