import React,{useRef,useEffect,useState} from 'react'
import PropertyIcon from '../../../../images/property_pin.png'
const MapByLocation = ({latitude,longitude,name}) => {
  const refMap = useRef()
  const propertyiconCustom = {
    url: PropertyIcon,
    scaledSize: new window.google.maps.Size(45,65), 
  }
  const [map,setMap] = useState({})
  useEffect(() => {
    if(window.google)
    {    
      setMap(new window.google.maps.Map(refMap.current,{
        streetViewControl: false,
        zoom: 13,
        mapTypeControl: false,
        center:{lat: latitude, lng: longitude}
      }))
    }
  }, [])
  
  useEffect(() => {
    
    if(Object.keys(map).length > 0)
    {
        new window.google.maps.Marker({
          position: {lat: latitude, lng: longitude},
          map: map,
          icon: propertyiconCustom,
        });
    }

  }, [map])
  
  return (
    <div ref={refMap} style={{height:'60vh'}}></div>
  )
}

export default MapByLocation