import {
    SET_FIRST_NAME,
    SET_LAST_NAME,
    SET_EMAIL,
    SET_PHONE_NUMBER,
    SET_LOGO,
    UPDATE_USER_DATA,
    SET_PROFILE_IMAGE,
    SET_LOADING,
    SET_ACCOUNT_UPDATED,
    SET_GUESTS,
    SET_LIST_GUESTS
} from "../constant/user-form";

export const setAccountUpdated = (isAccountUpdated) => ({
    type: SET_ACCOUNT_UPDATED,
    payload: isAccountUpdated,
});

export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading,
});

export const setProfileImage = (profileImage) => ({
    type: SET_PROFILE_IMAGE,
    payload: profileImage,
});

export const setFirstName = (firstName) => ({
    type: SET_FIRST_NAME,
    payload: firstName,
});

export const setLastName = (lastName) => ({
    type: SET_LAST_NAME,
    payload: lastName,
});

export const setEmail = (email) => ({
    type: SET_EMAIL,
    payload: email,
});

export const setPhoneNumber = (phoneNumber) => ({
    type: SET_PHONE_NUMBER,
    payload: phoneNumber,
});

export const setLogo = (logo) => ({
    type: SET_LOGO,
    payload: logo,
});

export const updateUserData = (userData) => ({
    type: UPDATE_USER_DATA,
    payload: userData,
});

export const setGuests = data => ({
    type: SET_GUESTS,
    payload: data,
});

export const setListGuests = data => ({
    type: SET_LIST_GUESTS,
    payload: data,
});