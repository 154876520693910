import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { List } from "react-bootstrap-icons";
import "./styles/scrollbar-styles.css";
import FixedScrollbarNav from "./Sub-Components/FixedScrollbarNav";
import StickyScrollbarNav from "./Sub-Components/StickyScrollbarNav";
import { Link } from "react-scroll";

const Scrollbar = () => {
    const [scrollY, setScrollY] = React.useState(0);

    const handleScroll = () => {
        if (document.querySelector(".scrollbar-nav") && document.querySelector(".cta-title")) {
            StickyScrollbarNav();
            FixedScrollbarNav();
        }
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    React.useEffect(() => {
        const handleScrollY = () => setScrollY(window.scrollY);
        window.addEventListener('scroll', handleScrollY);
        return () => window.removeEventListener('scroll', handleScrollY);
    }, []);

    return (
        <>
            <Navbar expand="md" className="sticky-scrollbar scrollbar-nav mx-auto" style={{ margin: scrollY >= 695 ? '0' : '-45px 0 0 0' }}>
                <div className="scrollbar-nav__container d-flex justify-content-end align-items-end container-fluid">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav">
                        <List width="27px" height="27px" />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav border">
                        <Nav className="scrollbar">
                            <li className="scrollbar-nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                <Link
                                    activeClass="active"
                                    to="section-cta"
                                    spy={true}
                                    smooth={true}
                                    offset={-180}
                                    duration={300}
                                >
                                    What is the Oasis Competitive Difference?
                                </Link>
                            </li>
                            <li className="scrollbar-nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                <Link
                                    activeClass="active"
                                    to="section-our-solutions"
                                    spy={true}
                                    smooth={true}
                                    offset={-240}
                                    duration={300}
                                >
                                    Our Solutions
                                </Link>
                            </li>
                            <li className="scrollbar-nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                <Link
                                    activeClass="active"
                                    to="section-our-awards"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={300}
                                >
                                    Awards &amp; Accolades
                                </Link>
                            </li>
                            <li className="scrollbar-nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                <Link
                                    activeClass="active"
                                    to="section-featured-cities"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={300}
                                >
                                    Featured Cities
                                </Link>
                            </li>
                            <li className="scrollbar-nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                <Link
                                    activeClass="active"
                                    to="section-contact-form"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={300}
                                >
                                    Get In Touch With Us
                                </Link>
                            </li>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </>
    );
};

export default Scrollbar;