import React from "react";
import { Button } from "devextreme-react/button";
import { PencilFill } from "react-bootstrap-icons";
import Loading from "../../assets/controls/loading";

const ManageButton = ({ isLoading, handleMenuEvents,label }) => {
    return (
        <div className="manage-account-info-btn-container">
                {!isLoading &&
                    <span onClick={handleMenuEvents}><PencilFill />&nbsp;{label}</span>
                }
                <Loading isVisble={isLoading} />
        </div>
    );
};

export default ManageButton;