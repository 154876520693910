import {
    SET_LOADING_FORGOT
} from "../constant/forgot-reset";

const defaultState = {
    loading: false
};

export default function favoritesReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_LOADING_FORGOT:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
}