import React, { useEffect } from "react";

const NotMatchRout = () => {
    const pageStyles = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
        textAlign: "center"
    };

    const blueText = {
        color: "#008da9"
    }

    useEffect(() => {
        setInterval(() => {
            window.location.replace("/");
        }, 2000);
    },[])

    return (
        <>
            <div style={pageStyles}>
                <h2 className="mb-3">{`Redirecting to `}
                    <span style={blueText}>Landing page</span>
                    .......
                </h2>
            </div>
        </>
    );
};

export default NotMatchRout;