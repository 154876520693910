import React from "react";
import "./styles/property-request-styles.css";
import "devextreme/dist/css/dx.light.css";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import Validator, { RequiredRule, EmailRule } from "devextreme-react/validator";
import SelectBox from "devextreme-react/select-box";
import TextBox from "devextreme-react/text-box";
import RadioGroup from "devextreme-react/radio-group";
import { DateBox } from "devextreme-react/date-box";
import CheckBox from "devextreme-react/check-box";
import { Button } from "devextreme-react/button";
import NumberBox from "devextreme-react/number-box";
import TotalAdultsNumberBox from "./Sub-Components/TotalAdultsNumberbox";
import TotalChildrenNumberBox from "./Sub-Components/TotalChildrenNumberbox";
import ReCAPTCHA from "react-google-recaptcha";
import baseService from "../../services/base.service";
import { connect } from "react-redux";
import {
    updateCompany, updateFirstName, updateLastName, updatePhoneNumber, updateEmailAddress, updateAccommodationLevel, updatePropertyName, updateMaximumCommuteDistance, updateMaximumCommuteDistanceType, updateNightlyBudget, updateCheckInDate, updateCheckOutDate, updateDatesIsFlexible, updateTotalAdults, updateTotalChildren, updateSpecialRequirements, updateQuoteForm
} from "../../utils/property-request";
import { setPropertyDetails } from '../../utils/property-details'
import { accomodationLevelItems, unitItems } from "../../constant/property-request";
import { useParams, useNavigationType, useNavigate } from "react-router-dom";
import PropertyRequestSuccessMessage from "../property-request-success";
import PropertyRequestErrorMessage from "../property-request-error";
import CustomPhone from '../assets/elements/input/custom-phone'
import CustomEditor from '../assets/elements/input/custom-html-editor'
import ValidationGroup from "devextreme-react/validation-group";

import SearchDestinationCity from '../assets/elements/input/search-location'
import GoogleWrapper from '../assets/controls/google-wrapper'
import RequestHelper from "../../utils/helper";

const PropertyRequestForm = ({ propertyDetails, propertyAddress, propertyFloorPlans, company, firstName, lastName, phoneNumber, emailAddress, accommodationLevel, propertyName, maximumCommuteDistance, maximumCommuteDistanceType, nightlyBudget, checkInDate, checkOutDate, datesIsFlexible, totalAdults, totalChildren, specialRequirements, propertyNameValue, selectedFloorPlanItem }) => {
    const { id } = useParams();
    const [renderMessage, setRenderMessage] = React.useState(false);
    const [budgetCurrencyId, setBudgetCurrencyId] = React.useState([]);
    const [selectedCurrency, setSelectedCurrency] = React.useState(0);
    const [numberOfBedrooms, setNumberOfBedrooms] = React.useState([]);
    const [selectedFloorPlan, setSelectedFloorPlan] = React.useState(null);
    const [radioGroupValue, setRadioGroupValue] = React.useState(null);
    const [submitAttempted, setSubmitAttempted] = React.useState(false);
    const [showErrorMessage, setShowErrorMessage] = React.useState(false);
    const [isCaptchaChecked, setIsCaptchaChecked] = React.useState(false);
    const captchaRef = React.useRef(null);
    const [isCaptchaErrorVisible, setIsCaptchaErrorVisible] = React.useState(false);
    const [isPhoneValidState, setIsPhoneValidState] = React.useState(true)
    const [isPhoneValidMessage, setIsPhoneValidMessage] = React.useState("");
    const [destinationCityInput, setDestinationCityInput] = React.useState({
        id: '',
        value: ''
    })
    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const emailAddressRef = React.useRef(null);
    const accommodationLevelRef = React.useRef(null);
    const propertyNameRef = React.useRef(null);
    const floorPlanRef = React.useRef(null);
    const maximumCommuteDistanceRef = React.useRef(null);
    const maximumCommuteDistanceTypeRef = React.useRef(null);
    const validationGroupRef = React.useRef(null);

    const [selectBoxKey, setSelectBoxKey] = React.useState(0);

    React.useEffect(() => {
        async function fetchFloorPlans() {
            await baseService({
                method: "GET",
                baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
                url: "/api/v1/dictionaries/floorplans",
                headers: { "Content-Type": "application/json", },
            }).then(res => setNumberOfBedrooms(res.data)).catch(res => res);
        };
        async function fetchCurrencies() {
            await baseService({
                method: "GET",
                baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
                url: "/api/v1/dictionaries/currency",
                headers: { "Content-Type": "application/json", },
            }).then((res) => setBudgetCurrencyId(res.data)).catch((res) => res);
        };
        fetchFloorPlans();
        fetchCurrencies();
    }, []);

    React.useEffect(() => {
        propertyAddress && setDestinationCityInput({
            ...destinationCityInput,
            value: propertyAddress.formattedAddress
        });
    }, [propertyAddress]);

    React.useEffect(() => {
        id && Object.keys(propertyDetails).length <= 0 && setPropertyDetails(id);
    }, [id]);

    const handleCaptchaExpired = () => {
        setIsCaptchaChecked(false);
    };

    const handleSubmit = async (e) => {
        e.event.preventDefault();
        setSubmitAttempted(true);

        if (!phoneNumber) {
            setIsPhoneValidState(false)
            setIsPhoneValidMessage('The phone is required')
        };

        if (!isCaptchaChecked) {
            setIsCaptchaErrorVisible(true);
        } else {
            setIsCaptchaErrorVisible(false);
        };

        if (isPhoneValidState && phoneNumber) {
            radioGroupValue === 1 || radioGroupValue === 2 || radioGroupValue === 3 ? setSubmitAttempted(false) : setSubmitAttempted(true);
            const propertyId = parseInt(id, 10);
            const data = {
                company,
                firstName,
                lastName,
                phoneNumber,
                emailAddress,
                destinationCity: destinationCityInput.value,
                accomodationLevel: accommodationLevel,
                propertyName,
                maximumCommuteDistance,
                maximumCommuteDistanceType,
                checkInDate,
                checkOutDate,
                datesIsFlexible,
                totalAdults,
                totalChildren,
                specialRequirements,
                propertyId,
                nightlyBudget,
                numberOfBedrooms: parseInt(selectedFloorPlan, 10),
                budgetCurrencyId: selectedCurrency,
                distance: 0
            };

            await baseService({
                method: "POST",
                baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
                url: "/api/v1/quotes",
                data: data,
                headers: { "Content-Type": "application/json", },
            }).then(res => {
                if (res.status === 200) {
                    updateQuoteForm();
                    validationGroupRef.current.instance.reset();
                    setSelectBoxKey(selectBoxKey + 1);
                    captchaRef.current.reset();
                    setIsCaptchaChecked(false);
                    // show success message 
                    setRenderMessage(true);
                };
            }).catch(res => {
                if (res.error === 500) {
                    updateQuoteForm();
                    setRenderMessage(true);
                    setShowErrorMessage(true);
                };
            });
        };
    };

    React.useEffect(() => {
        updatePropertyName(propertyNameValue);
    }, [propertyNameValue]);

    const primaryCurrencies = budgetCurrencyId.filter((currency) => currency.sortOrder !== null).sort((a, b) => a.sortOrder - b.sortOrder);
    const secondaryCurrencies = budgetCurrencyId.filter((currency) => currency.sortOrder === null);
    const dataSource = [
        { key: "Primary", items: primaryCurrencies },
        { key: "Secondary", items: secondaryCurrencies },
    ];
    const groupHeaderTemplate = (groupData) => groupData.key;

    const [persistFloorPlan, setPersistFloorPlan] = React.useState(null);
    React.useEffect(() => {
        const storedSelectedFloorPlan = localStorage.getItem("selectedFloorPlanItem");
        storedSelectedFloorPlan ? setPersistFloorPlan(JSON.parse(storedSelectedFloorPlan)) : setPersistFloorPlan(selectedFloorPlanItem);
    }, []);

    return (
        <>
            <section className="property-request">
                <div className="property-request__header-image">
                    <div className="row">
                        <h1 className="property-request__header-text">property request</h1>
                    </div>
                </div>
                <div className="property-request__form">
                    {renderMessage && !showErrorMessage ? <PropertyRequestSuccessMessage /> : null}
                    {showErrorMessage ? <PropertyRequestErrorMessage /> : null}
                    {!renderMessage ?
                        <ValidationGroup ref={validationGroupRef}>
                            <Form className="property-request-form-container container">
                                <GroupItem colCount={1} cssClass="one-col-input">
                                    <SimpleItem>
                                        <div className="row">
                                            <h1 className="property-request__contact-title">contact information</h1>
                                        </div>
                                    </SimpleItem>
                                    <SimpleItem cssClass="label label-flex-wrap">
                                        <Label text="Company" />
                                        <TextBox value={company} onValueChanged={(e) => updateCompany(e.value)} />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colCount={1} cssClass="one-col-input">
                                    <SimpleItem cssClass="label-required label-flex-wrap">
                                        <Label text="First Name" isRequired={true} />
                                        <TextBox value={firstName} onValueChanged={(e) => updateFirstName(e.value)} ref={firstNameRef}>
                                            <Validator>
                                                <RequiredRule message="First Name is required" />
                                            </Validator>
                                        </TextBox>
                                    </SimpleItem>
                                    <SimpleItem cssClass="label-required label-flex-wrap">
                                        <Label text="Last Name" isRequired={true} />
                                        <TextBox value={lastName} onValueChanged={(e) => updateLastName(e.value)} ref={lastNameRef}>
                                            <Validator>
                                                <RequiredRule message="Last Name is required" />
                                            </Validator>
                                        </TextBox>
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colCount={1} cssClass="one-col-input">
                                    <SimpleItem cssClass="label-required label-flex-wrap">
                                        <Label text="Phone" isRequired={true} />
                                        <CustomPhone
                                            value={phoneNumber}
                                            setValue={updatePhoneNumber}
                                            requiredValue={true}
                                            isValid={isPhoneValidState}
                                            setIsValid={setIsPhoneValidState}
                                            validMessage={isPhoneValidMessage}
                                            setValidMessage={setIsPhoneValidMessage}
                                        />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colCount={1} cssClass="one-col-input">
                                    <SimpleItem cssClass="label-required label-flex-wrap">
                                        <Label text="Email" isRequired={true} className="label" />
                                        <TextBox value={emailAddress} onValueChanged={(e) => updateEmailAddress(e.value)} ref={emailAddressRef}>
                                            <Validator>
                                                <RequiredRule message="Email is required" />
                                                <EmailRule message="Email is invalid" />
                                            </Validator>
                                        </TextBox>
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colCount={1} cssClass="one-col-input">
                                    <SimpleItem>
                                        <div className="row">
                                            <h1 className="property-request__property-title">property information</h1>
                                        </div>
                                    </SimpleItem>
                                    <SimpleItem cssClass="label-required label-flex-wrap">
                                        <Label text="Destination City" isRequired={true} />
                                        <GoogleWrapper>
                                            <SearchDestinationCity inputId='propertyRequestSearchDestinyCity' location={destinationCityInput} onChange={setDestinationCityInput} requiredValue={true} />
                                        </GoogleWrapper>
                                        <span className="sub-text-color">Providing a detailed address as your destination will ensure we offer you properties that are closest to your requested location</span>
                                    </SimpleItem>
                                    <SimpleItem cssClass="label-required label-flex-wrap">
                                        <Label text="Accommodation Level" isRequired={true} />
                                        <RadioGroup
                                            dataSource={accomodationLevelItems}
                                            displayExpr={"name"}
                                            layout="horizontal"
                                            valueExpr={(item) => item.value}
                                            value={radioGroupValue}
                                            onValueChanged={(e) => {
                                                setRadioGroupValue(e.value);
                                                updateAccommodationLevel(e.value);
                                            }}
                                            ref={accommodationLevelRef}
                                        >
                                            <Validator validationStatus={submitAttempted && !radioGroupValue ? "invalid" : "valid"}>
                                                <RequiredRule message="Accommodation Level is required" />
                                            </Validator>
                                        </RadioGroup>
                                    </SimpleItem>
                                    <SimpleItem cssClass="label-required label-flex-wrap">
                                        <Label text="Property Name" isRequired={true} />
                                        <TextBox
                                            value={propertyName}
                                            onValueChanged={(e) => updatePropertyName(e.value)}
                                            ref={propertyNameRef}
                                            disabled={true}
                                        >
                                            <Validator>
                                                <RequiredRule message="Property Name is required" />
                                            </Validator>
                                        </TextBox>
                                    </SimpleItem>
                                    <SimpleItem cssClass="label-required label-flex-wrap">
                                        <Label text="Floor Plan" isRequired={true} />
                                        <SelectBox
                                            dataSource={propertyFloorPlans ? propertyFloorPlans : numberOfBedrooms}
                                            valueExpr={"id"}
                                            displayExpr={"name"}
                                            placeholder=""
                                            value={persistFloorPlan}
                                            onValueChanged={(e) => {
                                                setSelectedFloorPlan(e.value);
                                                setPersistFloorPlan(e.value);
                                                localStorage.setItem("selectedFloorPlanItem", JSON.stringify(e.value));
                                            }}
                                            ref={floorPlanRef}
                                        >
                                            <Validator>
                                                <RequiredRule message="Floor Plan is required" />
                                            </Validator>
                                        </SelectBox>
                                    </SimpleItem>
                                    <SimpleItem cssClass="label-required label-flex-wrap">
                                        <Label text="Maximum commute distance" isRequired={true} />
                                        <div className="d-flex align-items-center">
                                            <NumberBox
                                                className="mr-2 distance-input"
                                                defaultValue=""
                                                showSpinButtons={false}
                                                value={maximumCommuteDistance === 0 ? null : maximumCommuteDistance}
                                                onValueChanged={(e) => updateMaximumCommuteDistance(e.value)}
                                                ref={maximumCommuteDistanceRef}
                                                max={1000}
                                                min={1}
                                            >
                                                <Validator>
                                                    <RequiredRule message="Maximum commute distance is required" />
                                                </Validator>
                                            </NumberBox>
                                            <SelectBox
                                                key={selectBoxKey}
                                                items={unitItems.map((item) => item.text)}
                                                value={maximumCommuteDistanceType === 0 ? "" : maximumCommuteDistanceType === 1 ? "miles" : maximumCommuteDistanceType === 2 ? "kilometers" : null}
                                                onValueChanged={(e) => {
                                                    if (e.value === "miles") {
                                                        updateMaximumCommuteDistanceType(1);
                                                    } else if (e.value === "kilometers") {
                                                        updateMaximumCommuteDistanceType(2);
                                                    } else {
                                                        updateMaximumCommuteDistanceType(0);
                                                    }
                                                }}
                                                placeholder="miles / kilometers"
                                                className="distance-box"
                                            >
                                                <Validator ref={maximumCommuteDistanceTypeRef}>
                                                    <RequiredRule message="Maximum commute distance type is required" />
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colCount={2} cssClass="two-col-input">
                                    <SimpleItem cssClass="label label-flex-wrap">
                                        <Label text="Nightly budget" />
                                        <NumberBox
                                            defaultValue=""
                                            value={nightlyBudget === 0 ? null : nightlyBudget}
                                            onValueChanged={(e) => updateNightlyBudget(e.value)}
                                            max={10000}
                                            min={1}
                                        />
                                    </SimpleItem>
                                    <SimpleItem cssClass="label label-flex-wrap">
                                        <Label text="Currency" />
                                        <SelectBox
                                            dataSource={dataSource}
                                            placeholder=""
                                            displayExpr="name"
                                            valueExpr="id"
                                            grouped={true}
                                            groupTemplate={groupHeaderTemplate}
                                            value={selectedCurrency}
                                            onValueChanged={(e) => setSelectedCurrency(e.value)}
                                        />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colCount={2} cssClass="two-col-input">
                                    <SimpleItem cssClass="label label-flex-wrap">
                                        <Label text="Check-in/Check-out dates" />
                                        <div className="d-flex justify-content-center align-items-center">
                                            <DateBox
                                                type="date"
                                                displayFormat={RequestHelper.getLocalDateFormat()}
                                                className="w-50"
                                                value={checkInDate}
                                                onValueChanged={(e) => updateCheckInDate(e.value)}
                                            />
                                            <span>&nbsp;-&nbsp;</span>
                                            <DateBox
                                                type="date"
                                                displayFormat={RequestHelper.getLocalDateFormat()}
                                                className="w-50"
                                                value={checkOutDate}
                                                onValueChanged={(e) => updateCheckOutDate(e.value)}
                                            />
                                        </div>
                                    </SimpleItem>
                                    <SimpleItem cssClass="label checkbox">
                                        <CheckBox text="My dates are flexible" value={datesIsFlexible} onValueChanged={(e) => updateDatesIsFlexible(e.value)} />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colCount={2} cssClass="two-col-input">
                                    <SimpleItem cssClass="label label-flex-wrap">
                                        <Label text="Total Adults" />
                                        <TotalAdultsNumberBox totalAdults={totalAdults} updateTotalAdults={(value) => updateTotalAdults(value)} />
                                    </SimpleItem>
                                    <SimpleItem cssClass="label label-flex-wrap">
                                        <Label text="Total Children" />
                                        <TotalChildrenNumberBox totalChildren={totalChildren} updateTotalChildren={(value) => updateTotalChildren(value)} />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colCount={1} cssClass="one-col-input">
                                    <SimpleItem cssClass="label label-flex-wrap">
                                        <Label text="Questions or Special Requirements" />
                                        <CustomEditor
                                            value={specialRequirements}
                                            setValue={updateSpecialRequirements}
                                        />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colCount={1} cssClass="one-col-input">
                                    <SimpleItem cssClass="label label-flex-wrap">
                                        {isCaptchaErrorVisible && <div className="text-danger text-center mb-3">Please verify the reCAPTCHA.</div>}
                                        <ReCAPTCHA
                                            ref={captchaRef}
                                            sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`}
                                            className="g-recaptcha property-request-recaptcha"
                                            onChange={() => {
                                                setIsCaptchaChecked(true);
                                                setIsCaptchaErrorVisible(false);
                                            }}
                                            onExpired={() => handleCaptchaExpired()}
                                        />
                                    </SimpleItem>
                                </GroupItem>
                                <GroupItem colCount={1} cssClass="one-col-input">
                                    <SimpleItem cssClass="property-request-submit-btn">
                                        <Button text="Submit" type="success" className="property-request-submit-btn-button" useSubmitBehavior={true} onClick={handleSubmit} />
                                    </SimpleItem>
                                </GroupItem>
                            </Form>
                        </ValidationGroup> : null}
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        company: state.propertyRequest.company,
        firstName: state.propertyRequest.firstName,
        lastName: state.propertyRequest.lastName,
        phoneNumber: state.propertyRequest.phoneNumber,
        emailAddress: state.propertyRequest.emailAddress,
        destinationCity: state.propertyRequest.destinationCity,
        accommodationLevel: state.propertyRequest.accommodationLevel,
        propertyName: state.propertyRequest.propertyName,
        maximumCommuteDistance: state.propertyRequest.maximumCommuteDistance,
        maximumCommuteDistanceType: state.propertyRequest.maximumCommuteDistanceType,
        nightlyBudget: state.propertyRequest.nightlyBudget,
        checkInDate: state.propertyRequest.checkInDate,
        checkOutDate: state.propertyRequest.checkOutDate,
        datesIsFlexible: state.propertyRequest.datesIsFlexible,
        totalAdults: state.propertyRequest.totalAdults,
        totalChildren: state.propertyRequest.totalChildren,
        specialRequirements: state.propertyRequest.specialRequirements,
        propertyDetails: state.details.propertyDetails,
        propertyAddress: state.details.propertyDetails.address,
        propertyFloorPlans: state.details.propertyDetails.availableFloorPlans,
        propertyNameValue: state.details.propertyDetails.name,
        selectedFloorPlanItem: state.details.floor,
    };
};

export default connect(mapStateToProps)(PropertyRequestForm);