import {
    SET_MY_REQUESTS,
    SET_ALL_REQUESTS,
    SET_MY_REQUESTS_LOADING,
    SET_MY_REQUESTS_DEFAULT,
    SET_MY_REQUESTS_OPTION_DETAILS,
    SET_MY_REQUESTS_OPTION_AMENITIES,
    SET_MY_REQUESTS_OPTION_LOADING,
    SET_MY_REQUESTS_STATUS,
    SET_MY_REQUESTS_OPTION_STATUS,
    SET_MY_REQUESTS_SKIP
} from "../constant/my-requests";

export const setRequests = (total, data) => ({
    type: SET_MY_REQUESTS,
    payload: {
        totalCount: total,
        data: data
    }
});

export const setAllRequests = data => ({
    type: SET_ALL_REQUESTS,
    payload: data
});

export const setRequestsSkip = data => ({
    type: SET_MY_REQUESTS_SKIP,
    payload: data
});

export const setLoading = value => ({
    type: SET_MY_REQUESTS_LOADING,
    payload: value 
});

export const setOptionLoading = value => ({
    type: SET_MY_REQUESTS_OPTION_LOADING,
    payload: value 
});

export const setRequestOptionDetails = value => ({
    type: SET_MY_REQUESTS_OPTION_DETAILS,
    payload: value 
});

export const setMyRequestOptionAmenities = (title,value) => ({
    type: SET_MY_REQUESTS_OPTION_AMENITIES,
    payload: {
        title,
        value
    }
});

export const setRequestsDefault = _ => ({
    type: SET_MY_REQUESTS_DEFAULT
});

export const setRequestStatus = (requestId,statusId) => ({
    type: SET_MY_REQUESTS_STATUS,
    payload: {
        requestId,
        statusId
    } 
});

export const setRequestOptionStatus = (requestId,optionId,statusId) => ({
    type: SET_MY_REQUESTS_OPTION_STATUS,
    payload: {
        requestId,
        optionId,
        statusId
    } 
});