import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import RequestList from './request-list'

function RequestTabs() {
    const [key, setKey] = useState("active-request");

    return (
        <div>
            <Tabs
                id="request-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 request-tabs"
            >
                <Tab eventKey="active-request" title="Active Request" />
                <Tab eventKey="past-request" title="Past Request"/>
                <Tab eventKey="cancelled-request" title="Cancelled Request"/>
            </Tabs>
            <RequestList type={key}/>
        </div>
    );
};

export default RequestTabs;