import axios from "axios";
import { USER_ACCESS_TOKEN } from '../constant/session'
import store from "../store";
import { receiveUserProfileData } from "../actions/auth";
import { getIsAdmin } from '../utils/admin'

const AuthorizationService = {
    
    setUserAccessToken(accessToken) {
        return this.getUserToken(accessToken)
            .then(res => {
                if (res.status === 200) {
                    const profile = this.parseJwt(res.data);
                    store.dispatch(receiveUserProfileData(profile));
                    localStorage.setItem(USER_ACCESS_TOKEN,
                        JSON.stringify({
                            token: res.data,
                            profile: profile,
                            expires_at: new Date(profile.exp * 1000)
                        })
                    );

                    if(getIsAdmin())
                        this.createCookieInMinutes("service.authentication", res.data, 10);

                }
                return new Promise((resolve, reject) => { resolve(res)});
            });
    },

    logout() {
        localStorage.removeItem(USER_ACCESS_TOKEN);
        store.dispatch(receiveUserProfileData(null));
    },

    getUserToken(accessToken) {
        return axios.post(`${process.env.REACT_APP_USER_MANAGEMENT}/api/v1/users/token`, {},
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": `Bearer ${accessToken}`
                }
            });
    },

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    },

    createCookieInMinutes(cookieName, cookieValue, minutesToExpire) {
        let date = new Date();
        date.setTime(date.getTime() + (minutesToExpire * 60 * 1000));
        document.cookie = cookieName + " = " + cookieValue + "; expires = " + date.toGMTString();
    },
}

export { AuthorizationService }