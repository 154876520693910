import '../styles/presentation-tag.css'

const PresentationTag = ({data}) => {
  return (
    <div className='presentation-tag'>
        <div className='presentation-tag__img'>
          <img src={data.image}/>
        </div>
        <div className='presentation-tag__information'>
            <p>{data.description}</p>
            <p dangerouslySetInnerHTML={{__html:data.personal}}></p>
        </div>
    </div>
  )
}

export default PresentationTag