import React from "react";

const ValidationIcon = (props) => {
    const iconClassName = props.isValid ? "validation-success" : props.isInvalid ? "validation-error" : "validation-default";

    return (
        <div className="requirement">
            <i className={`fas fa-check-circle ${iconClassName}`} />
            <p>{props.text}</p>
        </div>
    );
};

export default ValidationIcon;