//components
import ProcessButton from '../assets/elements/button/action-button'
import DeleteButton from '../assets/elements/button/delete-button'
import CancelButton from '../assets/elements/button/secondary-action-button'

//styles
import './styles/request-modal-content.css'

const RequestsDelete = ({title,message,onCancel,onAccept,isDelete}) => {
  return (
    <div className='request-modal-content'>
        <span className='request-modal-content__title'>{title}</span>
        <span className='request-modal-content__message'>{message}</span>
        <div className='request-modal-content__buttons'>
            {
              isDelete ?  
                <DeleteButton action={onAccept}>Delete</DeleteButton>
                :
                <ProcessButton action={onAccept}>Process</ProcessButton>
            }
            <CancelButton action={onCancel}>Cancel</CancelButton>
        </div>
    </div>
  )
}

export default RequestsDelete