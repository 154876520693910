import React from "react";
import { Link } from "react-router-dom";
import AdminEditArticleModal from "./AdminEditArticleModal";
import Eye from "../../../images/admin-icons/eye-icon.svg";
import Pencil from "../../../images/admin-icons/pencil-icon.svg";
import {ReactComponent as TrashCan} from "../../../images/admin-icons/trash-icon.svg";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import baseService from "../../../services/base.service";

const NewsSectionArticleAdmin = ({ id, image, alt, title, date, time, author, summary, onArticleDeleted, onArticleUpdated }) => {
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = React.useState(false);
    const [articleTitle, setArticleTitle] = React.useState(null);

    const handleEditClick = () => setShowEditModal(true);
    const handleCloseEditModal = () => setShowEditModal(false);

    const handleDelete = async () => {
        try {
            const response = await baseService({
                method: "GET",
                baseURL: process.env.REACT_APP_API_ENDPOINT,
                url: `/api/v1/news/${id}`,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            setArticleTitle(response.data.title);
            setShowConfirmDeleteModal(true);
        } catch (error) {
            console.log(error);
        };
    };

    return (
        <>
            {id ? (
                <div className="row news-section-article flex-wrap mb-3">
                    {image ? (
                        <div className="d-flex justify-content-start align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-2 p-0">
                            <div className="img-container">
                                <img src={image} alt={alt} style={{ width: "295px", height: "184px" }} />
                            </div>
                        </div>
                    ) : null}
                    {image ? (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-10 p-0 ps-lg-5 p-xl-0 ps-xxl-5">
                            <div className="row justify-content-start align-items-center">
                                <div className="col-md-8 col-lg-6 col-xl-8 col-xxl-9 pe-md-0 ps-lg-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-2">
                                    <span className="news-section-article__title admin-breakpoint">
                                        {title.substring(0, 70) + "..."}
                                    </span>
                                </div>
                                <div className="col-md-4 col-lg-6 col-xl-4 col-xxl-3 ps-xxl-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-2 py-2 py-md-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img src={Eye} alt="eye-icon" className="eye-icon" />&nbsp;
                                            <Link to={`/news/${id}`} className="text-oasis m-0 p-0">
                                                View
                                            </Link>
                                            &nbsp;
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img src={Pencil} alt="pencil-icon" className=" pencil-icon" />&nbsp;
                                            <button className="text-oasis m-0 p-0" onClick={handleEditClick}>
                                                Edit
                                            </button>
                                            &nbsp;
                                        </div>
                                        {showEditModal ? (
                                            <AdminEditArticleModal id={id} onClose={handleCloseEditModal} onArticleUpdated={onArticleUpdated} />
                                        ) : null}
                                        <div className="d-flex justify-content-center align-items-center">
                                            <TrashCan style={{fill:'#d9534f'}} />&nbsp;
                                            <button className="delete-btn m-0 p-0" onClick={handleDelete}>
                                                Delete
                                            </button>
                                        </div>
                                        {showConfirmDeleteModal ? (
                                            <ConfirmDeleteModal
                                                id={id}
                                                closeModal={() => setShowConfirmDeleteModal(false)}
                                                articleTitle={articleTitle}
                                                onArticleDeleted={onArticleDeleted}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-start align-items-center admin-breakpoint">
                                <div className="col-xl-10 col-lg-12 pe-lg-0 pe-lg-2 ps-lg-0">
                                    <div className="news-section-article__date-author-info mt-2 mb-2">
                                        <span className="news-section-article__date">{date}</span>,&nbsp;
                                        <span className="news-section-article__time">{time}</span> by&nbsp;
                                        <span className="news-section-article__author">{author}</span>&nbsp;
                                    </div>
                                    <span className="news-section-article__summary" dangerouslySetInnerHTML={{ __html: summary.substring(0, 260) }} />
                                    <Link to={`/news/${id}`} className="news-section-article__read-more">
                                        Full Story &gt;&gt;
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="col-sm-12">
                            <div className="row justify-content-start align-items-center">
                                <div className="col-sm-12 col-md-8 col-lg-9 p-0 m-0">
                                    <span className="news-section-article__title">
                                        {title.substring(0, 70) + "..."}
                                    </span>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-3 p-0 m-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-2 py-2 py-lg-0">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img src={Eye} alt="eye-icon" className="eye-icon" />&nbsp;
                                            <Link to={`/news/${id}`} className="text-oasis m-0 p-0">
                                                View
                                            </Link>
                                            &nbsp;
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img src={Pencil} alt="pencil-icon" className=" pencil-icon" />&nbsp;
                                            <button className="text-oasis m-0 p-0" onClick={handleEditClick}>
                                                Edit
                                            </button>
                                            &nbsp;
                                        </div>
                                        {showEditModal ? (
                                            <AdminEditArticleModal id={id} onClose={handleCloseEditModal} onArticleUpdated={onArticleUpdated} />
                                        ) : null}
                                        <div className="d-flex justify-content-center align-items-center">
                                            <TrashCan style={{fill:'#d9534f'}}/>&nbsp;
                                            <button className="delete-btn m-0 p-0" onClick={handleDelete}>
                                                Delete
                                            </button>
                                        </div>
                                        {showConfirmDeleteModal ? (
                                            <ConfirmDeleteModal
                                                id={id}
                                                closeModal={() => setShowConfirmDeleteModal(false)}
                                                articleTitle={articleTitle}
                                                onArticleDeleted={onArticleDeleted}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-start align-items-center">
                                <div className="col-sm-12 p-0 m-0">
                                    <div className="news-section-article__date-author-info mt-2 mb-2">
                                        <span className="news-section-article__date">{date}</span>,&nbsp;
                                        <span className="news-section-article__time">{time}</span> by&nbsp;
                                        <span className="news-section-article__author">{author}</span>&nbsp;
                                    </div>
                                    <span className="news-section-article__summary" dangerouslySetInnerHTML={{ __html: summary.substring(0, 300) }} />
                                    <Link to={`/news/${id}`} className="news-section-article__read-more">
                                        Full Story &gt;&gt;
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : null}
        </>
    );
};

export default NewsSectionArticleAdmin;