import {
    SET_BASKET,
    SET_MY_BASKET_TIMER,
    SET_CLEAR_LOCATION_FIELD,
    SET_BASKET_DEFAULT,
    SET_LOADING_BASKET,
    SET_LOADING_SEND_REQUEST,
    SET_DATA_TO_CHECK,
    SET_ALL_BASKET,

    SET_LOCATION_BASKET,
    SET_MILES,
    SET_FLOOR_PLAN,
    SET_CURRENCY_TYPE,
    SET_CURRENCY_ERROR,
    SET_BUILDING_TYPES,
    SET_NOTES,

    SET_BUDGET_MIN,
    SET_BUDGET_MAX,

    SET_QTY_ADULTS,
    SET_QTY_CHILDREN,

    SET_IS_PETS,
    SET_QTY_PETS,
    SET_PET_DETAILS,
    // SET_PET_TYPE,
    SET_PET_WEIGHT,
    SET_PET_WEIGHT_TYPE,

    SET_MOVE_IN,
    SET_MOVE_OUT,
    SET_DATE_RANGE,

    SET_SELECTED_GUEST_ID,
    SET_GUEST_USER,
    SET_GUEST_USER_EMPTY,
    SET_GUEST_PHONE,
    SET_IS_GUEST_PHONE_VALID_STATE,
    SET_IS_GUEST_PHONE_VALID_MESSAGE,
    SET_GUEST_EMAIL,
    SET_IS_EXISTING_USER,
    SET_IS_GUEST_CHECKBOX_SELECTED,

    SET_DATA_KEYS_AMENITIES,
    SET_SELECTED_DATA_AMENITIES,
    SET_NO_MATCH,
    SET_MAID_SERVICE,
    SET_PARKING_SLOTS
} from "../constant/my-basket";


export const setMaidService = data => ({
    type: SET_MAID_SERVICE,
    payload: data
});
export const setParkingSlots = data => ({
    type: SET_PARKING_SLOTS,
    payload: data
});

export const setBasket = data => ({
    type: SET_BASKET,
    payload: data
});
export const setBasketTimer = data => ({
    type: SET_MY_BASKET_TIMER,
    payload: data
});
export const setNoMatch = data => ({
    type: SET_NO_MATCH,
    payload: data
});
export const setClearLocationField = data => ({
    type: SET_CLEAR_LOCATION_FIELD,
    payload: data
});

export const setAllBasket = data => ({
    type: SET_ALL_BASKET,
    payload: data
});

export const setLoading = value => ({
    type: SET_LOADING_BASKET,
    payload: value 
});

export const setLoadingSendRequest = value => ({
    type: SET_LOADING_SEND_REQUEST,
    payload: value 
});

export const setDataToCheck = (latitude, longitude, radius, propertyType,floor,pet) => ({
    type: SET_DATA_TO_CHECK,
    payload: {
        latitude,
        longitude,
        radius,
        propertyType,
        floor,
        pet
    } 
});

export const setLocation = value => ({
    type: SET_LOCATION_BASKET,
    payload: value 
});

export const setMiles = value => ({
    type: SET_MILES,
    payload: value 
});

export const setFloorPlan = value => ({
    type: SET_FLOOR_PLAN,
    payload: value 
});

export const setCurrencyType = value => ({
    type: SET_CURRENCY_TYPE,
    payload: value 
});


export const setCurrencyError = value => ({
    type: SET_CURRENCY_ERROR,
    payload: value 
});

export const setBuildingTypes = value => ({
    type: SET_BUILDING_TYPES,
    payload: value 
});

export const setNotes = value => ({
    type: SET_NOTES,
    payload: value 
});

export const setBudgetMin = value => ({
    type: SET_BUDGET_MIN,
    payload: value 
});


export const setBudgetMax = value => ({
    type: SET_BUDGET_MAX,
    payload: value 
});

export const setQtyAdults = value => ({
    type: SET_QTY_ADULTS,
    payload: value 
});

export const setQtyChildren = value => ({
    type: SET_QTY_CHILDREN,
    payload: value 
});


export const setIsPets = value => ({
    type: SET_IS_PETS,
    payload: value 
});

export const setQtyPets = value => ({
    type: SET_QTY_PETS,
    payload: value 
});
export const setPetDetails = value => ({
    type: SET_PET_DETAILS,
    payload: value 
});

// export const setPetType = (id,value) => ({
//     type: SET_PET_TYPE,
//     payload: {id,value} 
// });

export const setPetWeight = (id,value) => ({
    type: SET_PET_WEIGHT,
    payload: {id,value} 
});

export const setPetWeightType = (id,value) => ({
    type: SET_PET_WEIGHT_TYPE,
    payload: {id,value}  
});


export const setMoveIn = value => ({
    type: SET_MOVE_IN,
    payload: value 
});
export const setMoveOut = value => ({
    type: SET_MOVE_OUT,
    payload: value 
});
export const setDateRange = value => ({
    type: SET_DATE_RANGE,
    payload: value 
});


export const setGuestUser = value => ({
    type: SET_GUEST_USER,
    payload: value 
});

export const setGuestUserEmpty = value => ({
    type: SET_GUEST_USER_EMPTY,
    payload: value 
});

export const setGuestPhone = value => ({
    type: SET_GUEST_PHONE,
    payload: value 
});

export const setIsGuestPhoneValidMessage = value => ({
    type: SET_IS_GUEST_PHONE_VALID_MESSAGE,
    payload: value 
});

export const setIsGuestPhoneValidState = value => ({
    type: SET_IS_GUEST_PHONE_VALID_STATE,
    payload: value 
});

export const setGuestEmail = value => ({
    type: SET_GUEST_EMAIL,
    payload: value 
});

export const setIsExistingUser = value => ({
    type: SET_IS_EXISTING_USER,
    payload: value 
});

export const setIsGuestCheckboxSelected = value => ({
    type: SET_IS_GUEST_CHECKBOX_SELECTED,
    payload: value 
});

export const setDataKeysAmenities = value => ({
    type: SET_DATA_KEYS_AMENITIES,
    payload: value 
});

export const setSelectedDataAmenities = value => ({
    type: SET_SELECTED_DATA_AMENITIES,
    payload: value 
});

export const setSelectedGuestId = value => ({
    type: SET_SELECTED_GUEST_ID,
    payload: value 
});

export const setBasketDefault = _ => ({
    type: SET_BASKET_DEFAULT
});