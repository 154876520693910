import {
    SET_LOADING_QUICK_REQUEST,
    SET_DEFAULT_QUICK_REQUEST,
    SET_CLEAR_LOCATION_FIELD_QUICK_REQUEST,
    
    //FIELDS
    SET_LOCATION_QUICK_REQUEST,
    
    SET_MILES_QUICK_REQUEST,
    SET_FLOOR_PLAN_QUICK_REQUEST,
    SET_CURRENCY_TYPE_QUICK_REQUEST,
    SET_CURRENCY_ERROR_QUICK_REQUEST,
    SET_BUILDING_TYPES_QUICK_REQUEST,
    SET_NOTES_QUICK_REQUEST,
    
    SET_BUDGET_MIN_QUICK_REQUEST,
    SET_BUDGET_MAX_QUICK_REQUEST,
    
    SET_QTY_ADULTS_QUICK_REQUEST,
    SET_QTY_CHILDREN_QUICK_REQUEST,
    
    SET_IS_PETS_QUICK_REQUEST,
    SET_QTY_PETS_QUICK_REQUEST,
    SET_PET_DETAILS_QUICK_REQUEST,
    
    SET_MOVE_IN_QUICK_REQUEST,
    SET_MOVE_OUT_QUICK_REQUEST,
    SET_DATE_RANGE_QUICK_REQUEST,
    
    SET_SELECTED_GUEST_ID_QUICK_REQUEST,
    SET_GUEST_USER_QUICK_REQUEST,
    SET_GUEST_USER_EMPTY_QUICK_REQUEST,
    SET_GUEST_PHONE_QUICK_REQUEST,
    SET_IS_GUEST_PHONE_VALID_STATE_QUICK_REQUEST,
    SET_IS_GUEST_PHONE_VALID_MESSAGE_QUICK_REQUEST,
    SET_GUEST_EMAIL_QUICK_REQUEST,
    SET_IS_EXISTING_USER_QUICK_REQUEST,
    SET_IS_GUEST_CHECKBOX_SELECTED_QUICK_REQUEST,
    SET_DATA_KEYS_AMENITIES_QUICK_REQUEST,
    SET_SELECTED_DATA_AMENITIES_QUICK_REQUEST,
    SET_MAID_SERVICE_QUICK_REQUEST,
    SET_PARKING_SLOTS_QUICK_REQUEST
} from "../constant/quick-request";

import {DEFAULT_CURRENCY} from '../constant/dictionary'

const defaultState = {
    loading: false,
    clearLocationField: true,
    fields: {
        location:{
            id: '',
            value: ''
        },
        miles:10,
        parkingSlots: null,
        maidService: null,
        budgetMin: null,
        budgetMax: null,
        qtyAdults: null,
        qtyChildren: null,
        
        isPets:false,
        qtyPets: null,
        petDetails:[],
        
        moveIn:'',
        moveOut:'',
        dateRange: [null,null],
    
        floorPlan:[],
        currencyType: DEFAULT_CURRENCY,
        currencyError:false,
        buildingTypes:[],
    
        notes:'',
        guestUser:{},
        selectedGuestId: null,
        isGuestUserEmpty: false,
        guestPhone:'',
        isGuestPhoneValidState: true,
        isGuestPhoneValidMessage: '',
        guestEmail:'',
        isExistingUser:false,
        isGuestCheckBoxSelected:false,
        dataKeysAmenities:[],
        selectedDataAmenities:[]
    }
};

export default function basketReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_MAID_SERVICE_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    maidService: action.payload
                }
            };
        case SET_PARKING_SLOTS_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    parkingSlots: action.payload
                }
            };
        case SET_CLEAR_LOCATION_FIELD_QUICK_REQUEST:
            return {
                ...state,
                clearLocationField: action.payload
            };
        case SET_LOADING_QUICK_REQUEST:
            return {
                ...state,
                loading: action.payload
            };
        case SET_LOCATION_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    location: action.payload
                }
            };
        case SET_MILES_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    miles: action.payload
                }
            };
        case SET_FLOOR_PLAN_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    floorPlan: action.payload
                }
            };
        case SET_CURRENCY_TYPE_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    currencyType: action.payload
                }
            };
        case SET_CURRENCY_ERROR_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    currencyError: action.payload
                }
            };
        case SET_BUILDING_TYPES_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    buildingTypes: action.payload
                }
            };
        case SET_NOTES_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    notes: action.payload
                }
            };

        case SET_BUDGET_MIN_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    budgetMin: action.payload
                }
            };
        case SET_BUDGET_MAX_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    budgetMax: action.payload
                }
            };

        case SET_QTY_ADULTS_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    qtyAdults: action.payload
                }
            };
        case SET_QTY_CHILDREN_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    qtyChildren: action.payload
                }
            };

        case SET_IS_PETS_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isPets: action.payload
                }
            };
        case SET_QTY_PETS_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    qtyPets: action.payload
                }
            };
        case SET_PET_DETAILS_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    petDetails: action.payload
                }
            };

        case SET_MOVE_IN_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    moveIn: action.payload
                }
            };
        case SET_MOVE_OUT_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    moveOut: action.payload
                }
            };
        case SET_DATE_RANGE_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dateRange: action.payload
                }
            };
        case SET_GUEST_USER_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    guestUser: action.payload
                }
            };
        case SET_GUEST_USER_EMPTY_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isGuestUserEmpty: action.payload
                }
            };
        case SET_GUEST_PHONE_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    guestPhone: action.payload
                }
            };
        case SET_IS_GUEST_PHONE_VALID_MESSAGE_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isGuestPhoneValidMessage: action.payload
                }
            };
        case SET_IS_GUEST_PHONE_VALID_STATE_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isGuestPhoneValidState: action.payload
                }
        };
        case SET_GUEST_EMAIL_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    guestEmail: action.payload
                }
            };
        case SET_IS_EXISTING_USER_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isExistingUser: action.payload
                }
            };
        case SET_IS_GUEST_CHECKBOX_SELECTED_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isGuestCheckBoxSelected: action.payload
                }
            };
        case SET_DATA_KEYS_AMENITIES_QUICK_REQUEST:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dataKeysAmenities: action.payload
                }
            };
        case SET_SELECTED_DATA_AMENITIES_QUICK_REQUEST:
            return {
            ...state,
            fields: {
                ...state.fields,
                selectedDataAmenities: action.payload
            }
            };

        case SET_SELECTED_GUEST_ID_QUICK_REQUEST:
            return {
            ...state,
            fields: {
                ...state.fields,
                selectedGuestId: action.payload
            }
            };
        case SET_DEFAULT_QUICK_REQUEST:
            return defaultState;
        default:
            return state;
    }
}