export const HEADER = {
    title: 'LIST YOUR PROPERTY',
    subtitle: 'You have a property that you want to share with us, we are welcome.',
    button: 'BECOME A PARTNER'
}

export const OWNERSHIP = [
    {
        id: 1,
        name: 'Owner'
    },
    {
        id: 2,
        name: 'Property Manager'
    }
]
export const PROPERTY_TYPE = [
    {
        id: 1,
        name: 'Apartment'
    },
    {
        id: 2,
        name: 'Home'
    }
]

export const SQUARE_FOOTAGE = [
    {id: 2, name: 'ft²'},
    {id: 1, name: 'm²'}
]
export const INPUT_LABELS = {
    firstname: 'First Name',
    lastname: 'Last Name',
    phone: 'Phone',
    email: 'Email',
    owner_type: 'Ownership type',
    property_type: 'Type of property',
    city: 'City',
    street: 'Street',
    floor_plan: 'Floor Plan',
    indoor_footage: 'Indoor square footage',
    outdoor_footage: 'Outdoor square footage',
    description: 'General description of features, amenities, and style',
    images: {
        title: 'Photos of property',
        notifyLabel: 'Photo',
        allowedExtensions: '.jpeg,.png,.gif',
        maxFiles: 5,
        message: 'Please upload 1-5 photos of your home in a good quality* (jpeg, png, gif)',
        invalidFileSizeMessage: "Uploaded file(s) is too large. Maximum size is 5MB",
        maxFileSize: 5242880 // 5 MB
    },
    hear_us: 'How did you hear about us?'
}
export const PROPERTY_SENT = {
    title: 'Your property has been sent successfully!',
    description: 'We will contact with you as soon as possible.',
    link: {
        add_property: '+ Add one more Property',
        main: 'Go to the Main Page',
        partner: 'Become a Partner'
    }
}
export const BUTTON_LABEL = 'Submit'
export const  SET_PROPERTY_SENT = 'SET_PROPERTY_SENT'
export const  SET_LOADING = 'SET_LOADING'
export const  SET_DEFAULT = 'SET_DEFAULT'
