import baseService from "../services/base.service";
import { getPrimaryPropertyImage } from './files'
import {isLoggedIn} from './user'

import store from '../store'

import {setFavorites,setFavoritesTotal,setLoadingFavorites} from '../actions/favorites'


import notify from 'devextreme/ui/notify';


let controller;

const getIsThisPropertyLiked = async(propertyId) => {
  try {
    if(isLoggedIn())
    {
      let favorites = await getFavorites()
      let isPropertyLiked = favorites.filter(favorite => favorite.id == propertyId)
      return (isPropertyLiked.length > 0)
    }
    else{
      return false
    }
  } catch (error) {
    return false
  }
}

const getFavorites = async () => {
  try {
    // store.dispatch(setLoadingRecommendedProperties(true))
    if(isLoggedIn())
    {
      // const userId = JSON.parse(localStorage.getItem('ODYSSEY.USER.AUTHENTICATION'))?.profile?.sub

      const urlTotal = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/likedproperties?skip=0&take=1&requireTotalCount=true`;
     
      let responseTotal = await baseService.get(urlTotal, {
        headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
        }
      })
      // let formattedData = response.data.map(item => item.propertyId)
      
      // return response.data
      const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/likedproperties?skip=0&take=${responseTotal.data.totalCount}&requireTotalCount=true`;

      let response = await baseService.get(url, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
         }
       })

      return response?.data?.data
    }
    else{
      return []
    }
  } catch (error) {
    return []
  }
}

const getFavoritesPerPage = async (skip) => {
  try {
    // store.dispatch(setLoadingRecommendedProperties(true))
    if(isLoggedIn())
    {
      // const userId = JSON.parse(localStorage.getItem('ODYSSEY.USER.AUTHENTICATION'))?.profile?.sub

      const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/likedproperties?skip=${skip}&take=20&requireTotalCount=true`;
     
      let response = await baseService.get(url, {
        headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
        }
      })

      return response?.data
    }
    else{
      return []
    }
  } catch (error) {
    return []
  }
}

const getFavoriteProperties = async (skip) => {
  try {
    store.dispatch(setLoadingFavorites(true))
    let favorites = await getFavoritesPerPage(skip)
  
    let formattedData = favorites.data.map(favorite => ({
      ...favorite,
      isLiked: true,
        imageUrl: getPrimaryPropertyImage(favorite.id)
    }))
  
    store.dispatch(setFavorites(formattedData))
    store.dispatch(setFavoritesTotal(favorites.totalCount))
    store.dispatch(setLoadingFavorites(false))
  } catch (error) {
    
  }

}

const postFavorite = async (propertyId, idMessage) => {
  try {
    if(isLoggedIn())
    {
      // store.dispatch(setLoadingRecommendedProperties(true))
      // const userId = JSON.parse(localStorage.getItem('ODYSSEY.USER.AUTHENTICATION'))?.profile?.sub
      const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/likedproperties`;
      const formData = new FormData()
      formData.append('PropertyId',propertyId)
      baseService.post(url, formData, {
        headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
        },
      }).then((data) => {

        notify(`The property ${idMessage ? idMessage : propertyId} has been added to your favorites`, "success" , 3000);
        return true
      })
      .catch(error => {
        notify(`Something went wrong try again later`, "error" , 3000);
        return false
      })
    }
    else{
      return false
      // let container = document.createElement('div');
      // container.innerHTML = 'Please, <a href="#" onclick="loginFunction()">Login</a> or <a href="#" onclick="registerFunction()">Register</a> to add property to your Favourite List';
       
      // document.body.appendChild(container);
       
      // notify({
      //     message: container,
      //     width: '80%'
      // }, 'warning', 3000);
    }
  } catch (error) {
    notify(`Something went wrong try again later`, "error" , 3000);
    return false
  }

}

//deleteFavorite
const deleteFavorite = async (propertyId) => {
  try {
    if(isLoggedIn())
    {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/likedproperties`;
      const formData = new FormData()
      formData.append('PropertyId',propertyId)
      let response = await baseService.delete(url, {
        headers: {
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: formData
      })
      return response.data
    }
    else{
      // notify('You need to be logged in', "error" , 3000);
      return false
      // return false
    }
  } catch (error) {
    return false
    // store.dispatch(setRecommendedPropertiesData([]))
    // store.dispatch(setLoadingRecommendedProperties(false))
  }

}

export { getFavorites, postFavorite, deleteFavorite,getFavoriteProperties,getIsThisPropertyLiked }