import React from "react";
import "../styles/CustomImageUploader.css";
import FileUploader from "devextreme-react/file-uploader";

const CustomImageUploader = ({ setPhoto, setCurrentPhotoId, fetchedPhotoId }) => {
    const handleFileUpload = (e) => {
        const file = e.value[0];
        setPhoto(file);
        setCurrentPhotoId(file ? null : fetchedPhotoId);
    };

    return (
        <div>
            <FileUploader
                className="file-uploader"
                multiple={false}
                accept="image/*"
                uploadMode="useForm"
                onValueChanged={handleFileUpload}
            />
        </div>
    );
};

export default CustomImageUploader;