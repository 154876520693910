import React from "react";
import ValidationIcon from "./ValidationIcon";
import { connect } from "react-redux";

const ValidationIcons = ({ lengthValid, upperValid, lowerValid, numberValid, specialValid, password }) => {
    return (
        <div className="requirements">
            <span className="validation-icons-title">Your password must include:</span>
            <ValidationIcon text="At least 8 characters" isValid={lengthValid} isInvalid={!lengthValid && password.length > 0} />
            <ValidationIcon text="At least 1 uppercase letter" isValid={upperValid} isInvalid={!upperValid && password.length > 0} />
            <ValidationIcon text="At least 1 lowercase letter" isValid={lowerValid} isInvalid={!lowerValid && password.length > 0} />
            <ValidationIcon text="At least 1 number" isValid={numberValid} isInvalid={!numberValid && password.length > 0} />
            <ValidationIcon text="At least 1 special character" isValid={specialValid} isInvalid={!specialValid && password.length > 0} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        password: state.formData.password,
        lengthValid: state.formData.lengthValid,
        upperValid: state.formData.upperValid,
        lowerValid: state.formData.lowerValid,
        numberValid: state.formData.numberValid,
        specialValid: state.formData.specialValid,
    };
};

export default connect(mapStateToProps)(ValidationIcons);