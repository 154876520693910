import {useState,useCallback,useEffect,memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-input.css'

//components
import { TextBox } from 'devextreme-react/text-box';
import {
    Validator,
    RequiredRule,
    CustomRule
  } from 'devextreme-react/validator';

//utils
import {getGeometryByGoogle} from '../../../../utils/google-search'

//constant
import {EXCLUDED_CITIES, EXCLUDED_CITIES_DETAILS} from '../../../../constant/dictionary'

const SearchCity = ({label, value, setValue,requiredValue,nameField,setGeometry,placeId,setPlaceId,labelSize,setCountryISOCode,setLocalityCity,localityCity}) => {
  const [isFocus, setisFocus] = useState()
  const pacContainer = document.querySelector('.pac-container')
  pacContainer?.classList?.add('pac-container-city')
  let autocomplete = {}
  let autocompleteservice = {}
  
  const options = {
    types: ['(cities)']
  }
  const onPlaceChanged = _ => {

    // setGeometry({lat: autocomplete.getPlace().geometry.location.lat(),lng: autocomplete.getPlace().geometry.location.lng()})
    getPlaceId(document.querySelector(`#${nameField}`)?.value)
    setValue(document.querySelector(`#${nameField}`)?.value)
  } 

  const onChangeValue = useCallback((data) => {
      getPlaceId(data)
      setValue(data)
  }, []);

  const getPlaceId = (value) => {
    autocompleteservice = new window.google.maps.places.AutocompleteService()
    if(value)
      {
        autocompleteservice.getQueryPredictions({ 'input': value},(prediction)=>{
        let place = []
        if(prediction)
        {
          place = prediction.filter(result => {
            return result.description.toLowerCase().trim().normalize('NFD').replace(/\p{Diacritic}/gu, "") === value.toLowerCase().trim().normalize('NFD').replace(/\p{Diacritic}/gu, "")
          })
        }
        setPlaceId(place.length === 1 ? place[0].place_id : '')
      }) 
    }
    else  
      setPlaceId('')
  }
  const getGeometry = async(id) =>
  {
    
    let geometry = await getGeometryByGoogle(id)
    
    let geometryBounds
    if(geometry.results[0]?.geometry?.bounds)
    {
      
      geometryBounds = geometry.results[0]?.geometry?.bounds
    }
    else
    {
      
      if(geometry.results[0]?.geometry?.viewport)
      geometryBounds = geometry.results[0]?.geometry?.viewport
    }

    if(geometryBounds?.northeast && geometryBounds?.southwest)
        setGeometry(geometryBounds)
    else
        setGeometry()
  }
  useEffect(() => {
    autocomplete = new window.google.maps.places.Autocomplete(document.querySelector(`#${nameField}`), options);
    autocomplete.addListener('place_changed',onPlaceChanged) 
    return () => {
      const pacContainers = document.querySelectorAll('.pac-container')
      pacContainers.forEach(container => {
        container.parentNode.removeChild(container)
      })
    }
  }, [])  
  
  useEffect(() => {
    if(placeId)
    {
        let geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({placeId: placeId},(results,status) => {
          if(results.length > 0)
          {
            let addressComponents = results[0].address_components
            addressComponents.forEach(component => {
              if(component.types.includes('country'))
                setCountryISOCode(component.short_name)
              if(component.types.includes('locality'))
                setLocalityCity(component.long_name)
            })
          }
          else
          {
            setCountryISOCode('')
            setLocalityCity('')
          }
        })
        getGeometry(placeId)
    }
    else
    { 
        setCountryISOCode('')
        setLocalityCity('')
        setGeometry()
    }
  }, [placeId])
  const onKeyValidation = useCallback(e=>{
    e.event.key.toLowerCase() === 'enter' && e.event.preventDefault()
    let regex = /[<>]/
    regex.test(e.event.key) && e.event.preventDefault()
  },[])
  const onFocusOutCity = _ => {
    // getPlaceId(document.querySelector(`#${nameField}`)?.value)
    setisFocus(false)
  }
  const isAValidCity = _ => {
    if(!localityCity)
    {
      if(value)
      {
        let checkValue = value.split(",")?.[0]
        if(EXCLUDED_CITIES.includes(checkValue.trim().toLowerCase().replace(/[^a-z]/g, '')))
        {
          setGeometry({...EXCLUDED_CITIES_DETAILS[checkValue.trim().toLowerCase().replace(/[^a-z]/g, '')]})
          setLocalityCity(checkValue)
          return true
        }
        else
          return false
      }
      else
        return false
    }
    else
      return localityCity
  }
  const getValidPlaceId = _ => {

    return !isFocus ? (placeId && isAValidCity()) : true
  }
  return (
    <div className='custom-input' style={labelSize ? {gridTemplateColumns: `${labelSize} calc(100% - ${labelSize} - 20px)`} : {}}>
        <div className='custom-input__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>
        <div>
          <TextBox
            inputAttr={{
              id: nameField
            }}
            placeholder=''
            value={value}
            valueChangeEvent="input"
            onValueChange={onChangeValue}
            onKeyDown={onKeyValidation}
            onFocusIn={_=>setisFocus(true)}
            onFocusOut={onFocusOutCity}
          >
            {requiredValue &&
              <Validator>
                <RequiredRule message={`${label} is required`} />
                <CustomRule
                    validationCallback={getValidPlaceId}
                    message={`${label} is not valid`}
                />
              </Validator>
            }
          </TextBox>
        </div>
    </div>
  )
}

export default memo(SearchCity)