import {
    ADD_NEWS,
    DELETE_NEWS,
    UPDATE_NEWS,
    FETCH_NEWS_REQUEST
} from '../constant/news';

const initialState = {
    newsData: [],
};

export default function newsReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_NEWS:
            return {
                ...state,
                newsData: [...state.newsData, action.payload]
            };
        case DELETE_NEWS:
            return {
                ...state,
                newsData: state.newsData.filter(article => article.id !== action.payload)
            };
        case UPDATE_NEWS:
            return {
                ...state,
                newsData: state.newsData.filter(article => article.id !== action.payload)
            };
        case FETCH_NEWS_REQUEST:
            return {
                ...state,
                newsData: action.payload
            };
        default:
            return state;
    };
};