import {useCallback,memo} from 'react'

//styles
import 'devextreme/dist/css/dx.light.css';
import '../../styles/input/custom-textarea.css'

//components

import { HtmlEditor,Toolbar,Item } from 'devextreme-react/html-editor';
import {
    Validator,
    RequiredRule
  } from 'devextreme-react/validator';

const CustomHtmlEditor = ({label, value, setValue,requiredValue,labelSize,isDisabled, messageName,height}) => {
  const onChangeValue = useCallback((data) => {
    setValue(data.value)
  }, []);

  return (
    <div className={`${label ? 'custom-textarea' : 'custom-textarea--nolabel'}`} style={labelSize ? {gridTemplateColumns: `${labelSize} calc(100% - ${labelSize} - 20px)`} : {}}>
        {label && <div className='custom-textarea__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>}
        <HtmlEditor
            valueType='html'
            height={height ? height : 120}
            value={value}
            disabled={isDisabled}
            onValueChanged={onChangeValue}
            maxLength={1000}
        >
            {!isDisabled && <Toolbar multiline={true}>
                <Item name="bold" />
                <Item name="italic" />
                <Item name="underline" />
                <Item name="separator" />
                <Item name="orderedList" />
                <Item name="bulletList" />
            </Toolbar>}
            {requiredValue &&
                <Validator>
                <RequiredRule message={`${label ? label : messageName} is required`} />
                </Validator>
            }
        </HtmlEditor>
        {/* <TextArea          
          valueChangeEvent="input"     
          height={120}
          
        >
        </TextArea> */}

    </div>
  )
}

export default memo(CustomHtmlEditor)