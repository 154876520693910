import MyBasketContent from './my-basket/my-basket-content'

import { Navigate, useLocation } from 'react-router-dom';

import {isLoggedIn} from '../utils/user'
const MyFavorites = () => {
    const location = useLocation();

    return (
        isLoggedIn()  ? <MyBasketContent /> : <Navigate to="/login" state={{ from: location }} replace />
    );
}


  export default MyFavorites;