import React from "react";

const ImageCard = ({ image, title, alt }) => {
    return (
        <div className="image-container">
            <h3>
                <img src={image} alt={alt} className="slider-image" />
                <p className="slider-title">{title}</p>
            </h3>
        </div>
    );
};

export default ImageCard;