import React from "react";
import { CustomRule } from "devextreme-react/validator";

export const ValidationIconRules = ({ lengthValid, upperValid, lowerValid, numberValid, specialValid }) => {
    return (
        <>
            <CustomRule
                validationCallback={() => lengthValid}
                message="Password must have at least 8 characters"
            />
            <CustomRule
                validationCallback={() => upperValid}
                message="Password must have at least 1 uppercase letter"
            />
            <CustomRule
                validationCallback={() => lowerValid}
                message="Password must have at least 1 lowercase letter"
            />
            <CustomRule
                validationCallback={() => numberValid}
                message="Password must have at least 1 number"
            />
            <CustomRule
                validationCallback={() => specialValid}
                message="Password must have at least 1 special character"
            />
        </>
    );
};

export default ValidationIconRules;