import React, { useRef } from 'react'
import Title from '../../assets/elements/title'
import Subtitle from '../../assets/elements/subtitle'
import MainButton from '../../assets/elements/button/main-button'
import SearchBar from './Sub-Components/search-bar'

const SearchContainer = () => {
  return (
    <>
      <div className='hero-content overflow-hidden'>
        <div className='text-center'>
          <Title>STAY WITH OASIS</Title>
          <Subtitle>Luxury Living for the Serious Traveler</Subtitle>
          <SearchBar />
        </div>
      </div>
      <div className='hero-video-container'>
        <video
        className='hero-video-container borders hide-controls'
        poster={require('../../../images/homeslide01.jpg')}
          preload='auto'
          autoPlay
          loop
          muted
              >
                  <source src={require('../../../images/video/oasishome-vid-ios.mp4')} type="video/mp4" />
        </video>
      </div>
    </>
  )
}

export default SearchContainer