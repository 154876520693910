import store from "../store";
import baseService from "../services/base.service";

import {setAllRequests,setRequestsData,setPreviousRequestsUrl,setAllRequestsIsLoaded,setRequestsLoading,setRequestsMessageNoData} from '../actions/received-requests'

import {PAGINATION_DEFAULT,REQUEST_TYPES} from '../constant/received-requests'
import {ADVERTISEMENTS} from '../constant/dropdown'
import {SQUARE_FOOTAGE} from '../constant/list-a-property'
import {accomodationLevelItems,unitItems} from '../constant/property-request'
import {getOdysseyFile} from './files'

let controller;
const getAllRequests = _ => {
  try {
    store.dispatch(setAllRequestsIsLoaded(false))
    if (controller)
      controller.abort()

    controller = new AbortController();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/messages/info`;

    baseService.get(url, {
      method: 'GET',
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': `${process.env.PUBLIC_URL}`,
        'Content-Type': 'application/json',
      },
      signal: controller.signal
    }).then(({ data }) => {
        store.dispatch(setAllRequests(data))
    }).finally(_ => {
        store.dispatch(setAllRequestsIsLoaded(true))
    })
    .catch(error => {
        store.dispatch(setAllRequests({}))
    })
  } catch (error) {
    store.dispatch(setAllRequests({}))
  }
}

const getRequestsData = (skip,type) => {
  try {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/${type}?skip=${skip}&take=${PAGINATION_DEFAULT.take}&requireTotalCount=true`;
    if(store.getState().receivedRequests.previousUrl !== url)
    {
      if (controller)
        controller.abort()

      controller = new AbortController();
    
      baseService.get(url, {
        method: 'GET',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': `${process.env.PUBLIC_URL}`,
          'Content-Type': 'application/json',
        },
        signal: controller.signal
      }).then(({ data }) => {
          store.dispatch(setRequestsData(data))
      }).catch(error => {
          store.dispatch(setRequestsData({}))
      })
    }
    store.dispatch(setPreviousRequestsUrl(url))
  } catch (error) {
    store.dispatch(setRequestsData({}))
  }
}

const getRequestsDataByFilter = (skip,type,filterType,isUpdate) => {
  try {
    store.dispatch(setRequestsLoading(true))
    store.dispatch(setRequestsMessageNoData(false))
    const filter = filterType === 'deleted' ? '&filter=["isActive","=",false]' : ( filterType === 'new' ? '&filter=[["isProcessed","=",false],["isActive","=",true]]' : (filterType === 'viewed' ? '&filter=[["isProcessed","=",true],["isActive","=",true]]' : '&filter=["isActive","=",true]'))

    let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/${type}?skip=${skip}&take=${PAGINATION_DEFAULT.take}${filter}&requireTotalCount=true`;
       
    if(store.getState().receivedRequests.previousUrl !== url || isUpdate)
    {
      if (controller)
        controller.abort()

      controller = new AbortController();
    
      baseService.get(url, {
      method: 'GET',
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': `${process.env.PUBLIC_URL}`,
        'Content-Type': 'application/json',
      },
      signal: controller.signal
      }).then(({ data }) => {
          let formattedData
          switch (type) {
            case REQUEST_TYPES.careers.path:
              formattedData = {
                ...data,
                data: data.data.map(({howDidYouHearAboutUsType,resumeFileId,...items}) => {
                  let hearUsObj = ADVERTISEMENTS.filter(advertisement => advertisement.id === howDidYouHearAboutUsType)
                  let imageUrl = getOdysseyFile(resumeFileId)
                  return{
                    ...items,
                    requestsCardTitle: `${items.vacancy.jobTitle} ${items.vacancy.location}`,
                    howDidYouHearAboutUs: hearUsObj[0] ? hearUsObj[0].name : '',
                    'Resume /CV attached': imageUrl
                  }
                })
              }
              break;
            case REQUEST_TYPES['list-your-properties'].path:
              formattedData = {
                ...data,
                data: data.data.map(({outdoorSquareFootageType,indoorSquareFootageType,...items}) => {
                  let indoorType = SQUARE_FOOTAGE.filter(type => type.id === indoorSquareFootageType)
                  let outdoorType = SQUARE_FOOTAGE.filter(type => type.id === outdoorSquareFootageType)
                  return{
                    ...items,
                    requestsCardTitle: items.streetAddress,
                    indoorSquareFootage: `${items.indoorSquareFootage} ${indoorType ? indoorType[0].name : ''}`,
                    outdoorSquareFootage: `${items.outdoorSquareFootage} ${outdoorType ? outdoorType[0].name : ''}`,
                  }
                })
              }
              break;
            case REQUEST_TYPES['contact-us'].path:
              formattedData = {
                ...data,
                data: data.data.map(item => {
                  return{
                    ...item,
                    requestsCardTitle: item.natureOfInquiryType
                  }
                })
              }
              break;
            case REQUEST_TYPES['became-a-partner'].path:
              formattedData = {
                ...data,
                data: data.data.map(item => {
                  return{
                    ...item,
                    requestsCardTitle: item.partnershipType
                  }
                })
              }
              break;
            case REQUEST_TYPES['request-a-property'].path:
              formattedData = {
                ...data,
                data: data.data.map(({maximumCommuteDistanceType,...items}) => {
                  let currencyTypes = store.getState().dictionary.currencyTypes
                  let acommodationName = accomodationLevelItems.filter(type => type.value === items.accomodationLevel)
                  let distanceType = unitItems.filter(type => type.value === maximumCommuteDistanceType)
                  let currency = currencyTypes.filter(type => type.id === items.budgetCurrencyId)
                  return{
                    ...items,
                    requestsCardTitle: items.propertyName,
                    accomodationLevel: acommodationName[0] ? acommodationName[0].name : '',
                    maximumCommuteDistance: `${items.maximumCommuteDistance} ${distanceType[0] ? distanceType[0].text : ''}`,
                    budgetCurrencyId: currency[0] ? currency[0].name : ''
                  }
                })
              }
              break;
            default:
              formattedData = data
              break;
          } 

          store.dispatch(setRequestsData(formattedData))
          formattedData.data.length <= 0 ? store.dispatch(setRequestsMessageNoData(true)) : store.dispatch(setRequestsMessageNoData(false))
      }).finally(()=>{
        store.dispatch(setRequestsLoading(false))
      })
      .catch(error => {
          store.dispatch(setRequestsData({}))
          store.dispatch(setRequestsLoading(false))
          store.dispatch(setRequestsMessageNoData(true))
      })
    }
    else
    {
      store.dispatch(setRequestsLoading(false))
    }
    store.dispatch(setPreviousRequestsUrl(url))
  } catch (error) {
    store.dispatch(setRequestsData({}))
    store.dispatch(setRequestsLoading(false))
    store.dispatch(setRequestsMessageNoData(true))
  }
}
const refreshRequests = (skip,type,filterType) => {
  try {
    if (controller)
      controller.abort()

    controller = new AbortController();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/messages/info`;

    baseService.get(url, {
      method: 'GET',
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': `${process.env.PUBLIC_URL}`,
        'Content-Type': 'application/json',
      },
      signal: controller.signal
    }).then(({ data }) => {
        store.dispatch(setAllRequests(data))
    }).finally(_ => {
        getRequestsDataByFilter(skip,type,filterType,true)
    })
    .catch(error => {
        store.dispatch(setAllRequests({}))
    })
  } catch (error) {
    store.dispatch(setAllRequests({}))
  }
}
const deleteRequest = async(id,type) => {
  try {
      if (controller)
        controller.abort()

      controller = new AbortController();
      let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/${type}/${id}`;
      let response = await baseService.delete(url, {
        method: 'DELETE',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': `${process.env.PUBLIC_URL}`,
          'Content-Type': 'application/json',
        },
        signal: controller.signal
      })
      return response.data
  } catch (error) {
    return false
  }
}
const processRequest = async(id,type) => {
  try {
      if (controller)
        controller.abort()

      controller = new AbortController();
      let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/${type}/${id}/process`;
      let response = await baseService.put(url, {
        method: 'PUT',
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': `${process.env.PUBLIC_URL}`,
          'Content-Type': 'application/json',
        },
        signal: controller.signal
      })
      return response.data
  } catch (error) {
    return false
  }
}

export { getAllRequests,getRequestsData,getRequestsDataByFilter,deleteRequest,processRequest,refreshRequests }