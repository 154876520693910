import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { OidcProvider } from "redux-oidc";
import NavbarComponent from "./navbar/NavbarComponent";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "react-router-redux";

import userManager from '../utils/user-manager';
import store from '../store';
import LandingPage from './landing-page';
import PropertySearch from './property-search'
import PropertyDetails from './property-details'
import BecomeAPartner from './become-a-partner'
import Profile from './profile';
import PrivateRoute from './private-route';
import AdminRoute from './admin-route'
import Login from './login';
import CreateAccount from './create-account';
import Registered from './registered-account';
import VerifyAccount from "./verify-account";
import VerificationSuccess from './verification-success';
import VerificationError from "./verification-error";
import AccountActivated from "./account-activated";
import AuthCallback from './auth-callback';
import PostLogout from './post-logout';
import AuthSilentRenew from './auth-silent-renew';
import ListAProperty from './list-a-property'
import Career from './career'
import CareerById from './career/career-by-id'
import CareerApply from './career/career-apply'
import OldPortalPropertyDetails from "./old-portal-property-details";


import Faq from './faq';
import News from './news';
import ViewFullStory from './news/Sub-Components/ViewFullStory';
import Footer from './landing-page/Footer/Footer'
import MyRequestsOptionDetails from "./my-request-option-details";

import MyRequests from "./my-requests";

import MyFavorites from "./my-favorites";
import PropertyRequests from "./property-request";
import QuickRequest from "./quick-request";
import QuickRequestSuccessMessage from "./quick-request-success";
import MaintenancePage from "./maintenance-page";
import NotFoundPage from "./not-found";
import NoMatchRoute from "./no-match-route";
import PropertyNotFoundPage from "./property-not-found";
import SafeRoute from "./safe-route";
import MaintenanceHub from "../components/system/maintenance-hub";
import IntegrationHub from "../components/system/integration-hub";
import Spinner from "../components/load-indicator";
import UnderConstructionPage from "../components/under-construction-page";
import MyBasket from '../components/my-basket'

import PrivacyPolicyPage from "./landing-page/Footer/components/PrivacyPolicy/privacy-policy";
import TermsPage from "./landing-page/Footer/components/TermsConditions/terms-conditions";
import ReceivedRequests from './received-requests'
import Administration from './administration'

import ImageDownload from "./property-details/image-download";

import ForgotReset from './login/forgot-reset'

export const history = syncHistoryWithStore(createBrowserHistory(), store);

const App = () => {

	return (<>
		<Provider store={store}>
			<OidcProvider store={store} userManager={userManager}>
				<BrowserRouter history={history}>
					<NavbarComponent />
					<div className="main-container">
						<Routes>
							<Route path='/' element={<LandingPage />} />
							<Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
							<Route path='/terms-conditions' element={<TermsPage />} />
							<Route path='/quick-request' element={<QuickRequest />} />
							<Route path='/quick-request-success' element={<QuickRequestSuccessMessage />} />
							<Route element={<SafeRoute />} >
								<Route path='/not-found' element={<NotFoundPage />} />
								<Route path='/property-not-found' element={<PropertyNotFoundPage />} />
								<Route path='/property-search'>
									<Route path=':address/:id' element={<PropertySearch />} />
									<Route path=':address/:id/:checkIn/:checkOut' element={<PropertySearch />} />
									<Route path='' element={<PropertySearch />} />
								</Route>
								<Route path='/property/dashboard/:id' element={<PropertyDetails />} />
								<Route path='/property-details/:id' element={<OldPortalPropertyDetails />} />

								<Route path='/become-a-partner' element={<BecomeAPartner />} />
								<Route path='/list-your-property' element={<ListAProperty />} />

								<Route path='/list-your-property/become-a-partner' element={<BecomeAPartner />} />

								<Route path='/login' element={<Login />} />
								<Route path="/account">
									<Route path="create" element={<CreateAccount />} />
									<Route path="registered" element={<Registered />} />
									<Route path="verify/:id" element={<VerifyAccount />} />
									<Route path="verify/:id/:username" element={<VerifyAccount />} />
									<Route path="exist/:username" element={<VerifyAccount />} />
									<Route path="already-exists" element={<AccountActivated />} />
									<Route path="verification-success" element={<VerificationSuccess />} />
									<Route path="verification-failed" element={<VerificationError />} />
								</Route>
								<Route path="/careers" element={<Career />} />
								<Route path="/careers/details/:id" element={<CareerById />} />
								<Route path="/careers/apply/:id" element={<CareerApply />} />

								<Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
								<Route path="/callback" element={<AuthCallback />} />
								<Route path="/postlogout" element={<PostLogout />} />
								<Route path="/faq" element={<Faq />} />
								<Route path="/news" element={<News />} />
								<Route path="/news/:id" element={<ViewFullStory />} />
								<Route path="/my-requests" element={<MyRequests />}/>
								<Route path="/my-requests/option/:requestId/:optionId" element={<MyRequestsOptionDetails />}/>

								<Route path="/my-favorites" element={<MyFavorites />} />
								<Route path="/my-cart-for-quotes" element={<MyBasket />} />
								<Route path="/property-request" element={<PropertyRequests />} />
								<Route path="/property-request/:id" element={<PropertyRequests />} />

								<Route path='/authentication/:type' element={<ForgotReset />} />
								<Route path='/privacy-policy' element={<UnderConstructionPage />} />
								<Route path="/received-requests/:name" element={<AdminRoute><ReceivedRequests /></AdminRoute>} />
								<Route path="/administration/:name" element={<AdminRoute><Administration /></AdminRoute>} />

							</Route>
							<Route path="/maintenance" element={<MaintenancePage />} />
							<Route exact={true} path="/silent_renew" element={<AuthSilentRenew />} />
							<Route path="/property/images/:fileName" element={<ImageDownload />} />
							<Route path="*" element={<NoMatchRoute />} />
						</Routes>
						<Footer />
						<Spinner />
						<MaintenanceHub />
						<IntegrationHub />
					</div>
				</BrowserRouter>
			</OidcProvider>
		</Provider>
	</>)
}

export default App;