import baseService from "../services/base.service";
import notify from 'devextreme/ui/notify';

import store from '../store'

//actions
import {setLoadingForgot} from '../actions/forgot-reset'

//constant
import {TYPE} from '../constant/forgot-reset'

const postEmail = async(type,email) => {
    try {
        store.dispatch(setLoadingForgot(true))
        baseService({
            method: "GET",
            baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
            url: `/api/v1/accounts/exist/email/${email}`,
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {
            if(res.data)
            {
                //TODO 
                // baseService({
                //     method: "POST",
                //     baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
                //     url: `${TYPE[type]?.url}/${email}`,
                //     headers: {
                //         "Content-Type": "application/json",
                //     },
                // }).then(res => {
                    notify(TYPE[type]?.message, "success" , 3000)
                // }).catch(error => {
                //     notify(error, "error" , 3000)
                // })
            }
            else{
                notify('This email does not exist', "error" , 3000)
            }
        }).finally(_=>{
            store.dispatch(setLoadingForgot(false))
        }).catch(error => {
            notify(error, "error" , 3000)
            store.dispatch(setLoadingForgot(false))
        })
    } catch (error) {
        store.dispatch(setLoadingForgot(false))
    }
   
  }
  
  export {postEmail}