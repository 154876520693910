export const BUILDING_TYPES = [
    {
        id: 1,
        name: 'Hotels'
    },
    {
        id: 2,
        name: 'Apartments'
    },
    {
        id: 4,
        name: 'Single Family Home'
    },
    {
        id: 3,
        name: 'Town House'
    },
    { 
        id: 10,
        name: 'Oasis Collections Exclusive Properties'
    }
]

