import React, { useEffect } from "react";
import CtaContent from "./CtaContent";
import CtaVideo from "./CtaVideo";
import "../styles/cta-styles.css";

const Cta = () => {
    const setupVideos = () => {
        for (const video of document.querySelectorAll("video")) {
            video.controls = true;
            video.addEventListener("mouseover", () => { video.controls = "controls" });
        }
    }

    useEffect(() => {
        setupVideos();
        window.addEventListener("load", setupVideos, false);
    }, []);

    return (
        <>
            <section id="section-cta" className="cta">
                <div className="cta-container">
                    <div className="row">
                        <h3 className="cta-title cta-title-p-1">
                            what is the oasis competitive difference?
                        </h3>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <CtaContent />
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <CtaVideo />
                        </div>
                    </div>
                </div>
            </section>
            <section className="cta-icons">
                <div className="cta-icons-container">
                    <div className="cta-icons-container__items">
                        <div>
                            <div className="d-flex justify-content-center align-items-center">
                                {/* card- 1 */}
                                <div className="cta-card">
                                    <img
                                        src={require("../../../../images/landing/icons-landing/process.png")}
                                        alt="process"
                                        className="mb-1 mx-auto d-flex w-auto h-auto"
                                        style={{ width: '126px', height: '108px' }}
                                    />
                                    <p className="text-center text-uppercase fw-bold cta-card__title">process</p>
                                    <p style={{ fontSize: "15px" }} className="text-left">
                                        The Oasis Process begins with matching customers with their ideal temporary housing package. To ensure you receive the best value, we go the extra mile by pre-qualifying 100% of our properties by inspection before you arrive. Our comprehensive checklist and high standards must be met before we turn the keys over to you.<br />In addition to our most popular listings, we procure additional partnerships to provide more flexibility options to match your needs. Every day, we find the best properties in the world for traveling professionals to call &#34;home&#34; temporarily. We know the questions to ask and have the local connections to match your unique needs with a furnished apartment in a great location with amenities.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center align-items-center">
                                {/* card-2 */}
                                <div className="cta-card">
                                    <img
                                        src={require("../../../../images/landing/icons-landing/people.png")}
                                        alt="process"
                                        className="mb-1 mx-auto d-flex w-auto h-auto"
                                        style={{ width: '126px', height: '108px' }}
                                    />
                                    <p className="text-center text-uppercase fw-bold cta-card__title">people</p>
                                    <p style={{ fontSize: "15px" }} className="text-left">
                                        At Oasis, we take great pride in our top-tier recruitment and hiring process. Our team of specialists have diverse experiences and skill-sets with a common goal of pursing perfection in all interactions. Our specialists are detail oriented and ultra responsive, providing a convenient &#34;one-point of contact&#34; for the duration of your stay. Whether you are planning your next move or currently staying with Oasis, our talented team members are ready to assist you every step of the way.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center align-items-center">
                                {/* card-3 */}
                                <div className="cta-card">
                                    <img
                                        src={require("../../../../images/landing/icons-landing/properties.png")}
                                        alt="process"
                                        className="mb-1 mx-auto d-flex w-auto h-auto"
                                        style={{ width: '126px', height: '108px' }}
                                    />
                                    <p className="text-center text-uppercase fw-bold cta-card__title">properties</p>
                                    <p style={{ fontSize: "15px" }} className="text-left">
                                        Oasis has worldwide partnerships with property owners and a thorough selection process providing a wide variety of temporary housing solutions. We inspect 100% of our properties with a comprehensive checklist before you arrive. We assess your unique needs locate a furnished space with the size, location and amenities you desire. From hotels to single-family homes, we will exceed your expectations for a successful stay regardless of your needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center align-items-center">
                                {/* card-4 */}
                                <div className="cta-card">
                                    <img
                                        src={require("../../../../images/landing/icons-landing/technology.png")}
                                        alt="process"
                                        className="mb-1 mx-auto d-flex w-auto h-auto"
                                        style={{ width: '126px', height: '108px' }}
                                    />
                                    <p className="text-center text-uppercase fw-bold cta-card__title">technology</p>
                                    <p style={{ fontSize: "15px" }} className="text-left">
                                        Oasis is a recognized leader within the relocation and accomodations industry. Our technology team continues to evolve our global network, enabling access to our interactive website and growing database of properties from any mobile device you choose. Your customers demand a greater ease of business interaction with mobile access at their fingertips. Oasis is available to you 24/7 with professional customer serivce and advanced technology to provide a high standard of service that exceeds expectations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cta;