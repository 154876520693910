import {
    SET_LOAD_INDICATOR_VISIBILITY
} from "../constant/load-indicator";

export const showLoader = () => ({
    type: SET_LOAD_INDICATOR_VISIBILITY,
    payload: true
});
export const hideLoader = () => ({
    type: SET_LOAD_INDICATOR_VISIBILITY,
    payload: false
});
