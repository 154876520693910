import React from "react";
import "../components/create-account/styles/already-exists-styles.css";
import { Link } from "react-router-dom";

const AccountActivated = () => {
    const outerContainer = {
        width: "100%",
        height: "auto",
        minHeight: "100vh",
        margin: "0 auto",
    };

    const outerContent = {
        width: "470px",
        height: "120px",
        margin: "2rem auto 0 auto",
        borderRadius: "3px",
        border: "1px solid #ffca1a",
        backgroundColor: "rgba(255, 202, 26, 0.16)",
    };

    return (
        <>
            <div className="container" style={outerContainer}>
                <div className="ae-outer-content" style={outerContent}>
                    <div className="row">
                        <h1 className="mb-1" style={{ fontSize: "18px" }}>Warning!</h1>
                        <h3 className="mb-3" style={{ fontSize: "16px" }}>Account is already activated!</h3>
                    </div>
                    <div className="ae-buttons">
                        <button className="ae-btn">
                            <Link to="/login" style={{ color: "#fff" }}>
                                Login
                            </Link>
                        </button>or
                        <Link to="/property-search" className="mx-3" style={{ color: "#008da9" }}>
                            Go to the Property Search
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountActivated;