import React,{useState} from "react";
import "./styles/create-account-styles.css";
import "devextreme/dist/css/dx.light.css";
import ReCAPTCHA from "react-google-recaptcha";
import ValidationIcons from "./Sub-Components/ValidationIcons";
import ValidationIconRules from "./Sub-Components/ValidationIconRules";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import Validator, { RequiredRule, EmailRule, CompareRule } from "devextreme-react/validator";
import ValidationGroup from "devextreme-react/validation-group";
import { Button } from 'devextreme-react/button';
import TextBox from "devextreme-react/text-box";
import { connect } from "react-redux";
import baseService from "../../services/base.service";
import { updateEmail, updateUserName, updateFirstName, updateLastName, updatePhoneNumber, updatePassword, updateConfirmPassword, setIsCaptchaChecked, setIsCaptchaErrorVisible, setIsCaptchaExpired, setIsPhoneValidMessage, setIsPhoneValidState, updateFormData, handlePasswordChange } from "../../utils/create-account";
import { useNavigate } from "react-router-dom";
import CustomPhone from '../assets/elements/input/custom-phone'
import Loading from '../assets/controls/loading'

//images
import EyeIcon from '../../images/admin-icons/eye-small.svg'
import CloseEyeIcon from '../../images/admin-icons/eye-slash.svg'

const CreateAccountForm = ({formData, email, userName, firstName, lastName, phoneNumber, password, confirmPassword, isCaptchaChecked, isCaptchaErrorVisible, isCaptchaExpired, isPhoneValidState, isPhoneValidMessage }) => {
    const navigate = useNavigate();
    const captchaRef = React.useRef(null);
    const validationGroupRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false)
    const emailRef = React.useRef(null);
    const userNameRef = React.useRef(null);
    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const confirmPasswordRef = React.useRef(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false)
    const [retypePasswordVisible, setRetypePasswordVisible] = useState(false)

    const handleRegister = async (e) => {
        e.preventDefault();
        const result = await validationGroupRef.current.instance.validate();
        if (!result.isValid || !phoneNumber || !isPhoneValidState || !isCaptchaChecked) {
            onValidation();
            return;
        }
        else
        {
            if(formData.lengthValid && formData.upperValid && formData.lowerValid && formData.numberValid && formData.specialValid)
            {
                const data = { email, userName, firstName, lastName, phoneNumber, password, confirmPassword };
                postRegister(data);
            }
        }

        
    };

    const postRegister = async (data) => {
        setIsSubmitting(true);
        // setTimeout(resetFormAndCaptcha, 100);
        setLoading(true)
        try {
            const response = await baseService({
                method: "POST",
                baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
                url: "/api/v1/accounts",
                data: data,
            });

            if (response.data.isSuccess === true) {
                setLoading(false)
                await resetFormAndCaptcha();
                navigate("/account/registered");
            } else {
                setLoading(false)
                navigate("/account/verification-failed");
            }
        } catch (error) {
            setLoading(false)
            if (error.response && error.response.status === 422) {
                updateFormData();
                navigate("/account/already-exists");
            }
        };
    };

    const resetFormAndCaptcha = () => {
        return new Promise((resolve) => {
            setIsCaptchaChecked(false);
            setIsCaptchaErrorVisible(false);
            setIsCaptchaExpired(false);
            updateFormData();
            validationGroupRef.current.instance.reset();
            captchaRef.current.reset();
            resolve();
        });
    };

    const goToLogin = (e) => {
        e.event.preventDefault();
        navigate("/login");
    };

    const onValidation = _ => {
        if (!phoneNumber) {
            setIsPhoneValidState(false)
            setIsPhoneValidMessage('The phone is required')
        };
        if (!isCaptchaChecked || isCaptchaExpired) {
            setIsCaptchaErrorVisible(true);
        }
        else {
            setIsCaptchaErrorVisible(false);
        }
    };

    return (
        <>
            <div className="create-account">
                <div className="row mt-3 mb-3 p-4">
                    <h3 className="text-center text-uppercase">create login account</h3>
                </div>
                <form onSubmit={handleRegister}>
                    <ValidationGroup ref={validationGroupRef}>
                        <div className="create-account-form">
                                <div className="simple-item-styles">
                                    <div className="simple-item-styles__label">Email: <span className="simple-item-styles--required">*</span></div>
                                    <TextBox name="email" value={email} ref={emailRef} disabled={loading}
                                        onValueChanged={(e) => {
                                            updateEmail(e.value);
                                        }}>
                                        <Validator>
                                            <RequiredRule message="Email is required" />
                                            <EmailRule message="Email is invalid" />
                                        </Validator>
                                    </TextBox>
                                </div>
                                <div className="simple-item-styles">
                                    <div className="simple-item-styles__label">Username: <span className="simple-item-styles--required">*</span></div>
                                    <TextBox name="username" value={userName} ref={userNameRef} disabled={loading}
                                        onValueChanged={(e) => {
                                            updateUserName(e.value);
                                        }}>
                                        <Validator>
                                            <RequiredRule message="Username is required" />
                                        </Validator>
                                    </TextBox>
                                </div>
                                <div className="simple-item-styles">
                                    <div className="simple-item-styles__label">First Name: <span className="simple-item-styles--required">*</span></div>
                                    <TextBox name="firstName" value={firstName} ref={firstNameRef} disabled={loading}
                                        onValueChanged={(e) => {
                                            updateFirstName(e.value);
                                        }}>
                                        <Validator>
                                            <RequiredRule message="First Name is required" />
                                        </Validator>
                                    </TextBox>
                                </div>
                                <div className="simple-item-styles">
                                    <div className="simple-item-styles__label">Last Name: <span className="simple-item-styles--required">*</span></div>
                                    <TextBox name="lastName" value={lastName} ref={lastNameRef} disabled={loading}
                                        onValueChanged={(e) => {
                                            updateLastName(e.value);
                                        }}>
                                        <Validator>
                                            <RequiredRule message="Last Name is required" />
                                        </Validator>
                                    </TextBox>
                                </div>
                                <div className="simple-item-styles phone-block">
                                    <div className="simple-item-styles__label">Phone: <span className="simple-item-styles--required">*</span></div>
                                    <div>
                                        <CustomPhone
                                            value={phoneNumber}
                                            setValue={updatePhoneNumber}
                                            requiredValue={true}
                                            isValid={isPhoneValidState}
                                            setIsValid={setIsPhoneValidState}
                                            validMessage={isPhoneValidMessage}
                                            setValidMessage={setIsPhoneValidMessage}
                                            isDisabled={loading}
                                        />
                                </div>
                                <div className="simple-item-styles__label phone-policy-label"></div>
                                <div className="phone-policy-block">
                                    <span>By providing my phone number to "Oasis", I agree and acknowledge that "Oasis" may send text messages to my wireless phone number for any purpose. Message and data rates may apply. </span>
                                    <span>Oasis will only send one SMS as a reply to you, and you will be able to Opt-out by replying "STOP". For more information on how your data will be handled please visit <a href="/privacy-policy" target="_blank">Privacy Policy</a></span>
                                    <span>No mobile information wil be shared with third parties/addiliates for marketing/promotional purposes. All the above categories exclude text messageng originator Opt-in data and consent; this information will not be shared with any third parties.</span>
                                </div>
                                </div>
                                <div className="simple-item-styles">
                                    <div className="simple-item-styles__label">Password: <span className="simple-item-styles--required">*</span></div>
                                    <div className="create-account__password">
                                        <TextBox mode={passwordVisible ? 'input' : 'password'} name="password" value={password} ref={passwordRef} disabled={loading}
                                            onInput={(e) => {
                                                handlePasswordChange(e.event.target.value);
                                            }}
                                            onValueChanged={(e) => {
                                                updatePassword(e.value);
                                            }}>
                                            <Validator>
                                                <RequiredRule message="Password is required" />
                                                <ValidationIconRules />
                                            </Validator>
                                        </TextBox>
                                        <div className="create-account__password__image-container">
                                            <img src={passwordVisible ? CloseEyeIcon : EyeIcon} className={`create-account__password__image ${passwordVisible ? '' : 'create-account__password__image--no-visible'}`} onClick={_=>setPasswordVisible(!passwordVisible)}/>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="create-account__validation-icons">
                                    <ValidationIcons />
                                </div>
                                <div className="simple-item-styles">
                                    <div className="simple-item-styles__label">Retype Password: <span className="simple-item-styles--required">*</span></div>
                                    <div className="create-account__password">
                                        <TextBox mode={retypePasswordVisible ? 'input' : 'password'} name="confirmPassword" value={confirmPassword} ref={confirmPasswordRef} disabled={loading}
                                        onValueChanged={(e) => {
                                            updateConfirmPassword(e.value);
                                        }}>
                                        {isSubmitting ? (
                                            <Validator>
                                                <RequiredRule message="Retype Password is required" />
                                            </Validator>
                                        ) : (
                                            <Validator>
                                                <RequiredRule message="Retype Password is required" />
                                                <CompareRule message="Passwords do not match" comparisonTarget={() => password} />
                                            </Validator>
                                        )}
                                    </TextBox>
                                        <div className="create-account__password__image-container">
                                            <img src={retypePasswordVisible ? CloseEyeIcon : EyeIcon} className={`create-account__password__image ${retypePasswordVisible ? '' : 'create-account__password__image--no-visible'}`} onClick={_=>setRetypePasswordVisible(!retypePasswordVisible)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-account__recaptcha">
                                    {isCaptchaErrorVisible && <div className="text-danger">Please verify the reCAPTCHA.</div>}
                                    <ReCAPTCHA
                                        ref={captchaRef}
                                        sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`}
                                        className="g-recaptcha create-account-recaptcha"
                                        onChange={() => {
                                            setIsCaptchaChecked(true);
                                            setIsCaptchaExpired(false);
                                            setIsCaptchaErrorVisible(false);
                                        }}
                                        onExpired={() => {
                                            setIsCaptchaChecked(false);
                                            setIsCaptchaExpired(true);
                                        }}
                                    />
                                </div>
                                <div className="register-buttons">
                                    <div className="register-buttons__register">
                                        <Button useSubmitBehavior={true} className="btn-register" text="Register" disabled={loading} onClick={onValidation} />
                                        <Loading isVisble={loading} size={35}/>
                                    </div>
                                    <Button useSubmitBehavior={false} className="btn-return" text="Back to Login" onClick={goToLogin} disabled={loading}/>
                                </div>
                        </div>
                    </ValidationGroup>
                </form>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        formData: state.formData,
        email: state.formData.email,
        userName: state.formData.userName,
        firstName: state.formData.firstName,
        lastName: state.formData.lastName,
        phoneNumber: state.formData.phoneNumber,
        password: state.formData.password,
        confirmPassword: state.formData.confirmPassword,
        lengthValid: state.formData.lengthValid,
        upperValid: state.formData.upperValid,
        lowerValid: state.formData.lowerValid,
        numberValid: state.formData.numberValid,
        specialValid: state.formData.specialValid,
        isCaptchaChecked: state.formData.isCaptchaChecked,
        isCaptchaExpired: state.formData.isCaptchaExpired,
        isCaptchaErrorVisible: state.formData.isCaptchaErrorVisible,
        isPhoneValidState: state.formData.isPhoneValidState,
        isPhoneValidMessage: state.formData.isPhoneValidMessage,
    };
};

export default connect(mapStateToProps)(CreateAccountForm);