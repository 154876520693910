import {
    FETCH_FAQ_REQUEST,
    FETCH_FAQ_SUCCESS,
    FETCH_FAQ_ERROR
} from '../constant/faq'

const initialState = {
    data: [],
    loading: false,
    error: null
}

export default function faqReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_FAQ_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_FAQ_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            }
        case FETCH_FAQ_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}