import {useState,useEffect,useCallback} from 'react'
import CareerListAdmin from './career/career-list-admin/career-list-admin';
import CareerList from './career/career-list'
import {getIsAdmin} from '../utils/admin'
const Career = () => {
  const [isAdmin, setIsAdmin] = useState(getIsAdmin())
  const storageChanged = useCallback(e => {
    setIsAdmin(getIsAdmin())
  },[])

  useEffect(() => {
    window.addEventListener("storage", storageChanged);
    return () => window.removeEventListener("storage", storageChanged)
  }, []) 
  // const isAdmin = getIsAdmin()
  return (isAdmin ? <CareerListAdmin/> : <CareerList/>);
}

export default Career

