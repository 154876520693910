import {useCallback,useRef,memo,useEffect,useState} from 'react'
import 'devextreme/dist/css/dx.light.css';

//components

import { DropDownBox } from 'devextreme-react/drop-down-box';
import SelectBox from "devextreme-react/select-box";

import { List } from "devextreme-react/list";

//style
import '../../styles/input/custom-dropdown-alt.css'

const Dropdown = ({values,value,setValue,disable,label,requiredValue,isGroup,isEmpty,setIsEmpty,valueName,inputIcon,placeholder,noBorders}) => {

  const dropDownBoxRef = useRef(null);
  const listDropdownRef = useRef(null)
  const changeDropDownBoxValue = useCallback((arg) => {
      setValue(arg.addedItems[0].id);
      dropDownBoxRef.current.instance.close();
  }, []);
  const changeSelecboxValue = useCallback((e) => {
    setValue(e.value);
  }, []);

  const onBlur = () =>{
    if(dropDownBoxRef.current)
    {
      dropDownBoxRef.current.instance.close()
    }
  }

  useEffect(() => {
    if(value)
    {
      setIsEmpty && setIsEmpty(false)
    }
  }, [value])
  
  
 

  return (
    <div className={`custom-dropdown-alt ${noBorders ? 'custom-dropdown-alt--no-borders' : ''}`}>
        {label && <div className='custom-dropdown-alt__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>}
        <div className={isEmpty && requiredValue ? 'custom-dropdown-alt__container--error':''}>
          <div className={`custom-dropdown-alt__container ${inputIcon ? '' : 'custom-dropdown-alt__container--noIcon'}`}>
              {isGroup ?
                <SelectBox
                dataSource={values}
                placeholder={placeholder ? placeholder : "Choose option"}
                displayExpr={valueName ? valueName : "name"}
                valueExpr="id"
                grouped={true}
                groupTemplate={data=>data.key}
                value={value}
                onValueChanged={changeSelecboxValue}
                disabled={disable}
                />
                :
                <DropDownBox
                value={[value]}
                ref={dropDownBoxRef}
                placeholder={placeholder ? placeholder : "Choose option"}
                valueExpr="id"
                displayExpr="name"
                keyExpr="id"
                dataSource={values}
                disabled={disable}
                onBlur={onBlur}
                >
                  <List
                      ref={listDropdownRef}
                      className={`custom-dropdown-alt__list ${inputIcon ? 'custom-dropdown-alt__list--icon' : ''}`}
                      selectionMode="single"
                      dataSource={values}
                      valueExpr="id"
                      displayExpr="name"
                      keyExpr="id"
                      selectedItemKeys={[value]}
                      onSelectionChanged={changeDropDownBoxValue}
                  />
                  </DropDownBox>
                }
              
            <div className='custom-dropdown-alt__container__icon'><img src={inputIcon}/></div>
          </div>
          {(requiredValue && isEmpty) ? <div className='custom-dropdown-alt__container__message--error'>{label} is required</div> : null}
       </div>
    </div>
  )
}
export default memo(Dropdown)