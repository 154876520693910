import React from "react";
import "../styles/cta-styles.css";

const CtaContent = () => {
    return (
        <>
            <div className="cta-content">
                <p>
                    Oasis is a global provider that was born to bring "the way it ought to be" to our clients. Our properties are the best in their class. Our furniture is the furniture you want in your house. Our responsiveness is the type you wish all companies still had (a real person answers your calls 24/7). As a global company, we provide our clients with the simplicity of having one point of contact for all temporary furnished housing needs throughout the world. <br /> By combining twice the space of hotel rooms and up to half the cost, Oasis brings cost-effective ideas, personal service and the simplicity of our one point of contact. No matter if we are serving one individual or your entire corporation, we can provide the best in temporary furnished apartments anywhere. <br /> When you are on an extended trip and want that "home away from home" feel, Oasis can help you find exactly what you are looking for.
                </p>
                <h5 className="cta-sub-title">OUR STORY</h5>
                <p>
                    As a provider of best-in-class, temporary lodging across the globe, Oasis understands the needs of travelers. Since 2003, we have provided furnished rentals and executive accommodations for groups and individuals in varied temporary assignments and unique situations. We serve the needs of diverse corporations, healthcare professionals, insurance carriers, adjusters, policy holders /ALE, entertainment and production companies, government entities, contractors, and individuals and families. Our properties are optimally located near business centers, and public and resort attractions, providing all the basic amenities you expect for a longer-term stay. <br /> If you're away from home for a relocation, temporary business assignment, or extended vacation, you want simplicity, comfort and personal services that are second to none. That is why we provide a single point-of-contract 24/7 to respond to any questions you may have. At Oasis, we will help you locate a property at the best value for your needs. <br /> Typical basic amenities provided: full furnishings, housewares, kitchenware, linens, electronics, cable, Wi-Fi, phone, and scheduled housekeeping. We can confirm if specific amenities are included with an offer.
                </p>
            </div>
        </>
    )
}

export default CtaContent;