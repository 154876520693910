import {useEffect,useState} from 'react'
import '../styles/pagination.css'
const Pagination = ({totalPages,currentPage,setCurrentPage}) => {
  const [arrows,setArrows]=useState({
    begin: false,
    previous: false,
    next: false,
    end: false
  })

  const [numberPages,setNumberPages] = useState([])


  const onChangeCurrentPage = type => {
    switch (type) {
      case 'begin':
        setCurrentPage(1)
        break;
      case 'previous':
        if(currentPage > 1)
          setCurrentPage(currentPage - 1)
        break;
      case 'next':
        if(currentPage < totalPages)
          setCurrentPage(currentPage + 1)
        break;
      case 'end':
        setCurrentPage(totalPages)
        break;
      default:
        setCurrentPage(type)
        break;
    }
  }

  const getNumbers = (begin,end) => {
    let tempNumbers = []
    for(let i = begin; i <= end; i++)
    {
      tempNumbers.push(i)
    }
    return tempNumbers
  }

  useEffect(() => {
    if(totalPages <= 4)
    {
      setNumberPages(getNumbers(1,totalPages))
    }
    else
    {
      if(totalPages > 4 && totalPages <= 6)
      {
        if(currentPage >= 4)
        {
          setNumberPages(getNumbers(3,totalPages))
        }
        else{
          setNumberPages(getNumbers(1,4))
        }
      }
      else{
        if(currentPage > 3 && currentPage < totalPages - 2)
        {  
          setNumberPages(getNumbers(currentPage+2 - 4,currentPage+1))
        }
        else if(currentPage >= totalPages - 3)
        {
          setNumberPages(getNumbers(totalPages - 3 , totalPages))
        }
        else{
          setNumberPages(getNumbers(1,4))
        }
      }
    }  
    if(totalPages > 1)  
    {  if(currentPage === 1)
      setArrows({
        begin: false,
        previous: false,
        next: true,
        end: true
      })
      else if(currentPage > 1 && currentPage < totalPages)
      setArrows({
        begin: true,
        previous: true,
        next: true,
        end: true
      })
      else
      setArrows({
        begin: true,
        previous: true,
        next: false,
        end: false
      })
    }
    else
    {
      setArrows({
        begin: false,
        previous: false,
        next: false,
        end: false
      })
    }
  }, [currentPage,totalPages])
  
  return (
    <div className='pagination'>
      <button onClick={()=>onChangeCurrentPage('begin')} disabled={!arrows.begin}>{'<<'}</button>
      <button onClick={()=>onChangeCurrentPage('previous')} disabled={!arrows.previous}>{'<'}</button>
      { numberPages.map((page,index)=>
        <button key={index}  onClick={()=>onChangeCurrentPage(page)} className={currentPage === page ? 'page--active' : null} disabled={currentPage === page}>{page}</button>
        )
      }
      <button onClick={()=>onChangeCurrentPage('next')} disabled={!arrows.next}>{'>'}</button>
      <button onClick={()=>onChangeCurrentPage('end')} disabled={!arrows.end}>{'>>'}</button>
    </div>
  )
}

export default Pagination