import {useEffect} from 'react'
import '../styles/custom-nav-tabs.css'
const NavTabs = ({tabs,setCurrent,current}) => {
    useEffect(() => {
      setCurrent(tabs[Object.keys(tabs)[0]])
    }, [])
    
  return (
    <div className='custom-nav-tabs'>
        {   Object.keys(tabs).map(
                tab => <span key={tab} onClick={()=>setCurrent(tabs[tab])} className={`custom-nav-tab ${current === tabs[tab] ? 'custom-nav-tab--active': ''}`}>{tabs[tab]}</span>
            )
        }
        
    </div>
  )
}

export default NavTabs