import {useState} from 'react'
//libs
import moment from "moment";
//component
import Title from '../../assets/elements/section-subtitle'
import RequestAccordion from './request-accordion'
import RequerimentsCard from './request-requirements-card'
import PropertyCard from './request-properties-card'
import PropertyOptions from './request-properties-card-options'

//constant
import {SET_REQUEST_STATUS, GET_REQUEST_STATUS} from '../../../constant/dictionary'

//images
import RequestVolt from '../../../images/request_volt.svg'

//styles
import '../styles/request-list-item.css'

import store from '../../../store';
import RequestHelper from "../../../utils/helper";

const RequestListItem = ({ request,hiddeActions }) => {
    const [bookedFilter, setBookedFilter] = useState(true)
    const getLengthOfStay = (moveIn, moveOut) => {
        let start = moment.utc(moveIn).startOf('day');
        let end = moment.utc(moveOut).startOf('day');

        let los = end.diff(start, 'days');
        return `${los} days`
    }

    let allFloorPlans = store.getState().dictionary.floorPlanTypes;
    const getFloorPlanName = (typeId) => {
        return allFloorPlans.filter(f => f.id === typeId)[0].name;
    }
    const statusMapping = id => {
      let value = SET_REQUEST_STATUS.filter(item => item.id === id)
      if(value.length > 0)
        return value[0].name
      else
        return ''
    }
  return (
    <div className='request-list-item' key={request.id}>
        <Title><div className="request-list-item__title"><div>{request.title} {request.isQuickRequest && <img src={RequestVolt} width={20} height={20}/>} </div><div className={`request-list-item__status-request-tag ${request.statusId === 1 ? 'request-list-item__status-request-tag--new': ''}`}>{statusMapping(request.statusId)}</div></div></Title>
        <div className='request-list-item__accordions'>
              <RequestAccordion id={`${request.title}-requirements`} title='Requirements' isActive={request.preferredProperties.length == 0 && request.propertyOptions.length == 0} >
                <RequerimentsCard 
                      key={request.requirements.id}
                      requirements={request.requirements}
                      requestId={request.id}
                      statusId={request.statusId}
                      hiddeActions={hiddeActions}
                      mutedActions={request.statusId !== 1 && request.statusId !== 3 && request.statusId !== 2}
                      mutedMessage={request.statusId === 5 ? '-You have Active Reservation, cancel it before Cancel Request' : ' You have Booked Reservation, cancel booking before Cancel Request'}
                />
              </RequestAccordion>
          {
                  (request.preferredProperties.length > 0 || request.propertyOptions.length > 0) && 
              <RequestAccordion id={`${request.title}-properties`} title={`Requested Properties (${request.preferredProperties.length + request.propertyOptions.length})`} isActive={true} filter={bookedFilter} setFilter={setBookedFilter} isFilterActive={request.statusId === GET_REQUEST_STATUS.Booked || request.statusId === GET_REQUEST_STATUS.Pending_Booking_Cancellation}>
                {request.preferredProperties.map(property => 
                  <PropertyCard
                        key={`properties${property.id}`}
                        bookedFilter={(request.statusId === GET_REQUEST_STATUS.Booked || request.statusId === GET_REQUEST_STATUS.Pending_Booking_Cancellation) && bookedFilter}
                        id={property.id}
                        floorPlan={property.floorPlan}
                        name={property.name}
                        building={property.buildingType}
                        image={property.imageUrl}
                        address={property.address}
                        dates={property.dates}
                        hiddeActions={hiddeActions}
                        declineReason={property.declineReasonId}
                        requestId={request.id}
                  />
                )}
                          {request.propertyOptions.map(property =>
                              <PropertyOptions
                                  key={`options${property.id}`}
                                  bookedFilter={(request.statusId === GET_REQUEST_STATUS.Booked || request.statusId === GET_REQUEST_STATUS.Pending_Booking_Cancellation) && bookedFilter}
                                  option_id={property.id}
                                  request_id={request.id}
                                  request_status={request.statusId}
                                  property_id={property.propertyId}
                                  property_name={property.propertyName}
                                  property_address={property.propertyFormattedAddress}
                                  unit_size={property.squareFootage > 0 ? property.squareFootage : null}
                                  distance={property.distance}
                                  parking={property.parkingAccessibility ? "Yes" : "No"}
                                  property_grade={"A+"} //to fix in integrationEventHandler
                                  final_rate={property.finalRate}
                                  floor_plan={getFloorPlanName(property.floorPlanId)}
                                  dates_available={RequestHelper.getDurationString(property.checkInDate, property.checkOutDate)}
                                  checkInDate={property.checkInDate}
                                  checkOutDate={property.checkOutDate}
                                  length_of_stay={getLengthOfStay(property.checkInDate, property.checkOutDate)}
                                  floor_level={property.floorLevel}
                                  maid_service={property.maidFrequency}
                                  telephone={property.telephone}
                                  ntv_requirement={property.noticeRequired}
                                  option_status={property.statusId}
                                  parking_accessibility={property.parkingAccessibility}
                                  currency_code={property.currency.code}
                                  hiddeActions={hiddeActions}
                                  extensions={request.extensions}
                  />
                  )
                }
              </RequestAccordion>
          }
        </div>
        
    </div>
  )
}

export default RequestListItem