import React from "react";
import { Quote } from "react-bootstrap-icons";
import baseService from "../../../services/base.service";

const SingleArticle = ({ id }) => {
    const [article, setArticle] = React.useState(null);

    React.useEffect(() => {
        async function fetchData() {
            await baseService({
                method: "GET",
                baseURL: process.env.REACT_APP_API_ENDPOINT,
                url: `/api/v1/news/${id}`,
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(res => {
                setArticle(res.data);
                return res;
            }).catch(res => {
                return res;
            });
        };
        fetchData();
    }, [id]);

    return (
        <>
            <div className="row">
                <div className="article-title">
                    <h1 className="text-uppercase fw-bold">{article ? article.title : null}</h1>
                </div>
                <div className="article-date">
                    <span>
                        {article ? new Date().toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        }) : null}
                    </span> by&nbsp;
                    <span className="article-author">{article ? article.addedBy : null}</span>
                </div>
                <div className="article-body mt-2 mb-3" dangerouslySetInnerHTML={{ __html: article ? article.textOfNews : null }} />
                <hr className="mb-3" />
                {/* comments */}
                {/* <div className="article-comments">
                    <div className="d-flex mb-3 fw-bold">
                        <span className="text-capitalize comments-border">&nbsp;comments</span>&nbsp;
                        <span className="comments-count">&#40;2&#41;</span>
                    </div>
                    <div className="comments-box mb-3">
                        <div className="container">
                            <div className="row">
                                <div className="comment-container d-flex">
                                    <div className="col-md-2">
                                        <div className="comment-date">
                                            <span>Dec 23,2020, 16:06 PM</span>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="comment">
                                            <span className="comment-quotes">
                                                <Quote size={27} />
                                            </span>
                                            <div className="comment-content w-100">
                                                <div className="comment-body">
                                                    Lorem ipsum dolor sit amet consectetur
                                                </div>
                                                <div className="comment-author">
                                                    by&nbsp;<span className="comment-author-name">John Doe</span>
                                                </div>
                                                <hr className="comment-dotted-border" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="comment-container d-flex">
                                    <div className="col-md-2">
                                        <div className="comment-date">
                                            <span>Dec 23,2020, 16:06 PM</span>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="comment">
                                            <span className="comment-quotes">
                                                <Quote size={27} />
                                            </span>
                                            <div className="comment-content w-100">
                                                <div className="comment-body">
                                                    Lorem ipsum dolor sit amet consectetur
                                                </div>
                                                <div className="comment-author">
                                                    by&nbsp;<span className="comment-author-name">John Doe</span>
                                                </div>
                                                <hr className="comment-dotted-border" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default SingleArticle;