import { useState,useEffect } from 'react'
//images
// import NoFavoriteImage from '../../../../images/heart.png'
// import FavoriteImage from '../../../../images/heart_red.png'
import { ReactComponent as HeartIcon } from "../../../../images/heart.svg";

//styles
import '../../styles/button/favorite-button.css'

const FavoriteButton = ({propertyId,metisId,isLiked,likeAProperty,unLikeAProperty,cardType}) => {
  const [isFavorite, setIsFavorite] = useState(isLiked)
  const onChangeFavorite = e =>{
    e.preventDefault()
    e.stopPropagation()
    setIsFavorite(!isFavorite)
    if(!isFavorite)
    {
      likeAProperty(propertyId,metisId)
    }
    else{
      unLikeAProperty(propertyId)
    }
  }
  useEffect(() => {
    setIsFavorite(isLiked)
  }, [isLiked])
  
  return (
    <>
      <HeartIcon className={`favorite-button ${isFavorite ? 'favorite-button--active' : ''} ${cardType === 'square' ? 'favorite-button--square' : 'favorite-button--small'}`} onClick={onChangeFavorite}/>
        {/* {isFavorite ? <img src={FavoriteImage} onClick={onChangeFavorite}/> : <img src={NoFavoriteImage} onClick={onChangeFavorite}/>} */}
    </>
  )
}

export default FavoriteButton