import {useState,useEffect,useRef} from 'react'
import {connect} from 'react-redux'
import notify from 'devextreme/ui/notify';
//components
import ContentWrapper from './assets/elements/content-wrapper'
import Alert from './assets/elements/custom-alert'
import CustomRecaptcha from './assets/elements/custom-recaptcha'

import SectionHeader from './assets/elements/section-header'
import CustomText from './assets/elements/input/custom-text'
import ActionButton from './assets/elements/button/action-button'
import CustomPhone from './assets/elements/input/custom-phone'
import CustomEmail from './assets/elements/input/custom-email'
import CustomTextarea from './assets/elements/input/custom-textarea'
import SearchCity from './assets/elements/input/search-city'
import SearchStreet from './assets/elements/input/search-street'

import CustomDropdown from './assets/elements/input/custom-dropdown'
import CustomRadio from './assets/elements/input/custom-radio'
import CustomGroup from './assets/elements/input/custom-number-group-dropdown'
import CustomUploader from './assets/elements/input/custom-uploader'
import PropertySent from './list-a-property/property-sent'
import GoogleWrapper from './assets/controls/google-wrapper'
import Loading from './assets/controls/loading'
//utils
import {sendProperty} from '../utils/list-a-property'
//actions
import {setDefault} from '../actions/list-a-property'
//constants
import {HEADER,INPUT_LABELS,BUTTON_LABEL,OWNERSHIP,PROPERTY_TYPE,SQUARE_FOOTAGE} from '../constant/list-a-property'
import {FLOORS,ADVERTISEMENTS} from '../constant/dropdown'  
import {PHONE} from '../constant/messages' 
//styles
import './list-a-property/styles/list-a-property.css'
//images
import Homeslide from '../images/homeslide01.jpg'
const ListAProperty = ({loading,propertySent,floorPlanTypes,setDefault}) => {
  const [name, setName] = useState('')
  const [lastname,setLastname] = useState('')
  const [phone,setPhone] = useState('')
  const [email,setEmail] = useState('')
  const [city,setCity] = useState('')
  const [street,setStreet] = useState('')
  const [description,setDescription] = useState('')
  const [floor, setFloor] = useState('')
  const [hearOfUs, setHearOfUs] = useState('')
  const [geometryCity, setGeometryCity] = useState()
  const [placeIdCity, setPlaceIdCity] = useState('')
  const [placeIdStreet,setPlaceIdStreet] = useState('')
  const [countryISOCode,setCountryISOCode] = useState('')
  const [localityCity,setLocalityCity] = useState('')

  const [ownership,setOwnership] = useState()
  const [propertyType,setPropertyType] = useState()
  const [indoorFootage,setIndoorFootage] = useState()
  const [indoorFootageType,setIndoorFootageType] = useState(SQUARE_FOOTAGE[0])
  const [outdoorFootage,setOutdoorFootage] = useState()
  const [outdoorFootageType,setOutdoorFootageType] = useState(SQUARE_FOOTAGE[0])
  const [images,setImages] = useState([])
  const [recaptchaInvalid, setRecaptchaInvalid] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [isPhoneValidState, setIsPhoneValidState] = useState(true)
  const [isPhoneValidMessage,setIsPhoneValidMessage] = useState('')
  
  const recaptchaRef = useRef()
  
  const isMaxFileSizeValid = () => {
    const sumImageSize = images.map(item => item.size).reduce((prev, curr) => prev + curr, 0);
    if (sumImageSize > INPUT_LABELS.images.maxFileSize){
       notify(INPUT_LABELS.images.invalidFileSizeMessage,"error", 3000);
       return false;
      }
     return true;
  }

  const onSubmitProperty = e => {
    e.preventDefault()
    if(recaptchaRef.current.getValue() && isPhoneValidState && phone && isMaxFileSizeValid() && floor)
    {
        sendProperty({
            'FirstName': name,
            'LastName':lastname,
            'PhoneNumber':phone,
            'EmailAddress':email,
            'OwnershipType':ownership.id,
            'FloorPlanTypeId':floor,
            'BuildingType':propertyType.id,
            'City':city,
            'StreetAddress':street,
            'IndoorSquareFootage': indoorFootage,
            ...(outdoorFootage != null && { 'OutdoorSquareFootage': outdoorFootage, }),
            'IndoorSquareFootageType':indoorFootageType.id,
            'OutdoorSquareFootageType':outdoorFootageType.id,
            'Description':description,
            'HowDidYouHearAboutUsType':hearOfUs
        },images)
        
    }        
  }
  
  useEffect(() => {
    if(propertySent.status)
    {
        setName('')
        setIsEmpty(false)
        setLastname('')
        setPhone('')
        setEmail('')
        setCity('')
        setStreet('')
        setPlaceIdCity('')
        setDescription('')
        setFloor('')
        setHearOfUs('')
        setGeometryCity()
        setOwnership()
        setPropertyType()
        setIndoorFootage()
        setIndoorFootageType(SQUARE_FOOTAGE[0])
        setOutdoorFootage()
        setOutdoorFootageType(SQUARE_FOOTAGE[0])
        setImages([])
    }
    else
    {
        propertySent.message && notify(propertySent.message, "error", 3000);
    }
  }, [propertySent])

  const validateSubmit = _ => {
    if(!floor)
    {
      setIsEmpty(true)
    }
    if(!phone)
    {
        setIsPhoneValidState(false)
        setIsPhoneValidMessage(PHONE.required)
    }
    if(recaptchaRef.current.getValue())
        setRecaptchaInvalid(false)
    else
        setRecaptchaInvalid(true)
  }

  useEffect(() => {
    return () => setDefault()
  }, [])
  
  return (
    <div>
        <SectionHeader title={HEADER.title} subtitle={HEADER.subtitle} buttonLabel={HEADER.button} navigateTo='/list-your-property/become-a-partner' image={Homeslide}/>
        <ContentWrapper>
                <div className='list-a-property-content' id='list-a-property-content'>
                {
                    !loading && !propertySent.status &&
                    <form action="" onSubmit={onSubmitProperty}>
                    <div className='list-a-property-content__row'>
                        <CustomText labelSize='170px' label={INPUT_LABELS.firstname} value={name} setValue={setName} requiredValue={true}/>
                        <CustomText labelSize='170px' label={INPUT_LABELS.lastname} value={lastname} setValue={setLastname} requiredValue={true}/>
                    </div>
                    <div className='list-a-property-content__row'>
                        <CustomPhone 
                            labelSize='170px' 
                            label={INPUT_LABELS.phone} 
                            value={phone} 
                            setValue={setPhone} 
                            requiredValue={true}
                            isValid={isPhoneValidState}
                            setIsValid={setIsPhoneValidState}
                            validMessage={isPhoneValidMessage}
                            setValidMessage={setIsPhoneValidMessage}
                            />
                    </div>
                    <CustomEmail labelSize='170px' label={INPUT_LABELS.email} value={email} setValue={setEmail} requiredValue={true}/>
                    <CustomRadio labelSize='170px' label={INPUT_LABELS.owner_type} value={ownership} setValue={setOwnership} requiredValue={true} items={OWNERSHIP}/>
                    <CustomRadio labelSize='170px' label={INPUT_LABELS.property_type} value={propertyType} setValue={setPropertyType} requiredValue={true} items={PROPERTY_TYPE}/>

                    <GoogleWrapper>
                        <SearchCity labelSize='170px' label={INPUT_LABELS.city} value={city} setValue={setCity} requiredValue={true} nameField='refCity' setGeometry={setGeometryCity} placeId={placeIdCity} setPlaceId={setPlaceIdCity} setCountryISOCode={setCountryISOCode} setLocalityCity={setLocalityCity} localityCity={localityCity}/>

                        {/* <SearchLocation label='City' value={city} setValue={setCity} requiredValue={true} nameField='refCity' type={1}  setGeometry={setGeometryCity}/> */}
                        {/* <SearchLocation label='Street' value={street} setValue={setStreet} requiredValue={true} nameField='refStreet' type={2} geometry={geometryCity}/> */}
                        <SearchStreet labelSize='170px' label={INPUT_LABELS.street} value={street} setValue={setStreet} requiredValue={true} nameField='refStreet' geometryCity={geometryCity} countryISOCode={countryISOCode} localityCity={localityCity} placeId={placeIdStreet} setPlaceId={setPlaceIdStreet}/>
                    </GoogleWrapper>
                    <CustomDropdown isEmpty={isEmpty} setIsEmpty={setIsEmpty} labelSize='170px' label={INPUT_LABELS.floor_plan} values={floorPlanTypes} value={floor} setValue={setFloor} requiredValue={true}/>
                    <div className='list-a-property-content__row'>
                        <CustomGroup labelSize='170px' label={INPUT_LABELS.indoor_footage} value={indoorFootage} setValue={setIndoorFootage} requiredValue={true} dropdownValue={indoorFootageType} setDropdownValue={setIndoorFootageType} values={SQUARE_FOOTAGE} min={1} max={3000} />
                        <CustomGroup labelSize='170px' label={INPUT_LABELS.outdoor_footage} value={outdoorFootage} setValue={setOutdoorFootage} dropdownValue={outdoorFootageType} setDropdownValue={setOutdoorFootageType} values={SQUARE_FOOTAGE} min={0} max={3000} />
                    </div>
                    <CustomTextarea labelSize='170px' label={INPUT_LABELS.description} value={description} setValue={setDescription} requiredValue={true}/>
                    <CustomUploader labelSize='170px' label={INPUT_LABELS.images.title} value={images} setValue={setImages} requiredValue={true} message={INPUT_LABELS.images.message} notifyLabel={INPUT_LABELS.images.notifyLabel} allowedExtensions={INPUT_LABELS.images.allowedExtensions} maxFiles={INPUT_LABELS.images.maxFiles}/>
                    <CustomDropdown labelSize='170px' label={INPUT_LABELS.hear_us} values={ADVERTISEMENTS} value={hearOfUs} setValue={setHearOfUs}/>
                    <div className='list-a-property__buttonarea'>
                        <CustomRecaptcha recaptchaRef={recaptchaRef} isInvalid={recaptchaInvalid}/>
                        <div>
                            <ActionButton action={validateSubmit}>{BUTTON_LABEL}</ActionButton>
                        </div>
                    </div>
                    </form>
                }
                <Loading isVisble={loading}/>
                {
                    !loading && propertySent.status && <Alert><PropertySent /></Alert>
                }
                </div>
        </ContentWrapper>
    </div>
  )
}
function mapStateToProps({ listProperty,dictionary }) {
    return {
      propertySent: listProperty.propertySent,
      loading: listProperty.loading,
      floorPlanTypes: dictionary.floorPlanTypes
    };
  }
function mapDispatchToProps( dispatch ) {
    return {
      setDefault: _ => dispatch(setDefault())
    };
  }
export default connect(mapStateToProps,mapDispatchToProps)(ListAProperty)