import { PencilFill } from "react-bootstrap-icons";
import { connect } from "react-redux";
import ManageButton from './ManagAccountInfoButton'
const ProfileImage = ({ isLoading, profileImage, DefaultAvatar, setShowEditPhotoModal }) => {
    return (
            <div className="profile-image-container">
                <div className="profile-image">
                    <img alt="profile-image" src={profileImage ? `data:image/jpeg;base64,${profileImage}` : DefaultAvatar} />
                </div>
                <ManageButton isLoading={isLoading} handleMenuEvents={() => setShowEditPhotoModal(true)} label='Edit/Upload Photo'/>
            </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.userForm.isLoading,
        profileImage: state.userForm.profileImage,
    };
};

export default connect(mapStateToProps)(ProfileImage);