import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { loadUser } from "redux-oidc";
import reducer from "./reducer";
import userManager from "./utils/user-manager";
import { receiveAccessToken } from "./actions/auth";

// create the middleware with the userManager
// const oidcMiddleware = createOidcMiddleware(userManager);

const loggerMiddleware = store => next => action => {
  //console.log("Action type:", action.type);
  //console.log("Action payload:", action.payload);
  //console.log("State before:", store.getState());
  next(action);
  //console.log("State after:", store.getState());
};

const initialState = {};

const createStoreWithMiddleware = compose(
    applyMiddleware(loggerMiddleware, routerMiddleware(createBrowserHistory()))
)(createStore);

const store = createStoreWithMiddleware(reducer, initialState);

userManager.events.addUserLoaded(user => {
    store.dispatch(receiveAccessToken(user.access_token));
});

loadUser(store, userManager);

export default store;
