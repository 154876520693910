import {useRef,useEffect} from 'react'
import {connect} from 'react-redux'
import {useNavigate} from 'react-router-dom'

//components
import CustomRequirements from '../assets/controls/custom-requirements'

//constant
import {PHONE} from '../../constant/messages'

//utils
import {postSendRequest} from '../../utils/quick-request'
import {getDetailsByGoogle} from '../../utils/google-search'

//actions
import {
    setLocation,
    setMiles,
    setBudgetMax,
    setBudgetMin,
    setQtyAdults,
    setQtyChildren,
    setIsPets,
    setQtyPets,
    setMoveIn,
    setMoveOut,
    setDateRange,
    setCurrencyError,
    setCurrencyType,
    setBuildingTypes,
    setFloorPlan,
    setNotes,
    setGuestUser,
    setSelectedGuestId,
    setGuestPhone,
    setGuestEmail,
    setIsGuestCheckboxSelected,
    setIsExistingUser,
    setSelectedDataAmenities,
    setDataKeysAmenities,
    setIsGuestPhoneValidMessage,
    setIsGuestPhoneValidState,
    setGuestUserEmpty,
    setClearLocationField,
    setPetDetails,
    setMaidService,
    setParkingSlots
  } from '../../actions/quick-request'

//devextreme
import {ValidationGroup} from 'devextreme-react/validation-group'

import notify from 'devextreme/ui/notify';

const QuickRequestRequirements = ({
    loading,
    clearLocationField,
    currencyTypes,
    floorPlanTypes,
    amenities,
    fields,
    setLocation,
    setMiles,
    setBudgetMin,
    setBudgetMax,
    setQtyAdults,
    setQtyChildren,
    setIsPets,
    setQtyPets,
    setMoveIn,
    setMoveOut,
    setDateRange,
    setCurrencyError,
    setCurrencyType,
    setBuildingTypes,
    setFloorPlan,
    setNotes,
    setGuestUser,
    setGuestPhone,
    setGuestEmail,
    setIsGuestCheckboxSelected,
    setSelectedDataAmenities,
    setDataKeysAmenities,
    setIsGuestPhoneValidMessage,
    setIsGuestPhoneValidState,
    setGuestUserEmpty,
    setIsExistingUser,
    setSelectedGuestId,
    setClearLocationField,
    setPetDetails,
    setParkingSlots,
    setMaidService,
    disabled
  }) => {
  const navigate = useNavigate()
  const refValidationGroup = useRef(null)
  const onQuickRequest = async(e) => {
    e.preventDefault()
    if(await onRequestValidation(e))
    {
      let address = await getDetailsByGoogle(fields.location.id)
      if(address.isValid)
      {
        let {isValid,message,...rest} = address
        if(await postSendRequest(rest)) 
          navigate('/my-requests')
      }
      else
      {
        notify(address.message, "error" , 3000);
      }
    }
      
  }

  const onRequestValidation = async _ => {
    try {
        let isValid = true
        // isValid = e?.validationGroup?.validate()?.isValid
        isValid = refValidationGroup.current.instance.validate().isValid

        if((fields.budgetMin || fields.budgetMax) && !fields.currencyType)
        {
          setCurrencyError(true)
          isValid = false
          notify(`Missing required fields`, "error" , 3000);
        }
  
        if(fields.isGuestCheckBoxSelected)
        {
          if(!fields.guestPhone)
          {
            setIsGuestPhoneValidState(false)
            setIsGuestPhoneValidMessage(PHONE.required)
            isValid = false
            notify(`Missing required fields`, "error" , 3000);
          }
          if(!fields.isGuestPhoneValidState)
          {
            isValid = false
            notify(`Missing required fields`, "error" , 3000);
          }
          if(Object.keys(fields.guestUser).length <= 0 || fields.guestUser?.firstName.length <= 0 || fields.guestUser?.lastName.length <= 0 )
          {
            setGuestUserEmpty(true)
            isValid = false
            notify(`Missing required fields`, "error" , 3000);
          }
        }
        if(!isValid)
        {
          notify(`Missing required fields`, "error" , 3000);
        }
       
        return isValid

      } catch (error) {
        notify(`Something went wrong`, "error" , 3000);
        return false
      }
  }
  useEffect(() => {
    setClearLocationField(false)
  }, [])
  return (
    <form className='my-basket' onSubmit={onQuickRequest}>
        <ValidationGroup ref={refValidationGroup}>
            <CustomRequirements
                disabled={disabled}
                buttonMessage={true}
                isLoading={loading}
                clearLocationField = {clearLocationField}
                currencyTypes = {currencyTypes}
                floorPlanTypes = {floorPlanTypes}
                amenities = {amenities}
                fields = {fields}
                setLocation = {setLocation}
                setMiles = {setMiles}
                setBudgetMin = {setBudgetMin}
                setBudgetMax = {setBudgetMax}
                setQtyAdults = {setQtyAdults}
                setQtyChildren = {setQtyChildren}
                setIsPets = {setIsPets}
                setQtyPets = {setQtyPets}
                setMoveIn = {setMoveIn}
                setMoveOut = {setMoveOut}
                setDateRange = {setDateRange}
                setCurrencyError = {setCurrencyError}
                setCurrencyType = {setCurrencyType}
                setBuildingTypes = {setBuildingTypes}
                setFloorPlan = {setFloorPlan}
                setNotes = {setNotes}
                setGuestUser = {setGuestUser}
                setGuestPhone = {setGuestPhone}
                setGuestEmail = {setGuestEmail}
                setIsGuestCheckboxSelected = {setIsGuestCheckboxSelected}
                setSelectedDataAmenities = {setSelectedDataAmenities}
                setDataKeysAmenities = {setDataKeysAmenities}
                setIsGuestPhoneValidMessage = {setIsGuestPhoneValidMessage}
                setIsGuestPhoneValidState = {setIsGuestPhoneValidState}
                setGuestUserEmpty = {setGuestUserEmpty}
                setIsExistingUser = {setIsExistingUser}
                setSelectedGuestId = {setSelectedGuestId}
                setClearLocationField = {setClearLocationField}
                setPetDetails = {setPetDetails}
                setMaidService={setMaidService}
                setParkingSlots={setParkingSlots}
                action = {onRequestValidation}
                labelActionButton = 'Create quick request'
            />
        </ValidationGroup>
    </form>
  )
}
function mapStateToProps({ quickRequest }) {
  return {
    loading: quickRequest.loading,
    clearLocationField: quickRequest.clearLocationField,
    fields: quickRequest.fields
  };
}
function mapDispatchToProps( dispatch ) {
  return {
    setLocation: value => dispatch(setLocation(value)),
    setMiles: value => dispatch(setMiles(value)),
    setBudgetMax: value => dispatch(setBudgetMax(value)),
    setBudgetMin: value => dispatch(setBudgetMin(value)),
    setQtyAdults: value => dispatch(setQtyAdults(value)),
    setQtyChildren: value => dispatch(setQtyChildren(value)),

    setIsPets: value => dispatch(setIsPets(value)),
    setQtyPets: value => dispatch(setQtyPets(value)),
    setPetDetails: value => dispatch(setPetDetails(value)),

    setMoveIn: value => dispatch(setMoveIn(value)),
    setMoveOut: value => dispatch(setMoveOut(value)),
    setDateRange: value => dispatch(setDateRange(value)),

    setCurrencyError: value => dispatch(setCurrencyError(value)),
    setCurrencyType: value => dispatch(setCurrencyType(value)),

    setBuildingTypes: value => dispatch(setBuildingTypes(value)),
    setFloorPlan: value => dispatch(setFloorPlan(value)),

    setNotes: value => dispatch(setNotes(value)),

    setGuestUser: value => dispatch(setGuestUser(value)),
    setSelectedGuestId: value => dispatch(setSelectedGuestId(value)),
    setGuestUserEmpty: value => dispatch(setGuestUserEmpty(value)),

    setGuestPhone: value => dispatch(setGuestPhone(value)),
    setIsGuestPhoneValidMessage: value => dispatch(setIsGuestPhoneValidMessage(value)),
    setIsGuestPhoneValidState: value => dispatch(setIsGuestPhoneValidState(value)),

    setGuestEmail: value => dispatch(setGuestEmail(value)),

    setIsExistingUser: value => dispatch(setIsExistingUser(value)),
    setIsGuestCheckboxSelected: value => dispatch(setIsGuestCheckboxSelected(value)),

    setSelectedDataAmenities: value => dispatch(setSelectedDataAmenities(value)),
    setDataKeysAmenities: value => dispatch(setDataKeysAmenities(value)),
    setClearLocationField: value => dispatch(setClearLocationField(value)),
    setMaidService: value => dispatch(setMaidService(value)),
    setParkingSlots: value => dispatch(setParkingSlots(value))
  };
}
export default connect(mapStateToProps,mapDispatchToProps) (QuickRequestRequirements)
