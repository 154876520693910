import MyFavoritesContent from './my-favorites/my-favorites-content'

import { Navigate, useLocation } from 'react-router-dom';

import {isLoggedIn} from '../utils/user'
const MyFavorites = () => {
    const location = useLocation();

    return (
        isLoggedIn()  ? <MyFavoritesContent /> : <Navigate to="/login" state={{ from: location }} replace />
    );
}


  export default MyFavorites;