import {
    SET_FAVORITES,
    SET_PROPERTY_ID,
    SET_FAVORITES_TOTAL,
    SET_PAGES_FAVORITES,
    SET_LOADING_FAVORITES
} from "../constant/favorites";

const defaultState = {
    propertyId: '',
    favorites: [],
    favoritesTotal: 0,
    currentPage: 0,
    loading: false
};

export default function favoritesReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_FAVORITES:
            return {
                ...state,
                favorites: action.payload
            };
        case SET_PROPERTY_ID:
            return {
                ...state,
                propertyId: action.payload
            };
        case SET_PAGES_FAVORITES:
            return {
                ...state,
                currentPage: action.payload
            };
        case SET_FAVORITES_TOTAL:
            return {
                ...state,
                favoritesTotal: action.payload
            };
        case SET_LOADING_FAVORITES:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
}