import {useState,useCallback,useEffect} from "react";
import { connect } from "react-redux";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import userManager from "../utils/user-manager";
import isEmpty from "lodash/isEmpty";
import { AuthorizationService } from "../services/authorization.service";
import { isLoggedIn } from "../utils/user";
import { getIsAdmin } from '../utils/admin'
//images
import { ReactComponent as HeartIcon } from "../images/heart.svg";
//components
import Administration from './navbar/Sub-Components/administration'
import ReceivedRequests from './navbar/Sub-Components/received-requests'
import BasketCart from './navbar/Sub-Components/basket-cart'
const AuthNav = ({ idToken,userProfile }) => {
	const location = useLocation();
	const pathName = location.pathname;
	const navigate = useNavigate();
	const [menuOpen, setMenuOpen] = useState(false);
	const [isAdmin, setisAdmin] = useState()
	const [isLogged, setisLogged] = useState()
	const toggleMenu = () => setMenuOpen(!menuOpen);
	const [iconColor, setIconColor] = useState("text-white");
	
	useEffect(() => {
		if (pathName === "/") {
			const handleScroll = () => (window.scrollY > 695) ? setIconColor("text-dark") : setIconColor("text-white");
			window.addEventListener("scroll", handleScroll);
			return () => window.removeEventListener("scroll", handleScroll);
		} else {
			setIconColor("text-dark");
		}
	}, [pathName]);

	const logout = () => {
		toggleMenu();
		AuthorizationService.logout();
		userManager.signoutRedirect({ "id_token_hint": idToken });
		// navigate("/");
	}

	const showProfile = () => {
		navigate("/profile");
		toggleMenu();
	}

	const scrollToTop = () => {
		window.scrollTo(0, 0);
		toggleMenu();
	};
	const scrollToTopOtherIcons = () => window.scrollTo(0, 0);
	const myFavoritesAction = _ => {
		scrollToTopOtherIcons()
		navigate('my-favorites')
	} 

	  useEffect(() => {
		setisAdmin(getIsAdmin())
	  }, [userProfile])
	  
	return isLoggedIn() ? 
			<div className="auth-container">
				{isAdmin && 
				<>
					<Administration iconColor={iconColor}/>
					<ReceivedRequests iconColor={iconColor}/>
				</>}
				<BasketCart iconColor={iconColor}/>
				<Navbar.Collapse>
					<Nav>
						<NavDropdown title={<i className={`fas fa-user ${iconColor}`}></i>} id="basic-nav-dropdown" className="auth-nav-styles" show={menuOpen} onToggle={toggleMenu}>
							<Nav.Link as={Link} to={"/profile"} title={"Profile"} onClick={showProfile}>
								Profile
							</Nav.Link>
							<div style={{cursor: 'pointer', color: 'rgba(0,0,0,.9)', padding: '0px 0.5rem'}} onClick={logout}>
								Logout
							</div>
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
				<Nav>
					{/* <Nav.Link as={Link} to="/" onClick={scrollToTopOtherIcons}>
						<i className={`fas fa-shopping-cart ${iconColor}`}></i>
					</Nav.Link>
					<Nav.Link as={Link} to="/" onClick={scrollToTopOtherIcons}>
						<i className={`fas fa-heart ${iconColor}`}></i>
					</Nav.Link>  */}
					<HeartIcon style={{ fill: iconColor === 'text-white' ? '#fff' : '#000', cursor: 'pointer' }} onClick={myFavoritesAction}/>
				</Nav>
			</div>
			:
			<div className="auth-container">
			<Navbar.Collapse>
					<Nav>
						<NavDropdown title={<i className={`fas fa-user ${iconColor}`}></i>} id="basic-nav-dropdown" className="auth-nav-styles" show={menuOpen} onToggle={toggleMenu}>
							<Nav.Link as={Link} to="/login" title="Login" onClick={scrollToTop}>
								Login
							</Nav.Link>
							<Nav.Link as={Link} to="/account/create" title="Register" onClick={scrollToTop}>
								Register
							</Nav.Link>
						</NavDropdown>
					</Nav>
			</Navbar.Collapse>
			</div>
}

const mapStateToProps = ({ auth }) => {
	return {
		idToken: auth.idToken,
		userProfile: auth.userProfile
	}
};

function mapDispatchToProps(dispatch) {
	return {
		dispatch
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthNav);