import './styles/footer-styles.css'
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

import ChpaLogo from '../../../images/landing/chpa-logo-light.png';
import WorldLogo from '../../../images/landing/world-wide-logo.png';
import './styles/footer-styles.css';

const Footer = () => {
    const scrollToTop = () => window.scrollTo(0, 0);
    return ( 
    <Navbar sticky="bottom" expand="md" className='bg-dark box-shadow custom-footer'>
        <div className="container-fluid custom-footer__container">
            <div className="custom-footer__container__images">
                <a href="https://www.chpaonline.org/" target="_blank"><img src={ChpaLogo} className="custom-footer__container__images__chpa-logo" alt='ChpaLogo'/></a>
                <a href=" http://www.worldwideerc.org/Pages/index.aspx" target="_blank"><img src={WorldLogo} className="custom-footer__container__images__world-wide-logo" /></a>
            </div>
            <div className="custom-footer__container__info">
                <div className="custom-footer__container__info__footer-nav">
                    <Link to="/faq" onClick={scrollToTop}><span>FAQ</span>&nbsp;&nbsp;|&nbsp;</Link>
                    <Link to="/terms-conditions"><span>TERMS &amp; CONDITIONS</span>&nbsp;&nbsp;|&nbsp;</Link>
                    <Link to="/privacy-policy"><span>PRIVACY POLICY</span></Link>
                </div>
                <p className="custom-footer__container__info__phone"><i className="fa fa-phone" aria-hidden="true" />&nbsp;&#40;800&#41; 578-0256</p>
                <p className="custom-footer__container__info__copyright">&copy; 2014-{new Date().getFullYear()} Oasis Software, LLC. All rights reserved.</p>
            </div>
        </div>
    </Navbar>
    )
}

export default Footer;