import {
    CHANGE_LOCATION,
    CHANGE_CHECKIN,
    CHANGE_CHECKOUT,
} from "../constant/landing";

export const changeLocation = locationValue => ({
    type: CHANGE_LOCATION,
    payload: locationValue
});

export const changeCheckIn = checkIn => ({
    type: CHANGE_CHECKIN,
    payload: checkIn 
});

export const changeCheckOut = checkOut => ({
    type: CHANGE_CHECKOUT,
    payload: checkOut 
});
