import {
    SET_CURRENCY_TYPES,
    SET_FLOOR_PLAN_TYPES,
    SET_AMENITIES,
    SET_ALL_AMENITIES,
    SET_DICT_BUILDING_TYPES
} from "../constant/dictionary";

export const setCurrencyTypes = data => ({
    type: SET_CURRENCY_TYPES,
    payload: data
});

export const setFloorPlanTypes = data => ({
    type: SET_FLOOR_PLAN_TYPES,
    payload: data
});

export const setAmenities = data => ({
    type: SET_AMENITIES,
    payload: data
});

export const setAllAmenities = data => ({
    type: SET_ALL_AMENITIES,
    payload: data
});

export const setDictBuildingTypes = data => ({
    type: SET_DICT_BUILDING_TYPES,
    payload: data
})