import {useState,useEffect} from 'react'
import notify from 'devextreme/ui/notify';
import {connect} from 'react-redux'

//components
import NavTabs from '../../assets/controls/nav-tabs'

import SectionHeader from '../../assets/elements/section-header'
import ContentWrapper from '../../assets/elements/content-wrapper'
import Title from '../../assets/elements/section-title'
import ActiveButton from '../../assets/elements/button/action-button'
import HistorySection from './career-list-admin-history'
import ActiveSection from './career-list-admin-active'
import CustomPopup from '../../assets/elements/custom-popup'
import CareerForm from './career-list-form'

//constants
import {HEADER,CAREER_ADMIN} from '../../../constant/career'
//styles
import '../styles/career-list.css'

const CareerListAdmin = ({careerAdminFormMessage}) => {
    const [currentNavTab, setCurrentNavTab] = useState()
    const [visible,setVisible] = useState(false)
    const [type, setType] = useState('')
    const [id, setId] = useState('')
    const onHiding = (type,id) => {
      setVisible(!visible)
      setId(id)
      setType(type)
    }
    useEffect(() => {
      careerAdminFormMessage.content && notify(careerAdminFormMessage.content, careerAdminFormMessage.error ? "error" : "success", 3000);
    }, [careerAdminFormMessage])
    
  return (
      <div className="section-content-wrapper">
          <SectionHeader title={HEADER.title} subtitle={HEADER.subtitle} image={HEADER.img}/>
          <ContentWrapper>
            <div className='career-list'>
                <div className='career-list__title'>
                  <Title>{CAREER_ADMIN.title}</Title>
                </div>
                <div className='career-list__nav-tabs'>
                  <NavTabs tabs={CAREER_ADMIN.navbar} setCurrent={setCurrentNavTab} current={currentNavTab}/>
                  <ActiveButton action={()=>onHiding('create')}>{CAREER_ADMIN.buttonCreate}</ActiveButton>
                </div> 
                {currentNavTab === CAREER_ADMIN.navbar.firstTab && <ActiveSection action={onHiding}/> }
                {currentNavTab === CAREER_ADMIN.navbar.secondTab &&<HistorySection /> }          
            </div>
            <CustomPopup visible={visible} onHiding={onHiding}>
              <CareerForm id={id} type={type} onHiding={onHiding}/>
            </CustomPopup>
          </ContentWrapper>
     </div>
  )
}
function mapStateToProps({ career }) {
  return {
    careerAdminFormMessage: career.careerAdminFormMessage,
  };
}
export default connect(mapStateToProps)(CareerListAdmin)