import {
    updateFormEmail,
    updateFormUserName,
    updateFormFirstName,
    updateFormLastName,
    updateFormPhoneNumber,
    updateFormPassword,
    updateFormConfirmPassword,
    clearFormData,
    setLengthValidation,
    setLowerValidation,
    setUpperValidation,
    setNumberValidation,
    setSpecialValidation,
    setCaptchaChecked,
    setCaptchaExpired,
    setCaptchaError,
    setPhoneValidState,
    setPhoneValidMessage,
} from "../actions/create-account";
import store from "../store";

/* Form Data */
export const updateEmail = (e) => {
    store.dispatch(updateFormEmail(e));
};

export const updateUserName = (e) => {
    store.dispatch(updateFormUserName(e));
};

export const updateFirstName = (e) => {
    store.dispatch(updateFormFirstName(e));
};

export const updateLastName = (e) => {
    store.dispatch(updateFormLastName(e));
};

export const updatePhoneNumber = (e) => {
    store.dispatch(updateFormPhoneNumber(e));
};

export const updatePassword = (e) => {
    store.dispatch(updateFormPassword(e));
};

export const updateConfirmPassword = (e) => {
    store.dispatch(updateFormConfirmPassword(e));
};

export const updateFormData = (e) => {
    store.dispatch(clearFormData(e));
};

/* RegEx Validation */
export const setLengthValid = (isValid) => {
    store.dispatch(setLengthValidation(isValid));
};

export const setUpperValid = (isValid) => {
    store.dispatch(setUpperValidation(isValid));
};

export const setLowerValid = (isValid) => {
    store.dispatch(setLowerValidation(isValid));
};

export const setNumberValid = (isValid) => {
    store.dispatch(setNumberValidation(isValid));
};

export const setSpecialValid = (isValid) => {
    store.dispatch(setSpecialValidation(isValid));
};

/* Validation Icons */
const lengthRegex = /.{8,}/;
const upperRegex = /[A-Z]/;
const lowerRegex = /[a-z]/;
const numberRegex = /\d/;
const specialRegex = /[°¬¡¿~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

export const handlePasswordChange = (value) => {
    updatePassword(value);
    setLengthValid(lengthRegex.test(value));
    setUpperValid(upperRegex.test(value));
    setLowerValid(lowerRegex.test(value));
    setNumberValid(numberRegex.test(value));
    setSpecialValid(specialRegex.test(value));
};

/* Recaptcha */
export const setIsCaptchaChecked = (isValid) => {
    store.dispatch(setCaptchaChecked(isValid));
}

export const setIsCaptchaExpired = (isValid) => {
    store.dispatch(setCaptchaExpired(isValid));
}

export const setIsCaptchaErrorVisible = (isValid) => {
    store.dispatch(setCaptchaError(isValid));
}

/* Phone Validation */
export const setIsPhoneValidState = (isValid) => {
    store.dispatch(setPhoneValidState(isValid));
}

export const setIsPhoneValidMessage = (isValid) => {
    store.dispatch(setPhoneValidMessage(isValid));
}