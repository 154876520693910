import { useState, useEffect } from 'react'
import RequestHelper from '../../../utils/helper'
import moment from 'moment'

const FormattedDate = ({fdate}) => {
  const [dateRenovationFormatted, setDateRenovationFormatted] = useState('')

    useEffect(() => {
        setDateRenovationFormatted(`${fdate && moment(fdate).format(RequestHelper.getLocalDateFormat())}`)
  }, [fdate])
  
  return (
    <>Recently renovated - {dateRenovationFormatted}</>
  )
}

export default FormattedDate