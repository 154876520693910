import {
    SET_BASKET,
    SET_MY_BASKET_TIMER,
    SET_LOADING_BASKET,
    SET_LOADING_SEND_REQUEST,
    SET_DATA_TO_CHECK,
    SET_ALL_BASKET,
    SET_BASKET_DEFAULT,

    SET_CLEAR_LOCATION_FIELD,
    SET_LOCATION_BASKET,
    SET_MILES,
    SET_FLOOR_PLAN,
    SET_CURRENCY_TYPE,
    SET_CURRENCY_ERROR,
    SET_BUILDING_TYPES,
    SET_NOTES,

    SET_BUDGET_MIN,
    SET_BUDGET_MAX,

    SET_QTY_ADULTS,
    SET_QTY_CHILDREN,

    SET_IS_PETS,
    SET_QTY_PETS,
    SET_PET_DETAILS,
    // SET_PET_TYPE,
    // SET_PET_WEIGHT,
    // SET_PET_WEIGHT_TYPE,

    SET_MOVE_IN,
    SET_MOVE_OUT,
    SET_DATE_RANGE,

    SET_SELECTED_GUEST_ID,
    SET_GUEST_USER,
    SET_GUEST_USER_EMPTY,
    SET_GUEST_PHONE,
    SET_IS_GUEST_PHONE_VALID_STATE,
    SET_IS_GUEST_PHONE_VALID_MESSAGE,
    SET_GUEST_EMAIL,
    SET_IS_EXISTING_USER,
    SET_IS_GUEST_CHECKBOX_SELECTED,

    SET_DATA_KEYS_AMENITIES,
    SET_SELECTED_DATA_AMENITIES,
    SET_NO_MATCH,
    SET_MAID_SERVICE,
    SET_PARKING_SLOTS
} from "../constant/my-basket";

import {DEFAULT_CURRENCY} from '../constant/dictionary'

const defaultState = {
    basket: {
        totalCount: 0,
        data: []
    },
    basketTimer: null,
    noMatch: false,
    allBasket: {
        totalCount: 0,
        data: []
    },
    loading: false,
    loadingSendRequest: false,
    dataToCheck:{},
    clearLocationField: true,
    fields: {
        location:{
            id: '',
            value: ''
        },
        miles:10,
        parkingSlots: null,
        maidService: null,
        budgetMin: null,
        budgetMax: null,
        qtyAdults: null,
        qtyChildren: null,
        
        isPets:false,
        qtyPets: null,
        petDetails:[],
        // petType:'',
        // petWeight:'',
        // petWeightType:{id: 2, name: "kg"},
        
        moveIn:'',
        moveOut:'',
        dateRange: [null,null],
    
        floorPlan:[],
        currencyType: DEFAULT_CURRENCY,
        currencyError:false,
        buildingTypes:[],
    
        notes:'',
        guestUser:{},
        selectedGuestId: null,
        isGuestUserEmpty: false,
        guestPhone:'',
        isGuestPhoneValidState: true,
        isGuestPhoneValidMessage: '',
        guestEmail:'',
        isExistingUser:false,
        isGuestCheckBoxSelected:false,
        dataKeysAmenities:[],
        selectedDataAmenities:[]
    }
};

export default function basketReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_MAID_SERVICE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    maidService: action.payload
                }
            };
        case SET_PARKING_SLOTS:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    parkingSlots: action.payload
                }
            };

        case SET_BASKET:
            return {
                ...state,
                basket: action.payload
            };
        case SET_MY_BASKET_TIMER:
            return {
                ...state,
                basketTimer: action.payload
            };
        case SET_NO_MATCH:
            return {
                ...state,
                noMatch: action.payload
            };
        case SET_CLEAR_LOCATION_FIELD:
            return {
                ...state,
                clearLocationField: action.payload
            };
        case SET_LOADING_BASKET:
            return {
                ...state,
                loading: action.payload
            };
        case SET_LOADING_SEND_REQUEST:
            return {
                ...state,
                loadingSendRequest: action.payload
            };
        case SET_DATA_TO_CHECK:
            return {
                ...state,
                dataToCheck: action.payload
            };
        case SET_ALL_BASKET:
            return {
                ...state,
                allBasket: action.payload
            };
        case SET_LOCATION_BASKET:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    location: action.payload
                }
            };
        case SET_MILES:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    miles: action.payload
                }
            };
        case SET_FLOOR_PLAN:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    floorPlan: action.payload
                }
            };
        case SET_CURRENCY_TYPE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    currencyType: action.payload
                }
            };
        case SET_CURRENCY_ERROR:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    currencyError: action.payload
                }
            };
        case SET_BUILDING_TYPES:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    buildingTypes: action.payload
                }
            };
        case SET_NOTES:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    notes: action.payload
                }
            };

        case SET_BUDGET_MIN:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    budgetMin: action.payload
                }
            };
        case SET_BUDGET_MAX:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    budgetMax: action.payload
                }
            };

        case SET_QTY_ADULTS:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    qtyAdults: action.payload
                }
            };
        case SET_QTY_CHILDREN:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    qtyChildren: action.payload
                }
            };

        case SET_IS_PETS:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isPets: action.payload
                }
            };
        case SET_QTY_PETS:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    qtyPets: action.payload
                }
            };
        case SET_PET_DETAILS:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    petDetails: action.payload
                }
            };
        // case SET_PET_TYPE:
        //     return {
        //         ...state,
        //         fields: {
        //             ...state.fields,
        //             petDetails: state.fields.petDetails.map(pet => {
        //                 if(pet.id === action.payload.id)
        //                 {
        //                     return {
        //                         ...pet,
        //                         petType: action.payload.value
        //                     }
        //                 }
        //                 else
        //                     return pet
        //             })
        //         }
        //     };
        // case SET_PET_WEIGHT:
        //     return {
        //         ...state,
        //         fields: {
        //             ...state.fields,
        //             petWeight: action.payload
        //         }
        //     };
        // case SET_PET_WEIGHT_TYPE:
        //     return {
        //         ...state,
        //         fields: {
        //             ...state.fields,
        //             petWeightType: action.payload
        //         }
        //     };

        case SET_MOVE_IN:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    moveIn: action.payload
                }
            };
        case SET_MOVE_OUT:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    moveOut: action.payload
                }
            };

        case SET_DATE_RANGE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dateRange: action.payload
                }
            };

        case SET_GUEST_USER:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    guestUser: action.payload
                }
            };
        case SET_GUEST_USER_EMPTY:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isGuestUserEmpty: action.payload
                }
            };
        case SET_GUEST_PHONE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    guestPhone: action.payload
                }
            };
        case SET_IS_GUEST_PHONE_VALID_MESSAGE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isGuestPhoneValidMessage: action.payload
                }
            };
        case SET_IS_GUEST_PHONE_VALID_STATE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isGuestPhoneValidState: action.payload
                }
        };
        case SET_GUEST_EMAIL:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    guestEmail: action.payload
                }
            };
        case SET_IS_EXISTING_USER:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isExistingUser: action.payload
                }
            };
        case SET_IS_GUEST_CHECKBOX_SELECTED:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    isGuestCheckBoxSelected: action.payload
                }
            };
        case SET_DATA_KEYS_AMENITIES:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    dataKeysAmenities: action.payload
                }
            };
        case SET_SELECTED_DATA_AMENITIES:
            return {
            ...state,
            fields: {
                ...state.fields,
                selectedDataAmenities: action.payload
            }
            };

        case SET_SELECTED_GUEST_ID:
            return {
            ...state,
            fields: {
                ...state.fields,
                selectedGuestId: action.payload
            }
            };
        case SET_BASKET_DEFAULT:
            return defaultState;
        default:
            return state;
    }
}