import {
    RECEIVE_PROFILE_DATA,
    RECEIVE_ACCESS_TOKEN,
    RECEIVE_REFRESH_TOKEN,
    RECEIVE_ID_TOKEN,
    RECEIVE_USER_PROFILE_DATA,
    LOGOUT
} from "../constant/auth";

export const receiveProfileData = profile => ({
    type: RECEIVE_PROFILE_DATA,
    payload: { profile }
});

export const receiveUserProfileData = userProfile => ({
    type: RECEIVE_USER_PROFILE_DATA,
    payload: { userProfile }
});

export const receiveAccessToken = accessToken => ({
    type: RECEIVE_ACCESS_TOKEN,
    payload: { accessToken }
});

export const receiveRefreshToken = refreshToken => ({
    type: RECEIVE_REFRESH_TOKEN,
    payload: { refreshToken }
});

export const receiveIdToken = idToken => ({
    type: RECEIVE_ID_TOKEN,
    payload: { idToken }
});

export const logout = () => ({
    type: LOGOUT
});
