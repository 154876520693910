export const DEFAULT_VALUES = {
    floor:[],
    miles: 20
}

export const PET_WEIGHT = [
    {id: 1, name: 'kg'},
    {id: 2, name: 'lb'}
]

export const FLOORS = [
    /*{
        id: '',
        name: 'All floor plans',
    },*/
    {
        id: 1,
        name: 'Studio / 1 Bathroom'
    },
    {
        id: 2,
        name: '1 Bedroom / 1 Bathroom',
    },
    {
        id: 3,
        name: '2 Bedroom / 1 Bathroom'
    },
    {
        id: 4,
        name: '2 Bedroom / 2 Bathroom',
    },
    {
        id: 5,
        name: '3 Bedroom / 2 Bathroom'
    },
    {
        id: 6,
        name: '4 Bedroom / 2.5 Bathroom',
    },
    {
        id: 7,
        name: '> 4 Bedroom'
    },
    {
        id: 8,
        name: '1 Bedroom / 1 Bathroom with Loft (den)',
    },
    {
        id: 9,
        name: '2 Bedroom / 2 Bathroom with Loft (den)'
    },
    {
        id: 10,
        name: '3 Bedroom / 1 Bathroom'
    },
    {
        id: 11,
        name: '3 Bedroom / 3 Bathroom'
    },
    {
        id: 12,
        name: '1 Bedroom / 1.5 Bathroom'
    },
    {
        id: 13,
        name: '2 Bedroom / 1.5 Bathroom'
    },
    {
        id: 14,
        name: '2 Bedroom / 2.5 Bathroom'
    },
    {
        id: 15,
        name: '3 Bedroom / 2.5 Bathroom'
    },
    {
        id: 16,
        name: '3 Bedroom / 4 Bathroom'
    }
    
]



export const MILES = [
    {
        id: 5,
        name: '5 Miles'
    },
    {
        id: 10,
        name: '10 Miles'
    },
    {
        id: 15,
        name: '15 Miles'
    },
    {
        id: 20,
        name: '20 Miles'
    },
    {
        id: 25,
        name: '25 Miles'
    },
    {
        id: 50,
        name: '50 Miles'
    }
]

export const ADVERTISEMENTS = [
    {
        id: 1,
        name: 'AirBnB'
    },
    {
        id: 2,
        name: 'Conference'
    },
    {
        id: 3,
        name: 'Direct Mailer'
    },
    {
        id: 4,
        name: 'Google Search'
    },
    {
        id: 5,
        name: 'LinkedIn'
    },
    {
        id: 6,
        name: 'Oasis Website'
    },
    {
        id: 7,
        name: 'Other Distribution Platform'
    },
    {
        id: 8,
        name: 'Press'
    },
    {
        id: 9,
        name: 'Referral'
    },
    {
        id: 10,
        name: 'Other'
    },
]