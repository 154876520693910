import {
    CHANGE_LOCATION,
    // CHANGE_GEOMETRY,
    CHANGE_CHECKIN,
    CHANGE_CHECKOUT,
    CHANGE_FLOOR,
    CHANGE_BUILDING_TYPES,
    CHANGE_PETS_FRIENDLY,
    CHANGE_MILES,
    CHANGE_PAGES,
    CHANGE_MESSAGE,
    CLEAR_PAGES,
    CHANGE_LOADING_FILTER,
    CHANGE_FILTER_TOTAL_PROPERTIES,
    CLEAR_DATA,
    PREVIOUS_URL,
    SET_PROPERTY_MAP,
    SET_FILTER_PROPERTIES,
    SET_PROPERTY_FROM_MAP,
    SET_PROPERTY_FROM_LIST,
    SET_CARD_TYPE,
    SET_PROPERTY_SEARCH_VIEW,
    SET_SWITCH_LOCATION_SEARCH,
    SET_CLEAR_LOCATION_FIELD,
    SET_MAP_LOADING,
    SET_CURRENT_MAP,
    SET_CURRENT_MAP_ZOOM,
    SET_CURRENT_MAP_CENTER,
    SET_CURRENT_MAP_MARKERS
} from "../constant/property-search";

import { DEFAULT_VALUES } from '../constant/pagination'

const defaultState = {
    url: '',
    location: {
        id: '',
        value: ''
    },
    checkIn: '',
    petsFriendly: false,
    buildingTypes: [],
    checkOut: '',
    floor: [],
    miles: 10,
    pages: {
        skip: DEFAULT_VALUES.skip,
        take: DEFAULT_VALUES.take
    },
    message: '',
    loading: false,
    filterProperties: [],
    filterTotalProperties: 0,
    propertyMap: 
    {
        type: null,
        data: []
    },
    selectedPropertyFromList: '',
    selectedPropertyFromMap: '',
    switchLocationSearch: {
      type:  'field',
      value: null
    },
    baseUrl: process.env.REACT_APP_IMG_BASE_URL,
    cardType: 'square',
    propertySearchView: 'grid',
    clearLocationField: false,
    loadindMap: false,
    currentMap: {},
    currentMapZoom: 10,
    currentMapCenter: null,
    currentMapMarkers: []
};

export default function propertySearchReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_CURRENT_MAP:
            return {
                ...state,
                currentMap: action.payload
            };

        case SET_CURRENT_MAP_CENTER:
            return {
                ...state,
                currentMapCenter: action.payload
            };
        case SET_CURRENT_MAP_ZOOM:
            return {
                ...state,
                currentMapZoom: action.payload
            };
        case SET_CURRENT_MAP_MARKERS:
            return {
                ...state,
                currentMapMarkers: action.payload
            };
    
        case SET_MAP_LOADING:
            return {
                ...state,
                loadingMap: action.payload
            };
        case CHANGE_LOCATION:
            return {
                ...state,
                location: action.payload
            };
        case SET_CLEAR_LOCATION_FIELD:
            return {
                ...state,
                clearLocationField: action.payload
            };
        case SET_PROPERTY_FROM_MAP:
            return {
                ...state,
                selectedPropertyFromMap: action.payload
            };
        case SET_PROPERTY_SEARCH_VIEW:
            return {
                ...state,
                propertySearchView: action.payload
            };
        case SET_PROPERTY_FROM_LIST:
            return {
                ...state,
                selectedPropertyFromList: action.payload
            };
        // case CHANGE_GEOMETRY:
        //     return {
        //         ...state,
        //         geometry: action.payload
        //     };
        case CHANGE_CHECKIN:
            return {
                ...state,
                checkIn: action.payload
            };
        case CHANGE_CHECKOUT:
            return {
                ...state,
                checkOut: action.payload
            };
        case CHANGE_FLOOR:
            return {
                ...state,
                floor: action.payload
            };
        case CHANGE_MILES:
            return {
                ...state,
                miles: action.payload
            };
        case CHANGE_BUILDING_TYPES:
            return {
                ...state,
                buildingTypes: action.payload
            };
        case CHANGE_PETS_FRIENDLY:
            return {
                ...state,
                petsFriendly: action.payload
            };
        case CHANGE_LOADING_FILTER:
            return {
                ...state,
                loading: action.payload
            }
        case CHANGE_FILTER_TOTAL_PROPERTIES:
            return {
                ...state,
                filterTotalProperties: action.payload
            }
        case CHANGE_PAGES:
            return {
                ...state,
                pages: action.payload
            }
        case CHANGE_MESSAGE:
            return {
                ...state,
                message: action.payload
            }
        case PREVIOUS_URL:
            return {
                ...state,
                url: action.payload
            }
        case SET_FILTER_PROPERTIES:
            return {
                ...state,
                loading: false,
                filterProperties: action.payload
            };
        case SET_PROPERTY_MAP:
            return {
                ...state,
                propertyMap: action.payload
            }
        case SET_CARD_TYPE:
            return {
                ...state,
                cardType: action.payload
            }
        case SET_SWITCH_LOCATION_SEARCH:
            return {
                ...state,
                switchLocationSearch: action.payload
            }
        case CLEAR_DATA:
            return {
                ...state,
                url: '',
                location: {
                    id: '',
                    value: ''
                },
                checkIn: '',
                checkOut: '',
                floor: [],
                miles: 10,
                pages: {
                    skip: DEFAULT_VALUES.skip,
                    take: DEFAULT_VALUES.take
                },
                loading: false,
                filterProperties: [],
                filterTotalProperties: 0,
                propertyMap: {
                    type: null,
                    data: []
                },
                petsFriendly: false,
                buildingTypes: [],
                selectedPropertyFromList: '',
                selectedPropertyFromMap: ''
            }
        case CLEAR_PAGES:
            return {
                ...state,
                pages: {
                    skip: DEFAULT_VALUES.skip,
                    take: DEFAULT_VALUES.take
                }
            }
        default:
            return state;
    }
}