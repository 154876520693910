import {useEffect,useState} from 'react'
import {useParams,useNavigate} from 'react-router-dom'
import {connect} from 'react-redux'
import notify from 'devextreme/ui/notify';

//components
import SectionHeader from '../assets/elements/section-header'
import ContentWrapper from '../assets/elements/content-wrapper'
import ActionButton from '../assets/elements/button/action-button'
import CustomText from '../assets/elements/input/custom-text'
import CustomHtmlEditor from '../assets/elements/input/custom-html-editor'
import CustomEmail from '../assets/elements/input/custom-email'
import CustomPhone from '../assets/elements/input/custom-phone'
import CustomUploader from '../assets/elements/input/custom-uploader'
import CustomDropdown from '../assets/elements/input/custom-dropdown'
import Loading from '../assets/controls/loading'
//constants
import {CAREER_APPLY} from '../../constant/career'
import {ADVERTISEMENTS} from '../../constant/dropdown'
import {PHONE} from '../../constant/messages'
//actions
import {setFirstname,setLastname,setEmail,setHearUs,setPhone,setIsPhoneValidState,setIsPhoneValidMessage,setCoverLetter,setResume, setDefaultApply, setDefaultApplyMessage} from '../../actions/career'
//utils
import {postVacancyApply} from '../../utils/career'
//styles
import './styles/career-apply.css'

const CareerApply = ({careerApply,setFirstname,setLastname,setEmail,setHearUs,setPhone,setIsPhoneValidState,setIsPhoneValidMessage,setCoverLetter,setResume,setDefault,setDefaultMessage}) => {
  const params = useParams()
  const [isEmpty, setIsEmpty] = useState(false)
  const navigate = useNavigate()
  const submitApplication = e => {
    e.preventDefault()
    if(validateSubmit())
        postVacancyApply(params.id)
  }
  const validateSubmit = _ => {
    let response = true
    if(!careerApply.phone)
    {
        setIsPhoneValidState(false)
        setIsPhoneValidMessage(PHONE.required)
        response = false
    }
    if(!careerApply.hearUs)
    {
        setIsEmpty(true)
        response = false
    }

    return response

  }
  useEffect(() => {
    careerApply.message?.description && notify(careerApply.message.description, careerApply.message.status ? "error" : "success", 3000);
    careerApply.message?.description && !careerApply.message.status && navigate(`/careers/details/${params.id}`)
  }, [careerApply.message])
  useEffect(() => {  
    return () => {
        setDefault()
        setDefaultMessage()
    }
  }, [])

  return (
      <div className="section-content-wrapper">
        <SectionHeader title={CAREER_APPLY.title} subtitle={CAREER_APPLY.subtitle} image={CAREER_APPLY.img}/>
        <ContentWrapper>
            <div className='career-apply'>
                {/* <Loading isVisble={careerApply.loading}/> */}
                {/* {!careerApply.loading &&  */}
                    <form onSubmit={submitApplication}>
                    <CustomText 
                        label={CAREER_APPLY.form.firstname} 
                        value={careerApply.firstname} 
                        setValue={setFirstname} 
                        requiredValue={true}/>
                    <CustomText 
                        label={CAREER_APPLY.form.lastname} 
                        value={careerApply.lastname} 
                        setValue={setLastname} 
                        requiredValue={true}/>
                    <CustomEmail 
                        label={CAREER_APPLY.form.email} 
                        value={careerApply.email} 
                        setValue={setEmail} 
                        requiredValue={true}/>
                    <CustomPhone 
                        label={CAREER_APPLY.form.phone} 
                        value={careerApply.phone} 
                        setValue={setPhone} 
                        requiredValue={true}
                        isValid={careerApply.isPhoneValidState}
                        setIsValid={setIsPhoneValidState}
                        validMessage={careerApply.isPhoneValidMessage}
                        setValidMessage={setIsPhoneValidMessage}
                        />
                    <CustomUploader 
                        label={CAREER_APPLY.form.pdf.title} 
                        value={careerApply.resume} 
                        setValue={setResume} 
                        message={CAREER_APPLY.form.pdf.message}
                        requiredValue={true} 
                        notifyLabel={CAREER_APPLY.form.pdf.notifyLabel} 
                        allowedExtensions={CAREER_APPLY.form.pdf.allowedExtensions} 
                        maxFiles={CAREER_APPLY.form.pdf.maxFiles}/>
                    <CustomHtmlEditor 
                        label={CAREER_APPLY.form.cover} 
                        value={careerApply.coverLetter} 
                        setValue={setCoverLetter} 
                        requiredValue={true}/>
                    <CustomDropdown 
                        label={CAREER_APPLY.form.hearUs} 
                        values={ADVERTISEMENTS}
                        value={careerApply.hearUs} 
                        setValue={setHearUs} 
                        requiredValue={true}
                        isEmpty={isEmpty}
                        setIsEmpty={setIsEmpty}
                    />
                    <div className='career-apply__button'>
                        <ActionButton action={validateSubmit} disabled={careerApply.loading}>{CAREER_APPLY.buttonName}</ActionButton>
                        <Loading isVisble={careerApply.loading} size={30}/>
                    </div>
                    </form>
                {/* } */}
            </div>
        </ContentWrapper>
    </div>
  )
}
function mapStateToProps({ career }) {
    return {
        careerApply: career.careerApply
    };
  }
function mapDispatchToProps(dispatch){
    return{
        setFirstname: data => dispatch(setFirstname(data)),
        setLastname: data => dispatch(setLastname(data)),
        setEmail: data => dispatch(setEmail(data)),
        setHearUs: data => dispatch(setHearUs(data)),
        setPhone: data => dispatch(setPhone(data)),
        setIsPhoneValidState: data => dispatch(setIsPhoneValidState(data)),setIsPhoneValidMessage: data => dispatch(setIsPhoneValidMessage(data)),
        setCoverLetter: data => dispatch(setCoverLetter(data)),
        setResume: data => dispatch(setResume(data)),
        setDefault: () => dispatch(setDefaultApply()),
        setDefaultMessage: () => dispatch(setDefaultApplyMessage())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CareerApply)