//import baseService from '../services/base.service'
import {
    fetchDataRequest,
    fetchDataSuccess,
    // fetchDataError
} from '../actions/faq'
import store from '../store'
import faqData from '../components/faq/data/faq-data'

// export const fetchAccordionData = () => {
//     store.dispatch(fetchDataRequest())
//     baseService({
//         method: 'GET',
//         url: `${process.env.REACT_APP_ACCORDION}/faq-data.json`,
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     }).then(response => {
//         const data = response.data
//         store.dispatch(fetchDataSuccess(data))
//     }).catch(error => {
//         store.dispatch(fetchDataError(error.message))
//     })
// }

export const fetchAccordionData = () => {
    store.dispatch(fetchDataRequest())
    store.dispatch(fetchDataSuccess(faqData))
}