export const REQUEST_STATUS = Object.freeze({
        New : 1,
        Open : 2,
        Quoted : 3,
        PendingReservation : 4,
        ReservationCreated : 5,
        PendingBooking : 6,
        Booked : 7,
        NotBooked : 8,
        Expired : 9,
        Declined : 10,
        PendingExtension : 11,
        Extended : 12,
        PendingTermination : 13,
        Terminated : 14,
        Completed : 15,
        Cancelled: 16,
        CancelledByManager:18,
});

export const OPTION_STATUS = Object.freeze({
        New : 1,
        Quoted : 2,
        DeclinedByRecipient : 3,
        DeclinedBySupplier : 4,
        PendingSupplier : 5,
        PaperworkInProgress : 6,
        Booked : 7,
        NotBooked : 8,
        DeclinedByClient : 9,
        PendingManager : 10
})