import {useEffect} from 'react'
import { useLocation,useNavigate } from "react-router-dom";

//utils
import userManager from '../../../utils/user-manager'

//style
import '../styles/not_logged_message.css'

//images
import WarningIcon from '../../../images/warning_icon.svg'

const NotLoggedMessage = ({message,visible,setVisible,resetTrigger}) => {
  const location = useLocation();
  const navigate = useNavigate()
  let timeOutId

  const handleLoginRedirect = (e) => {
    e.preventDefault();
    e.stopPropagation()
    userManager.signinRedirect({ state: { redirectTo: location.pathname } });
  };
  const handleRegister = (e) => {
    e.preventDefault();
    e.stopPropagation()

    navigate('/account/create')
  };
  useEffect(() => {
    if(visible)
    {
        clearTimeout(timeOutId)
        timeOutId = setTimeout(() => {
            setVisible(false)
        }, 6000);
    }
    return () => {
        clearTimeout(timeOutId)
    }
  }, [visible,resetTrigger])
  
  return (
    <div className='not-logged-message'>
        <div className={`not-logged-message__content ${visible ? 'not-logged-message__content--active' : 'not-logged-message__content--inactive'}`}>
            <img src={WarningIcon}/>
            <span>Please,</span>
            <span className='not-logged-message__content__link' onClick={handleLoginRedirect}>
                Login
            </span> 
            <span>or</span>
            <span className='not-logged-message__content__link' onClick={handleRegister}>
                Register
            </span>
            <span>{message}</span>
        </div>
        
    </div>
  )
}

export default NotLoggedMessage