import {useState,useEffect} from 'react'
//images
import ArrowIcon from '../../../images/arraw.svg'
//styles
import '../styles/request-accordion.css'
const RequestAccordion = ({id,children,title,isActive,filter,setFilter,isFilterActive}) => {
  const [active, setActive] = useState(false)
  useEffect(() => {
    setActive(isActive)
  }, [isActive])
  
  return (
    <div id={id} key={id}>
      <div className='request-accordion__wrapper'>
        <div onClick={_=>setActive(!active)} className='request-accordion__title'>
          <div>{title}</div> 
          <div className={active ? 'request-accordion__title--active' : 'request-accordion__title--inactive'}><img src={ArrowIcon}/></div>
        </div>
        <div className='request-accordion__wrapper__filter' style={setFilter && isFilterActive ? {} : {display:'none'} }>
          <div className='request-accordion__wrapper__filter__title'>Filter:</div>
          <div className={`request-accordion__wrapper__filter__button ${setFilter && filter === false ? 'request-accordion__wrapper__filter__button--active' : ''}`} onClick={_=>setFilter(false)}>All</div>
          <div className={`request-accordion__wrapper__filter__button ${setFilter && filter === true ? 'request-accordion__wrapper__filter__button--active' : ''}`} onClick={_=>setFilter(true)}>Booked</div>
        </div>
      </div>
        
        <div className={`request-accordion__content ${active ? 'request-accordion__content--active' : 'request-accordion__content--inactive' }`}>
            {children}
        </div>
    </div>
  )
}

export default RequestAccordion