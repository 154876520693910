import {useState} from 'react'
//components
import PrimaryButton from '../../assets/elements/button/action-button'
import SecondaryButton from '../../assets/elements/button/secondary-action-button'
import CustomEmail from '../../assets/elements/input/custom-email'
import CustomPhone from '../../assets/elements/input/custom-phone'
import CustomText from '../../assets/elements/input/custom-text'

//constants
import {PHONE} from '../../../constant/messages'

//utils
import {onRequestPartnership} from '../../../utils/become-a-partner'

//styles
import '../styles/contact.css'

const Contact = ({type,action}) => {
  const [companyName, setCompanyName] = useState('')
  const [ceoname, setCeoName] = useState('')
  const [primaryContact, setPrimaryContact] = useState('')
  const [phone, setPhone] = useState('')
  const [ceoemail,setCeoEmail] = useState('')
  const [primaryContactEmail,setPrimaryContactEmail] = useState('')
  const [isPhoneValidState, setIsPhoneValidState] = useState(true)
  const [isPhoneValidMessage,setIsPhoneValidMessage] = useState('')
  const onSubmit = e => {
    e.preventDefault()
    if(isPhoneValidState && phone)
    {
      onRequestPartnership(JSON.stringify({
          "partnershipType": type.id,
          "ceoName": ceoname,
          "companyName": companyName,
          "primaryContact": primaryContact,
          "telephoneNumber": phone,
          "ceoEmail": ceoemail,
          "primaryContactEmail": primaryContactEmail
        }))
      action()
    }
  }
  const onCancel = () => {
    action()
  }
  const validateSubmit = _ => {
    if(!phone)
    {
        setIsPhoneValidState(false)
        setIsPhoneValidMessage(PHONE.required)
    }
  }
  return (
    <form onSubmit={onSubmit} className='contact-info'>
        <div className='contact-info__title'>
            REQUEST A {type.name} PARTNERSHIP
        </div>
        <div className='contact-info__fields'>
            <CustomText label='Company Name' value={companyName} setValue={setCompanyName} requiredValue={true} />
            <CustomText label='CEO Name' value={ceoname} setValue={setCeoName} requiredValue={true} />
            <CustomPhone 
              label='Telephone Number' 
              value={phone} 
              setValue={setPhone} 
              requiredValue={true}
              isValid={isPhoneValidState}
              setIsValid={setIsPhoneValidState}
              validMessage={isPhoneValidMessage}
              setValidMessage={setIsPhoneValidMessage}
              />
            <CustomEmail label='CEO Email' value={ceoemail} setValue={setCeoEmail} requiredValue={true}/>
            <CustomText label='Primary Contact' value={primaryContact} setValue={setPrimaryContact} requiredValue={true}/>
            <CustomEmail label='Primary Contact Email' value={primaryContactEmail} setValue={setPrimaryContactEmail} requiredValue={true}/>
        </div>
        <div className='contact-info__buttons'>
            <PrimaryButton action={validateSubmit}>Send</PrimaryButton>
            <SecondaryButton action={onCancel}>Cancel</SecondaryButton>
        </div>
    </form>
  )
}

export default Contact