import {useState} from 'react'
//styles
import '../styles/partner-levels-card.css'
//components
import ActionButton from '../../assets/elements/button/action-button'
import CustomPopup from '../../assets/elements/custom-popup'
import Contact from '../contact/contact'
const PartnerLevelsCard = ({data}) => {
  const [visible, setVisible] = useState(false)
  const [type,setType] = useState({})
  const onHiding = (title,type) => {
    setType({
        id: type,
        name: title
    })
    setVisible(!visible)
  }
  return (
    <div className='partner-level-card'>
        <div className={`partner-level-card__title ${data.button.title === 'GOLD' ? 'partner-level-card__title--gold' : (data.button.title === 'SILVER' ? 'partner-level-card__title--silver' : (data.button.title === 'BRONZE' ? 'partner-level-card__title--bronze' : 'partner-level-card__title--platinum'))}`}>
            {data.title} PARTNERS
        </div>
        <div className='partner-level-card__content'>
            <ul>
                {data.points.map( (point,index) => 
                    <li key={index} dangerouslySetInnerHTML={{__html: point}}/>
                )}
            </ul>
        </div>
        <div className='partner-level-card__buttonArea'>
            {data.button.required ? 
                <ActionButton action={()=>onHiding(data.title,data.type)}>{`Request a ${data.button.title} Partnership`}</ActionButton>
                :
                <span>{data.button.title}</span>
            }
        </div>
        <CustomPopup visible={visible} onHiding={onHiding}>
            <Contact type={type} action={onHiding}/>
        </CustomPopup>
    </div>
  )
}

export default PartnerLevelsCard