import {useRef,useState,useEffect,useCallback} from 'react'
//styles
import '../styles/slider.css'
//images
import LeftActive from '../../../images/landing/arrows-landing/left-active.png'
import RightActive from '../../../images/landing/arrows-landing/right-active.png'
import LeftPassive from '../../../images/landing/arrows-landing/left-passive.png'
import RightPassive from '../../../images/landing/arrows-landing/right-passive.png'

const Slider = ({data,action, arrowHeight, arrowWidth}) => {
  const slideRef = useRef()
  const [controllersVisible, setControllersVisible] = useState(true)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [maxPosition, setMaxPosition ] = useState()
  
  const scrollSlider = (type) =>{
    switch (type) {
        case 'prev':
            scrollPosition > 0 && (slideRef.current.scrollLeft -= 125)
            break;
        case 'next':
            scrollPosition < (slideRef.current.scrollWidth - slideRef.current.clientWidth) && (slideRef.current.scrollLeft += 125)
            break;
        default:
            break;
    }        
  }
  const autoScrollSlider = e => {
    // setScrollPosition()
    setScrollPosition(e.currentTarget.scrollLeft)
  }

  const sliderRefFunc = useCallback (ref => {
    if(slideRef.current)
      slideRef.current=null
    if(ref)
    {      
      setMaxPosition(ref.scrollWidth - ref.clientWidth)
      if(ref.scrollWidth > ref.clientWidth)
        setControllersVisible(true)
      else
        setControllersVisible(false)
      
      slideRef.current = ref
    }
  },[])

  const windowResize = useCallback (_ => {
    if(slideRef.current.scrollWidth > slideRef.current.clientWidth)
        setControllersVisible(true)
      else
        setControllersVisible(false)
  })

  useEffect(() => {
    window.addEventListener('resize',windowResize)
    return () => {
      window.removeEventListener('resize',windowResize)
    }
  }, [])
  
  return (
    <div className='custom-slider-container'>
        {controllersVisible && <img className='custom-slider-container__arrow' onClick={()=>scrollSlider('prev')} src={scrollPosition <= 0 ? LeftPassive : LeftActive} alt="" height={arrowHeight} width={arrowWidth}/>}
        <div className='slider-container__images' ref={ref => sliderRefFunc(ref)} onScroll={autoScrollSlider}>
            {data.map((item,index)=>
                <div className='slider-container__images__image'>
                  <img key={index} onClick={()=>action(index)} src={item}/>
                </div>
            )}
        </div>
        {controllersVisible && <img className='custom-slider-container__arrow' onClick={()=>scrollSlider('next')} src={scrollPosition >= maxPosition ? RightPassive : RightActive} alt="" height={arrowHeight} width={arrowWidth}/>}
    </div>
  )
}

export default Slider