import React from 'react'
import FaqAccordion from './Sub-Components/Faq-Accordion'
import './styles/accordion-styles.css'

const FAQComponent = () => {
    return (
        <section className='faq'>
            <div className='row'>
                <div className='col'>
                    <div className='faq-bg'>
                        <h1 className='faq-text'>faq</h1>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <div className='faq-accordion'>
                        <FaqAccordion />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQComponent