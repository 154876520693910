import {
    SET_ALL_REQUESTS,
    SET_REQUESTS_DATA,
    SET_PREVIOUS_REQUESTS_URL,
    SET_ALL_REQUESTS_IS_LOADED,
    SET_REQUESTS_LOADING,
    SET_REQUESTS_MESSAGE_NO_DATA
} from "../constant/received-requests";

const defaultState = {
    allRequests: {},
    requestsData: {},
    previousUrl: '',
    messageNoData: false,
    loading: false,
    allRequestsIsLoaded: false
};

export default function propertyDetailsReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_ALL_REQUESTS:
            return {
                ...state,
                allRequests: action.payload
            };
        case SET_REQUESTS_DATA:
            return {
                ...state,
                requestsData: action.payload
            };
        case SET_PREVIOUS_REQUESTS_URL:
            return {
                ...state,
                previousUrl: action.payload
            };
        case SET_ALL_REQUESTS_IS_LOADED:
            return {
                ...state,
                allRequestsIsLoaded: action.payload
            };
        case SET_REQUESTS_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case SET_REQUESTS_MESSAGE_NO_DATA:
            return {
                ...state,
                messageNoData: action.payload
            };
        default:
            return state;
    }
}