import {useEffect,useRef} from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

//components
import ContentWrapper from '../../assets/elements/content-wrapper'
import Map from '../../assets/controls/map/map-one-location'
import Wrapper from '../../assets/controls/google-wrapper'
import Title from './sub-components/title'
import Gallery from './sub-components/property-images'
import MainInformation from './sub-components/property-main-information'
import SectionTitle from '../../assets/elements/section-subtitle'
import AmenitiesTags from './sub-components/amenities/amenities-tags'
import AmenitiesList from './sub-components/amenities/amenities-list'
import FloorPlans from './sub-components/floor-plans'
import DownloadButton from './sub-components/download-button'
import Loading from '../../assets/controls/loading'

//utils
import {getRequestOptionDetails} from '../../../utils/my-requests'

//images
import RenovatedIcon from '../../../images/renovated.png'

// styles
import '../styles/details-main.css'

const DetailsMain = ({optionDetails,optionLoading}) => {
  const params = useParams()
  const refPage = useRef(null)
  const refMap = useRef(null)
  useEffect(() => {
    // console.log(params,'params')
    getRequestOptionDetails(params.requestId,params.optionId)
  }, [params])

  return (
    <ContentWrapper>
        {Object.keys(optionDetails).length > 0 && !optionLoading &&
            <div ref={refPage} className='request-option__details-main__container'>
              <div className='request-option__details-main__container__title-area'>
                <Title 
                    requestId={params.requestId} 
                    optionId={params.optionId} 
                    address={optionDetails.option.propertyName} 
                    buildingType={optionDetails.option.unitType } 
                    isCertified={optionDetails.option.isOasisCertified} 
                    renovatedDate={optionDetails.option.recentlyRenovatedFormatted}
                />
                <DownloadButton pageRef={refPage}/>
              </div>
               
                <div className='request-option__details-main__container__main-information'>
                    <div className='request-option__details-main__container__main-information__gallery'>
                        <Gallery 
                            images={[...optionDetails.option.propertyImages, ...optionDetails.option.propertyFloorPlanImages]}
                            propertySize={optionDetails.option.propertyImages.length}
                            floorSize={optionDetails.option.propertyFloorPlanImages.length}
                        />
                    </div>
                    
                    <div className='request-option__details-main__container__main-information__information'>
                    <MainInformation data={optionDetails} refMap={refMap}/>
                    </div>
                </div>
                <div className='request-option__details-main__container__main-information__amenities'>
                  <SectionTitle>Amenities</SectionTitle>
                  <div className='request-option__details-main__container__main-information__amenities__content'>
                    <AmenitiesTags tags={optionDetails.option.tags}/>
                    <div className='request-option__details-main__container__main-information__amenities__content__section'>
                      <AmenitiesList list={optionDetails.option.formattedOptionAmenities}/>
                      <div className='request-option__details-main__container__main-information__amenities__content__section__item'>
                        <div>Additional Features:</div>
                        <div>{optionDetails.option.additionalFeatures ? optionDetails.option.additionalFeatures : <span className='request-option__details-main__container__main-information__amenities__content__section__item__missing'>Missing</span>}</div>
                      </div>
                    </div>
                  </div>
                </div>
                {optionDetails.option.propertyDescription && <div className='request-option__details-main__container__main-information__description'>
                  <SectionTitle>Property Description</SectionTitle>
                  <div dangerouslySetInnerHTML={{__html: optionDetails.option.propertyDescription}}/>
                </div>}
                {(optionDetails.option.recentlyRenovatedFormatted || optionDetails.option.renovationDetails) && <div className='request-option__details-main__container__main-information__renovation'>
                  <SectionTitle>Renovation</SectionTitle>
                  {optionDetails.option.recentlyRenovatedFormatted && <div className='request-option__details-main__container__main-information__renovation__date'><img src={RenovatedIcon}/><span>{optionDetails.option.recentlyRenovatedFormatted}</span></div>}
                  {optionDetails.option.renovationDetails && <div dangerouslySetInnerHTML={{__html: optionDetails.option.renovationDetails}}/>}
                </div>}
                <div className='request-option__details-main__container__main-information__terms'>
                  <SectionTitle>Terms</SectionTitle>
                  <div>Provided by request</div>
                </div>
                {optionDetails.option.propertyFloorPlanImages.length > 0 &&
                  <div className='request-option__details-main__container__main-information__floor-plans'>
                    <SectionTitle>Floor Plans</SectionTitle>
                    <FloorPlans slidesData={optionDetails.option.propertyFloorPlanImages.map(item => item.imageUrl)}/>
                  </div>
                }
                <div ref={refMap} className='request-option__details-main__container__main-information__location'>
                  <SectionTitle>Location</SectionTitle>
                  <Wrapper>
                    <Map latitude={optionDetails.option.latitude} longitude={optionDetails.option.longitude} name={optionDetails.option.propertyName}/>
                  </Wrapper>
                </div>
            </div>
        } 
        <Loading isVisble={optionLoading}/>
    </ContentWrapper>
  )
}

function mapStateToProps({ requests }) {
    return {
      optionDetails: requests.optionDetails,
      optionLoading: requests.optionLoading
    };
  }
//   function mapDispatchToProps(dispatch) {
//     return {
//       setRecommendedPropertiesData: () => dispatch(setRecommendedPropertiesData([]))
//     }
//   }
  export default connect(mapStateToProps)(DetailsMain)